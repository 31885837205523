import Home from '@material-ui/icons/Home'
import React from 'react'

export default {
  additionnalRoutes: ['dashboard', 'apps', 'groups'],
  hiddenEntities: [
    'Authorization',
    'Group',
    'PassportConfiguration',
    'Process',
    'AgentBehaviour',
    'Scenario',
    'Simulation',
    'Grid',
    'UserTask',
  ],
  menu: {
    simulator: {
      hidden: true,
    },
    authentication: {
      iconDisplayed: <Home />,
      submenu: {
        Passport: {
          iconDisplayed: <Home />,
        },
      },
    },
    cartography: {
      submenu: {},
    },
    navigation: {
      hidden: true,
    },
    missions: {
      hidden: true,
    },
    geolocation: {
      submenu: {
        Zone: {
          hidden: true,
        },
      },
    },
    videomanager: {
      submenu: {
        LiveStream: {
          hidden: true,
        },
        PlaybackStream: {
          hidden: true,
        },
      },
    },
    alarms: {
      submenu: {
        Attachment: {
          hidden: true,
        },
      },
    },
    flow: {
      submenu: {
        Process: {
          hidden: false,
        },
      },
    },
  },
}
