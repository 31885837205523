// @ts-nocheck
import { useContext, useCallback } from 'react'
import { AuthContext, useNotify, useLogoutIfAccessDenied } from 'react-admin'

const defaultError = {
  message: 'Fetch error',
  status: 400,
}

const useErrorFetch = () => {
  const authContext = useContext(AuthContext)
  const notify = useNotify()
  const logout = useLogoutIfAccessDenied()

  const memoizedFetchError = useCallback(
    async (error = defaultError) => {
      try {
        // if its authorization error return Promise.reject()
        await authContext.checkError(error)

        const message = typeof error === 'string' ? error : error.message

        const UNDOABLE = false

        notify(message, 'warning', {}, UNDOABLE)
      } catch {
        // catch authorization error, show notification, clear state, redirect to /login
        logout(error)
      }
    },
    [authContext, logout, notify],
  )

  return memoizedFetchError
}

export default useErrorFetch
