import React from 'react'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'

import {
  TEntityOverride,
  TEntityOverrideProp,
  TEntityOverridePropGetValue,
} from 'src/types'
import { AgentActivityToPDF } from 'src/parser/shared/PDF/ReportToPdf'
import { IAgentActivityReport } from '@obvious.tech/constellation'
import { CustomDatePicker } from './CustomDatePicker'

type TProps = {
  property: TEntityOverrideProp<IAgentActivityReport>
  record: IAgentActivityReport
  name: string
}

const DateTimeField = (props: TProps): JSX.Element | null => {
  const timestamp = props.record?.checkOut?.timestamp

  if (typeof timestamp !== 'string') {
    return <span>Not Checked Out</span>
  }

  const date = new Date(timestamp)

  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date)

  return <span>{formattedDate}</span>
}

const LinkToPDFReport = (props: TProps): JSX.Element | null => {
  if (typeof props.record.id !== 'string' || props.record.checkOut === null)
    return <div></div>
  const { id } = props.record

  return (
    <Link to={`/units/api/AgentActivityReport/${id}`}>
      <Chip
        size='small'
        style={{
          backgroundColor: '#a6dff3',
        }}
        onClick={e => e.stopPropagation()}
        label='Report'
      />
    </Link>
  )
}

const DatePickerFilter = (): JSX.Element => {
  return (
    <div
      style={{
        marginTop: 65,
        marginLeft: 10,
      }}
    >
      <CustomDatePicker />
    </div>
  )
}

const getTimestamp: TEntityOverridePropGetValue<
  IAgentActivityReport,
  'checkIn' | 'checkOut',
  Date | undefined
> = ({ name, record }) => {
  return record[name]?.timestamp
}

const override: TEntityOverride<IAgentActivityReport, '$PDFReport'> = {
  titleField: 'name',
  extraProperties: {
    $PDFReport: {
      type: 'undefined',
      readOnly: true,
      displayedName: 'PDF report',
      displayedComponent: LinkToPDFReport,
    },
  },
  global: {
    order: [
      'id',
      'passportId',
      '$PDFReport',
      'checkIn',
      'checkOut',
      'geofenceReference',
      'groups',
    ],
    properties: {
      id: {
        displayedName: 'ID',
      },
      groups: {
        displayedName: 'Owners',
        type: 'groups',
      },
      passportId: {
        displayedName: 'Username',
        referenceTo: 'authentication/api/Passport',
        referencedField: 'username',
      },
      checkIn: {
        displayedName: 'Checked In',
      },
      checkOut: {
        displayedName: 'Checked Out',
      },
      geofenceReference: {
        displayedName: 'Geofence',
      },
    },
  },
  list: {
    properties: {
      checkIn: {
        type: 'date-time',
        getValue: getTimestamp,
      },
      checkOut: {
        type: 'date-time',
        getValue: getTimestamp,
        displayedComponent: DateTimeField,
      },
      geofenceReference: {
        type: 'string',
        referenceSource: 'geofenceReference.id',
        referenceTo: 'geolocation/api/Geofence',
        referencedField: 'name',
      },
    },
    getFilters: props => {
      const { dateFrom, dateTo, passportId } = props

      const filters = {}
      if (dateTo !== undefined) {
        filters['checkIn.timestamp<='] = new Date(dateTo).toISOString()
      }
      if (dateFrom !== undefined) {
        filters['checkOut.timestamp>='] = new Date(dateFrom).toISOString()
      }
      if (passportId !== undefined) {
        filters['passportId=='] = passportId
      }

      return filters
    },
    renderRightColumn: () => <DatePickerFilter />,
  },
  edit: {
    displayedComponent: AgentActivityToPDF,
  },
  create: {
    hidden: true,
  },
}

export default override
