// @ts-nocheck

import React from 'react'
import { Authenticated } from 'react-admin'

export default function withAuthentication<Props>(
  Component: React.JSXElementConstructor<Props>,
) {
  return (props: Props) => (
    <Authenticated>
      <Component {...props} />
    </Authenticated>
  )
}
