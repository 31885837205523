import React from 'react'
import { View, StyleSheet, Text, Image, Link } from '@react-pdf/renderer'

import * as theme from 'src/parser/shared/PDF/theme'
import Section from 'src/parser/shared/PDF/Section'
import { TAlarmReportProps } from 'src/parser/shared/PDF/Provider'
import { TAttachmentWithURI } from './Provider/useAttachments'

const styles = StyleSheet.create({
  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  itemName: {
    ...theme.styles.subtitle,
    textTransform: undefined,
  },
  itemImage: {
    marginTop: theme.padding(1),
    height: 300,
    alignSelf: 'flex-start',
  },
})

export type TProps = TAlarmReportProps

const Attachments = ({ attachments, additionalDetails }: TProps) => {
  const { audioUrls = [], videoUrls = [], imageUrls = [] } = additionalDetails

  const getImageAttachmentData = (attachment: TAttachmentWithURI) => {
    const splittedAttachment = attachment.file?.name?.split('.')
    const name = `${attachment.file?.name} (${attachment.id.slice(0, 8)})`
    const format = splittedAttachment?.length
      ? splittedAttachment[splittedAttachment.length - 1]
      : 'jpg'
    const data = attachment.dataURI

    return {
      name,
      format,
      data: data?.includes('image/pjpeg')
        ? data.replace('image/pjpeg', 'image/jpeg')
        : data,
    }
  }

  const getAVAttachmentData = (attachment: TAttachmentWithURI) =>
    `${attachment.file?.name} (${attachment.id.slice(0, 8)})`

  const imageAttachments = attachments
    .filter(({ type }) => type === 'Image')
    .map(getImageAttachmentData)
  const videoAttachments = attachments
    .filter(({ type }) => type === 'Video')
    .map(getAVAttachmentData)
  const audioAttachments = attachments
    .filter(({ type }) => type === 'Audio')
    .map(getAVAttachmentData)

  const getResourceNameFromUrl = (url = '') => {
    const index = url.lastIndexOf('/') + 1
    const name = url.substr(index)

    return name
  }

  return (
    <Section title='alarm_report.attachments.title'>
      {imageAttachments.map(({ data, name }, i) => (
        <View
          wrap={false}
          key={`${name}_${i}`}
          style={styles.item}
        >
          <Text style={styles.itemName}>{name}</Text>
          {data && (
            <Image
              source={data}
              style={styles.itemImage}
            />
          )}
        </View>
      ))}
      {imageUrls?.map((imageUrl, i) => (
        <View
          wrap={false}
          key={`${imageUrl}_${i}`}
          style={styles.item}
        >
          <Text style={styles.itemName}>
            {getResourceNameFromUrl(imageUrl)}
            {'\n'}
            <Link src={imageUrl}>{imageUrl}</Link>
          </Text>
          <Image
            src={imageUrl}
            style={styles.itemImage}
          />
        </View>
      ))}
      {videoAttachments.map((name, i) => (
        <View
          wrap={false}
          key={`${name}_${i}`}
          style={styles.item}
        >
          <Text style={styles.itemName}>{name}</Text>
        </View>
      ))}
      {videoUrls.map((videoUrl, i) => (
        <View
          wrap={false}
          key={`${videoUrl}_${i}`}
          style={styles.item}
        >
          <Text style={styles.itemName}>
            {getResourceNameFromUrl(videoUrl)}
            {'\n'}
            <Link src={videoUrl}>{videoUrl}</Link>
          </Text>
        </View>
      ))}
      {audioAttachments.map((name, i) => (
        <View
          wrap={false}
          key={`${name}_${i}`}
          style={styles.item}
        >
          <Text style={styles.itemName}>{name}</Text>
        </View>
      ))}
      {audioUrls.map((audioUrl, i) => (
        <View
          wrap={false}
          key={`${audioUrl}_${i}`}
          style={styles.item}
        >
          <Text style={styles.itemName}>
            {getResourceNameFromUrl(audioUrl)}
            {'\n'}
            <Link src={audioUrl}>{audioUrl}</Link>
          </Text>
        </View>
      ))}
    </Section>
  )
}

export default Attachments
