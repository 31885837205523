import Marker from './Marker/marker'
import Agent from './Agent/agent'
import Channel from './Channel/channel'
import Image from './Image/image'
import Passport from './Passport/passport'
import AccessBadge from './AccessBadge/accessBadge'
import AccessBadgeCarrier from './AccessBadgeCarrier/accessBadgeCarrier'
import PointOfView from './PointOfView/pointOfView'
import Alarm from './Alarm/alarm'
import Attachment from './Attachment/attachment'
import Category from './Category/category'
import Certainty from './Certainty/certainty'
import Severity from './Severity/severity'
import Status from './Status/status'
import AccessNotification from './AccessNotification/accessNotification'
import AccessPoint from './AccessPoint/accessPoint'
import Acs from './Acs/acs'
import Diagram from './Diagram/diagram'
import Logs from './Logs/logs'
import Module from './Module/module'
import Process from './Process/process'
import UserTask from './UserTask/userTask'
import UserTaskList from './UserTaskList/userTaskList'
import ProductLicense from './ProductLicense/license'
import Camera from './Camera/camera'
import VideoExport from './VideoExport/videoExport'
import Vms from './Vms/vms'
import Mission from './Mission/mission'
import Map from './Map/map'
import Scenario from './Scenario/scenario'
import Track from './Track/track'
import NotificationType from './NotificationType/notificationType'
import Template from './Template/template'
import Connector from './Connector/connector'
import RoleTemplate from './RoleTemplate/roleTemplate'
import Role from './Role/role'
import AgentStatus from './AgentStatus/agentStatus'
import AgentActivityReport from './AgentActivityReport/agentActivityReport'
import AlarmSystem from './AlarmSystem/alarmSystem'
import AlarmLog from './AlarmLog/alarmLog'
import VariableMessageSign from './VariableMessageSign/variableMessageSign'
import SipPhoneConfiguration from './SipPhoneConfiguration/sipPhoneConfiguration'
import SipPhoneContact from './SipPhoneContact/sipPhoneContact'
import Notification from './Notification/notification'
import Node from './Node/node'
import Layer from './Layer/Layer'
import Drawing from './Drawing/drawing'
import Geofence from './Geofence/geofence'

export default {
  AccessBadge,
  AccessBadgeCarrier,
  AccessNotification,
  AccessPoint,
  Acs,
  Agent,
  Alarm,
  Attachment,
  Camera,
  Category,
  Certainty,
  Channel,
  Connector,
  Diagram,
  Image,
  Logs,
  Map,
  Marker,
  Mission,
  Module,
  NotificationType,
  Passport,
  PointOfView,
  Process,
  ProductLicense,
  Scenario,
  Severity,
  Status,
  Template,
  Track,
  UserTask,
  UserTaskList,
  VideoExport,
  Vms,
  RoleTemplate,
  Role,
  AgentStatus,
  AgentActivityReport,
  AlarmSystem,
  Log: AlarmLog,
  VariableMessageSign,
  SipPhoneConfiguration,
  SipPhoneContact,
  Notification,
  Node,
  Layer,
  Drawing,
  Geofence,
}
