import React, { useState } from 'react'
import {
  ListItem,
  Checkbox,
  TextField,
  makeStyles,
  FormControlLabel,
  List,
  Collapse,
  ListItemText,
  Box,
} from '@material-ui/core'

import { ExpandLess, ExpandMore } from '@material-ui/icons'

import { definitions } from 'src/adminPanel/Flow/components/Flow'
import BasicArrayInput from 'src/components/inputs/arrays/BasicArrayInput'
import ObjectArrayInput from 'src/components/inputs/arrays/ObjectArrayInput'

const useStyles = makeStyles(theme => ({
  formList: {
    width: '100%',
  },
  listItem: {
    padding: 0,
    width: '100%',
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
}))

interface ISimulationFormProps {
  entity: string
  value: {
    [key: string]: any
  }
  onChange: (data: any) => void
}

interface IRef {
  $ref: string
}

interface IProperty {
  name: string
  type: 'string' | 'number' | 'boolean' | 'object' | 'array'
  description: string
  entity: string
  allOf?: IRef[]
  items?: {
    type?: string
    $ref?: string
  }
}

const SimulationForm = ({
  entity,
  value = {},
  onChange,
}: ISimulationFormProps): null | JSX.Element => {
  const classes = useStyles()

  const [open, setOpen] = useState<{ [key: string]: boolean }>({})

  if (window.isNullish(entity)) {
    return null
  }

  const getField = (property: IProperty): JSX.Element | null => {
    switch (property.type) {
      case 'string':
        return (
          <TextField
            onChange={e => {
              onChange({
                ...value,
                [property.name]: e.target.value,
              })
            }}
            className={classes.input}
            id={`textfield_${property.name}`}
            label={property.name}
            defaultValue={value[property.name] ?? ''}
          />
        )
      case 'number':
        return (
          <TextField
            onChange={e => {
              const number =
                e.target.value === '' || isNaN(parseFloat(e.target.value))
                  ? 0
                  : parseFloat(e.target.value)

              onChange({
                ...value,
                [property.name]: number,
              })
            }}
            value={value[property.name]}
            className={classes.input}
            id={`textfield_${property.name}`}
            label={property.name}
            defaultValue={value[property.name]}
          />
        )
      case 'boolean':
        return (
          <FormControlLabel
            className={classes.input}
            control={
              <Checkbox
                checked={value[property.name] ?? false}
                onChange={e => {
                  onChange({
                    ...value,
                    [property.name]: e.target.checked,
                  })
                }}
                color='primary'
              />
            }
            label={property.name}
          />
        )
      case 'array': {
        const objectArrayType: boolean = !window.isNullish(property.items?.$ref)

        const onArrayInputChange = (array: any[]): void => {
          onChange({
            ...value,
            [property.name]: array,
          })
        }

        if (objectArrayType) {
          return (
            <Box className={classes.input}>
              <ObjectArrayInput
                name={property.name}
                value={value[property.name] ?? []}
                onChange={onArrayInputChange}
                itemProps={{
                  entity: property.entity ?? getEntityReference(property),
                }}
                property={{
                  items: {
                    displayedComponent: SimulationForm,
                  },
                }}
              />
            </Box>
          )
        }

        return (
          <BasicArrayInput
            classes={classes.input}
            property={{
              defaultValue: [],
            }}
            name={property.name}
            value={value[property.name]}
            onChange={onArrayInputChange}
          />
        )
      }
      case 'object':
        return (
          <Box width='100%'>
            <ListItem
              button
              onClick={() => {
                setOpen(open => ({
                  ...open,
                  [property.name]: !open[property.name],
                }))
              }}
            >
              <ListItemText primary={property.name} />
              {open[property.name] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={open[property.name]}
              timeout='auto'
              unmountOnExit
            >
              <Box paddingLeft={2}>
                <List
                  component='div'
                  disablePadding
                >
                  <SimulationForm
                    entity={property.entity ?? getEntityReference(property)}
                    value={value[property.name] ?? {}}
                    onChange={change => {
                      onChange({
                        ...value,
                        [property.name]: change,
                      })
                    }}
                  />
                </List>
              </Box>
            </Collapse>
          </Box>
        )

      default:
        return null
    }
  }

  const getEntityReference = ({
    allOf,
    items,
  }: IProperty): string | undefined => {
    if (window.isNullish(allOf ?? items)) return

    const $ref = allOf?.[0].$ref ?? items?.$ref

    if (window.isNullish($ref)) return

    const referenceEntity = $ref.split('/').pop()
    return referenceEntity
  }

  return (
    <List
      component='ul'
      className={classes.formList}
      disablePadding
    >
      {definitions.entities?.[entity]?.properties.map((property: IProperty) => (
        <ListItem
          className={classes.listItem}
          key={property.name}
        >
          {getField(property)}
        </ListItem>
      ))}
    </List>
  )
}

export default SimulationForm
