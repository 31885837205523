import { makeStyles } from '@material-ui/core/styles'
import ImagePreview from 'src/components/ImagePreview'
import React, { useState } from 'react'
import { ImageInput } from 'react-admin'

import { IInputProps } from 'src/types'
import { toDataURL } from 'src/utils/file'

export const useImageInputStyles = makeStyles({
  root: {
    '& .previews': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  removeButton: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
})
const useStyles = makeStyles({
  previewImage: {
    flex: 1,
    objectFit: 'contain',
    height: '200px',
    maxWidth: '100%',
  },
})

const transformFile = (file: File | string) => {
  if (typeof file === 'string') {
    return {
      url: file,
    }
  }
  if (!(file instanceof File)) return file
  const preview = URL.createObjectURL(file)
  const transformedFile = {
    rawFile: file,
    preview,
  }

  return transformedFile
}

const CustomImageInput = ({
  name,
  value,
  property,
  classes,
}: IInputProps<{ rawFile: File } | string | null>) => {
  const [src, setSrc] = useState<string | null>(null)
  const imageInputClasses = useImageInputStyles()
  const ownClasses = useStyles()

  React.useEffect(() => {
    if (typeof value === 'string') setSrc(`data:image/png;base64,${value}`)
    else if (value != null) {
      setSrcFromFile(value.rawFile)
    } else {
      setSrc(null)
    }
  }, [value])

  const setSrcFromFile = async (file: File) => {
    setSrc(await toDataURL(file))
  }

  const onChange = async (file: File | null) => {
    if (file != null) setSrcFromFile(file)
    else setSrc(null)
  }

  return (
    <ImageInput
      className={classes}
      source={name}
      label={property.displayedName ?? name}
      accept='image/png'
      multiple={false}
      classes={imageInputClasses}
      onChange={onChange}
      format={transformFile}
    >
      <div>
        <ImagePreview
          className={ownClasses.previewImage}
          alt='Preview'
          src={src ?? undefined}
        />
      </div>
    </ImageInput>
  )
}

export default CustomImageInput
