import { TEntityOverride } from 'src/types'

import entityIdInput from './entityIdInput'
import entityBehaviourInput from './entityBehaviour'

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    properties: {
      coordinates: {
        hidden: true,
      },
    },
  },
  create: {
    properties: {
      entityId: {
        displayedComponent: entityIdInput,
        basedOn: 'entityBehaviour',
      },
      entityBehaviour: {
        displayedComponent: entityBehaviourInput,
      },
      imageId: {
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
    },
  },
  edit: {
    properties: {
      entityId: {
        displayedComponent: entityIdInput,
        basedOn: 'entityBehaviour',
      },
      entityBehaviour: {
        displayedComponent: entityBehaviourInput,
      },
      imageId: {
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
    },
  },
}

export default override
