import Rete from '@obvious.tech/rete'

import { compareNodeOptions } from 'src/utils/flow'

import { sockets, definitions } from '../../..'
import SelectControl from '../../../Controls/SelectControl'
import { getSocketFromType } from '../../../Sockets/utils'
import { getControl } from '../../../../../helpers/ControlHelper'
import CheckboxesModalControl from '../../../Controls/Modal/CheckboxesModal'
import OutputWithDecompose from '../../../Outputs/OutputWithDecompose'

class ComposeComponent extends Rete.Component {
  constructor() {
    super('Compose')
    this.contextMenuPath = ['Objects']
    this.checkboxes = []
    this.task = {
      outputs: {
        out: 'output',
      },
    }
  }

  builder(node) {
    const options = Object.keys(definitions.entities)
      .map(key => ({
        value: key,
        label: key,
      }))
      .sort(compareNodeOptions)

    const ctrl = new SelectControl(
      this.editor,
      node,
      'entity',
      'Entity',
      this.building(node),
      options,
    )

    node.addControl(ctrl)

    if (node.data.entity) {
      this.building(node, true)(node.data.entity)
      if (node.data[node.data.entity]) {
        Object.keys(node.data[node.data.entity])
          .filter(key => node.data[node.data.entity][key])
          .forEach(key => {
            const { input } = this.checkboxes.find(
              checkbox => checkbox.key === key,
            )
            node.addInput(input)
          })
      }
    }
    return node
  }

  building =
    (node, init = false) =>
    entity => {
      if (init || entity !== node.data.entity) {
        /**
         * redraw inputs
         */
        node.inputs.forEach(i => {
          i.connections.map(connection =>
            this.editor.removeConnection(connection),
          )
          node.removeInput(i)
        })
        node.addInput(
          new Rete.Input('entity', entity, sockets[entity + 'Socket']),
        )
        const { properties, required } = definitions.entities[entity]
        properties
          .filter(p => required && required.includes(p.name) && !p.readOnly)
          .forEach(p => {
            const input = new Rete.Input(
              p.name,
              p.name + (required && required.includes(p.name) ? ' *' : ''),
              getSocketFromType(p.type, p.entity, p.format),
            )
            const control = getControl(
              this.editor,
              node,
              p.type,
              p.name,
              p.name,
            )
            control && input.addControl(control)
            node.addInput(input)
          })

        /**
         * redraw output
         */
        node.outputs.forEach(o => {
          o.connections.map(connection =>
            this.editor.removeConnection(connection),
          )
          node.removeOutput(o)
        })
        if (node.outputs.has('out')) {
          const output = node.outputs.get('out')
          output.socket = sockets[entity + 'Socket']
          output.name = entity
        } else {
          node.addOutput(
            new OutputWithDecompose(
              'out',
              entity,
              sockets[entity + 'Socket'],
              this.editor,
            ),
          )
        }

        this.checkboxes = properties
          .filter(p => (!required || !required.includes(p.name)) && !p.readOnly)
          .map(checkbox => {
            const input = new Rete.Input(
              checkbox.name,
              checkbox.name,
              getSocketFromType(
                checkbox.type,
                checkbox.entity,
                checkbox.format,
              ),
            )
            const control = getControl(
              this.editor,
              node,
              checkbox.type,
              checkbox.name,
              checkbox.name,
            )
            control && input.addControl(control)
            return {
              key: checkbox.name,
              name: checkbox.name,
              input,
            }
          })
        node.controls.forEach(i => {
          if (i.key !== 'entity' && !init) {
            node.removeControl(i)
          }
        })
        if (node.controls.size === 1) {
          node.addControl(
            new CheckboxesModalControl(
              this.editor,
              node,
              entity,
              this.handleInput(node),
              this.checkboxes,
              'Optionnal fields',
              'input',
            ),
          )
        }
      } else if (!entity) {
        node.data = {}
      }

      node.update()
      setTimeout(() => {
        this.editor.view.updateConnections({
          node,
        })
      }, 1)
    }

  handleInput = node => (inputModify, checked) => {
    const { input } = this.checkboxes.find(
      checkbox => checkbox.key === inputModify.key,
    )
    if (checked) {
      node.addInput(input)
    } else {
      node.data[input.key] = null
      input.connections.map(connection =>
        this.editor.removeConnection(connection),
      )
      node.removeInput(input)
    }
    node.update()
    setTimeout(() => {
      this.editor.view.updateConnections({
        node,
      })
    }, 1)
  }

  connected = connection => {
    if (connection && connection.input.key) {
      setTimeout(() => {
        connection.input.node.update()
        setTimeout(() => {
          this.editor.view.updateConnections({
            node: connection.input.node,
          })
        }, 1)
      })
    }
  }

  disconnected = connection => {
    if (connection.input.key) {
      setTimeout(() => {
        if (connection.input.node) {
          connection.input.node.data[connection.input.key] = null
          connection.input.node.update()
          setTimeout(() => {
            this.editor.view.updateConnections({
              node: connection.input.node,
            })
          }, 1)
        }
      })
    }
  }
}

export default ComposeComponent
