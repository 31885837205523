import React from 'react'
import { List } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import extendGlobalEntity from 'src/parser/entities/extendGlobalEntity'
import Pagination from 'src/components/DataGrid/Pagination'

import CustomFilter from '../Filter/CustomFilter'
import CustomBulkActionButtons from './BulkActionButtons'
import FieldList from '../../../components/fields/FieldList'
import CustomTitle from '../Title/Title'
import ListActions from './Actions'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  main: {
    display: 'block',
  },
}))

export const CustomList = ({
  entity,
  name,
  hasEdit,
  hasShow,
  hasCreate,
  hasList,
  passportId,
  ...props
}) => {
  const { id, basePath, resource } = props
  const location = useLocation()

  const { dateFrom, dateTo } = qs.parse(location.search)

  const classes = useStyles()
  const listEntity = extendGlobalEntity(entity, 'list')

  const DisplayedEmptyList = entity.list.displayedEmptyList
  const DisplayedActions = entity.list.displayedActions
  const DisplayedListToolbar = entity.list.displayedListToolbar
  const renderRightColumn = entity.list.renderRightColumn
  const getFilters = entity.list.getFilters

  const filters = getFilters?.({
    dateFrom,
    dateTo,
    passportId,
  })
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <List
        style={{ flex: 1 }}
        data-app='CustomDatagridContainer'
        empty={
          DisplayedEmptyList && (
            <DisplayedEmptyList
              entity={listEntity}
              {...props}
            />
          )
        }
        title={
          <CustomTitle
            entity={listEntity}
            {...props}
          />
        }
        basePath={basePath}
        resource={resource}
        className={classes.root}
        classes={{
          main: classes.main,
        }}
        id={id}
        key={id}
        hasEdit={hasEdit}
        hasCreate={hasCreate}
        hasList
        pagination={<Pagination />}
        hasShow={false}
        filters={
          <CustomFilter
            entity={listEntity}
            name={name}
          />
        }
        filter={filters}
        actions={
          DisplayedActions ? (
            <DisplayedActions
              entity={listEntity}
              hasCreate={hasCreate}
              {...props}
            />
          ) : (
            <ListActions />
          )
        }
        bulkActionButtons={
          DisplayedListToolbar ? (
            <DisplayedListToolbar
              entity={listEntity}
              {...props}
            />
          ) : (
            <CustomBulkActionButtons />
          )
        }
      >
        <FieldList
          entity={listEntity}
          {...props}
          hasEdit={hasEdit}
        />
      </List>
      {renderRightColumn?.()}
    </div>
  )
}

export default CustomList
