import Rete from '@obvious.tech/rete'
import React from 'react'
import AddRemoveBlock from '../../../../theme/components/AddRemoveBlock'

export default class SwitchCaseControl extends Rete.Control {
  component = props => {
    return <AddRemoveBlock {...props}></AddRemoveBlock>
  }

  constructor(key, label, onAdd, onRemove, run = true) {
    super(key)
    if (run && onAdd) {
      onAdd(true)
    }

    this.props = {
      label,
      onAdd: () => {
        onAdd && onAdd()
      },
      onRemove: () => {
        onRemove && onRemove()
      },
    }
  }
}
