import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import {
  commonStyles,
  renderTitleContainer,
  renderDetails,
} from './CommonUtils'
import shortenUUID from 'src/utils/shortenUUID'
import { TAgentReportProps } from '../Provider'
import * as theme from '../theme'

const agentStyles = StyleSheet.create({
  ...commonStyles,
  root: {
    ...commonStyles.documentHeader,
    backgroundColor: theme.colors.activityReportHeaderBg,
  },
  padding: {
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
  },
})

const AgentActivityTitle: React.FC<TAgentReportProps> = ({
  entity,
  assignedPassport,
}: TAgentReportProps): JSX.Element | null => {
  const { t, i18n } = useTranslation()
  if (entity.checkIn === undefined || assignedPassport === undefined) {
    return null
  }
  const agentDetails = assignedPassport[0]
  const date = new Date(entity.checkIn.timestamp)

  const dateFormatter = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  const timeFormatter = new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  const formattedDate = dateFormatter.format(date)
  const formattedTime = timeFormatter.format(date)

  return (
    <View
      fixed
      style={agentStyles.root}
    >
      {renderTitleContainer(
        t('agent_activity_report.header.title'),
        shortenUUID(entity.id),
        // @ts-expect-error
        agentStyles.padding,
      )}
      {renderDetails(
        // eslint-disable-next-line max-len
        `${agentDetails.firstname ?? ''} ${
          agentDetails.lastname ?? ''
        } - ${formattedDate} ${formattedTime}`,
        i18n.language,
      )}
    </View>
  )
}

export default AgentActivityTitle
