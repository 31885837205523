// @ts-nocheck

import React from 'react'
import { ThemeProvider } from '@material-ui/core'

import theme from 'src/theme/theme'

export default function withThemeProvider<Props>(
  Component: React.JSXElementConstructor<Props>,
) {
  return (props: Props) => (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  )
}
