import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { useField } from 'react-final-form'

const useStyles = makeStyles((theme: Theme) => ({
  fieldset: {
    display: 'flex',
    transition: `all ${theme.transitions.duration.standard}ms`,
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    padding: ({ condition }: any) =>
      condition ? `0 ${theme.spacing(2)}px` : 0,
    borderWidth: ({ condition }: any) => (condition ? 2 : 0),
    borderColor: ({ condition }: any) =>
      condition ? 'rgb(192,192,192)' : 'transparent',
  },
}))

type TConditionFormGroupProps = {
  label?: string | React.ReactNode
  className?: string
  // condition input name
  when: string
  // condition trigger value
  is: any
  type?: string
  style?: React.CSSProperties
  children: React.ReactChild | React.ReactChild[]
}

const ConditionFormGroup = ({
  label,
  when,
  is,
  children,
  type = 'input',
  className,
  style,
}: TConditionFormGroupProps) => {
  const { input } = useField(when, {
    type,
  })

  const inputValue = type === 'checkbox' ? input.checked ?? false : input.value
  const condition = inputValue === is

  const classes = useStyles({
    condition,
  })

  return (
    <fieldset
      className={`${classes.fieldset} ${className}`}
      style={style}
    >
      {label && <legend>{label}</legend>}
      {condition && children}
    </fieldset>
  )
}

export default ConditionFormGroup
