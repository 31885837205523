import React, { Fragment } from 'react'
import InputSocketAndControllerComponent from './InputSocketAndControllerComponent'

class CustomObjectInputComponent extends React.Component {
  render() {
    const { inputs, ...binds } = this.props

    return inputs.map((input, i) => (
      <Fragment key={input.key}>
        {i % 2 === 1 && <hr />}
        <div className='input'>
          <InputSocketAndControllerComponent
            input={input}
            {...binds}
          />
        </div>
      </Fragment>
    ))
  }
}

export default CustomObjectInputComponent
