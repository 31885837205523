import { TEntityOverride } from 'src/types'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
      imageId: {
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
    },
  },
  list: {
    properties: {},
  },
  create: {
    properties: {},
  },
  edit: {
    properties: {},
  },
}

export default override
