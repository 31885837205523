import React from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import NoContent from 'src/parser/shared/PDF/NoContent'
import { Header, Field } from './List'

import * as theme from 'src/parser/shared/PDF/theme'

export const MAPS_BASEPATH = 'https://www.google.com/maps'

const styles = StyleSheet.create({
  root: {
    ...theme.styles.text,
    marginBottom: theme.spacing(1.5),
  },
  guardTour: {
    marginTop: theme.spacing(1),
  },
  tourTitle: {
    fontWeight: 900,
    marginBottom: theme.spacing(0.5),
  },
})

const headerConfig = [
  {
    translation: 'agent_activity_report.guard_tours.date',
  },
  {
    translation: 'agent_activity_report.guard_tours.name',
  },
  {
    translation: 'agent_activity_report.guard_tours.answers',
  },
  {
    translation: 'agent_activity_report.guard_tours.location',
  },
]

const groupGuardToursByTitle = guardTours => {
  const groupedObjects = {}

  guardTours.forEach(obj => {
    const { taskGrouping, ...rest } = obj
    const title = taskGrouping.title

    if (!groupedObjects[title]) {
      groupedObjects[title] = []
    }
    groupedObjects[title].push({
      taskGrouping,
      ...rest,
    })
  })

  const result = Object.values(groupedObjects).map(group => group)
  return result
}

const Title = ({ label }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Text style={styles.tourTitle}>
      {`${t('agent_activity_report.guard_tours.description')} : ${label}`}
    </Text>
  )
}

const GuardTour = ({ guardTour }): JSX.Element => {
  return (
    <View style={styles.guardTour}>
      <Title label={guardTour[0].taskGrouping.title} />
      <Header config={headerConfig} />
      {guardTour.map((task, index) => {
        return (
          <Field
            task={task}
            key={index}
            index={index}
          />
        )
      })}
    </View>
  )
}

const GuardTours = ({ guardTours }): JSX.Element => {
  const sortedGuardTours = groupGuardToursByTitle(guardTours)

  if (guardTours.length === 0) return <NoContent />

  return (
    <View style={styles.root}>
      {sortedGuardTours.map((guardTour, index) => {
        return (
          <GuardTour
            guardTour={guardTour}
            key={index}
          />
        )
      })}
    </View>
  )
}

export default GuardTours
