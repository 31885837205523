import React from 'react'
import { View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { useTranslation } from 'react-i18next'

import NoContent from 'src/parser/shared/PDF/NoContent'
import styles from './styles'
import TableHeaderCell from './TableHeaderCell'
import Row from './TableRow'

import { getDirection } from 'src/parser/shared/PDF/Title/CommonUtils'

export type TCellStyle = Style | Array<Style | undefined> | undefined

export type TColumn<K extends string, V> = {
  title: React.ReactNode
  render: (value: V, key: K) => React.ReactNode
  style?: TCellStyle
}

export type TProps<Cols extends string, Row extends Record<Cols, unknown>> = {
  columns: {
    [Col in Cols]?: TColumn<Col, Row[Col]>
  }
  rows: Array<Row>
  getRowKey: (row: Row) => string | number
}

/**
 * Table component to be used in react-pdf instances
 *
 * Warning: Was adapted from previously created components,
 * implementation may not be ideal, and table columns may be easily
 * broken with different styles or contents
 */
const Table = <Cols extends string, Row extends Record<string, unknown>>({
  columns,
  rows,
  getRowKey,
}: TProps<Cols, Row>): JSX.Element => {
  const { i18n } = useTranslation()
  if (!rows.length) return <NoContent />

  const isRTL = getDirection(i18n.language) === 'rtl'

  const columnEntries = Object.entries(columns) as Array<
    [Cols, TColumn<Cols, unknown>]
  >

  return (
    <View style={styles.table}>
      <View style={isRTL ? { flexDirection: 'row-reverse' } : styles.row}>
        {columnEntries.map(([key, column]) => (
          <TableHeaderCell {...{ key, column }} />
        ))}
      </View>
      {rows.map((row, index) => {
        const rowKey = getRowKey(row)
        return (
          <Row
            key={rowKey}
            rowKey={rowKey}
            index={index}
            columns={columns}
            row={row}
          />
        )
      })}
    </View>
  )
}

export default Table
