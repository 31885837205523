import { TEntityOverride } from 'src/types'

import ConnectorParametersForm from './Inputs/ConnectorParametersForm'

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    properties: {
      templateId: {
        referencedField: 'name',
        referenceTo: 'connectors/api/Template',
      },
      parameters: {
        basedOn: 'templateId',
        displayedComponent: ConnectorParametersForm,
      },
    },
  },
  edit: {
    properties: {
      templateId: {
        referencedField: 'name',
        referenceTo: 'connectors/api/Template',
      },
      parameters: {
        basedOn: 'templateId',
        displayedComponent: ConnectorParametersForm,
      },
    },
  },
}

export default override
