export interface TCancellablePromise<T = any> extends Promise<T> {
  cancel: (reason?: any) => void
}

export default function cancellablePromise<T>(
  promise: Promise<T>,
): TCancellablePromise<T> {
  let isCancelled = false
  let reason: any

  const cancel = (_reason?: any) => {
    if (!isCancelled) {
      reason = _reason
      isCancelled = true
    }
  }
  const promiseWithCancel = Object.assign(
    new Promise<T>((res, rej) => {
      promise
        .then(d => {
          return isCancelled ? rej(reason) : res(d)
        })
        .catch(e => {
          rej(isCancelled ? reason : e)
        })
    }),
    {
      cancel,
    },
  )

  return promiseWithCancel
}
