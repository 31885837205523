// @ts-nocheck
import { HttpError, DataProvider, Identifier } from 'react-admin'
import { stringify } from 'query-string'

import { TAwaited, TDict } from 'src/types'

import { fetchJson } from './fetchJson'
import { Filter } from 'src/utils/api/Filter'

export const sanitizeResource = (resource: string): string => {
  return resource.replace(/^\/?|\/?$/g, '')
}

export const getOptions = (token?: string): { headers: Headers } => {
  const jwtToken = token ?? localStorage.getItem('token')
  const headers: TDict<string> = {
    'Access-Control-Expose-Headers': 'Content-Range',
  }

  if (jwtToken) headers.authorization = jwtToken

  return {
    headers: new Headers(headers),
  }
}

export const tryFetchJson = async (
  ...args: Parameters<typeof fetchJson>
): Promise<TAwaited<ReturnType<typeof fetchJson>>> => {
  /* eslint-disable @typescript-eslint/no-throw-literal, no-throw-literal */
  // In this case we need to throw a string for react-admin to show the error properly ( eg. with notify )
  try {
    return await fetchJson(...args)
  } catch (err) {
    if (err instanceof HttpError)
      throw String(err.message ?? 'An unexpected error occured')

    if (err instanceof Error) throw `${err.name}: ${err.message}`

    throw String(err)
  }
  /* eslint-enable @typescript-eslint/no-throw-literal */
}

const endsWithValidFilterOp = (str: string): boolean => {
  return Object.values(Filter).some(it => str.endsWith(it))
}

const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { pagination, sort, filter } = params
    const query: TDict = {}

    if (sort) {
      const { field, order } = sort
      query.Sorts = `${order === 'ASC' ? '' : '-'}${field}`
    }
    if (pagination) {
      const { page, perPage } = pagination
      if (page) query.Page = page
      if (perPage) query.PageSize = perPage
    }
    if (filter) {
      query.Filters = Object.keys(filter)
        .filter(filterKey => filterKey !== 'q')
        .map(filterKey => {
          if (endsWithValidFilterOp(filterKey)) {
            return `${filterKey}${filter[filterKey]}`
          }
          return `${filterKey}@=${filter[filterKey]}`
        })
        .reduce(
          (filters, filter, i) =>
            i === 0 ? filters + filter : filters + ',' + filter,
          '',
        )
    }
    if (filter?.q) {
      query.Search = filter.q
    }

    const url = `${window._env_.API_URL}/${sanitizeResource(
      resource,
    )}?${stringify(query)}`

    const res = await tryFetchJson(url, getOptions())

    return {
      data: res.json,
      total: getTotalFromHeadersOrJson(res),
    }
  },

  getOne: async (resource, params) => {
    const url = `${window._env_.API_URL}/${sanitizeResource(resource)}/${
      params.id
    }`
    const res = await tryFetchJson(url, getOptions())

    return {
      data: res.json,
    }
  },

  getMany: async (resource, params) => {
    const query: TDict = {}

    if (params.ids.length) query.Filters = `id==${params.ids.join('|')}`
    const url = `${window._env_.API_URL}/${sanitizeResource(
      resource,
    )}?${stringify(query)}`

    const res = await tryFetchJson(url, getOptions())

    return {
      data: res.json,
    }
  },

  getManyReference: async (resource, params) => {
    const { sort } = params
    const query = Object.entries({
      sorts: `${sort.order === 'ASC' ? '' : '-'}${sort.field}`,
      filters: Object.entries(
        Object.assign(
          { ...params.filter },
          typeof params.target === 'string' && typeof params.id === 'string'
            ? { [params.target]: params.id }
            : {},
        ),
      )
        .filter(([k, v]) => k !== '' && v !== '' && v != null)
        .map(([filterKey, dirtyFilterVal]) => {
          let filterVal: string = ''
          if (typeof dirtyFilterVal === 'string') {
            filterVal = dirtyFilterVal
          }
          return [filterKey, filterVal]
        })
        .map(parts => encodeURIComponent(parts.join('@='))),
    })
      .map(([key, val]) => `${key}=${String(val)}`)
      .join('&')

    const url = `${window._env_.API_URL}/${sanitizeResource(resource)}?${query}`

    const res = await tryFetchJson(url, getOptions())

    return {
      data: res.json,
      total: getTotalFromHeadersOrJson(res),
    }
  },

  update: async (resource, params) => {
    const url = `${window._env_.API_URL}/${sanitizeResource(resource)}/${
      params.id
    }`
    const res = await tryFetchJson(url, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
      ...getOptions(),
    })

    return {
      data: res.json,
    }
  },

  updateMany: async (resource, params) => {
    const query = stringify({
      filter: JSON.stringify({ id: params.ids }),
    })
    const url = `${window._env_.API_URL}/${sanitizeResource(resource)}?${query}`
    const res = await tryFetchJson(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getOptions(),
    })

    return {
      data: res.json,
    }
  },

  create: async (resource, params) => {
    const url = `${window._env_.API_URL}/${sanitizeResource(resource)}`

    const res = await tryFetchJson(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
      ...getOptions(),
    })

    return {
      data: {
        ...params.data,
        id: res.json.id,
      },
    }
  },

  delete: async (resource, params) => {
    const url = formatURL(resource, [params.id])
    await tryFetchJson(url, {
      method: 'DELETE',
      ...getOptions(),
    })

    return {
      data: {
        ...params.previousData,
        id: params.id,
      } as any,
    }
  },

  deleteMany: async (resource, params) => {
    const url = formatURL(resource, params.ids)
    await tryFetchJson(url, {
      method: 'DELETE',
      // body: JSON.stringify(params.data), // is this un-needed ?
      ...getOptions(),
    })

    return {
      data: [...params.ids],
    }
  },
}

const getTotalFromHeadersOrJson = ({
  headers,
  json,
}: TAwaited<ReturnType<typeof fetchJson>>): number => {
  const contentRange = headers.get('content-range')

  if (contentRange !== null) {
    const total = contentRange.split('/').pop()

    if (total === undefined) return Infinity
    return parseInt(total)
  }
  if (!isNaN(json.length)) return json.length
  return 0
}

const getResourceIDField = (resource: string): string => {
  const resourceIDFieldMatcher: Array<[RegExp, string]> = [
    [/^([^/].*\/)?api\/Group$/, 'name'],
  ]
  const [, fieldName] =
    resourceIDFieldMatcher.find(([matcher]) => matcher.test(resource)) ?? []

  return fieldName ?? 'id'
}

const formatURL = (resource: string, ids: Identifier[]): string => {
  ids = ids.filter(id => typeof id === 'string' && id.length > 0)

  if (!ids.length) throw new Error('Unable to format URL: No id(s) specified.')

  const idField = getResourceIDField(resource)
  const hasFiltersInsteadOfID = ids.length > 1 || idField !== 'id'
  let idURLComponent: Identifier | null
  let queryURLComponent: string

  if (hasFiltersInsteadOfID) {
    idURLComponent = null
    queryURLComponent = `?filters=${idField}==${ids.join('|')}`
  } else {
    idURLComponent = ids[0]
    queryURLComponent = ''
  }

  const url = [
    window._env_.API_URL,
    '/',
    sanitizeResource(resource),
    idURLComponent ? `/${idURLComponent}` : '',
    queryURLComponent,
  ].join('')

  return url
}

export default dataProvider
