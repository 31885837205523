import React from 'react'
import {
  Box,
  capitalize,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  BoxProps,
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  invalid: {
    color: theme.palette.error.main,
  },
  valid: {
    color: theme.palette.success.main,
  },
  default: {
    color: theme.palette.text.primary,
  },
  licenseKey: {
    overflowWrap: 'anywhere',
    textAlign: 'right',
    width: '50%',
  },
  licenseValue: {
    overflowWrap: 'anywhere',
    textAlign: 'left',
    width: '50%',
    paddingLeft: theme.spacing(1),
  },
  licenseGroupTitle: {
    textAlign: 'center',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  list: {
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  listItem: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}))

export interface ISectionRecord {
  key: string
  value: string
  type?: 'valid' | 'invalid' | 'default'
}

interface IProps extends BoxProps {
  title: string
  className?: string
  data: ISectionRecord[]
}

const LicenseSection = ({
  title,
  data,
  className,
  ...boxProps
}: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box
      overflow='hidden'
      borderRadius='4px'
      boxShadow={2}
      className={className}
      {...boxProps}
    >
      <Typography
        className={classes.licenseGroupTitle}
        variant='h6'
      >
        {title}
      </Typography>
      <List
        className={classes.list}
        disablePadding
      >
        {data.map(({ key, value, type = 'default' }) => (
          <ListItem
            className={classes.listItem}
            key={key}
          >
            <ListItemText className={classes.licenseKey}>
              {capitalize(key)}:
            </ListItemText>
            <ListItemText
              className={`${classes.licenseValue} ${classes[type]}`}
            >
              {value}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default LicenseSection
