const fetchModules = async ({ dataProvider, shouldTransform = true }) => {
  let modules = []
  if (window._env_.mode === 'development') return modules

  try {
    const [microservice, api] = dataProvider.resource.split('/')
    const { data } = await dataProvider.apiRequest.getList(
      `${microservice}/${api}/module`,
      {},
    )

    modules = shouldTransform
      ? data.reduce((acc, m) => {
          acc[m.name] = {
            data: m.data,
          }
          return acc
        }, {})
      : data
  } catch (e) {
    dataProvider.fetchError(e)
  }

  return modules
}

export default fetchModules
