import { IUserTaskList } from '@obvious.tech/constellation'

import { TEntityOverride } from 'src/types'
import z from 'zod'

const SUserTaskGroups = z.array(
  z.object({
    title: z.string().min(1),
    userTasks: z.array(
      z.object({
        description: z.string(),
        multipleAnswersAuthorized: z.boolean(),
        propositions: z.tuple([z.literal('Done')]),
        withNotes: z.boolean(),
        withFiles: z.boolean(),
      }),
    ),
  }),
)

const defaultTasksTemplate = [
  {
    title: 'Category 1',
    userTasks: [
      {
        description: 'Task 1',
        multipleAnswersAuthorized: false,
        propositions: ['Done'],
        withNotes: false,
        withFiles: false,
      },
    ],
  },
]

const override: TEntityOverride<IUserTaskList> = {
  order: [
    'id',
    'groups',
    'createdAt',
    'matchingGeofencesIds',
    'userTasksGroups',
  ],
  global: {
    properties: {
      id: {
        displayedName: 'ID',
      },
      groups: {
        displayedName: 'Owners',
        type: 'groups',
      },
      createdAt: {
        displayedName: 'Created at',
        readOnly: true,
        type: 'date-time',
      },
      matchingGeofencesIds: {
        displayedName: 'In Geofences',
        referenceTo: 'geolocation/api/Geofence',
        referencedField: 'name',
      },
      userTasksGroups: {
        displayedName: 'Generated Tasks (JSON)',
        type: 'JSONObject',
        defaultValue: defaultTasksTemplate,
        validations: [
          it => {
            const data = SUserTaskGroups.safeParse(it)
            if (data.success) return false

            const { message, path } = data.error.errors[0]
            const pathStr = path
              .map(it => (typeof it === 'string' ? `.${it}` : `[${it}]`))
              .join('')

            return `${message} (at ${pathStr})`
          },
        ],
      },
    },
  },
  list: {
    properties: {
      userTasksGroups: {
        hidden: true,
      },
    },
  },
}

export default override
