import React from 'react'
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers'
import { useTheme } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

import useStyles from './styles'

const TimeInput: React.FunctionComponent<KeyboardTimePickerProps> = ({
  value,
  onChange,
  format,
  label,
  onBlur,
  ...options
}: KeyboardTimePickerProps) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <KeyboardTimePicker
      value={value}
      clearable
      className={classes.root}
      DialogProps={{
        style: {
          zIndex: theme.zIndex.tooltip + 1,
        },
      }}
      label={label}
      keyboardIcon={<AccessTimeIcon />}
      onBlur={onBlur}
      format={format}
      onChange={onChange}
      onAccept={onBlur}
      {...options}
    />
  )
}

export default TimeInput
