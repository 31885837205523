import * as React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Typography, Breadcrumbs } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: theme.palette.common.white,
    },
    link: {
      color: 'inherit',
    },
    separator: {
      color: theme.palette.common.white,
    },
  }
})

export const AgentActivityReportTitle = ({ passport }) => {
  const classes = useStyles()
  if (passport.loading) return <div></div>

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<Typography className={classes.separator}>{'>'}</Typography>}
    >
      <Typography
        className={classes.title}
        variant='h6'
      >
        <Link
          className={classes.link}
          to='/dashboard'
        >
          Home
        </Link>
      </Typography>
      <Typography
        className={classes.title}
        variant='h6'
      >
        units
      </Typography>
      <Typography
        className={classes.title}
        variant='h6'
      >
        Agent
      </Typography>
      <Typography
        className={classes.title}
        variant='h6'
      >
        {passport.data?.username || passport.data?.id}
      </Typography>
      <Typography
        className={classes.title}
        variant='h6'
      >
        AgentActivityReport
      </Typography>
    </Breadcrumbs>
  )
}
