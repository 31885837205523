import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import NodeButton from './NodeButton'
import flowClasses from '../classes'

const useStyles = makeStyles({
  optiontitleContainer: {
    textAlign: 'left',
    position: 'relative',
    minWidth: '170px',
  },
  verticalAlign: flowClasses.verticalAlign,
})

export default props => {
  const { label, onAdd, onRemove } = props
  const classes = useStyles(props)
  return (
    <Box
      mx={2}
      className={classes.optiontitleContainer}
    >
      {label}
      <div className={classes.verticalAlign}>
        {onAdd && (
          <NodeButton onClick={onAdd}>
            <AddIcon
              style={{
                fontSize: '15pt',
              }}
            />
          </NodeButton>
        )}
        {onRemove && (
          <NodeButton
            onClick={onRemove}
            style={{
              marginLeft: '3px',
            }}
          >
            <RemoveIcon
              style={{
                fontSize: '15pt',
              }}
            />
          </NodeButton>
        )}
      </div>
    </Box>
  )
}
