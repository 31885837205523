import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import { Button, Box } from '@material-ui/core'
import { saveAs } from 'file-saver'

const DownloadLicense = ({
  record,
  resource,
  buttonProps = {},
  icon = null,
}) => {
  const notify = useNotify()
  const [fetching, setFetching] = useState(false)

  const fetchLicenceFile = async () => {
    const url = `${window._env_.API_URL}/${resource}/hardware/${record.hardwareId}`
    const options = {
      headers: new Headers({
        authorization: localStorage.getItem('token'),
      }),
      method: 'GET',
    }

    try {
      setFetching(true)
      const file = await fetch(url, options)
        .then(response => {
          if (!response.ok) {
            throw new Error()
          }

          return response.blob()
        })
        .then(blob => window.URL.createObjectURL(blob))
      saveAs(file, 'Licence.lic')
    } catch (e) {
      notify('Failed to load audio file.', 'error')
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <Button
        disabled={fetching}
        onClick={fetchLicenceFile}
        {...buttonProps}
      >
        {icon}
        Download
      </Button>
    </Box>
  )
}

export default DownloadLicense
