import fetch from 'node-fetch'

import SwaggerKafkaUtils from './Kafka/KafkaUtils'
import SwaggerMicroservices from './Microservices/MicroservicesParser'
import SwaggerPaths from './Paths/PathsParser'
import SwaggerMicroservicesUtils from './Microservices/MicroservicesUtils'
import SwaggerDefinitions from './Definitions/DefinitionsParser'

export default ({
  endpoint = '',
  fetch = fetch,
  swaggerfiles,
  options = {},
}) => {
  const validEndpoint = endpoint.replace(/\/$/, '')

  const paths = SwaggerPaths(swaggerfiles, validEndpoint, fetch, options)
  const microservices = SwaggerMicroservices(swaggerfiles, options)
  const microservicesUtils = SwaggerMicroservicesUtils(swaggerfiles, options)
  const definitions = SwaggerDefinitions(swaggerfiles, options)
  const kafkaUtils = SwaggerKafkaUtils(swaggerfiles, options)
  return {
    microservices: options.microservices
      ? options.microservices(swaggerfiles, options)
      : microservices,
    paths: options.paths
      ? options.paths(swaggerfiles, validEndpoint, options)
      : paths,
    definitions: options.definitions
      ? options.definitions(swaggerfiles, options)
      : definitions,
    microservicesUtils,
    kafkaUtils,
  }
}
