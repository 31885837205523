import React from 'react'
import { Text } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { commonStyles, createDirectionalStyles } from './Title/CommonUtils'

import * as theme from './theme'

export type TProps = {
  text?: string
}

const NoContent = ({ text = undefined }: TProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const directionalStyles = createDirectionalStyles(commonStyles, i18n.language)

  return (
    <Text style={[theme.styles.textSecondary, directionalStyles.textAlign]}>
      {text ?? t('alarm_report.no_content')}
    </Text>
  )
}

export default NoContent
