import React from 'react'

import ReferencedInput from 'src/components/inputs/ReferencedInput'
import AgentAutocompleteInput from 'src/components/inputs/AgentAutocompleteInput'
import StringInput from 'src/components/inputs/StringInput'

const resources = {
  Camera: {
    reference: 'videomanager/api/Camera',
    referencedField: 'name',
  },
  Alarm: {
    reference: 'alarms/api/Alarm',
    referencedField: 'id',
  },
}

const EntityIdInput = ({
  formData,
  name,
  onChange,
  value,
  record,
  ...rest
}) => {
  const getResource = entityBehaviour => {
    return resources[entityBehaviour] ?? {}
  }

  const { reference, referencedField } = getResource(formData.entityBehaviour)

  if (formData.entityBehaviour === 'Agent')
    return (
      <AgentAutocompleteInput
        name={name}
        onChange={onChange}
        record={record}
        value={value}
        {...rest}
      />
    )

  if (!reference)
    return (
      <StringInput
        name={name}
        {...rest}
        onChange={onChange}
        value={value}
        record={record}
      />
    )

  return (
    <span>
      <ReferencedInput
        name={name}
        {...rest}
        onChange={onChange}
        value={value}
        record={record}
        property={{
          referencedField: referencedField ?? 'id',
          referenceTo: reference ?? null,
        }}
      />
    </span>
  )
}

export default EntityIdInput
