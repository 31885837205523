const getMaxPermissionLevel = (permissions): number | null => {
  const permissionsWithLevels: number[] = permissions
    .filter(({ data }) => data.level !== null)
    .map(({ data }) => data.level)

  return permissionsWithLevels.length > 0
    ? Math.max(...permissionsWithLevels)
    : null
}

export default getMaxPermissionLevel
