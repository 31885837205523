import Rete from '@obvious.tech/rete'
import React from 'react'
import { Checkbox, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import flowClasses from '../../../../theme/classes'
import compareDiagramChangesHelper from '../../../../helpers/CompareDiagramChangesHelper'

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: '2px',
    marginBottom: '4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  verticalAlign: flowClasses.verticalAlign,
}))

const BooleanControlComponent = props => {
  const { label, value, onChange } = props
  const classes = useStyles(props)

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <div
        className='input-title'
        style={{
          color: 'rgba(0,0,0,0.87)',
        }}
      >
        {label}
      </div>

      <Checkbox
        classes={{
          root: classes.checkbox,
        }}
        color='default'
        checked={value}
        onChange={e => onChange(e.target.checked)}
        margin='dense'
        inputProps={{
          'aria-label': 'primary checkbox',
        }}
      />
    </Box>
  )
}

export default class BooleanControl extends Rete.Control {
  static component = BooleanControlComponent

  constructor(emitter, node, key, label, onChange) {
    super(key)
    this.emitter = emitter
    this.key = key
    this.component = BooleanControl.component
    this.initialLabel = label
    const initial = node.data[key] || false

    node.data[key] = initial
    this.props = {
      label: this.initialLabel ? this.initialLabel : String(initial),
      value: initial || false,
      onChange: v => {
        this.setValue(v)
        onChange && onChange(v)
        compareDiagramChangesHelper.compare(this.emitter.toJSON())
      },
    }
  }

  setValue(val) {
    this.props.value = val
    this.props.label = this.initialLabel ? this.initialLabel : String(val)
    this.putData(this.key, val)
    this.update()
  }
}
