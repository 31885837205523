import { DateTimePicker } from '@material-ui/pickers'
import React from 'react'

const DateTimeInput = ({
  property,
  name,
  value,
  onChange,
  classes,
  ...props
}) => {
  return (
    <DateTimePicker
      disabled={property.readOnly}
      className={classes}
      value={value}
      label={property.displayedName ?? name}
      onChange={e => {
        onChange(e.toUTCString())
      }}
      // @ts-expect-error
      views={['year', 'month', 'date', 'hours', 'minutes', 'seconds']}
      format='yyyy/MM/dd hh:mm:ss'
    />
  )
}

export default DateTimeInput
