import { TNonEmptyArray } from 'src/types'

export type TLoadableIdle = {
  loading: false
  loaded: false
  data: null
  error: null
}

export type TLoadableLoading = {
  loading: true
  loaded: false
  data: null
  error: null
}

export type TLoadableSuccess<T> = {
  loading: false
  loaded: true
  data: T
  error: null
}

export type TLoadableFailure<E extends {} = Error> = {
  loading: false
  loaded: true
  data: null
  error: E
}

export type TLoadableGetError<T extends TLoadable<any, any>> = Exclude<
  T['error'],
  null
>

export type TLoadableGetData<T extends TLoadable<any, any>> = Exclude<
  T['data'],
  null
>

export type TLoadable<T, E extends {} = Error> =
  | TLoadableIdle
  | TLoadableLoading
  | TLoadableSuccess<T>
  | TLoadableFailure<E>

const idle = <T, E extends {} = Error>(): TLoadable<T, E> => ({
  loading: false,
  loaded: false,
  data: null,
  error: null,
})

const loading = <T, E extends {} = Error>(): TLoadable<T, E> => ({
  loading: true,
  loaded: false,
  data: null,
  error: null,
})

const success = <T, E extends {} = Error>(data: T): TLoadable<T, E> => ({
  loading: false,
  loaded: true,
  data,
  error: null,
})

const failure = <T, E extends {} = Error>(error: E): TLoadable<T, E> => ({
  loading: false,
  loaded: true,
  data: null,
  error,
})

const isSuccess = <T>(
  loadable: TLoadable<T, any>,
): loadable is TLoadableSuccess<T> => {
  return loadable.loaded && !loadable.error
}

const isFailure = <E extends {} = Error>(
  loadable: TLoadable<any, E>,
): loadable is TLoadableFailure<E> => {
  return loadable.error !== null
}

const isLoading = (
  loadable: TLoadable<any, any>,
): loadable is TLoadableLoading => {
  return loadable.loading
}

const isIdle = (loadable: TLoadable<any, any>): loadable is TLoadableIdle => {
  return !loadable.loading && !loadable.loaded
}

export type TLoadableAll<T extends Record<string, TLoadable<any, any>>> =
  | TLoadableIdle
  | TLoadableLoading
  | TLoadableFailure<
      TNonEmptyArray<
        {
          [K in keyof T]: [K, Exclude<T[K]['error'], null>]
        }[keyof T]
      >
    >
  | TLoadableSuccess<{
      [K in keyof T]: TLoadableGetData<T[K]>
    }>

export const all = <T extends Record<string, TLoadable<any, any>>>(
  loadableDict: T,
): TLoadableAll<T> => {
  const loadables = Object.values(loadableDict)

  if (loadables.every(isSuccess)) {
    return success(
      Object.fromEntries(
        Object.entries(loadableDict).map(([k, { data }]) => [k, data]),
      ),
    ) as TLoadableAll<T>
  }

  if (loadables.some(isFailure)) {
    return failure(
      Object.entries(loadableDict)
        .map(([k, { error }]) => [k, error])
        .filter(([k, error]) => error != null),
    ) as TLoadableAll<T>
  }

  if (loadables.some(e => e.loading)) {
    return loading()
  }

  return idle()
}

export const fold =
  <T extends TLoadable<any, any>>(loadable: T) =>
  <B>({
    idle,
    loading,
    failure,
    success,
  }: {
    idle: () => B
    loading: () => B
    failure: (error: TLoadableGetError<T>) => B
    success: (data: TLoadableGetData<T>) => B
  }) => {
    if (loadable.loading) return loading()
    if (loadable.error !== null) return failure(loadable.error)
    if (loadable.loaded) return success(loadable.data)
    return idle()
  }

export default {
  idle,
  loading,
  success,
  failure,
  isSuccess,
  isFailure,
  isLoading,
  isIdle,
  all,
  fold,
}
