import { TState, TAction, actionTypes } from './types'

export const initialState: TState = {}

export default (state: TState = initialState, action: TAction) => {
  switch (action.type) {
    case actionTypes.SET_MICROSERVICES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
