export const deepReplace =
  (
    _shouldReplace: ((value: unknown) => boolean) | unknown,
    _replace: ((value: unknown) => unknown) | unknown,
  ) =>
  (input: unknown): unknown => {
    const shouldReplace =
      typeof _shouldReplace !== 'function'
        ? (value: unknown) => value === _shouldReplace
        : _shouldReplace
    const replace =
      typeof _replace !== 'function' ? (_: unknown) => _replace : _replace

    if (shouldReplace(input)) return replace(input)

    const recurse = deepReplace(shouldReplace, replace)

    if (Array.isArray(input)) {
      return input.map(e => recurse(e))
    }

    if (typeof input === 'object' && input !== null) {
      return Object.fromEntries(
        Object.entries(input).map(([k, v]) => [k, recurse(v)]),
      )
    }

    return input
  }
