import React from 'react'

export default (time: number = 1000) => {
  const [timeoutID, _setTimeoutID] = React.useState<NodeJS.Timeout | null>(null)

  const setTimeoutID = (id: NodeJS.Timeout | null) => {
    if (timeoutID !== null) clearTimeout(timeoutID)
    _setTimeoutID(id)
  }

  const debounce = <T, A extends any[]>(
    f: (...args: A) => Promise<T>,
  ): ((...args: A) => Promise<T>) => {
    return async (...args: A) =>
      await new Promise(resolve => {
        setTimeoutID(
          setTimeout(() => {
            resolve(f(...args))
            setTimeoutID(null)
          }, time),
        )
      })
  }

  return {
    debounce,
  }
}
