import React from 'react'
import BasicArrayInput from './BasicArrayInput'
import ObjectArrayInput from './ObjectArrayInput'

export default ({ property = {}, value, onChange, ...props }) => {
  const inputProps = {
    property,
    value,
    onChange,
    ...props,
  }
  const { items = {} } = property
  const { $ref, type } = items

  if (!!$ref || type === 'object') {
    return React.createElement(ObjectArrayInput, inputProps)
  }
  return React.createElement(BasicArrayInput, inputProps)
}
