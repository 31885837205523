import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export type TProps = {
  value: boolean | null | 'loading'
  disabled?: boolean
  onSubmit: (value: true | null) => void
}

const ScreenshotAuthorization = (props: TProps) => {
  return (
    <FormControlLabel
      label='Screenshot'
      disabled={props.value === 'loading' || props.disabled === true}
      checked={props.value === true}
      onChange={(_, checked) => props.onSubmit(checked ? true : null)}
      control={<Checkbox color='primary' />}
    />
  )
}

export default ScreenshotAuthorization
