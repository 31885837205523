import { TextField, Tooltip, Zoom } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { useTranslate } from 'react-admin'
import { IInputProps } from 'src/types'

const StringInput: FunctionComponent<IInputProps<string>> = ({
  property,
  name,
  onChange,
  value,
  classes,
  ...props
}: IInputProps<string>) => {
  const translate = useTranslate()

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = ({ target: { value } }) => {
    onChange(value)
  }

  return (
    <Tooltip
      TransitionComponent={Zoom}
      placement='right'
      title={property.description || ''}
      arrow
    >
      <TextField
        fullWidth
        className={classes}
        variant={property?.multiline === true ? 'outlined' : undefined}
        onChange={handleChange}
        placeholder={props.placeholder ?? property.displayedName ?? name}
        label={property.displayedName ?? name}
        value={value || property.defaultValue || ''}
        helperText={
          props.invalid
            ? typeof props.error === 'string'
              ? translate(props.error)
              : translate(props.error.message, props.error.args)
            : ''
        }
        disabled={property && property.readOnly}
        multiline={property.multiline}
        maxRows={25}
        InputLabelProps={props.InputLabelProps}
      />
    </Tooltip>
  )
}

export default StringInput
