const prepareEventName = event => {
  const eventsLinks = {
    handleUpdateEntity: 'update',
    handleCreateEntity: 'create',
    handleDeleteEntity: 'delete',
    Updated: 'update',
    Created: 'create',
    Deleted: 'delete',
  }

  return eventsLinks[event]
}
// TODO: change params to obj type
const createEventName = (microService, entityName, eventName, type) => {
  const event = prepareEventName(eventName)
  const regex = /\(|\)/
  let name = `${microService} - ${type || 'On'} ${entityName} ${event}`
  name = name.replace(regex, '')

  return name
}

export default createEventName
