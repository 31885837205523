export default class Action {
  constructor(editor) {
    this.editor = editor
  }

  undo() {}
  redo() {}

  isExecutableConnectionAction(connection) {
    if (!connection?.input || !connection.output) return false
    const { input, output } = connection

    if (!input.node || !output.node) return false

    const inputNode = this.editor.components.get(input.node.name)
    const outputNode = this.editor.components.get(output.node.name)
    if (!inputNode || !outputNode) return false

    return true
  }
}
