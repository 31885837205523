import Rete from '@obvious.tech/rete'
import StringControl from '../../Controls/SocketControls/StringControl'
import { sockets } from '../..'
import SelectControl from '../../Controls/SelectControl'
import DefaultNode from '../../Components/CustomNode'
import NodeTypizer from '../../Components/NodeTypizer'

export default class ModuleInputComponent extends Rete.Component {
  constructor(isDiagram) {
    super('Module Input')
    this.task = {
      outputs: {},
    }
    this.isHidden = !!isDiagram
    this.contextMenuPath = ['Module']
    this.data.component = NodeTypizer(DefaultNode, {
      type: 'module',
    })
    this.module = {
      nodeType: 'input',
      socket: node => {
        return sockets[node.data.socketType]
      },
    }
  }

  builder(node) {
    const options = Object.keys(sockets).map(s => ({
      value: s,
      label: s.split('Socket')[0],
    }))
    const handleOutput = name => {
      node.outputs.forEach(o => {
        o.connections.forEach(connection =>
          this.editor.removeConnection(connection),
        )
        node.removeOutput(o)
      })
      if (name) {
        node.addOutput(
          new Rete.Output('output', name.split('Socket')[0], sockets[name]),
        )
      }
      node.update()
      setTimeout(() => {
        this.editor.view.updateConnections({
          node,
        })
      }, 1)
    }

    return node
      .addControl(
        new SelectControl(
          this.editor,
          node,
          'socketType',
          'Entity',
          handleOutput,
          options,
        ),
      )
      .addControl(new StringControl(this.editor, node, 'name', 'Socket name'))
  }
}
