import React, { useState } from 'react'
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CloseIcon from '@material-ui/icons/Close'

import { Table } from 'src/components/DataGrid'

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  title: {
    fontWeight: 'bold',
  },
  table: {
    border: `1px solid ${theme.palette.grey[300]}`,
    '& thead': {
      backgroundColor: theme.palette.grey[300],
    },
    '& thead th': {
      fontWeight: 'bold',
    },
    '& td': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}))

const guides = [
  {
    control: 'Hold left mouse button & drag background',
    action: 'Pan',
  },
  {
    control: 'Mouse wheel up / down',
    action: 'Zoom in / out',
  },
  {
    control: 'Left mouse click',
    action: 'Select',
  },
  {
    control: 'Hold [B] & drag',
    action: 'Box select',
  },
  {
    control: 'Hold [Ctrl]',
    action: 'Add / Remove from selection',
  },
]

const columns = [
  {
    label: 'Control',
    key: 'control',
  },
  {
    label: 'Action',
    key: 'action',
  },
]

const HelpDialog = (): JSX.Element => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <Box>
      <IconButton
        size='small'
        onClick={openDialog}
      >
        <HelpOutlineIcon />
      </IconButton>
      <Dialog
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography
            variant='h6'
            className={classes.title}
          >
            Help
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Table
            columns={columns}
            hover={false}
            rowData={guides}
            className={classes.table}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default HelpDialog
