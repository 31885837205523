import React from 'react'

import { TEntityOverrideProp } from 'src/types'

type TProps = {
  property: TEntityOverrideProp
  name: string
  record: Record<string, unknown>
}

const CustomDateField = ({ property, name, record }: TProps): JSX.Element => {
  if (!record) return <span />

  const { getValue } = property

  const value =
    typeof getValue === 'function'
      ? getValue({
          record,
          name,
          property,
        })
      : record[name]

  let formattedDate: string = 'Invalid date'
  if (
    typeof value === 'number' ||
    value instanceof Date ||
    typeof value === 'string'
  ) {
    const date = new Date(value)
    formattedDate = Intl.DateTimeFormat('en-GB', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(date)
  }

  return <span>{formattedDate}</span>
}

export default CustomDateField
