import { useEffect } from 'react'
import { IPassport, IAlarm } from '@obvious.tech/constellation'
import api, { TApiError, TEntity } from 'src/utils/api'
import Loadable, { TLoadable } from 'src/utils/Loadable'

const getPassportByID = api.getResourceByID<IPassport>(
  'authentication/api/Passport',
)

export type TUseAssignedPassportResult = TLoadable<
  TEntity<IPassport> | undefined,
  TApiError
>

export const useAssignedPassport = ({
  entity,
  authorization,
}: {
  entity: TEntity<IAlarm>
  authorization: string | undefined
}): TUseAssignedPassportResult => {
  const [passport, getPassport] = api.useApiAction(getPassportByID)
  const assignedPassportId = entity.assignedPassportId

  useEffect(() => {
    if (assignedPassportId === undefined) return
    return getPassport({
      id: assignedPassportId,
      token: authorization,
    })
  }, [assignedPassportId, authorization, getPassport])

  if (assignedPassportId === undefined) {
    return Loadable.success(undefined)
  }
  return passport
}

export default useAssignedPassport
