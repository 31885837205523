import React from 'react'
import { ReferenceField } from 'react-admin'
import { connect } from 'react-redux'
import CustomFields from '.'

const getCustomFieldSpecificProps = (type, property) => {
  switch (type) {
    case 'Image':
      return {
        source: property.referencedField || property.source,
      }
    default:
      return {}
  }
}

const CustomReferenceField = ({ name, property, entities, ...props }) => {
  const referencedEntityName = property.referenceTo?.split('/')?.pop()
  if (!referencedEntityName) return <span />
  const referencedEntity = entities[referencedEntityName]
  const referencedField = property.referencedField ?? 'id'
  const referencedProperty = {
    ...referencedEntity.global.properties[referencedField],
    ...(referencedEntity.list.properties[referencedField] ?? {}),
  }

  return (
    <ReferenceField
      {...props}
      label={property.displayedName || name}
      reference={property.referenceTo}
      source={property.referenceSource ?? props.source}
    >
      {React.createElement(CustomFields[referencedProperty.type], {
        label: property.displayedName ?? name,
        name: referencedField,
        property,
        ...getCustomFieldSpecificProps(referencedProperty.type, property),
      })}
    </ReferenceField>
  )
}

const mapStateToProps = state => {
  return {
    entities: state.definitions.entities,
  }
}

export default connect(mapStateToProps, null)(CustomReferenceField)
