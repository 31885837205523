import { useEffect } from 'react'
import { IAgentActivityReport, IAgent } from '@obvious.tech/constellation'

import api, { TApiError, TEntity } from 'src/utils/api'
import Loadable, { TLoadable } from 'src/utils/Loadable'

const getAgentByPassportID = ({ id, token }: { id: string; token?: string }) =>
  api.getResourcesWithFilters<IAgent>('units/api/Agent')({
    filters: [['passportId', api.Filter.Eq, id]],
    token,
  })

export type TProps = {
  entity: TEntity<IAgentActivityReport>
  authorization?: string
}

export type TUseAgentAssignedPassportResult = TLoadable<
  TEntity<IAgent>[] | undefined,
  TApiError
>

const useAgentAssignedPassport = ({
  entity,
  authorization,
}: TProps): TUseAgentAssignedPassportResult => {
  const [agent, getAgent] = api.useApiAction(getAgentByPassportID)
  const assignedPassportId = entity.passportId

  useEffect(() => {
    if (assignedPassportId === undefined) return
    return getAgent({
      id: assignedPassportId,
      token: authorization,
    })
  }, [assignedPassportId, authorization, getAgent])

  if (assignedPassportId === undefined) {
    return Loadable.success(undefined)
  }
  return agent
}

export default useAgentAssignedPassport
