import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

import * as theme from '../theme'

export const getDirection = (lang: string) =>
  ['ar', 'he', 'fa'].includes(lang) ? 'rtl' : 'ltr'

export const createDirectionalStyles = (baseStyles: any, lang: string) => {
  const direction = getDirection(lang)
  const isRTL = direction === 'rtl'

  return {
    container: {
      flexDirection: isRTL ? 'row-reverse' : 'row',
      textAlign: isRTL ? 'right' : 'left',
      fontFamily: isRTL ? 'RobotoArabic' : 'Roboto',
    },
    text: {
      direction,
      fontFamily: isRTL ? 'RobotoArabic' : 'Roboto',
    },
    textAlign: {
      textAlign: isRTL ? 'right' : 'left',
      fontFamily: isRTL ? 'RobotoArabic' : 'Roboto',
    },
    ...baseStyles,
  }
}

export const commonStyles = StyleSheet.create({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: theme.fontSize.pageTitle,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: theme.fontSize.pageSubtitle,
    fontWeight: 'normal',
  },
  documentHeader: {
    backgroundColor: theme.colors.documentHeaderBg,
    color: theme.colors.documentHeader,
    fontWeight: 'bold',
  },
  detailsRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    fontSize: theme.fontSize.text,
    fontWeight: 'normal',
    paddingBottom: theme.padding(2),
    paddingTop: theme.padding(1),
  },
  padding: {
    paddingBottom: 0,
  },
})

export const renderTitleContainer = (
  title: string,
  subtitle: string,
  lang: string,
  customStyle: { paddingBottom: number } | object = {},
): JSX.Element => {
  const directionalStyles = createDirectionalStyles(commonStyles, lang)

  return (
    <View
      style={[
        commonStyles.titleContainer,
        directionalStyles.container,
        customStyle,
      ]}
    >
      <Text style={[commonStyles.title, directionalStyles.text]}>{title}</Text>
      <Text style={[commonStyles.title, directionalStyles.text]}>
        {subtitle}
      </Text>
    </View>
  )
}

export const renderDetails = (details: string, lang: string): JSX.Element => {
  const directionalStyles = createDirectionalStyles(commonStyles, lang)

  return (
    <View style={directionalStyles.detailsRoot}>
      <Text style={directionalStyles.details}>{details}</Text>
    </View>
  )
}
