import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import { getControl } from '../../../../helpers/ControlHelper'

export default class TimerComponent extends Rete.Component {
  constructor(name) {
    super(name)
    this.contextMenuPath = ['Actions']
    this.task = {
      outputs: {
        result: 'option',
      },
    }
  }

  builder(node) {
    const inp1 = new Rete.Input('act', 'Execute', sockets.actionSocket)
    const inp2 = new Rete.Input('input2', 'Timeout', sockets.numberSocket)
    inp2.addControl(
      getControl(this.editor, node, 'number', 'input2', 'Timeout'),
    )
    const out = new Rete.Output('result', 'Then', sockets.actionSocket)

    return node.addInput(inp1).addInput(inp2).addOutput(out)
  }

  async worker(node, inputs) {
    const controlValues = Object.keys(node.data).reduce(
      (prev, key) =>
        node.data[key]
          ? {
              ...prev,
              [key]: node.data[key],
            }
          : prev,
      {},
    )
    const inputsValues =
      inputs &&
      Object.keys(inputs).reduce(
        (prev, key) => ({
          ...prev,
          [key]: inputs[key][0],
        }),
        {},
      )
    const values = {
      ...controlValues,
      ...inputsValues,
    }
    if (values.input2 !== undefined) {
      const startDate = new Date()
      let currentDate = null
      do {
        currentDate = new Date()
      } while (currentDate - startDate < values.input2)
    }
  }
}
