import Rete from '@obvious.tech/rete'
import DecomposeControl from '../Controls/DecomposeControl'

export default class OutputWithDecompose extends Rete.Output {
  constructor(key, title, socket, editor) {
    super(key, title, socket, undefined)
    const control = new DecomposeControl(key, editor)
    this.control = control
    control.parent = this
  }

  isOutputForObject() {
    return (
      this.socket &&
      this.socket.compatible.findIndex(
        c => c.name.toLowerCase() === 'object',
      ) !== -1
    )
  }

  showControl() {
    return this.isOutputForObject() && this.control !== null
  }
}
