import { TEntityOverride } from 'src/types'

const override: TEntityOverride = {
  titleField: 'id',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    properties: {
      passportId: {
        displayedName: 'Passport',
        referencedField: 'username',
        referenceTo: 'authentication/api/Passport',
      },
    },
  },
  edit: {
    properties: {
      passportId: {
        displayedName: 'Passport',
        referencedField: 'username',
        referenceTo: 'authentication/api/Passport',
      },
    },
  },
}

export default override
