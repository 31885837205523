import React, { useCallback, useMemo } from 'react'
import {
  TablePagination,
  TablePaginationBaseProps,
  Toolbar,
} from '@material-ui/core'
import {
  useListPaginationContext,
  sanitizeListRestProps,
  useNotify,
} from 'react-admin'
import { useTranslation } from 'react-i18next'

import PaginationActions from './PaginationActions'
import PaginationLimit from './PaginationLimit'

interface IPaginationProps extends TablePaginationBaseProps {
  rowsPerPageOptions?: number[]
  actions?: any
  limit?: React.ReactElement
}

const Pagination = (props: IPaginationProps): JSX.Element => {
  const { rowsPerPageOptions, actions, limit, ...rest } = props
  const { loading, page, perPage, total, setPage, setPerPage } =
    useListPaginationContext(props)
  const notify = useNotify()
  const { t } = useTranslation()

  const totalPages = useMemo(() => Math.ceil(total / perPage), [perPage, total])
  const rowsPerPage = useMemo(
    () =>
      window.isNullish(rowsPerPageOptions) ? [5, 10, 25] : rowsPerPageOptions,
    [rowsPerPageOptions],
  )
  const ActionsComponent = useMemo(
    () => (window.isNullish(actions) ? PaginationActions : actions),
    [actions],
  )
  const LimitComponent = useMemo(
    () => (window.isNullish(limit) ? <PaginationLimit /> : limit),
    [limit],
  )

  const handlePageChange = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number,
    ) => {
      if (!window.isNullish(event)) event.stopPropagation()

      if (page < 0 || page > totalPages - 1) {
        notify(
          t('navigation.page_out_of_boundaries', {
            page: page + 1,
          }),
          'warning',
        )
        return
      }

      setPage(page + 1)
    },
    [totalPages, setPage, t, notify],
  )

  const handlePerPageChange = useCallback(
    event => {
      setPerPage(event.target.value)
    },
    [setPerPage],
  )

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      t('navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [t],
  )

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant='dense' /> : LimitComponent
  }

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onPageChange={handlePageChange}
      component='div'
      onRowsPerPageChange={handlePerPageChange}
      ActionsComponent={ActionsComponent}
      labelRowsPerPage={t('navigation.page_rows_per_page')}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPage}
      {...sanitizeListRestProps(rest)}
    />
  )
}

export default React.memo(Pagination)
