import Rete from '@obvious.tech/rete'
import React, { Fragment } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = {
  dialogContainer: {
    width: '40wh',
  },
  methodContainer: {
    flex: 1,
  },
  endPointContainer: {
    flex: 4,
  },
  urlContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  parametersContainer: {
    width: '40wh',
  },
}

export default class GenericDecomposeControl extends Rete.Control {
  component = ({
    label,
    title,
    keysList,
    handleChangeKey,
    handleChangeType,
    addParam,
    removeParam,
    handleDialog,
    open,
  }) => {
    return (
      <Fragment key='GenericDecomposeControl'>
        <Button
          onClick={handleDialog(true)}
          color='primary'
          variant='outlined'
        >
          {label}
        </Button>
        <Dialog
          onClose={handleDialog(false)}
          open={open}
          fullWidth
          key={'objectDecomposeDialog'}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <div style={styles.parametersContainer}>
              <List
                style={{
                  margin: 4,
                  flex: 1,
                }}
              >
                {keysList &&
                  keysList.map((objectElement, i) => (
                    <ListItem
                      key={objectElement.key + '_' + objectElement.id}
                      id={'objectKeyListItem_' + objectElement.id}
                    >
                      <div
                        style={{
                          margin: 4,
                          flex: 1,
                        }}
                        id={'objectKeyContainer_' + objectElement.id}
                      >
                        <FormControl
                          variant='outlined'
                          style={{
                            minWidth: 100,
                          }}
                          key={`objectKeyForm_${objectElement.id}`}
                        >
                          <TextField
                            onChange={e =>
                              handleChangeKey(objectElement.id, e.target.value)
                            }
                            error={false}
                            id={'genericObjectController_' + i}
                            key={`objectKey_${i}`}
                            label='Key'
                            defaultValue={objectElement.key}
                          />
                        </FormControl>
                      </div>
                      <div
                        style={{
                          margin: 4,
                          flex: 1,
                        }}
                      >
                        <FormControl
                          variant='outlined'
                          style={{
                            minWidth: 100,
                          }}
                        >
                          <InputLabel id='demo-simple-select-outlined-label'>
                            Method :
                          </InputLabel>
                          <Select
                            id={`genericObjectControllerSelect_${i}`}
                            value={objectElement.type || ''}
                            onChange={e => {
                              handleChangeType(objectElement.id, e.target.value)
                            }}
                          >
                            <MenuItem value=''>
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={'string'}>string</MenuItem>
                            <MenuItem value={'number'}>number</MenuItem>
                            <MenuItem value={'boolean'}>boolean</MenuItem>
                            <MenuItem value={'object'}>object</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          margin: 4,
                          flex: 1,
                        }}
                      >
                        <IconButton
                          aria-label='delete'
                          onClick={() => {
                            removeParam(objectElement.id)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </ListItem>
                  ))}
                <ListItem>
                  <Button onClick={addParam}>ADD</Button>
                </ListItem>
              </List>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }

  constructor(
    emitter,
    node,
    title,
    label,
    handleRemoveKey,
    handleUpdateKey,
    handleUpdateType,
    handleAddKey,
    optionKey,
  ) {
    super(title)
    this.title = title
    this.emitter = emitter

    const initial = node.data.keysList || []

    this.props = {
      title,
      label,
      keysList: initial,
      open: node.data.open || false,
      removeParam: idx => {
        const newValues = this.props.keysList.filter(
          objectAttribute => idx !== objectAttribute.id,
        )
        handleRemoveKey && handleRemoveKey(idx)
        this.props.keysList = newValues
        this.putData('keysList', newValues)
        this.emitter.trigger('process')
        this.update()
      },
      addParam: () => {
        const idList = this.props.keysList.map(value => value.id)
        let id = 0
        while (idList.length && idList.includes(id)) {
          id += 1
        }
        const newValues = [
          ...this.props.keysList,
          {
            id,
            key: '',
            type: '',
          },
        ]
        this.props.keysList = newValues
        this.putData('keysList', newValues)
        handleAddKey && handleAddKey(id)
        this.emitter.trigger('process')
        this.update()
      },
      handleDialog: value => () => {
        this.props.open = value
        this.putData('open', value)
        this.emitter.trigger('process')
        this.update()
      },
      handleChangeKey: (idx, newKey) => {
        const oldKey = this.props.keysList[idx].key
        const newValues = this.props.keysList.map(objectElement =>
          objectElement.id === idx
            ? {
                ...objectElement,
                key: newKey,
              }
            : objectElement,
        )
        handleUpdateKey && handleUpdateKey(oldKey, newKey, idx)
        this.props.keysList = newValues
        this.putData('keysList', newValues)
        this.emitter.trigger('process')
        // this.update();
      },
      handleChangeType: (idx, type) => {
        const id = this.props.keysList[idx].id
        const newValues = this.props.keysList.map((objectElement, i) =>
          idx === i
            ? {
                ...objectElement,
                type,
              }
            : objectElement,
        )
        handleUpdateType && handleUpdateType(id, type)
        this.props.keysList = newValues
        this.putData('keysList', newValues)
        this.emitter.trigger('process')
        this.update()
      },
    }
  }
}
