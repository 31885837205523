import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useUpdate } from 'react-admin'

export default ({ record = {}, source, basePath }) => {
  const [value, setValue] = useState(record[source])
  const [update, { loading, loaded, data }] = useUpdate(
    basePath,
    record.id,
    {
      [source]: value === 'active' ? 'stopped' : 'active',
    },
    record,
  )

  useEffect(() => {
    if (!loading && loaded) {
      setValue(data[source])
    }
  }, [loaded, loading, setValue, data, source])

  return (
    <div onClick={e => e.stopPropagation()}>
      <FormControlLabel
        control={
          <Switch
            checked={(data?.[source] || record[source]) === 'active'}
            disabled={loading}
            onChange={update}
          />
        }
        label={
          <Typography variant='body2'>
            {data?.[source] || record[source]}
          </Typography>
        }
      />
    </div>
  )
}
