import React from 'react'
import { FileField, FileInput } from 'react-admin'

const CustomFileInput = ({ property, name, ...rest }) => {
  return (
    <FileInput
      source={name}
      label={property.displayedName ?? name}
      {...rest}
    >
      <FileField
        source='src'
        title='title'
      />
    </FileInput>
  )
}

CustomFileInput.defaultProps = {
  addLabel: false,
}

export default CustomFileInput
