import { IconButton, makeStyles } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { LatLng, LatLngTuple } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { Rectangle } from 'react-leaflet'
import { connect } from 'react-redux'

import { getProperty } from 'src/parser/EntitiesCustomization'
import Map from '../Map/Map'
import ObjectInput from './ObjectInput'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  coordinateLabel: {},
  CoordinatesInput: {},
  coordinateMap: {},
  coordinateInputs: {
    margin: 8,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  fullfill: {},
})

const MapManifestInput = ({
  name,
  property,
  record,
  objects,
  onChange,
  value,
  classes,
  ...rest
}) => {
  const ownClasses = useStyles()
  const boundingBox = value?.boudingBox ?? {}
  const { north, south, east, west } = boundingBox

  const [bounds, setBounds] = useState<LatLngTuple[]>([])
  const [manifestProperty, setManifestProperty] = useState({})

  useEffect(() => {
    if (north && east && south && west) {
      setBounds([
        [north, east],
        [south, west],
      ])
    }
  }, [boundingBox, east, north, south, west])

  useEffect(() => {
    if (!objects?.MapManifest) return

    const mapManifestEntity = {
      ...objects.MapManifest,
    }
    if (!mapManifestEntity.properties) return

    // override mapManifest properties, so they can be rendered correctly in GenericInput
    Object.keys(mapManifestEntity.properties).forEach(key => {
      const property = getProperty(key, mapManifestEntity.properties[key])
      mapManifestEntity.properties[key] = property
    })

    setManifestProperty(mapManifestEntity)
  }, [objects])

  const onClickMap = (e: { latlng: LatLng }) => {
    const { lat, lng } = e.latlng
    if (!bounds?.length) {
      setBounds([[lat, lng]])
    } else if (bounds.length === 1) {
      setBounds([bounds[0], [lat, lng]])

      onChange({
        ...value,
        boundingBox: {
          north: lat > bounds[0][0] ? lat : bounds[0][0],
          east: lng > bounds[0][1] ? lng : bounds[0][1],
          south: lat < bounds[0][0] ? lat : bounds[0][0],
          west: lng < bounds[0][1] ? lng : bounds[0][1],
        },
      })
    }
  }
  return (
    <div className={classes}>
      <div className={ownClasses.coordinateLabel}>
        <label>{property.displayedName}</label>
      </div>
      <div className={ownClasses.flex}>
        <Map
          center={
            north && south && east && west
              ? {
                  lat: (north + south) / 2,
                  lng: (east + west) / 2,
                }
              : {
                  lat: 48,
                  lng: 2,
                }
          }
          // length={4}
          zoom={13}
          onclick={onClickMap}
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          {bounds.length === 2 && (
            <Rectangle
              bounds={bounds}
              color='black'
            />
          )}
        </Map>
        <div className={ownClasses.coordinateInputs}>
          <div className={ownClasses.fullfill}>
            <IconButton
              aria-label='clear'
              color='primary'
              onClick={() => {
                onChange(record[name])
                setBounds([])
              }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <ObjectInput
            value={value}
            onChange={onChange}
            property={manifestProperty}
            name={'Map manifest'}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    objects: state.definitions.objects || {},
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MapManifestInput)
