import React from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  listItem: {
    paddingLeft: '1rem',
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: '1rem',
  },
  sidebarIsOpen: {
    paddingLeft: 16,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
}

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  classes,
  children,
}) =>
  children.length > 1 ? (
    <>
      <ListItem
        dense
        button
        onClick={handleToggle}
        className={classes.listItem}
      >
        <ListItemText
          primary={isOpen ? name : ''}
          secondary={isOpen ? '' : name}
          className={classes.listItemText}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={isOpen}
        timeout='auto'
      >
        <List
          dense
          component='div'
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  ) : (
    children
  )

const enhance = withStyles(styles)

export default enhance(SubMenu)
