import { TPathsOf } from 'src/types'

export const Filter = {
  /** Equals */
  Eq: '==',
  /** Not equals */
  NEq: '!=',
  /** Greater than */
  Gt: '>',
  /** Less than */
  Lt: '<',
  /** Greater than or equal to */
  GtEq: '>=',
  /** Less than or equal to */
  LtEq: '<=',
  /** Contains */
  Contains: '@=',
  /** Starts with */
  Starts: '_=',
  /** Ends with */
  Ends: '_-=',
  /** Does not Contains */
  NContains: '!@=',
  /** Does not Starts with */
  NStarts: '!_=',
  /** Does not Ends with */
  NEnds: '!_-=',
  /** Case-insensitive string Contains */
  ContainsCi: '@=*', //
  /** Case-insensitive string Starts with */
  StartsCi: '_=*', //
  /** Case-insensitive string Ends with */
  EndsCi: '_-=*', //
  /** Case-insensitive string Equals */
  EqCi: '==*', //
  /** Case-insensitive string Not equals */
  NEqCi: '!=*', //
  /** Case-insensitive string does not Contains */
  NContainsCi: '!@=*', //
  /** Case-insensitive string does not Starts with */
  NStartsCi: '!_=*',
} as const

// Constellation is using https://github.com/Biarity/Sieve
type TFilterOp = (typeof Filter)[keyof typeof Filter]
type TFilterValue =
  | number
  | string
  | null
  | Date
  | Array<number | string | null>
type TFilterKey<T = any> = TPathsOf<T> | Array<TPathsOf<T>>

// probably incomplete
const needsEscaping = [...Object.values(Filter), '(', ')', '|', 'null']

const sanitizeSieveString = (dirty: string): string => {
  return needsEscaping.reduce<string>((sanitized, specialSequence) => {
    return sanitized.replaceAll(specialSequence, `\\${specialSequence}`)
  }, dirty)
}

const stringifyValue = (val: Exclude<TFilterValue, any[]>): string => {
  if (val === null) return 'null'
  if (val instanceof Date) return encodeURIComponent(val.toISOString())
  return sanitizeSieveString(val.toString())
}

const sanitizeFilterValue = (
  val: TFilterValue,
): string[] | string | undefined => {
  if (!Array.isArray(val)) return stringifyValue(val)

  const values = val.map(stringifyValue)
  if (val.length === 0) return undefined
  if (values.length === 1) return values[0]
  return values
}

const stringifyFilterValue = (val: TFilterValue): string | undefined => {
  const values = sanitizeFilterValue(val)

  if (values === undefined) return undefined
  if (!Array.isArray(values)) return values
  return values.join('|')
}

const stringifyFilterKey = (key: TFilterKey<any>): string | undefined => {
  const keys = sanitizeFilterValue(key)

  if (keys === undefined) return undefined
  if (!Array.isArray(keys)) return keys
  return `(${keys.join('|')})`
}

const throwInvalidFilter = ({
  kind,
  given,
  parsed,
}:
  | {
      kind: 'key'
      given: TFilterKey<any>
      parsed: string | undefined
    }
  | {
      kind: 'value'
      given: TFilterValue
      parsed: string | undefined
    }): never => {
  throw new Error(
    `Invalid filter ${kind}:\n` +
      `${JSON.stringify(
        {
          given,
          parsed,
        },
        undefined,
        2,
      )}`,
  )
}

/**
 *
 * @throws {Error} If the filter is invalid
 */
export const filterOptsToQueryString = (opts: TFilterOpts): string => {
  return opts
    .reduce<string[]>((filters, [key, op, val]) => {
      const stringKey = stringifyFilterKey(key)
      const stringValue = stringifyFilterValue(val)

      if (stringKey === '' || stringKey === undefined) {
        throwInvalidFilter({
          kind: 'key',
          given: key,
          parsed: stringKey,
        })
      }
      if (stringValue === undefined) {
        throwInvalidFilter({
          kind: 'value',
          given: val,
          parsed: stringValue,
        })
      }

      return [...filters, `${stringKey}${op}${stringValue}`]
    }, [])
    .join(',')
}

export type TFilterOpts<T = any> = Array<
  [TFilterKey<T>, TFilterOp, TFilterValue]
>
