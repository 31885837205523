import React, { useEffect, useState } from 'react'
import { IconButton, TableRow, Button, TableCell } from '@material-ui/core'
import { useCreate } from 'react-admin'

import DeleteIcon from '@material-ui/icons/Delete'

import { useDeleteGroup } from 'src/utils/hooks'

type TProps = {
  group: string
}

export default ({ group }: TProps) => {
  const [callDelete, { loading, loaded }] = useDeleteGroup()
  const [recreate, { loading: createLoading, loaded: createLoaded }] =
    useCreate('authentication/api/Group', {
      name: group,
      groups: [group],
    })

  const [deleted, setDeleted] = useState(false)
  useEffect(() => {
    if (loaded && !loading) setDeleted(true)
  }, [loading, loaded])
  useEffect(() => {
    if (createLoaded && !createLoading) setDeleted(false)
  }, [createLoading, createLoaded])

  return (
    <TableRow key={group}>
      <TableCell
        component='th'
        scope='row'
      >
        {group}
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        align='right'
      >
        {!deleted ? (
          <IconButton
            aria-label='expand row'
            size='small'
            disabled={loading}
            onClick={async () => await callDelete(group)}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Button onClick={recreate}>Undo deletion</Button>
        )}
      </TableCell>
    </TableRow>
  )
}
