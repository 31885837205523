import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import NumberControl from '../../Controls/SocketControls/NumberControl'

export default class NumberComponent extends Rete.Component {
  constructor() {
    super('Number')
    this.task = {
      outputs: {
        num: 'output',
      },
    }
    this.contextMenuPath = ['Inputs']
  }

  builder(node) {
    const out1 = new Rete.Output('num', 'Number', sockets.numberSocket)
    const ctrl = new NumberControl(this.editor, node, 'num', '', null)

    return node.addControl(ctrl).addOutput(out1)
  }

  worker(node, inputs, data) {}
}
