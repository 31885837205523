import React from 'react'
import { Box, BoxProps } from '@material-ui/core'

interface IProps extends BoxProps {
  children: React.ReactNode
}

const AuthorizationBox = ({ children, ...boxProps }: IProps): JSX.Element => {
  return (
    <Box
      m={2}
      borderRadius={4}
      boxShadow={2}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default AuthorizationBox
