import Rete from '@obvious.tech/rete'
import { sockets } from '../..'

export default class PushToArrayComponent extends Rete.Component {
  constructor() {
    super('Push to array')
    this.task = {
      outputs: {
        out: 'output',
      },
    }
    this.contextMenuPath = ['Arrays']
  }

  builder(node) {
    const inp1 = new Rete.Input('act', 'Execute', sockets.actionSocket)
    const inp2 = new Rete.Input('inp', 'Any Data', sockets.anyTypeSocket)
    const inp3 = new Rete.Input(
      'collectedArray',
      'To Array',
      sockets.anyTypeSocket,
    )

    return node.addInput(inp1).addInput(inp2).addInput(inp3)
  }
}
