import { TEntityOverride } from 'src/types'

import Flow from '../../../adminPanel/Flow'
import ListBulkActions from './Actions/ListBulkActions'
import EditDiagramToolbar from './Actions/EditDiagramToolbar'
import CreateDiagram from './Actions/CreateDiagram'
import StatusInput from 'src/components/flow/StatusInput'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      createdAt: {
        hidden: true,
      },
      data: {
        hidden: true,
      },
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    displayedListToolbar: ListBulkActions,
    properties: {
      status: {
        type: 'DiagramStatus',
      },
    },
  },
  create: {
    displayedComponent: CreateDiagram,
    properties: {
      id: {
        hidden: true,
      },
      data: {
        hidden: true,
      },
      lastUpdate: {
        hidden: true,
      },
      events: {
        hidden: true,
      },
      status: {
        displayedComponent: StatusInput,
        defaultValue: 'stopped',
      },
    },
  },
  edit: {
    displayedComponent: Flow,
    displayedFormToolbar: EditDiagramToolbar,
    properties: {
      events: {
        hidden: true,
      },
      lastUpdate: {
        hidden: true,
      },
      status: {
        displayedComponent: StatusInput,
      },
    },
  },
}

export default override
