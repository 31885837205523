// @ts-nocheck
import React from 'react'
import { Edit, Record, SimpleForm } from 'react-admin'
import {
  Box,
  Divider,
  Typography,
  Theme,
  makeStyles,
  BoxProps,
} from '@material-ui/core'
import { IPassport, IRole, IRoleTemplate } from '@obvious.tech/constellation'

import EntityPropertiesList from 'src/components/EntityPropertiesList'
import CustomTitle from 'src/adminPanel/screens/Title/Title'
import ConnectedInput from 'src/adminPanel/screens/inputs/ConnectedInput'
import { TEntityOverrideView } from 'src/types'
import EditAuthorizationToolbar from 'src/components/Authorizations/EditAuthorizationToolbar'

const useStyles = makeStyles((theme: Theme) => ({
  userEdit: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}))

interface IProps extends BoxProps {
  title: string
  recordNameField: 'username' | 'name'
  transform?: (record: Record) => Record
  resource: string
  entity:
    | TEntityOverrideView<'edit', IPassport>
    | TEntityOverrideView<'edit', IRoleTemplate>
    | TEntityOverrideView<'edit', IRole>
  basePath: string
  id: string
}

const EditAuthorizationForm = ({
  title,
  transform,
  recordNameField,
  resource,
  entity,
  id,
  basePath,
  ...boxProps
}: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box
      boxShadow={3}
      mr={2}
      minWidth='400px'
      borderRadius={4}
      {...boxProps}
    >
      <Box p={2}>
        <Typography variant='h5'>{title}</Typography>
      </Box>
      <Divider />
      <Edit
        title={
          <CustomTitle
            record={null}
            entity={entity}
          />
        }
        id={id}
        resource={resource}
        basePath={basePath}
        className={classes.userEdit}
        transform={transform}
      >
        <SimpleForm
          toolbar={
            <EditAuthorizationToolbar
              alwaysEnableSaveButton
              recordNameField={recordNameField}
            />
          }
        >
          {
            // @ts-expect-error
            <EntityPropertiesList
              entity={entity}
              childProps={{}}
              render={({ propertyKey, property, entity }) => (
                <ConnectedInput
                  classes={{}}
                  id={propertyKey}
                  key={propertyKey}
                  name={propertyKey}
                  resource={resource}
                  property={property}
                />
              )}
            />
          }
        </SimpleForm>
      </Edit>
    </Box>
  )
}

export default EditAuthorizationForm
