import { Node } from './node'

export class Selected {
  private _list: Set<Node> = new Set()
  private _wasSelectedOnPress = false

  get list() {
    return [...this._list]
  }

  /**
   * @returns true if element was added to selection, false if it existed before the call
   */
  select(item: Node): boolean {
    const hasItem = this.contains(item)

    if (!hasItem) {
      this._list.add(item)
      item.update()
      return true
    }
    return false
  }

  deselect(item: Node) {
    if (this._list.delete(item)) item.update()
  }

  onNodePress(item: Node, ctrlHeld: boolean = false) {
    if (ctrlHeld) {
      this._wasSelectedOnPress = this.select(item)
      return
    }

    if (!this.contains(item)) {
      this.clear()
      this._wasSelectedOnPress = this.select(item)
    }
  }

  onNodeRelease(
    item: Node,
    ctrlHeld: boolean = false,
    wasMoved: boolean = false,
  ) {
    if (wasMoved) return

    if (ctrlHeld) {
      if (!this._wasSelectedOnPress) this.deselect(item)
      return
    }

    this.each(node => {
      if (item !== node) this.deselect(node)
    })
  }

  clear() {
    this.each(node => this.deselect(node))
  }

  contains(item: Node) {
    return this._list.has(item)
  }

  each(callback: (n: Node) => void) {
    this._list.forEach(callback)
  }
}
