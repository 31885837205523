import Rete from '@obvious.tech/rete'
import BooleanControl from '../../Controls/SocketControls/BooleanControl'
import { sockets } from '../..'

export default class BooleanComponent extends Rete.Component {
  constructor() {
    super('Boolean')
    this.task = {
      outputs: {
        bool: 'output',
      },
    }
    this.contextMenuPath = ['Inputs']
  }

  builder(node) {
    const out1 = new Rete.Output('bool', 'Boolean', sockets.booleanSocket)
    const ctrl = new BooleanControl(this.editor, node, 'bool')

    return node.addControl(ctrl).addOutput(out1)
  }

  worker(node, inputs, data) {}
}
