import { TEntityOverride } from 'src/types/index'

import { AlarmToPDF } from 'src/parser/shared/PDF/ReportToPdf'

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    hidden: true,
  },
  edit: {
    displayedComponent: AlarmToPDF,
  },
}

export default override
