import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

import * as theme from 'src/parser/shared/PDF/theme'
import { TEntity } from 'src/utils/api'
import { IAgentActivityReport } from '@obvious.tech/constellation'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  title: {
    textTransform: 'uppercase',
    color: 'black',
    fontWeight: 'bold',
    fontSize: 10,
  },
  root: {
    ...theme.styles.text,
    color: theme.colors.documentSubHeader,
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    color: 'black',
  },
})

export type TProps = {
  entity: TEntity<IAgentActivityReport>
}

const durationToString = (millis: number): string => {
  const MS_IN_MIN = 1000 * 60
  const MINS_IN_HOUR = 60

  const minsTotal = Math.floor(millis / MS_IN_MIN)
  const mins = minsTotal % MINS_IN_HOUR
  const hours = Math.floor(minsTotal / MINS_IN_HOUR)

  return `${hours}h ${mins}m`
}

export default ({ entity }: TProps): JSX.Element => {
  const { t } = useTranslation()
  let checkIn: Date | null = null
  if (typeof entity.checkIn?.timestamp === 'string') {
    try {
      checkIn = new Date(entity.checkIn.timestamp)
    } catch (error) {
      console.error('Error parsing check-in timestamp:', error)
    }
  }

  let checkOut: Date | null = null
  if (typeof entity.checkOut?.timestamp === 'string') {
    try {
      checkOut = new Date(entity.checkOut.timestamp)
    } catch (error) {
      console.error('Error parsing check-out timestamp:', error)
    }
  }

  const timeDiffInMs =
    checkIn !== null && checkOut !== null
      ? checkOut.valueOf() - checkIn.valueOf()
      : null

  return (
    <View style={styles.root}>
      <Text style={styles.title}>
        {`${t('agent_activity_report.attendance_duration')} : `}
      </Text>
      <Text style={styles.content}>
        {timeDiffInMs !== null
          ? durationToString(timeDiffInMs)
          : t('agent_activity_report.no_content')}
      </Text>
    </View>
  )
}
