import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import * as theme from './theme'

export const PageFooter = (): JSX.Element => {
  const render = React.useCallback(
    ({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`,
    [],
  )

  return (
    <Text
      fixed
      style={styles.pageFooter}
      render={render}
    />
  )
}

const FOOTER_FONTSIZE = theme.fontSize.text
const FOOTER_PADDING = theme.padding(4)

export const styles = StyleSheet.create({
  page: {
    fontFamily: theme.fontFamily,
    paddingTop: theme.spacing(2),
    paddingBottom: FOOTER_FONTSIZE + FOOTER_PADDING * 2,
  },
  pageFooter: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
    color: theme.colors.text,
    fontSize: FOOTER_FONTSIZE,
    lineHeight: 1,
    padding: FOOTER_PADDING,
  },
})
