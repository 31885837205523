import React from 'react'
import { TextField } from 'react-admin'

export const CustomStringField = ({ property, name, ...props }) => {
  return (
    <TextField
      {...props}
      source={name}
    />
  )
}
export default CustomStringField
