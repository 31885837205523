import { Emitter } from '../core/emitter'
import { EventsTypes } from '../events'
import { IO } from '../io'
import { Node } from '../node'

export class SocketView extends Emitter<EventsTypes> {
  el: HTMLElement
  type: string
  io: IO
  node: Node

  constructor(
    el: HTMLElement,
    type: string,
    io: IO,
    node: Node,
    emitter: Emitter<EventsTypes>,
  ) {
    super(emitter)
    this.el = el
    this.type = type
    this.io = io
    this.node = node

    this.trigger('rendersocket', { el, [type]: this.io, socket: io.socket })
  }

  getPosition({ position }: { position: number[] }): [number, number] {
    const el = this.el

    return [
      position[0] + el.offsetLeft + el.offsetWidth / 2,
      position[1] + el.offsetTop + el.offsetHeight / 2,
    ]
  }
}
