import { capitalize } from '@material-ui/core'

// for single letter or F1-12 buttons
const singleLetterRegex = /\b[a-zA-Z]{1}\b|\bf{1}([1-9]|1[0-2])\b/gi

const prettifyShortcut = shortcut => {
  if (Array.isArray(shortcut)) {
    return shortcut.map(el => prettifyShortcut(el)).join(' / ')
  }

  return shortcut.split('+').join(' + ').replace(singleLetterRegex, capitalize)
}

export default prettifyShortcut
