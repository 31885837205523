// @ts-nocheck
import { Record } from 'react-admin'

export { default as mapDefaultMicroservices } from './mapDefaultMicroservices'
export { default as getMaxPermissionLevel } from './getMaxPermissionLevel'

export type TMappedPermission = {
  entity: string
  permissionLevel: number | null
  source: string
}

export const filterPermissionsForEntity = (arr: Record[], entity: string) =>
  arr.filter(
    permission =>
      permission?.data?.entity === entity || permission?.data?.entity === '*',
  )
