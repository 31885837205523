// @ts-nocheck
import React from 'react'
import { ImageInput, Record } from 'react-admin'

import { useImageInputStyles } from 'src/components/inputs/ImageInput'

import ImageField from './ImageField'

interface IImageInputProps {
  name: string
  value: { id: string; name: string } | { rawFile: File }
  resource: string
  record: Record
}

const CustomImageInput = ({
  name,
  value,
  resource,
  record,
}: IImageInputProps): JSX.Element => {
  const imageInputClasses = useImageInputStyles()

  return (
    <ImageInput
      accept='image/*'
      source={name}
      multiple={false}
      classes={imageInputClasses}
    >
      <ImageField
        value={value}
        resource={resource}
        recordId={record.id}
      />
    </ImageInput>
  )
}

export default CustomImageInput
