import { IAlarm } from '@obvious.tech/constellation'

import { uncapitalize } from 'src/utils/str'
import Loadable, { TLoadable } from 'src/utils/Loadable'
import { TEntity } from 'src/utils/api'

type TDetailData = {
  AudioUrl: { url: string[] }
  ImageUrl: { url: string }
  VideoUrl: { url: string }
  ImageData: { base64: string }
  TextData: { text: string }
  Circle: {
    color: string
    radius: number
    filled: boolean
  }
  Polyline: { points: Array<[number, number]> }

  CarBarrierPassage: unknown
  DetectionsLocation: unknown
  ImageEntity: unknown
  RelatedMission: unknown
  SuspectImage: unknown
}

type TRawDetail<T extends keyof TDetailData = keyof TDetailData> = {
  [K in keyof TDetailData]: {
    type?: K
    data?: TDetailData[K]
  }
}[T]

type TParsedDetail<T extends keyof TDetailData = keyof TDetailData> = {
  AudioUrl: TDetailData['AudioUrl']['url']
  ImageUrl: Array<TDetailData['ImageUrl']['url']>
  VideoUrl: Array<TDetailData['VideoUrl']['url']>
  ImageData: Array<TDetailData['ImageData']['base64']>
  TextData: Array<TDetailData['TextData']['text']>
  Circle: Array<TDetailData['Circle']>
  Polyline: Array<TDetailData['Polyline']['points']>
  CarBarrierPassage: any[]
  DetectionsLocation: any[]
  ImageEntity: any[]
  RelatedMission: any[]
  SuspectImage: any[]
}[T]

const wrapIfDefined = <T>(v: T | undefined): [T] | undefined => {
  if (v === undefined) return undefined
  return [v]
}

const detailParsers: {
  [K in keyof TDetailData]?: (
    raw: TRawDetail<K>,
  ) => TParsedDetail<K> | undefined
} = {
  AudioUrl: raw => raw.data?.url,
  ImageUrl: raw => wrapIfDefined(raw.data?.url),
  VideoUrl: raw => wrapIfDefined(raw.data?.url),
  ImageData: raw => wrapIfDefined(raw.data?.base64),
  TextData: raw => wrapIfDefined(raw.data?.text),
  Circle: raw => wrapIfDefined(raw.data),
  Polyline: raw => wrapIfDefined(raw.data?.points),
}

const parseDetail = <T extends keyof TDetailData>(
  raw: TRawDetail<T>,
): TParsedDetail<T> | undefined => {
  if (raw.type === undefined) return undefined

  const parse = detailParsers[raw.type] as any

  return parse?.(raw)
}

export type TAdditionalDetails = {
  [K in keyof TDetailData as `${Uncapitalize<K>}s`]?: TParsedDetail<K>
}

export type TUseAdditionalDetailsResult = TLoadable<TAdditionalDetails>

export default ({
  alarm,
}: {
  alarm: TEntity<IAlarm>
}): TUseAdditionalDetailsResult => {
  const additionalDetails = alarm?.additionalDetails as
    | Array<TRawDetail>
    | undefined

  const varify = <S extends string>(type: S) => {
    return uncapitalize(`${type}s`)
  }

  const parsedDetails =
    additionalDetails?.reduce<TAdditionalDetails>((acc, rawDetail) => {
      if (rawDetail.type === undefined) return acc

      const parsedDetails = parseDetail(rawDetail)
      if (parsedDetails === undefined) return acc

      const key = varify(rawDetail.type)
      const detailsOfType: TParsedDetail = acc[key] ?? []

      return {
        ...acc,
        [key]: [...detailsOfType, ...parsedDetails],
      }
    }, {}) ?? {}

  return Loadable.success(parsedDetails)
}
