// @ts-nocheck
import { Record } from 'react-admin'

import { TState } from 'src/redux/microservices/types'
import { TAuthorizationPermission } from './Authorizations'

export type TMappedPermission = {
  entity: string
  permissionLevel: number | null
  source: string
}

export const parseDefaultMicroservices = (
  microservices: TState,
): TAuthorizationPermission[] => {
  return Object.entries(microservices)
    .map(
      ([microservice, entity]) => [
        ...entity.map(service => ({
          microservice,
          entity: service,
          permissionLevels: {
            role: null,
            user: null,
          },
          source: [],
        })),
      ],
      [],
    )
    .flat()
}

const getMaxPermissionLevel = (
  permissions: TMappedPermission[],
): number | null => {
  // @ts-expect-error
  const permissionsWithLevels: number[] = permissions
    .filter(permission => permission.permissionLevel !== null)
    .map(permission => permission.permissionLevel)

  return permissionsWithLevels.length > 0
    ? Math.max(...permissionsWithLevels)
    : null
}

export const mapAuthorizations = (
  authorizations: TAuthorizationPermission[],
  roleGroupPermissions: TMappedPermission[],
  passportGroupPermissions: TMappedPermission[],
): TAuthorizationPermission[] => {
  return authorizations.map(authorization => {
    const cAuthorization = {
      ...authorization,
    }
    const roleEntityPermissions = filterPermissionsForEntity(
      roleGroupPermissions,
      authorization.entity,
    )
    const passportEntityPermission = filterPermissionsForEntity(
      passportGroupPermissions,
      authorization.entity,
    )

    cAuthorization.source = combineAuthorizationSource([
      ...roleEntityPermissions,
      ...passportEntityPermission,
    ])
    cAuthorization.permissionLevels = {
      role: getMaxPermissionLevel(roleEntityPermissions),
      user: getMaxPermissionLevel(passportEntityPermission),
    }

    return cAuthorization
  })
}

export const filterPermissionsForEntity = (
  arr: TMappedPermission[],
  entity: string,
): TMappedPermission[] =>
  arr.filter(
    permission => permission.entity === entity || permission.entity === '*',
  )

export const mapPermissionForGroup = (name: string, permissions: Record[]) =>
  permissions
    .filter(({ group }) => group === name)
    .map(({ data, source }) => ({
      entity: data.entity,
      permissionLevel: data.level,
      source,
    }))

const combineAuthorizationSource = (
  permissions: TMappedPermission[],
): string[] => {
  if (permissions.length === 0) return []

  return Array.from(new Set(permissions.map(permission => permission.source)))
}
