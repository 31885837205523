import Rete from '@obvious.tech/rete'

const primitiveSocketsObject = {
  numberSocket: 'number',
  actionSocket: 'action',
  arraySocket: 'array',
  stringSocket: 'string',
  booleanSocket: 'boolean',
  objectSocket: 'object',
  functionSocket: 'function',
  noTypeSocket: 'no type',
  dateSocket: 'date',
  'date-timeSocket': 'date-time',
  byteSocket: 'byte',
  binarySocket: 'binary',
}

export const primitiveSockets = primitiveSocketsObject

export const getSockets = async definitions => {
  const anyTypeSocket = new Rete.Socket('any type')
  let sockets = Object.keys(primitiveSocketsObject).reduce(
    (acc, socket) => {
      acc[socket] = new Rete.Socket(primitiveSocketsObject[socket])
      acc[socket].combineWith(anyTypeSocket)
      return acc
    },
    {
      anyTypeSocket,
    },
  )

  ;['dateSocket', 'date-timeSocket', 'byteSocket', 'binarySocket'].forEach(
    socket => {
      sockets.stringSocket.combineWith(sockets[socket])
      sockets[socket].combineWith(sockets.stringSocket)
    },
  )
  Object.keys(definitions.entities).forEach(definitionKey => {
    sockets = {
      ...sockets,
      [`${definitionKey}Socket`]: new Rete.Socket(definitionKey),
      [`${definitionKey}ArraySocket`]: new Rete.Socket(definitionKey + 'Array'),
      [`${definitionKey}CallbackSocket`]: new Rete.Socket(
        definitionKey + 'Callback',
      ),
    }

    sockets[`${definitionKey}Socket`].combineWith(sockets.objectSocket)
    sockets[`${definitionKey}Socket`].combineWith(sockets.anyTypeSocket)
    sockets[`${definitionKey}ArraySocket`].combineWith(sockets.arraySocket)
    sockets[`${definitionKey}ArraySocket`].combineWith(sockets.anyTypeSocket)
  })
  Object.keys(definitions.enums).forEach(enumName => {
    sockets = {
      ...sockets,
      [`${enumName}Socket`]: new Rete.Socket(enumName),
    }
    sockets[`${enumName}Socket`].combineWith(sockets.anyTypeSocket)
  })
  return sockets
}

export default getSockets
