import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MenuItemLink, useTranslate } from 'react-admin'
import { makeStyles, List, ListItemText, capitalize } from '@material-ui/core'

import SubMenu from './subMenu'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(3),
    width: theme.sidebarWidth,
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'auto',
  },
  listItem: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
  listItemText: {
    fontSize: '1rem',
  },
}))

const Menu = ({ onMenuClick, open, microservices: menuConfiguration = {} }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()

  const [menuState, setMenuState] = useState(
    history.location.pathname
      ? {
          [history.location.pathname.split('/', 2)[1]]: true,
        }
      : {},
  )

  if (!open) return null
  const handleToggle = menuId => {
    setMenuState({
      ...menuState,
      [menuId]: !menuState[menuId],
    })
  }

  return (
    <List
      className={classes.root}
      dense
    >
      {Object.keys(menuConfiguration).map(menuKey => {
        const menuItem = menuConfiguration[menuKey]
        return Object.keys(menuItem.submenu).length ? (
          <SubMenu
            key={`${menuKey}_submenu`}
            name={capitalize(translate(menuKey))}
            handleToggle={() => handleToggle(menuKey)}
            isOpen={!!menuState[menuKey]}
            sidebarIsOpen={open}
          >
            {Object.keys(menuItem.submenu).map(entityKey => {
              const entity = menuItem.submenu[entityKey] || {}
              return (
                <MenuItemLink
                  key={entity.route || `/${menuKey}/api/${entityKey}`}
                  to={entity.route || `/${menuKey}/api/${entityKey}`}
                  primaryText={
                    <ListItemText
                      primary={capitalize(translate(entityKey))}
                      className={classes.listItemText}
                    />
                  }
                  onClick={onMenuClick}
                />
              )
            })}
          </SubMenu>
        ) : (
          <MenuItemLink
            className={classes.listItem}
            key={menuItem.route || `/${menuKey}`}
            to={menuItem.route || `/${menuKey}`}
            onClick={onMenuClick}
            primaryText={
              <ListItemText
                primary={capitalize(translate(menuKey))}
                className={classes.listItemText}
              />
            }
          />
        )
      })}
    </List>
  )
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
})

const enhance = connect(mapStateToProps, {})

export default enhance(Menu)
