import React from 'react'

export default (WrappedComponent, types) => {
  const { type, InputBlockComponent } = types
  return function CustomNodeWithType(props) {
    return (
      <WrappedComponent
        type={type}
        InputBlockComponent={InputBlockComponent}
        {...props}
      />
    )
  }
}
