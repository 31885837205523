import { IAgentActivityReport, IAlarm } from '@obvious.tech/constellation'
import CustomMarker from 'src/components/Map/Marker'
import CheckInOutMarker from 'src/components/Map/CheckInOutMarker'
import React, {
  useState,
  useEffect,
  useRef,
  ComponentProps,
  ReactElement,
} from 'react'
import { Map, TileLayer, withLeaflet, Circle, Polyline } from 'react-leaflet'
import PrintControlDefault from 'src/utils/leaflet-easyprint/react-leaflet-easyprint'
import { TEntity } from 'src/utils/api/index'

const MAP_HEIGHT = 400
const MAP_WIDTH = 1000

const PrintControl = withLeaflet(PrintControlDefault)

const getMapReady = (
  printEl: React.RefObject<PrintControlDefault>,
  setMap,
  setLoading,
): void => {
  const getMapSnapshot = (): (() => void) => {
    const onFinished = (e): void => {
      setMap(e.event)
      setLoading('Ready')
    }
    const onError = (e): void => {
      console.error(e)
      setLoading('Ready')
    }

    const map = (printEl.current?.leafletElement as { _map: L.Map } | undefined)
      ?._map

    map?.addEventListener('easyPrint-finished', onFinished)
    map?.addEventListener('easyPrint-error', onError)

    printEl.current?.printMap('A4Landscape', 'MyFileName')

    return () => {
      map?.removeEventListener('easyPrint-finished', onFinished)
      map?.removeEventListener('easyPrint-error', onError)
    }
  }
  getMapSnapshot()
}

type TPolyline = {
  points: Array<{ lat: number; lon: number }>
}

const transformCoords = (polyline: TPolyline): Array<[number, number]> => {
  return polyline.points.map(coords => [coords.lat, coords.lon])
}

type TLatLng = [number, number]
type TBounds = [TLatLng, TLatLng]

const RenderPDFContent = ({
  bounds,
  printEl,
  setLoading,
  children,
}: {
  printEl: any
  setLoading: (state: 'MapLoaded') => void
  bounds?: TLatLng | TBounds
  children: React.ReactNode
}): JSX.Element => {
  const { OSM_ENDPOINT } = window._env_

  const isLatLng = (it: unknown): it is TLatLng => {
    return (
      Array.isArray(it) &&
      it.length >= 2 &&
      it.every(it => typeof it === 'number')
    )
  }
  const isBounds = (it: unknown): it is TBounds => {
    return Array.isArray(it) && it.every(isLatLng)
  }

  const mapProps: Pick<
    ComponentProps<typeof Map>,
    'bounds' | 'center'
  > = isBounds(bounds)
    ? {
        bounds,
      }
    : {
        center: bounds,
      }

  return (
    <div
      style={{
        position: 'absolute',
        /** renders the Map outside of the parent element (hidden) */
        top: -MAP_HEIGHT,
      }}
    >
      <Map
        {...mapProps}
        zoom={5}
        fadeAnimation={false} // needed so that pdf doesn't include faded tiles
        style={{
          height: MAP_HEIGHT,
          width: MAP_WIDTH,
        }}
      >
        {typeof OSM_ENDPOINT === 'string' && OSM_ENDPOINT !== '' && (
          <>
            <TileLayer
              url={window._env_.OSM_ENDPOINT}
              onload={() => setLoading('MapLoaded')}
            />
            {children}
          </>
        )}
        <PrintControl
          // @ts-expect-error
          ref={printEl}
          tileWait={5000}
          outputMode={'event'}
          sizeModes={['A4Landscape']}
        />
      </Map>
    </div>
  )
}

export const AlarmMapProvider = ({
  entity,
  loading: currentLoading,
  circles = [],
  polylines = [],
  children,
  ...rest
}: {
  entity: TEntity<IAlarm>
  loading: any
  circles: any[]
  polylines: any[]
  children: ReactElement
} & Record<any, unknown>): JSX.Element => {
  const { location } = entity

  const [map, setMap] = useState(null)
  const [loading, setLoading] = useState(location == null ? 'Ready' : null)
  const printEl = useRef<PrintControlDefault>(null)

  entity.additionalDetails?.forEach(detail => {
    if (detail.type === 'Circle') circles.push(detail.data)
    if (detail.type === 'Polyline') polylines.push(detail.data)
  })

  const latLng: TLatLng | undefined = React.useMemo(() => {
    return typeof location === 'object' && location !== null
      ? [location.latitude, location.longitude]
      : undefined
  }, [location])

  useEffect(() => {
    if (latLng === undefined || loading !== 'MapLoaded') return

    getMapReady(printEl, setMap, setLoading)
  }, [latLng, printEl, loading, location])

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
      }}
    >
      {loading === 'Ready' ? null : (
        <RenderPDFContent
          bounds={latLng}
          printEl={printEl}
          setLoading={setLoading}
        >
          {location != null && <CustomMarker coordinates={location} />}
          {polylines.map(points => (
            <Polyline
              positions={transformCoords(points)}
              key={polylines.indexOf(points)}
            />
          ))}
          {latLng !== undefined &&
            circles.map(circle => (
              <Circle
                radius={circle.radius}
                color={circle.color}
                fill={circle.filled}
                key={circles.indexOf(circle)}
                center={{
                  lat: latLng[0],
                  lng: latLng[1],
                }}
              />
            ))}
        </RenderPDFContent>
      )}
      {React.cloneElement(children, {
        ...rest,
        map,
        entity,
        loading: loading !== 'Ready' || currentLoading,
      })}
    </div>
  )
}

export const AgentActivityMapProvider = ({
  entity,
  loading: currentLoading,
  children,
  ...rest
}: {
  entity: TEntity<IAgentActivityReport>
  loading: any
  children: ReactElement
}): JSX.Element => {
  const checkInLatLng: TLatLng | undefined = React.useMemo(() => {
    return entity.checkIn?.location !== undefined
      ? [entity.checkIn.location.latitude, entity.checkIn.location.longitude]
      : undefined
  }, [entity.checkIn?.location])

  const checkOutLatLng: TLatLng | undefined = React.useMemo(() => {
    return entity.checkOut?.location !== undefined
      ? [entity.checkOut.location.latitude, entity.checkOut.location.longitude]
      : undefined
  }, [entity.checkOut?.location])

  const bounds: TLatLng | TBounds | undefined = React.useMemo(() => {
    return checkInLatLng !== undefined
      ? checkOutLatLng !== undefined
        ? [checkInLatLng, checkOutLatLng]
        : checkInLatLng
      : checkOutLatLng
  }, [checkInLatLng, checkOutLatLng])

  const [map, setMap] = useState(null)
  const [loading, setLoading] = useState(bounds === undefined ? 'Ready' : null)
  const printEl = useRef(null)

  useEffect(() => {
    if (bounds === undefined || loading !== 'MapLoaded') return

    getMapReady(printEl, setMap, setLoading)
  }, [printEl, loading, bounds])

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
      }}
    >
      {loading !== 'Ready' && (
        <RenderPDFContent
          bounds={bounds}
          printEl={printEl}
          setLoading={setLoading}
        >
          {checkInLatLng !== undefined && (
            <CheckInOutMarker
              variant='checkIn'
              latLng={checkInLatLng}
            />
          )}
          {checkOutLatLng !== undefined && (
            <CheckInOutMarker
              latLng={checkOutLatLng}
              variant='checkOut'
            />
          )}
        </RenderPDFContent>
      )}
      {React.cloneElement(children, {
        ...rest,
        map,
        entity,
        loading: loading !== 'Ready' || currentLoading,
      })}
    </div>
  )
}
