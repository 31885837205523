import React, { useEffect, useRef } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { InputAdornment, IconButton, TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  squareIcon: {
    padding: '3px',
    borderRadius: '3px',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
}))

const NodeInput = withStyles(theme => ({
  root: {
    '& .MuiInput-input': {
      textAlign: 'center',
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.contrastText,
    },
    '& label': {
      fontSize: theme.typography.fontSize,
    },
    '& label.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.primary,
    },
  },
}))(TextField)

type TControlInputProps = {
  value: string
  onChange: (value: string) => void
  label: string
  onBlur: (e: React.FocusEvent | React.MouseEvent) => void
  options: any
}

const Input = ({
  value,
  onChange,
  label,
  onBlur,
  ...options
}: TControlInputProps) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = inputRef?.current

    if (element != null) {
      const preventNodeMove = (e: MouseEvent) => e.stopPropagation()
      element.addEventListener('pointerdown', preventNodeMove)

      return () => {
        element.removeEventListener('pointerdown', preventNodeMove)
      }
    }
  }, [inputRef])

  const resetValue = (e: React.MouseEvent) => {
    onChange('')
    onBlur(e)
  }

  return (
    <NodeInput
      ref={inputRef}
      value={value}
      onChange={e => onChange(e.target.value)}
      label={label}
      onBlur={onBlur}
      size='small'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={resetValue}
              className={classes.squareIcon}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...options}
    />
  )
}

export default Input
