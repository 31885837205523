import { TState } from 'src/redux/microservices/types'

type TTemplate = {
  permissionLevel?: null
  permissionLevels?: {
    role: null
    user: null
  }
  source?: string
}

const mapDefaultMicroservices = (
  microservices: TState,
  template: TTemplate,
) => {
  return Object.entries(microservices)
    .map(
      ([microservice, entity]) => [
        ...entity.map(service => ({
          microservice,
          entity: service,
          ...template,
        })),
      ],
      [],
    )
    .flat()
}

export default mapDefaultMicroservices
