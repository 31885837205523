import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AreaPlugin from 'rete-area-plugin'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { editor } from './components/Flow'
import { validate } from './helpers/validationHelper'
import {
  currentBlueprint,
  fetchedBlueprints,
} from '../../redux/editor/editor.selectors'
import { areBlueprintsEqual } from './helpers/openedListFunctions'
import { DialogButton } from './components/shared'

const ERROR_TEXT = {
  info: 'Issues were found in your blueprint.',
  recommendation: "It's recommended to fix them before saving.",
}

export const UsePreSaveValidation = () => {
  const [errors, setErrors] = useState(null)

  const currentDiagramId = useSelector(currentBlueprint)
  const blueprints = useSelector(fetchedBlueprints)
  const currentDiagram = blueprints?.find(({ id }) => id === currentDiagramId)

  const isEditorChanged = () => {
    if (!editor || !currentDiagram) return
    return !areBlueprintsEqual(currentDiagram?.data, editor.toJSON())
  }

  const isEditorValid = () => {
    if (!isEditorChanged()) return true

    const validatedNodes = editor.nodes.reduce((acc, node) => {
      const validatedNode = node.validation
        ? {
            ...node.validation,
            ...validate(node),
          }
        : validate(node)

      node.validation = validatedNode
      node.update()
      const { validation } = node

      const errors =
        validation.errors && Object.values(validation.errors).filter(err => err)
      const warnings =
        validation.warnings &&
        Object.values(validation.warnings).filter(err => err)
      const inactive = !validation.isActive

      if (!errors?.length && !warnings?.length && !inactive) return acc
      node.validation.invalid = true

      return [...acc, node]
    }, [])

    if (!validatedNodes.length) {
      setErrors(null)
      return true
    }

    setErrors(validatedNodes)
    return false
  }

  return [errors, setErrors, isEditorValid]
}

export const PreSaveModal = ({
  onClose,
  fixActionCallback,
  open,
  errors,
  onSave,
}) => {
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    setLoading(true)
    await onSave()
    setLoading(false)
    onClose()
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {ERROR_TEXT.info}
        </DialogContentText>
        <DialogContentText id='alert-dialog-description'>
          {ERROR_TEXT.recommendation}
        </DialogContentText>
      </DialogContent>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        p={2}
      >
        <FixWarningsButton
          onClose={onClose}
          fixActionCallback={fixActionCallback}
          errors={errors}
        />
        <DialogButton
          disabled={loading}
          onClick={handleSave}
        >
          Save anyway
        </DialogButton>
      </Box>
    </Dialog>
  )
}

export const FixWarningsButton = ({ onClose, fixActionCallback, errors }) => {
  const handleFix = () => {
    onClose()
    fixActionCallback()
    // by default zoomAt expects array of all editor node and centers it, solution below to focus on first error node
    AreaPlugin.zoomAt(editor, [errors[0]])
  }

  return <DialogButton onClick={handleFix}>Fix warnings</DialogButton>
}

export const ErrorInfoText = ({ ...props }) => (
  <Box {...props}>
    <ErrorText>{ERROR_TEXT.info}</ErrorText>
    <ErrorText>{ERROR_TEXT.recommendation}</ErrorText>
  </Box>
)

const ErrorText = styled(Typography)({
  color: '#FE6B8B',
})

FixWarningsButton.defaultProps = {
  fixActionCallback: () => null,
  errors: [],
}
