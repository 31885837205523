import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import { getControl } from '../../../../helpers/ControlHelper'

export default class IfElseComponent extends Rete.Component {
  constructor() {
    super('If/Else')
    this.contextMenuPath = ['Actions']

    this.task = {
      outputs: {
        then: 'option',
        else: 'option',
      },
    }
  }

  builder(node) {
    const inp1 = new Rete.Input('act', 'Execute', sockets.actionSocket, true)
    const inp2 = new Rete.Input('bool', 'Boolean', sockets.booleanSocket)
    const control = getControl(this.editor, node, 'boolean', 'bool', 'Boolean')
    control && inp2.addControl(control)
    const out1 = new Rete.Output('then', 'Then', sockets.actionSocket)
    const out2 = new Rete.Output('else', 'Else', sockets.actionSocket)

    return node.addInput(inp1).addInput(inp2).addOutput(out1).addOutput(out2)
  }

  worker(_node, inputs) {}
}
