import React, { useState } from 'react'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import axios from 'axios'

const mime = require('mime')

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

const urlValidator = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
)

const useDownloadZip = props => {
  const [zipLoading, setZipLoading] = useState(false)
  const { attachments = [], additionalDetails, pdf } = props
  const alarm = props.entity

  const texts = additionalDetails.textDatas || []
  const imageUrls = additionalDetails.imageUrls || []
  const videoUrls = additionalDetails.videoUrls || []
  const audioUrls = additionalDetails.audioUrls || []

  const downloadZip = React.useCallback(async () => {
    if (zipLoading) return
    if (pdf.loading || pdf.error !== null || pdf.blob === null) return

    setZipLoading(true)

    const pdfName = `report-${alarm.id}.pdf`
    const zipName = `report-${alarm.id}.zip`

    const getUrlResource = async url => {
      return urlValidator.test(url)
        ? axios({ method: 'GET', url, responseType: 'blob' })
        : fetch(window._env_.API_URL + '/videomanager/api' + url)
    }

    const getResourceNameFromUrl = (url = '') => {
      const index = url.lastIndexOf('/') + 1
      const name = url.substr(index)

      return name
    }

    const getUrlFiles = async urlFiles =>
      Promise.allSettled(
        urlFiles.map(async urlFile => {
          const name = getResourceNameFromUrl(urlFile)
          try {
            const blob = await getUrlResource(urlFile)
            return { ok: true, name, data: blob }
          } catch (error) {
            return { ok: false, name, error }
          }
        }),
      )

    const detailedUrlFiles = await getUrlFiles([
      ...videoUrls,
      ...audioUrls,
      ...imageUrls,
    ])

    const zip = new JSZip()

    const handleUniqueFileName = name => {
      const files = Object.keys(zip.files)
      const fileExists = files.find(fileName => fileName === name)

      if (!fileExists) return name

      const getFileOrderRegex = /.*(?:\D|^)(-)(?<order>\d+)(\.).*(?:\D|^)/
      const hasOrder = name.match(getFileOrderRegex)

      if (!hasOrder) {
        const splittedName = name.split('.')
        const fileFormat = splittedName[splittedName.length - 1]
        const fileName = splittedName.slice(0, -1).join('')
        return handleUniqueFileName(`${fileName}-1.${fileFormat}`)
      }

      const order = hasOrder.groups.order

      return handleUniqueFileName(name.replace(order, Number(order) + 1))
    }

    detailedUrlFiles.forEach((file, i) => {
      const name = handleUniqueFileName(file.value.name)
      if (file.value.ok) {
        zip.file(name, file.value.data.data)
      } else {
        zip.file(`ERROR-${name}.txt`, file.value.error.message)
      }
    })
    texts.forEach((text, i) => {
      zip.file(`AttachedText_${i}.txt`, text)
    })

    attachments.forEach((attachment, i) => {
      const base64 = attachment?.dataURI
      const [mimeAndEncoding, data] = base64.split(/[,:]/).slice(1)
      const [mimetype, encoding] = mimeAndEncoding.split(';')

      if (!['base64', undefined].includes(encoding)) {
        console.error(`Unsupported DataURI encoding: ${encoding}`)
        return
      }
      if (data === undefined) {
        console.error('Empty DataURI data')
        return
      }
      if (!mime.getExtension(mimetype)) {
        console.error(`Unsupported DataURI mimetype: ${mimetype}`)
        return
      }
      const name = handleUniqueFileName(attachment.file?.name)
      const validBase64 = base64.includes('base64,')
        ? base64.split('base64,')[1]
        : base64
      zip.file(name, b64toBlob(validBase64, mimetype))
    })
    zip.file(pdfName, pdf.blob)
    zip.generateAsync({ type: 'blob' }).then(function (zipBlob) {
      FileSaver.saveAs(zipBlob, zipName)
    })
    setZipLoading(false)
  }, [
    attachments,
    texts,
    imageUrls,
    videoUrls,
    audioUrls,
    zipLoading,
    alarm.id,
    pdf.blob,
    pdf.error,
    pdf.loading,
  ])

  return [downloadZip, zipLoading]
}

export default useDownloadZip
