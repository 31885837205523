import React from 'react'
import MUButton from '@material-ui/core/Button'

export const DialogButton = ({ ...props }) => (
  <MUButton
    variant='contained'
    color='primary'
    size='small'
    {...props}
  />
)
