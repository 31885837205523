import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import { getSocketFromType } from '../../Sockets/utils'

export default class MergeObjectsComponent extends Rete.Component {
  constructor() {
    super('Merge Objects')
    this.task = {
      outputs: {
        object: 'output',
      },
    }
    this.contextMenuPath = ['Objects']
  }

  reDrawNode(node) {
    setTimeout(() => {
      node.update()
      this.editor.view.updateConnections({
        node,
      })
    })
  }

  removeOut(node) {
    node.outputs.forEach(o => {
      o.connections.forEach(connection => {
        this.editor.removeConnection(connection)
      })
      node.removeOutput(o)
    })
  }

  areAllsocketsOneType(node) {
    return node.inputs.get('objects').connections.reduce((acc, conn) => {
      if (!acc) return acc

      if (acc === true) {
        acc = conn.output.socket.name
      }
      if (acc !== conn.output.socket.name) {
        acc = false
      }
      return acc
    }, true)
  }

  getSocketName(node) {
    return node.inputs.get('objects').connections[0].output.socket.name
  }

  updateOutSocket(node) {
    this.removeOut(node)
    this.addActualOutput(node)
    this.reDrawNode(node)
  }

  addActualOutput(node) {
    let output
    if (
      node.inputs.get('objects').connections.length === 0 ||
      !this.areAllsocketsOneType(node) ||
      this.areAllsocketsOneType(node) === 'Object'
    ) {
      output = new Rete.Output('object', 'Object', sockets.objectSocket)
      node.data.socketType = 'Object'
    } else {
      output = new Rete.Output(
        'object',
        this.getSocketName(node),
        getSocketFromType('object', this.getSocketName(node)),
      )
      node.data.socketType = this.getSocketName(node)
    }
    node.addOutput(output)
  }

  updateOuputSocketName(node, socketType) {
    if (node.data.socketType !== socketType) {
      this.updateOutSocket(node)
    }
  }

  checkOutputSocketName(node) {
    this.updateOutSocket(node)
  }

  builder(node) {
    const inp = new Rete.Input('objects', 'Objects', sockets.objectSocket, true)
    node.addInput(inp)

    if (!node.data.socketType || node.data.socketType === 'Object') {
      node.addOutput(new Rete.Output('object', 'Object', sockets.arraySocket))
    } else {
      node.addOutput(
        new Rete.Output(
          'object',
          node.data.socketType,
          getSocketFromType('object', node.data.socketType),
        ),
      )
    }

    return node
  }

  connected = connection => {
    if (connection.input.key === 'objects') {
      if (!this.editor.silent) {
        this.updateOuputSocketName(
          connection.input.node,
          connection.output.socket.name,
        )
      }
    }
  }

  disconnected = connection => {
    if (connection.input.key === 'objects') {
      this.checkOutputSocketName(connection.input.node)
    }
  }

  worker(node, inputs) {}
}
