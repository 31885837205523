import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

import * as theme from 'src/parser/shared/PDF/theme'
import api, { TEntity } from 'src/utils/api'
import { IAlarm, IAgentActivityReport } from '@obvious.tech/constellation'
import { useTranslation } from 'react-i18next'

import { Header, AlarmEntry } from './List'

const styles = StyleSheet.create({
  title: {
    textTransform: 'uppercase',
    color: 'black',
    fontWeight: 'bold',
    fontSize: 10,
  },
  root: {
    ...theme.styles.text,
    marginBottom: theme.spacing(1.5),
  },
  content: {
    color: 'black',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
})

const headerConfig = [
  {
    translation: 'agent_activity_report.created_alarm.date',
  },
  {
    translation: 'agent_activity_report.created_alarm.severity',
  },
  {
    translation: 'agent_activity_report.created_alarm.status',
  },
  {
    translation: 'agent_activity_report.created_alarm.category',
  },
  {
    translation: 'agent_activity_report.created_alarm.location',
  },
]

export type TProps = {
  entity: TEntity<IAgentActivityReport>
  authorization: string
}

const getCreatedAlarms = async ({
  id,
  token,
  checkIn,
  checkOut,
}: {
  id: string
  checkIn: Date
  checkOut: Date | undefined
  token?: string
}) =>
  await api.getResourcesWithFilters<IAlarm>('alarms/api/Alarm')({
    filters: [
      // @ts-expect-error
      ['author.passportId', api.Filter.Eq, id],
      ['creationDate', api.Filter.GtEq, new Date(checkIn)],
      [
        'creationDate',
        api.Filter.LtEq,
        checkOut === undefined ? new Date() : new Date(checkOut),
      ],
    ],
    sorts: '-creationDate',
    token,
  })

export default ({ entity, authorization }: TProps): JSX.Element => {
  const { t } = useTranslation()
  const [alarms, getAlarms] = api.useApiAction(getCreatedAlarms)
  const token = authorization || localStorage.getItem('token')
  const createdAlarms = alarms.data
  const totalAlarms = createdAlarms?.length
  const checkIn = entity.checkIn?.timestamp as Date | undefined
  const checkOut = entity.checkOut?.timestamp as Date | undefined

  useEffect(() => {
    if (typeof token !== 'string') return
    if (checkIn === undefined) return
    return getAlarms({
      id: entity.passportId,
      checkIn,
      checkOut,
      token,
    })
  }, [checkIn, checkOut, entity.passportId, token, getAlarms])

  return (
    <View style={styles.root}>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>
          {`${t('agent_activity_report.created_alarm.title')} : `}
        </Text>
        <Text style={styles.content}>
          {`${
            totalAlarms !== undefined && totalAlarms > 0
              ? `${totalAlarms} ${t(
                  'agent_activity_report.created_alarm.created',
                )}`
              : t('alarm_report.no_content')
          }`}
        </Text>
      </View>
      {createdAlarms !== null && createdAlarms.length !== 0 ? (
        <View style={styles.root}>
          <Header config={headerConfig} />
          {createdAlarms.map((alarm, index) => {
            return (
              <AlarmEntry
                alarm={alarm}
                key={index}
                index={index}
              />
            )
          })}
        </View>
      ) : null}
    </View>
  )
}
