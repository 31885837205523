import { Switch } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React, { FunctionComponent } from 'react'
import { IInputProps } from 'src/types'

const CustomBooleanInput: FunctionComponent<IInputProps<boolean>> = ({
  property,
  name,
  onChange,
  value,
  classes,
}: IInputProps<boolean>) => {
  return (
    <FormGroup
      row
      className={classes}
    >
      <FormControlLabel
        control={
          <Switch
            disabled={property.readOnly}
            checked={value}
            onChange={e => onChange(e.target.checked)}
            name={name}
          />
        }
        label={property.displayedName ?? name}
      ></FormControlLabel>
    </FormGroup>
  )
}

export default CustomBooleanInput
