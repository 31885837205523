import Action from '../action'

class NodeAction extends Action {
  constructor(editor, node) {
    super()
    this.editor = editor
    this.node = node
  }
}

export class AddNodeAction extends NodeAction {
  undo() {
    this.editor.removeNode(this.node)
  }

  redo() {
    this.editor.addNode(this.node)
  }
}

export class RemoveNodeAction extends NodeAction {
  undo() {
    this.editor.addNode(this.node)
  }

  redo() {
    this.editor.removeNode(this.node)
  }
}

export class DragNodeAction extends NodeAction {
  constructor(editor, nodes, prevs) {
    super(editor)
    this.data = {}
    this.new = {}

    nodes.forEach(node => {
      const position = prevs[node.id]
      const newPosition = [...node.position]
      this.data = {
        ...this.data,
        [node.id]: {
          node,
          position,
        },
      }

      this.new = {
        ...this.new,
        [node.id]: {
          node,
          position: newPosition,
        },
      }
    })
  }

  _translate(data) {
    Object.values(data).forEach(({ node, position }) => {
      this.editor.view.nodes.get(node).translate(...position)
    })
  }

  undo() {
    this._translate(this.data)
  }

  redo() {
    this._translate(this.new)
  }

  update(node) {
    this.new = [...node.position]
  }
}
