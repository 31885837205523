import * as constants from './definitions.constants'
import parser from '../../parser/parser'

export const setDefinitions = definitions => ({
  type: constants.SET_DEFINITIONS,
  payload: definitions,
})

export const initDefinitions = () => {
  const { definitions: definitionsPromise } = parser(window._env_.API_URL)
  return async dispatch => {
    dispatch(setDefinitions(await definitionsPromise))
  }
}
