import Rete from '@obvious.tech/rete'

import { getControl } from '../../../../helpers/ControlHelper'
import { getSocketFromType } from '../../Sockets/utils'
import { validate, isRequired } from '../../../../helpers/validationHelper'

export default class FunctionComponent extends Rete.Component {
  task = {
    outputs: {
      result: 'output',
    },
  }

  constructor(name, types, returnType, action) {
    super(name)
    this.action = action
    this.returnType = returnType
    this.types = types
    this.contextMenuPath = ['Functions', types[0]]
    this.validation = {
      errorRules: this.types.reduce(
        (acc, input, i) => ({
          ...acc,
          [`input${i}`]:
            typeof input === 'object' ? input.validation : [isRequired()],
        }),
        {},
      ),
    }
  }

  builder(node) {
    this.types.forEach((input, i) => {
      const type = typeof input === 'object' ? input.type : input

      const inp = new Rete.Input(
        'input' + i,
        type,
        getSocketFromType(type, undefined),
      )
      inp.addControl(
        getControl(
          this.editor,
          node,
          type,
          'input' + i,
          type,
          () => null,
          validate,
        ),
      )
      node.addInput(inp)
    })
    const out1 = new Rete.Output(
      'result',
      this.returnType,
      getSocketFromType(this.returnType, undefined),
    )

    node.addOutput(out1)
  }
}
