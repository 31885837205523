import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import AddIcon from '@material-ui/icons/Add'
import { IconButton } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { makeStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import { useCreate, Title as HeaderTitlePortal, useNotify } from 'react-admin'

import GroupTableRow from './GroupTableRow'
import CustomTitle from '../../Title/Title'
import Loading from '../../Loader/CustomLoader'
import FormDialog from '../../../layout/Dialog'
import { cancellablePromise } from 'src/utils'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const callWithJwtToken = async fn => {
  const jwtToken = localStorage.getItem('token')
  return await fn.call(jwtToken)
}

function GroupList({ getGroups }) {
  const classes = useStyles()
  const notify = useNotify()

  const [groups, setGroups] = useState([])
  const [newGroup, setNewGroup] = useState('')
  const [newGroupDialog, setNewGroupDialog] = useState(false)
  const [groupsLoading, setGroupsLoading] = useState(true)
  const [create, { loading, error: createGroupError }] = useCreate(
    'authentication/api/Group',
    {
      name: newGroup,
      groups: [newGroup],
    },
    {
      onSuccess: () => {
        setNewGroup('')
      },
    },
  )

  useEffect(() => {
    if (!getGroups) return

    const cPromise = cancellablePromise(callWithJwtToken(getGroups))
    cPromise.then(retrievedGroups => {
      setGroups(retrievedGroups)
      setGroupsLoading(false)
    })
    return cPromise.cancel
  }, [getGroups, loading])

  useEffect(() => {
    if (!createGroupError) return

    const errorMsg =
      typeof createGroupError === 'string'
        ? createGroupError
        : 'Failed to create a new group.'
    notify(errorMsg, 'error')
  }, [createGroupError, notify])

  if (groupsLoading) return <Loading />

  const handleCreate = e => {
    create()
    closeNewGroupDialog()
  }

  const openNewGroupDialog = () => setNewGroupDialog(true)
  const closeNewGroupDialog = () => setNewGroupDialog(false)

  return (
    <TableContainer component={Paper}>
      <HeaderTitlePortal title={<CustomTitle />} />

      <Table
        className={classes.table}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Group</TableCell>
            <TableCell align='right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups?.map(({ id, name }) => (
            <GroupTableRow
              key={id}
              group={name}
            />
          ))}
          <TableRow key={'add_group'}>
            <TableCell>
              {loading ? (
                <span />
              ) : (
                <IconButton
                  aria-label='expand row'
                  size='small'
                  disabled={loading}
                  onClick={openNewGroupDialog}
                >
                  <AddIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <FormDialog
        title='Create a new group'
        description='Please write the group name, then confirm'
        inputLabel='Group name'
        onInputChange={setNewGroup}
        onConfirm={handleCreate}
        value={newGroup}
        open={newGroupDialog}
        onClose={closeNewGroupDialog}
      />
    </TableContainer>
  )
}

const mapStateToProps = state => {
  return {
    getGroups: state?.api?.Group?.functions?.getAll,
  }
}

export default connect(mapStateToProps, null)(GroupList)
