import { sockets } from '..'

export const getSocketFromType = (type, entity, format) => {
  if (format && sockets[`${format}Socket`]) {
    return sockets[`${format}Socket`]
  }
  if (sockets[`${entity || type}Socket`]) {
    return sockets[
      `${entity ? `${entity}${type === 'array' ? 'Array' : ''}` : type}Socket`
    ]
  } else {
    return sockets.anyTypeSocket
  }
}
