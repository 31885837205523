import React from 'react'
import { useSelector } from 'react-redux'
import { Layout } from 'react-admin'

import Menu from './menu'
import CustomAppBar from './AppBar'
import Notification from './Notification'
import getMenu from '../../parser/MenuCustomization'
import { makeStyles } from '@material-ui/core'
import { withThemeProvider } from 'src/utils/hocs'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 'unset',
    maxWidth: '100%',
  },
  content: {
    padding: theme.contentPadding,
    width: ({ sidebarOpen }) =>
      sidebarOpen ? `calc(100% - ${theme.sidebarWidth})` : '100%',
  },
}))

const CustomLayout = ({ microservices, ...props }) => {
  const sidebarOpen = useSelector(({ admin }) => admin.ui.sidebarOpen)
  const classes = useStyles({
    sidebarOpen,
  })
  return (
    <Layout
      {...props}
      classes={{
        content: classes.content,
      }}
      menu={() => null}
      className={classes.root}
      sidebar={props => (
        <Menu
          microservices={getMenu(microservices)}
          {...props}
        />
      )}
      appBar={CustomAppBar}
      notification={Notification}
    />
  )
}

export default withThemeProvider(CustomLayout)
