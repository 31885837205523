import { IAlarm, IUserTask } from '@obvious.tech/constellation'
import { useEffect } from 'react'

import api, { TEntity, TUseApiActionState } from 'src/utils/api'

const getUserTasksByAlarmID = ({ id, token }: { id: string; token?: string }) =>
  api.fetchResource<IUserTask[]>(`tasks/api/UserTask?Filters=alarmId==${id}`)({
    token,
  })

export type TProps = {
  alarm: TEntity<IAlarm>
  authorization?: string
}

export type TUseUserTasksResult = TUseApiActionState<
  typeof getUserTasksByAlarmID
>

const useUserTasks = ({
  alarm,
  authorization,
}: TProps): TUseUserTasksResult => {
  const [userTasks, getUserTasks] = api.useApiAction(getUserTasksByAlarmID)

  useEffect(() => {
    if (typeof alarm !== 'object') return

    return getUserTasks({
      id: alarm.id,
      token: authorization,
    })
  }, [alarm, getUserTasks, authorization])

  return userTasks
}

export default useUserTasks
