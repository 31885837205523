import { FormDataConsumer } from 'react-admin'
import React from 'react'

const BasedOnInput = ({
  children = () => <span />,
  name,
  property,
  ...props
}) => {
  if (!property.basedOn) return null

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (!formData[property.basedOn]) return null

        if (property.displayedComponent) {
          return React.createElement(property.displayedComponent, {
            ...props,
            ...rest,
            name,
            formData,
            property,
          })
        }

        return React.createElement(children, {
          source: name,
          formData,
          property,
        })
      }}
    </FormDataConsumer>
  )
}

export default BasedOnInput
