import React from 'react'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'

import useStyles from './styles'
import { useTheme } from '@material-ui/core'

const DateInput: React.FunctionComponent<KeyboardDatePickerProps> = ({
  value,
  onChange,
  format,
  label,
  onBlur,
  ...options
}: KeyboardDatePickerProps) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <KeyboardDatePicker
      value={value}
      className={classes.root}
      label={label}
      onChange={onChange}
      onAccept={onBlur}
      DialogProps={{
        style: {
          zIndex: theme.zIndex.tooltip + 1,
        },
      }}
      clearable
      disablePast
      onBlur={onBlur}
      format={format}
      {...options}
    />
  )
}

export default DateInput
