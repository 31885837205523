import { IAlarm, IAttachment } from '@obvious.tech/constellation'
import { useEffect } from 'react'

import { TNonEmptyArray } from 'src/types'
import api, {
  TApiError,
  TApiResponse,
  TEntity,
  TUseApiActionState,
} from 'src/utils/api'
import { isNonEmpty } from 'src/utils/ArrayU'

const getDataURLByAttachmentID = async ({
  id,
  token,
}: {
  id: string
  token?: string
}) => {
  const res = await api.getResourceBlobAsDataURL(
    `alarms/api/Attachment/${id}/download`,
  )({ token })

  if (res.error || res.data === undefined) {
    const error = new Error(
      `Failed to fetch Attachment ${id}: ` +
        `${res?.error?.message ?? 'An unexpected error occured'}`,
    )
    if (res.error) error.stack = res.error.stack

    return {
      error,
      data: null,
    }
  }

  return {
    error: null,
    data: res.data,
  }
}

export type TAttachmentWithURI = TEntity<IAttachment> & {
  dataURI?: string
}

const getAttachmentsByAlarmID = async ({
  id,
  token,
}: {
  id: string
  token?: string
}): Promise<
  TApiResponse<Array<TAttachmentWithURI>, TNonEmptyArray<TApiError>>
> => {
  const attachments = await api.fetchResource<Array<TEntity<IAttachment>>>(
    `alarms/api/Alarm/${id}/Attachment`,
  )({ token })

  if (attachments.error !== null) {
    return {
      data: null,
      error: [attachments.error],
    }
  }

  let errors: Array<TApiError> = []
  const attachmentIdToDataURLPairs = await Promise.all(
    attachments.data.map<Promise<[string, string | undefined]>>(
      async ({ id }) => {
        const res = await getDataURLByAttachmentID({ id, token })

        if (res.error !== null) {
          errors.push(res.error)
          return [id, undefined]
        }

        return [id, res.data]
      },
    ),
  )
  if (isNonEmpty(errors)) {
    return {
      data: null,
      error: errors,
    }
  }

  const attachmentIdToDataURL = Object.fromEntries(attachmentIdToDataURLPairs)

  return {
    data: attachments.data.map(attachment => ({
      ...attachment,
      dataURI: attachmentIdToDataURL[attachment.id],
    })),
    error: null,
  }
}

export type TProps = {
  alarm: TEntity<IAlarm>
  authorization?: string
}

export type TUseAttachmentsResult = TUseApiActionState<
  typeof getAttachmentsByAlarmID
>

const useAttachments = ({ alarm, authorization }: TProps) => {
  const [attachments, getAttachments] = api.useApiAction(
    getAttachmentsByAlarmID,
  )

  useEffect(() => {
    return getAttachments({
      id: alarm.id,
      token: authorization,
    })
  }, [alarm, authorization, getAttachments])

  return attachments
}

export default useAttachments
