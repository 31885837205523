// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Record, useInput, useQuery } from 'react-admin'

import { IInputProps } from 'src/types'
import TApi from 'src/types/api'
import { compare } from 'src/utils/str'

import SelectMultipleInput from './arrays/SelectMultipleInput'

interface TOption {
  type: 'Group' | 'Passport'
  displayName: string
  value: string
}

const GroupsInput = ({
  property,
  name,
  classes,
  ...rest
}: IInputProps<string | Record>): JSX.Element => {
  const {
    input: { onChange, value },
  } = useInput({
    source: name,
    ...rest,
  })

  const [loading, setLoading] = useState<boolean>(true)
  const apiGroups = useQuery({
    resource: '/authentication/api/Group',
    type: 'getList',
    payload: {},
  })
  const apiPassports = useQuery({
    resource: '/authentication/api/Passport',
    type: 'getList',
    payload: {},
  })
  const [options, setOptions] = useState<TOption[]>([])

  useEffect(() => {
    if (!apiGroups.loaded || !apiPassports.loaded) return

    const groupOptions: TOption[] = (apiGroups.data as TApi.Entity.Group[]).map(
      group => ({
        type: 'Group',
        displayName: group.name,
        value: group.name,
      }),
    )
    const passportOptions: TOption[] = (
      apiPassports.data as TApi.Entity.Passport[]
    ).map(passport => ({
      type: 'Passport',
      displayName: passport.username,
      value: `/passport/${passport.id}/`,
    }))

    const sortedOptions = [...groupOptions, ...passportOptions].sort((a, b) =>
      compare(a.displayName, b.displayName),
    )

    setOptions(sortedOptions)
    setLoading(false)
  }, [apiGroups.loaded, apiPassports.loaded, apiGroups.data, apiPassports.data])

  const getOptionLabel = (value: string): string => {
    const option = options.find(opt => opt.value === value)

    return option?.displayName ?? value
  }

  return (
    <SelectMultipleInput
      className={classes}
      loading={loading}
      property={{
        ...property,
        displayedName: 'Owners',
      }}
      placeholder='group or username'
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      value={value}
      options={loading ? [] : options.map(({ value }) => value)}
    />
  )
}

export default GroupsInput
