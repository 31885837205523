import * as actionNames from './editor.constants'

export const setTemplate = payload => ({
  type: actionNames.SET_TEMPLATE,
  payload,
})

export const saveDiagram = payload => ({
  type: actionNames.SAVE_DIAGRAM,
  payload,
})

export const addBlueprintToListOfOpened = payload => ({
  type: actionNames.ADD_BLUEPRINT_TO_LIST_OF_OPENED,
  payload,
})

export const addFetchedBlueprint = payload => ({
  type: actionNames.ADD_FETCHED_BLUEPRINT,
  payload,
})

export const updateFetchedBlueprint = payload => ({
  type: actionNames.UPDATE_FETCHED_BLUEPRINT,
  payload,
})

export const updateOpenedBlueprint = payload => ({
  type: actionNames.UPDATE_OPENED_BLUEPRINT,
  payload,
})

export const markBlueprintAsCurrent = payload => ({
  type: actionNames.MARK_BLUEPRINT_AS_CURRENT,
  payload,
})

export const renameBlueprint = payload => ({
  type: actionNames.RENAME_BLUEPRINT,
  payload,
})
export const showNewBlueprintDialog = () => ({
  type: actionNames.SHOW_NEWBLUEPRINT_DIALOG,
})
export const showChangeTitleDialog = payload => ({
  type: actionNames.SHOW_CHANGE_TITLE_DIALOG,
  payload,
})
export const showSaveAsDialog = payload => ({
  type: actionNames.SAVE_WITH_NAME_DIALOG,
  payload,
})
export const hideNewBlueprintDialog = () => ({
  type: actionNames.HIDE_NEWBLUEPRINT_DIALOG,
})

export const setEditorZoomValue = payload => ({
  type: actionNames.SET_EDITOR_ZOOM_VALUE,
  payload,
})

export const removeOpenedBlueprint = payload => {
  return {
    type: actionNames.REMOVE_OPENED_BLUEPRINT,
    payload,
  }
}

export const addBlueprint = payload => {
  return {
    type: actionNames.ADD_BLUEPRINT,
    payload,
  }
}

export const updateBlueprintInOpenedList = payload => {
  return {
    type: actionNames.UPDATE_BLUEPRINT,
    payload,
  }
}

export const toggleSimulationDialog = payload => {
  return {
    type: actionNames.TOGGLE_SIMULATION_DIALOG,
    payload,
  }
}

export const toggleConfDialog = payload => {
  return {
    type: actionNames.TOGGLE_CONF_DIALOG,
    payload,
  }
}

export const setConfDialogInEditor = payload => {
  return {
    type: actionNames.SET_CONF_DIALOG,
    payload,
  }
}
export const setCurrentBlueprint = payload => {
  return {
    type: actionNames.SET_CURRENT_BLUEPRINT,
    payload,
  }
}

export const replaceOpenedWith = payload => {
  return {
    type: actionNames.REPLACE_OPENED_WITH,
    payload,
  }
}

export const cleanOpened = payload => {
  return {
    type: actionNames.CLEAN_OPENED,
    payload,
  }
}

export const setToCloseDiagramId = payload => {
  return {
    type: actionNames.SET_TO_CLOSE_DIAGRAMID,
    payload,
  }
}

export const setFlowDefinitions = payload => ({
  type: actionNames.SET_FLOW_DEFINITIONS,
  payload,
})
