// @ts-nocheck

import { TRequiredKeys } from '@obvious.tech/constellation'
import { HttpError } from 'ra-core'
import { useCallback, useRef, useState } from 'react'

import { getOptions, sanitizeResource } from 'src/providers/dataProvider'
import { fetchJson } from 'src/providers/fetchJson'
import { TAwaited, TDeepReplace, TNonEmptyArray } from 'src/types'
import { cancellablePromise } from 'src/utils'
import { deepReplace } from '../deepReplace'
import { toDataURL } from '../file'
import Loadable, { TLoadable } from '../Loadable'
import { Filter, TFilterOpts, filterOptsToQueryString } from './Filter'

/**
 * Because current constellation types have some "Date" types that are in
 * reality strings with this implementation
 */
export type TEntity<T> = TDeepReplace<
  T extends { id?: string } ? TRequiredKeys<T, 'id'> : T,
  Date,
  string
>

export type TApiError = Error | HttpError

export type TApiResponseSuccess<T> = {
  data: T extends Blob
    ? T
    : T extends Array<infer E>
    ? Array<TEntity<E>>
    : TEntity<T>
  error: null
}

export type TApiResponseFailure<
  E extends TApiError | TNonEmptyArray<TApiError> = TApiError,
> = {
  data: null
  error: E
}

export type TApiResponse<
  T,
  E extends TApiError | TNonEmptyArray<TApiError> = TApiError,
> = TApiResponseSuccess<T> | TApiResponseFailure<E>

export type TApiAction<
  T = any,
  Options = any,
  E extends TApiError | TNonEmptyArray<TApiError> =
    | TApiError
    | TNonEmptyArray<TApiError>,
> = {
  (options: Options): Promise<TApiResponse<T, E>>
}

export const fetchResource =
  <T>(resource: string) =>
  async (_options: {
    method?: 'GET' | 'POST' | 'PATCH' | 'PUT'
    body?: any
    headers?: any
    token?: string
  }): Promise<TApiResponse<T>> => {
    const { token, ...fetchOptions } = _options
    const options = {
      ...getOptions(token),
      ...fetchOptions,
    }

    try {
      const { json: data } = await fetchJson(
        `${window._env_.API_URL}/${sanitizeResource(resource)}`,
        options,
      )

      return {
        error: null,
        /** Because current constellation types are wrong
         * (API may return null values, but models don't show it)
         */
        data: deepReplace(
          null,
          undefined,
        )(data) as TApiResponseSuccess<T>['data'],
      }
    } catch (e) {
      return {
        error: e instanceof Error ? e : new Error(String(e)),
        data: null,
      }
    }
  }

export const getResourceByID =
  <T>(resource: string) =>
  async ({
    id,
    token,
  }: {
    id: string | undefined
    token?: string
  }): Promise<TApiResponse<T>> => {
    return fetchResource<T>(`${resource}/${id}`)({
      token,
    })
  }

export const getResourcesWithFilters =
  <T extends { id?: string }>(resource: string) =>
  async ({
    filters,
    token,
    sorts,
  }: {
    filters?: TFilterOpts<T>
    token?: string
    sorts?: string
  }): Promise<TApiResponse<Array<T>>> => {
    const filterQs = filterOptsToQueryString(filters)
    return fetchResource<Array<T>>(
      sorts !== undefined
        ? `${resource}?Filters=${filterQs}&sorts=${sorts}`
        : `${resource}?Filters=${filterQs}`,
    )({
      token,
    })
  }

export const getResourceBlobAsDataURL =
  (resource: string) =>
  async ({
    token,
  }: {
    token?: string
  }): Promise<TApiResponse<string | undefined>> => {
    const options = {
      ...getOptions(token),
      token,
    }
    const url = `${window._env_.API_URL}/${sanitizeResource(resource)}`

    try {
      const res = await fetch(url, options)
      const blob = await res.blob()

      if (!res.ok)
        throw new HttpError(
          res.statusText === '' ? `HTTP ${res.status}` : res.statusText,
          res.status,
          { url },
        )

      const data = (await toDataURL(blob)) ?? undefined
      return {
        error: null,
        data,
      }
    } catch (dirtyError) {
      const error =
        dirtyError instanceof Error
          ? dirtyError
          : new Error(`${dirtyError ?? 'An unexpected error occured'}`)

      return {
        error: error,
        data: null,
      }
    }
  }

export type TUseApiActionStateData<T extends TApiAction<any, any, any>> =
  Exclude<TAwaited<ReturnType<T>>['data'], null>

export type TUseApiActionStateError<T extends TApiAction<any, any, any>> =
  Exclude<TAwaited<ReturnType<T>>['error'], null>

export type TUseApiActionState<T extends TApiAction<any, any, any>> = TLoadable<
  TUseApiActionStateData<T>,
  TUseApiActionStateError<T>
>

export const useApiAction = <
  T,
  O = any,
  E extends TApiError | TNonEmptyArray<TApiError> = TApiError,
>(
  action: TApiAction<T, O, E>,
): [
  TUseApiActionState<TApiAction<T, O, E>>,
  (opts: Parameters<typeof action>[0]) => () => void,
] => {
  const [state, setState] = useState<
    TLoadable<TEntity<T>, TNonEmptyArray<TApiError> | TApiError>
  >(Loadable.idle())
  const cancelRef = useRef<() => void>(() => {})

  const act = useCallback(
    (options: Parameters<typeof action>[0]): (() => void) => {
      cancelRef.current()

      setState(Loadable.loading())

      const promise = cancellablePromise(action(options))

      ;(async () => {
        const response = await promise

        if (response.error !== null)
          return setState(Loadable.failure(response.error))
        return setState(Loadable.success(response.data) as any)
      })()

      cancelRef.current = (reason?: any) => promise.cancel(reason)
      return cancelRef.current
    },
    [action],
  )

  return [state as any, act]
}

export default {
  fetchResource,
  getResourceByID,
  getResourcesWithFilters,
  useApiAction,
  getResourceBlobAsDataURL,
  Filter,
}
