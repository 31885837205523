import throttle from 'lodash.throttle'

export default class EditorBgHelper {
  _styleElem = document.getElementById('scaled-background')
  zoomValue = 1
  imageSize = [0, 0]

  translateBgThrottle = throttle(({ x, y }) => {
    this.translateBg({
      x,
      y,
    })
  }, 50)

  constructor(imageSize) {
    this.imageSize = imageSize

    this.setDefaultBg()
  }

  get style() {
    if (!this._styleElem) {
      this.setDefaultBg()
    }

    return this._styleElem
  }

  setDefaultBg() {
    this._styleElem = document.createElement('style')
    document.head.appendChild(this._styleElem)
    this._styleElem.type = 'text/css'
    this._styleElem.id = 'scaled-backgroud'
  }

  zoomBg(zoomValue) {
    const style = this.style
    const css = `.flow-cuztomize .blueprint.scaled-background::after { 
      background-size: ${this.imageSize[0] * zoomValue}px ${
      this.imageSize[1] * zoomValue
    }px;
    }`
    this.zoomValue = zoomValue
    style.appendChild(document.createTextNode(css))
  }

  translateBg({ x, y }) {
    const style = this.style
    const css = `.flow-cuztomize .blueprint.scaled-background::after {
      background-position: ${x}px ${y}px;
      background-size: ${this.imageSize[0] * this.zoomValue}px ${
      this.imageSize[1] * this.zoomValue
    }px;
    }`

    style.appendChild(document.createTextNode(css))
  }
}
