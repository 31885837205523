import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Loading } from 'react-admin'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    flex: 1,
    zIndex: 1,
  },
})

const PDFLoading = (): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Loading
        className={classes.loading}
        loadingPrimary={t('alarm_report.pdf_loading')}
        loadingSecondary=''
      />
    </div>
  )
}

export default PDFLoading
