import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

import * as theme from 'src/parser/shared/PDF/theme'
import { TEntity } from 'src/utils/api'
import { IAgentActivityReport } from '@obvious.tech/constellation'

const styles = StyleSheet.create({
  root: {
    ...theme.styles.text,
    color: theme.colors.documentSubHeader,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  fieldTitle: {
    color: 'black',
    fontWeight: 600,
    padding: theme.spacing(0.2),
  },
  fieldContent: {
    color: 'black',
    backgroundColor: 'white',
    padding: theme.spacing(0.2),
  },
  checkInTitle: {
    color: '#2ECFAD',
    fontWeight: 600,
  },
  checkOutTitle: {
    color: '#F44336',
    fontWeight: 600,
  },
  dataContainer: {
    marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(0.75),
    display: 'flex',
    flexDirection: 'row',
  },
})

type FieldProps = {
  title: string
  children: React.ReactNode
  position: 'left' | 'right'
  variant: 'CheckIn' | 'CheckOut'
}

const Field = ({
  title,
  children,
  position,
  variant,
}: FieldProps): JSX.Element | null => {
  return (
    <View
      style={
        position === 'right'
          ? {
              flex: 1,
            }
          : {}
      }
    >
      <Text
        style={[
          styles.fieldTitle,
          position === 'right'
            ? {
                paddingLeft: theme.spacing(3),
              }
            : {},
        ]}
      >
        {`${title} `}
      </Text>
      <Text
        style={[
          styles.fieldContent,
          position === 'right'
            ? {
                paddingLeft: theme.spacing(3),
              }
            : {},
          variant === 'CheckIn'
            ? {
                backgroundColor: '#E6E6E6',
              }
            : {},
        ]}
      >
        {children}
      </Text>
    </View>
  )
}

export type TProps = {
  entity: TEntity<IAgentActivityReport>
  variant: 'CheckIn' | 'CheckOut'
}

export const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp)

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(date)

  return formattedDate
}

export default ({ entity, variant }: TProps): JSX.Element => {
  const timestamp =
    variant === 'CheckIn'
      ? entity?.checkIn?.timestamp
      : entity?.checkOut?.timestamp

  const lat =
    variant === 'CheckIn'
      ? entity?.checkIn?.location?.latitude
      : entity?.checkOut?.location?.latitude

  const lng =
    variant === 'CheckIn'
      ? entity?.checkIn?.location?.longitude
      : entity?.checkOut?.location?.longitude

  return (
    <View style={styles.root}>
      <View style={styles.dataContainer}>
        <Field
          title={'date'}
          position={'left'}
          variant={variant}
        >
          {typeof timestamp === 'string' ? formatDate(timestamp) : 'No data'}
        </Field>
        <Field
          title={'location'}
          position={'right'}
          variant={variant}
        >
          {`${lat} ; ${lng}`}
        </Field>
      </View>
    </View>
  )
}
