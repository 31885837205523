import React from 'react'
import { Control as ReteControl } from '@obvious.tech/rete'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  nodeControl: {
    width: '100%',
    padding: '4px 10px',
  },
}))

type TControlProps = {
  bindControl: (el: HTMLElement, control: ReteControl) => void
  control: ReteControl | null
  className?: string
}

const Control = ({ bindControl, control, className }: TControlProps) => {
  const classes = useStyles()

  const createControl = (el: HTMLElement | null): void => {
    if (el == null || !control) return
    bindControl(el, control)
  }

  return (
    <div
      className={[classes.nodeControl, className].join(' ')}
      title={control?.key}
      ref={createControl}
    />
  )
}

export default Control
