import Rete from '@obvious.tech/rete'

import { compareNodeOptions } from 'src/utils/flow'

import { getSocketFromType } from '../../Sockets/utils'
import { sockets } from '../..'
import SelectControl from '../../Controls/SelectControl'
import { getControl } from '../../../../helpers/ControlHelper'
import { getStringFromStatusCode } from '../../../../helpers/HTTPRequestHelper'
import OutputWithDecompose from '../../Outputs/OutputWithDecompose'

export default class ConstellationComponent extends Rete.Component {
  constructor(name, microservice, functions) {
    super(name)
    this.function = ''
    this.contextMenuPath = ['Constellation', microservice]
    this.functions = functions
    this.task = {
      outputs: {
        ErrorResponse: 'output',
        Response: 'output',
      },
      init(task) {},
    }
  }

  handleCallChange = (editor, node) => entity => {
    if (!entity) return
    this.function = entity
    node.outputs.forEach(o => {
      o.connections.map(connection => this.editor.removeConnection(connection))
      node.removeOutput(o)
    })

    node.inputs.forEach(i => {
      if (!['act'].includes(i.key)) {
        i.connections.map(connection =>
          this.editor.removeConnection(connection),
        )
        node.removeInput(i)
      }
    })

    this.functions[entity].parameters.forEach(parameter => {
      if (parameter.name !== 'authorization') {
        const input = new Rete.Input(
          parameter.name,
          parameter.name,
          getSocketFromType(parameter.type, parameter.entity, parameter.format),
        )
        const control = getControl(
          this.editor,
          node,
          parameter.type,
          parameter.name,
          parameter.name,
        )
        control && input.addControl(control)
        node.addInput(input)
      }
    })

    this.functions[entity].responses.forEach(response => {
      this.task.outputs = {
        ...this.task.outputs,
        [response.name]: 'option',
      }
      node.addOutput(
        new Rete.Output(
          response.name,
          getStringFromStatusCode(parseInt(response.name, 10)),
          sockets.actionSocket,
        ),
      )
    })
    node.addOutput(
      new Rete.Output(
        'ErrorResponse',
        'ErrorResponse',
        sockets.HttpStatusCodeSocket,
      ),
    )

    const response = this.functions[entity].responses.find(
      response => response.entity !== 'ErrorResponse',
    )
    const respOut = new OutputWithDecompose(
      'Response',
      'Response',
      getSocketFromType(response.type, response.entity, response.format),
      editor,
    )

    node.addOutput(respOut)
    node.update()
  }

  builder(node) {
    const inpAct = new Rete.Input('act', 'Execute', sockets.actionSocket)
    const options = Object.keys(this.functions)
      .map(key => ({
        value: key,
        label: key,
      }))
      .sort(compareNodeOptions)

    const ctrl = new SelectControl(
      this.editor,
      node,
      'Call API',
      'Call API',
      this.handleCallChange(this.editor, node),
      options,
    )

    node.addControl(ctrl)
    return node.addInput(inpAct)
  }
}
