import { useEffect } from 'react'
import { IAgentActivityReport, IUserTask } from '@obvious.tech/constellation'

import api, { TApiError, TEntity } from 'src/utils/api'
import Loadable, { TLoadable } from 'src/utils/Loadable'

export type TParsedGuardTours = Array<TEntity<IUserTask>>

export type TUseGuardToursResults = TLoadable<TParsedGuardTours, TApiError>

export type TProps = {
  entity: TEntity<IAgentActivityReport>
  authorization?: string
}

const getGuardToursByID = ({ id, token }: { id: string; token?: string }) =>
  api.getResourcesWithFilters<IUserTask>('tasks/api/UserTask')({
    filters: [
      // @ts-expect-error
      ['taskGrouping.entityId', api.Filter.Eq, id],
      // @ts-expect-error
      ['taskGrouping.type', api.Filter.Eq, 'AgentActivityReport'],
    ],
    sorts: '-createdAt',
    token,
  })

const useGuardTours = ({
  entity,
  authorization,
}: TProps): TUseGuardToursResults => {
  const [guardTours, getGuardTours] = api.useApiAction(getGuardToursByID)

  useEffect(() => {
    return getGuardTours({
      id: entity.id,
      token: authorization,
    })
  }, [entity, authorization, getGuardTours])

  if (Loadable.isFailure(guardTours)) {
    return Loadable.failure(guardTours.error)
  }
  return guardTours
}

export default useGuardTours
