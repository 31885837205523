import Rete from '@obvious.tech/rete'
import React from 'react'
import {
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withThemeProvider } from 'src/utils/hocs'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.49);',
    textTransform: 'none',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'white',
      border: 'none',
    },
    '&:active': {
      backgroundColor: 'white',
      border: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      border: 'none',
    },
  },
}))

const CheckboxesModal = ({
  label,
  title,
  checkboxes,
  values,
  handleCheckbox,
  handleDialog,
  open,
}) => {
  const classes = useStyles()
  return (
    <Box>
      <Box
        py={1}
        style={{
          textAlign: 'center',
        }}
      >
        <Button
          className={classes.root}
          onClick={handleDialog(true)}
          color='primary'
          variant='outlined'
        >
          {label}
        </Button>
      </Box>
      <Dialog
        onClose={handleDialog(false)}
        open={open}
      >
        <DialogTitle> {title} </DialogTitle>
        <DialogContent>
          {checkboxes.map(item => (
            <FormControlLabel
              key={item.key}
              control={
                <Checkbox
                  checked={values[item.key] || false}
                  onChange={handleCheckbox(item)}
                  color='primary'
                />
              }
              label={item.name}
              style={{
                minWidth: 250,
              }}
            />
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default class CheckboxesModalControl extends Rete.Control {
  component = withThemeProvider(CheckboxesModal)

  constructor(emitter, node, key, onCheckboxChange, checkboxes = [], label) {
    super(key)
    this.key = key
    this.emitter = emitter

    this.values = node.data[key] || {}

    this.props = {
      title: key,
      label,
      values: this.values,
      checkboxes,
      open: node.data.open || false,
      handleDialog: value => () => {
        this.props.open = value
        this.putData('open', value)
        this.update()
      },
      handleCheckbox: item => e => {
        this.values[item.key] = e.target.checked
        this.putData(key, this.values)
        onCheckboxChange && onCheckboxChange(item, e.target.checked)
        this.update()
      },
    }
  }
}
