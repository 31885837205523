// @ts-nocheck
import { Tooltip, Zoom, TextField } from '@material-ui/core'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { IInputProps } from 'src/types'

export const JSONObjectInput: FunctionComponent<IInputProps<unknown>> = ({
  property,
  name,
  onChange,
  value,
  classes,
  ...props
}: IInputProps<unknown>) => {
  const translate = useTranslate()
  const [str, setStr] = useState<string>(() => {
    try {
      if (value == null) return ''
      return JSON.stringify(value, null, 2)
    } catch (e) {
      return ''
    }
  })
  const [err, setErr] = useState<string | null>(null)

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    ({ target: { value } }) => {
      setStr(value)

      try {
        const newValue = JSON.parse(value)
        onChange(newValue)
        setErr(null)
      } catch (e) {
        setErr('Must be valid JSON')
        onChange(null)
      }
    },
    [onChange],
  )

  const helperText = useMemo(() => {
    if (typeof err === 'string') return err
    if (props.invalid) {
      if (typeof props.error === 'string') return translate(props.error)
      return translate(props.error.message, props.error.args)
    }
    return undefined
  }, [props.invalid, props.error, translate, err])

  return (
    <Tooltip
      TransitionComponent={Zoom}
      placement='right'
      title={property.description || ''}
      arrow
    >
      <TextField
        fullWidth
        className={classes}
        variant='outlined'
        onChange={handleChange}
        placeholder={props.placeholder ?? property.displayedName ?? name}
        label={property.displayedName ?? name}
        value={str}
        error={typeof helperText === 'string'}
        helperText={helperText}
        disabled={property?.readOnly}
        multiline
        maxRows={25}
        InputLabelProps={props.InputLabelProps}
      />
    </Tooltip>
  )
}
