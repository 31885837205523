import Rete from '@obvious.tech/rete'
import ReactDOM from 'react-dom'
import * as d3 from 'd3-shape'
import AreaPlugin from 'rete-area-plugin'
import TaskPlugin from 'rete-task-plugin'
import ModulePlugin from '@obvious.tech/rete-module-plugin'
import HistoryPlugin from '@obvious.tech/rete-history-plugin'
import MinimapPlugin from '@obvious.tech/rete-minimap-plugin'
import LifecyclePlugin from 'rete-lifecycle-plugin'
import ConnectionPlugin from '@obvious.tech/rete-connection-plugin'
import ContextMenuPlugin from '@obvious.tech/rete-context-menu-plugin'
import ReactRenderPlugin from 'rete-react-render-plugin'
import ConnectionReroutePlugin from 'rete-connection-reroute-plugin'

import Editor from './rete/Editor'
import getSocketPromise from './Sockets/sockets'
import { store } from '../../../../ConnectedApp'
import DefaultNode from './Components/CustomNode'
import NodeTypizer from './Components/NodeTypizer'
import getEditorComponents from './Editor/Components'
import {
  validateIsActive,
  validateTouched,
} from '../../helpers/validationHelper'
import { deleteSelectedNodes } from '../../helpers/ReteHelpers'
import mountedEditorHelper from '../../helpers/MounterEditorHelper'
import compareDiagramChangesHelper from '../../helpers/CompareDiagramChangesHelper'
import fetchModules from './Editor/fetchModules'

const ZOOM_SETTINGS = {
  min: 0.15,
  max: 2.7,
}

export let sockets = {}
export let definitions = {}
export let editor

export async function reteEngine({
  container,
  diagram,
  bluePrintType,
  onZoom,
  changeBg,
  translateBg,
  notifyErrorCb,
  dataProvider,
}) {
  if (!container) return
  const isDiagram = bluePrintType === 'diagram'
  // reset rendered info, in case when editor instance is created,
  // but forced to recreate when the type of blueprint changed 'module | diagram'
  if (!mountedEditorHelper.isDiagramEmpty(diagram)) {
    mountedEditorHelper.resetRenderedInfo()
  }

  const json = JSON.parse(JSON.stringify(diagram.data))
  container.innerHTML = ''

  const { api: paths, microservices, editor: editorStore } = store.getState()

  if (!Object.keys(definitions).length) definitions = editorStore.definitions

  sockets = await getSocketPromise(definitions)

  if (editor) editor.destroy()
  // TODO: using NAME as id is bad idea. User can create name with spaces
  editor = new Editor(json.id, container)

  editor.use(ConnectionPlugin)
  editor.use(ReactRenderPlugin, {
    component: NodeTypizer(DefaultNode, {}),
  })
  editor.use(ContextMenuPlugin, {
    searchBar: false,
    delay: 200,
    allocate(component) {
      if (component.isHidden) return

      return component.contextMenuPath || []
    },
    rename(component) {
      if (component.name.indexOf('- On ') !== -1) {
        const formatedName = component.name.split(' ')
        return `${formatedName[2]} ${formatedName[4]}`
      }
      return component.contextMenuName || component.name
    },
    nodeItems() {
      if (editor.selected.list.length > 1) {
        return {
          'Delete all selected': () => {
            deleteSelectedNodes(editor)
          },
        }
      }
      return {}
    },
  })

  mountedEditorHelper.addListeners(
    () => editor.trigger('cleanhistory'),
    () => mountedEditorHelper.toggleMiniMap(),
  )

  editor.use(TaskPlugin)
  editor.use(LifecyclePlugin)
  editor.use(HistoryPlugin)
  editor.use(MinimapPlugin)
  mountedEditorHelper.toggleMiniMap()
  editor.use(AreaPlugin, {
    scaleExtent: ZOOM_SETTINGS,
  })
  editor.use(ConnectionReroutePlugin, {
    curve: d3.curveMonotoneX,
    curvature: 0.05,
  })

  const modules = await fetchModules({
    dataProvider,
  })

  const engine = new Rete.Engine(json.id)
  editor.use(ModulePlugin, {
    engine,
    modules,
  })

  const components = getEditorComponents({
    isDiagram,
    microservices,
    paths,
    dataProvider,
  })
  components.forEach(c => {
    editor.register(c)
  })

  if (json) {
    editor.fromJSON(json)
  }

  editor.on('clear', () => {
    mountedEditorHelper.handleClearedEditor()
  })

  editor.on(
    'nodecreated noderemoved connectioncreated connectionremoved nodetranslated',
    () => {
      if (!mountedEditorHelper.mounted) return

      compareDiagramChangesHelper.compare(editor.toJSON())
    },
  )

  editor.on('noderemove', node => {
    // unmount react node component
    const nodeContainer = document.querySelector(`.node-${node.id}`)?.parentNode

    if (nodeContainer) ReactDOM.unmountComponentAtNode(nodeContainer)
  })

  editor.on('nodecreated connectioncreated', data => {
    mountedEditorHelper.checkRenderedEditor(data)
  })

  editor.on('nodecreated', node => {
    const validatedNode = node.validation
      ? {
          ...node.validation,
          ...validateIsActive(node),
        }
      : validateIsActive(node)
    node.validation = validatedNode
    node.update()
  })

  editor.on('connectioncreated connectionremoved', connection => {
    const nodeConnections = Object.values(connection).filter(({ node }) => {
      if (!node) return false

      node.validation = {
        ...validateTouched(node),
      }
      node.update()
      return true
    })

    nodeConnections.forEach(({ node }) => {
      try {
        node &&
          editor.view.updateConnections({
            node,
          })
      } catch {
        console.error('connection not rendered yet')
      }
    })
  })

  editor.on('translate', ({ x, y }) => {
    translateBg({
      x,
      y,
    })
  })

  editor.on('zoom', e => {
    const shouldZoom = e.source !== 'dblclick'

    if (!shouldZoom) {
      return false
    }

    onZoom(e)
    changeBg(e.zoom)
  })

  editor.on('error warn', err => {
    if (!mountedEditorHelper.mounted) mountedEditorHelper.setRendered()
    if (err?.message) notifyErrorCb(err.message)
  })

  editor.view.resize()
  editor.trigger('process')

  // Debug purpose
  window.editor = editor
}
