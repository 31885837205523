import Rete from '@obvious.tech/rete'

import { sockets } from '../..'
import { isRequired } from '../../../../helpers/validationHelper'
export default class LogComponent extends Rete.Component {
  validation = {
    errorRules: {
      act: [isRequired('connection is required')],
    },
    warnRules: {
      inVal: [isRequired('connection is missing')],
    },
  }

  task = {
    outputs: {},
  }

  contextMenuPath = ['Outputs']

  constructor() {
    super('Send to Logs')
  }

  builder(node) {
    const actionInput = new Rete.Input(
      'act',
      'Execute',
      sockets.actionSocket,
      true,
    )
    const dataInput = new Rete.Input('inVal', 'Data', sockets.anyTypeSocket)
    const actionOutput = new Rete.Output(
      'actionOutput',
      'Then',
      sockets.actionSocket,
    )

    node.addInput(actionInput).addInput(dataInput).addOutput(actionOutput)
  }
}
