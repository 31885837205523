import React from 'react'
import { Text } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import styles from './styles'
import { TColumn } from './Table'
import { toStyleArray } from './utils'

import {
  commonStyles,
  createDirectionalStyles,
} from 'src/parser/shared/PDF/Title/CommonUtils'

const HeaderCell = <Cols extends string>({
  column,
}: {
  column: TColumn<Cols, unknown>
}): JSX.Element => {
  const { i18n } = useTranslation()
  const directionalStyles = createDirectionalStyles(commonStyles, i18n.language)

  return (
    <Text
      style={[
        styles.cell,
        styles.headerCell,
        ...toStyleArray(column.style),
        directionalStyles.textAlign,
      ]}
    >
      {column.title}
    </Text>
  )
}

export default HeaderCell
