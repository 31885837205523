import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import { getSocketFromType } from '../../Sockets/utils'
import isSocketPrimitive from '../../../../helpers/IsSocketPrimitive'

export default class ArrayComponent extends Rete.Component {
  constructor() {
    super('Make Array')
    this.task = {
      outputs: {
        arr: 'output',
      },
    }
    this.contextMenuPath = ['Arrays']
  }

  reDrawNode(node) {
    node.update()
    this.editor.view.updateConnections({
      node,
    })
  }

  removeOut(node) {
    node.outputs.forEach(o => {
      o.connections.forEach(connection => {
        this.editor.removeConnection(connection)
      })
      node.removeOutput(o)
    })
  }

  addOut(node, socketType) {
    const areAllsocketsOneType = () => {
      let firstSocketType
      return node.inputs.get('inp').connections.reduce((acc, conn) => {
        if (!acc) return acc

        if (!firstSocketType) {
          firstSocketType = conn.output.socket.name
        }
        if (firstSocketType !== conn.output.socket.name) {
          acc = false
        }
        return acc
      }, true)
    }

    let output
    if (
      !areAllsocketsOneType() ||
      isSocketPrimitive(socketType) ||
      socketType.includes('array')
    ) {
      output = new Rete.Output('arr', 'Array', sockets.arraySocket)
      node.data.socketType = 'array'
    } else {
      output = new Rete.Output(
        'arr',
        `Array of ${socketType}`,
        getSocketFromType('array', socketType),
      )
      node.data.socketType = socketType
    }

    node.addOutput(output)
  }

  toLocalType(socketType) {
    if (socketType.includes('array')) {
      return 'array'
    }
    if (isSocketPrimitive(socketType)) {
      return 'array'
    }

    return socketType
  }

  updateOuput(node, socketType) {
    if (node.data.socketType !== this.toLocalType(socketType)) {
      this.removeOut(node)
      this.addOut(node, socketType)
      this.reDrawNode(node)
    }
  }

  builder(node) {
    const inp = new Rete.Input('inp', 'Any', sockets.anyTypeSocket, true)
    node.addInput(inp)
    this.addOut(node, node.data.socketType || 'array')

    return node
  }

  connected = connection => {
    if (connection.input.key === 'inp') {
      if (!this.editor.silent) {
        setTimeout(() => {
          this.updateOuput(connection.input.node, connection.output.socket.name)
        })
      }
    }
  }

  disconnected = connection => {
    if (connection.input.key === 'inp') {
      setTimeout(() => {
        this.updateOuput(connection.input.node, 'Array')
      })
    }
  }

  worker(node, inputs) {}
}
