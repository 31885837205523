// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Record, useGetList } from 'react-admin'

import { API_ENDPOINTS } from 'src/api'
import { compare } from 'src/utils/str'

interface IGroupsAutocomplete {
  currentGroup: Record | null
  setCurrentGroup: (group: Record | null) => void
  rolePermissions: Record[] | null
  passportPermissions?: Record[] | null
}

const useStyles = makeStyles({
  boldOption: {
    fontWeight: 'bold',
  },
  autocomplete: {
    width: '100%',
    minWidth: 200,
    maxWidth: 400,
  },
})

const GroupsAutocomplete = ({
  currentGroup,
  setCurrentGroup,
  rolePermissions,
  passportPermissions,
}: IGroupsAutocomplete): JSX.Element => {
  const classes = useStyles()

  const { loaded, loading, data, error } = useGetList(API_ENDPOINTS.GROUP, {})

  const [groups, setGroups] = useState<Record[]>([])

  useEffect(() => {
    if (
      !loaded ||
      window.isNullish(rolePermissions) ||
      (passportPermissions !== undefined &&
        window.isNullish(passportPermissions))
    ) {
      return
    }

    const initialGroups = Object.values(data ?? {})

    const sortedGroups = initialGroups
      .map(group => {
        const roleGroupPermissions = hasPermission(rolePermissions, group.name)
        const passportGroupPermissions = hasPermission(
          passportPermissions ?? [],
          group.name,
        )

        return {
          ...group,
          name: group.name,
          hasAuthorizations: Boolean(
            roleGroupPermissions || passportGroupPermissions,
          ),
        }
      })
      .sort((a, b) => compare(a.name, b.name))
      .sort((a, b) => Number(b.hasAuthorizations) - Number(a.hasAuthorizations))

    setGroups(sortedGroups)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loaded,
    data,
    error,
    rolePermissions?.length,
    passportPermissions?.length,
  ])

  useEffect(() => {
    if (groups.length < 1) return

    setCurrentGroup(groups[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups.length])

  const hasPermission = (permissions: Record[], groupName: string): boolean => {
    const permission = permissions.find(({ group }) => group === groupName)
    return !window.isNullish(permission)
  }

  const isParsing = loaded && Object.values(data ?? {}).length !== groups.length

  return (
    <Autocomplete
      options={groups}
      loading={loading || isParsing}
      loadingText='Fetching groups...'
      value={currentGroup}
      onChange={(e, selectedGroup) => setCurrentGroup(selectedGroup)}
      getOptionLabel={({ name }) => name}
      getOptionSelected={(option, value) => option.name === value.name}
      className={classes.autocomplete}
      renderOption={({ name, hasAuthorizations }) => (
        <Typography
          className={hasAuthorizations ? classes.boldOption : undefined}
        >
          {!hasAuthorizations && '+ '}
          {name}
        </Typography>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label='On group'
          variant='outlined'
        />
      )}
    />
  )
}

export default GroupsAutocomplete
