import React from 'react'
import { Document, Page } from '@react-pdf/renderer'

import AgentActivityTitle from 'src/parser/shared/PDF/Title/AgentActivityTitle'
import { AgentActivityHeader } from 'src/parser/shared/PDF/Header/CommonHeader'
import Sections from 'src/parser/shared/PDF/Sections'
import Location from './Location'
import CheckInOutData from './CheckInOutData'
import GuardTours from './GuardTours'
import CreatedAlarms from './CreatedAlarms'
import AttendanceDuration from './AttendanceDuration'
import { TAgentReportProps } from 'src/parser/shared/PDF/Provider'
import { PageFooter, styles } from 'src/parser/shared/PDF/PageFooter'

const sections = [
  {
    title: 'agent_activity_report.check_in',
    Component: CheckInOutData,
    variant: 'CheckIn' as const,
  },
  {
    title: 'agent_activity_report.check_out',
    Component: CheckInOutData,
    variant: 'CheckOut' as const,
  },
  {
    Component: AttendanceDuration,
  },
  {
    title: 'agent_activity_report.gps_tracking',
    Component: Location,
  },
  {
    title: 'agent_activity_report.guard_tours.title',
    Component: GuardTours,
  },
  {
    Component: CreatedAlarms,
  },
]

export default (props: TAgentReportProps): JSX.Element => {
  return (
    <Document>
      <Page
        style={styles.page}
        wrap
      >
        <AgentActivityTitle {...props} />
        <AgentActivityHeader {...props} />
        <Sections
          // @ts-expect-error
          sectionProps={props}
          sections={sections}
          mode='ActivityReport'
        />
        <PageFooter />
      </Page>
    </Document>
  )
}
