import { TEntityOverride } from 'src/types'

import CreateRole from './CreateRole'
import EditRole from './EditRole'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    displayedComponent: CreateRole,
    properties: {
      description: {
        multiline: true,
      },
    },
  },
  edit: {
    displayedComponent: EditRole,
    properties: {
      description: {
        multiline: true,
      },
    },
  },
  list: {
    properties: {},
  },
}

export default override
