import React from 'react'
import { IAgent } from '@obvious.tech/constellation'
import { Link } from 'react-router-dom'

import { TEntityOverride, TEntityOverrideProp } from 'src/types'
import { Chip } from '@material-ui/core'

const LinkToActivityReport = (props: {
  property: TEntityOverrideProp<IAgent>
  record: IAgent
  name: string
}): JSX.Element | null => {
  if (typeof props.record.passportId !== 'string') return <div></div>
  const { passportId } = props.record

  return (
    <Link to={`/authentication/api/Passport/${passportId}/AgentActivityReport`}>
      <Chip
        size='small'
        style={{
          backgroundColor: '#a6dff3',
        }}
        onClick={e => e.stopPropagation()}
        label='Reports'
      />
    </Link>
  )
}

const override: TEntityOverride<IAgent, '$activityReports'> = {
  title: ({ lastname, firstname }) => {
    if (typeof lastname === 'string' && lastname.length > 0) {
      return `${firstname} ${lastname}`
    }
    return firstname
  },
  extraProperties: {
    $activityReports: {
      type: 'undefined',
      readOnly: true,
      displayedName: 'Activity reports',
      displayedComponent: LinkToActivityReport,
    },
  },
  global: {
    order: [
      'id',
      'groups',
      'label',
      'passportId',
      '$activityReports',
      'lastname',
      'firstname',
      'birthDate',
      'organization',
      'service',
      'job',
      'photoId',
      'status',
      'transportMode',
      'buildingGroup',
      'layers',
      'markerId',
      'markerImageId',
      'location',
      'locationTimestamp',
      'isConnected',
      'activeConnections',
      'cameraIds',
      'equipments',
      'geofencesIds',
      'missionId',
    ],
    properties: {
      id: {
        displayedName: 'ID',
      },
      label: {
        displayedName: 'Label',
      },
      firstname: {
        displayedName: 'Firstname',
      },
      lastname: {
        displayedName: 'Lastname',
      },
      organization: {
        displayedName: 'Organization',
      },
      service: {
        displayedName: 'Service',
      },
      job: {
        displayedName: 'Job',
      },
      passportId: {
        displayedName: 'Passport',
        referenceTo: 'authentication/api/Passport',
        referencedField: 'username',
      },
      cameraIds: {
        displayedName: 'Cameras',
        referenceTo: 'videomanager/api/Camera',
        referencedField: 'name',
      },
      missionId: {
        displayedName: 'Mission ID',
        referenceTo: 'missions/api/Mission',
        readOnly: false,
      },
      markerId: {
        displayedName: 'Marker ID',
        referenceTo: 'geolocation/api/Marker',
        referencedField: 'id',
      },
      isConnected: {
        type: 'boolean',
        displayedName: 'Is connected ?',
        readOnly: false,
      },
      location: {
        displayedName: 'Location',
      },
      locationTimestamp: {
        displayedName: 'Location last updated',
      },
      buildingGroup: {
        displayedName: 'Building group',
      },
      photoId: {
        displayedName: 'Photo',
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
      markerImageId: {
        displayedName: 'Marker icon / Avatar',
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
      activeConnections: {
        displayedName: 'Active connections',
      },
      transportMode: {
        displayedName: 'Transport mode',
      },
      groups: {
        type: 'groups',
        displayedName: 'Owners',
      },
      geofencesIds: {
        displayedName: 'Geofences',
        referenceTo: 'geolocation/api/Geofence',
        referencedField: 'name',
      },
      birthDate: {
        displayedName: 'Birth date',
        type: 'date-time',
      },
      layers: {
        displayedName: 'Layers/Filters',
      },
      status: {
        displayedName: 'Status',
        referenceTo: 'units/api/AgentStatus',
        referencedField: 'name',
      },
      equipments: {
        displayedName: 'Equipments',
      },
    },
  },
  create: {
    properties: {
      isConnected: {
        hidden: true,
      },
    },
  },
  edit: {
    properties: {
      isConnected: {
        readOnly: false,
      },
    },
  },
  list: {
    properties: {
      markerId: {
        hidden: true,
      },
      location: {
        hidden: true,
      },
      cameraIds: {
        hidden: true,
      },
      missionId: {
        hidden: true,
      },
      photoId: {
        hidden: true,
      },
      markerImageId: {
        hidden: true,
      },
    },
  },
}

export default override
