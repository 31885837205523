import { TEntityOverride } from 'src/types'

import CreateToolbar from './Actions/CreateToolbar'
import EditLicense from './EditLicense'
import DownloadLicense from './Actions/DownloadLicense'
import ListActions from './List/ListActions'

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    displayedActions: ListActions,
    properties: {
      hardwareId: {
        displayedName: 'license file',
        displayedComponent: DownloadLicense,
      },
      licenseText: {
        hidden: true,
      },
    },
  },
  create: {
    properties: {
      hardwareId: {
        hidden: true,
      },
      licenseText: {
        displayedName: 'License',
        type: 'file',
        readOnly: false,
      },
      groups: {
        hidden: true,
      },
    },
    displayedFormToolbar: CreateToolbar,
  },
  edit: {
    displayedComponent: EditLicense,
  },
}

export default override
