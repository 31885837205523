import Rete from '@obvious.tech/rete'

import { compareNodeOptions } from 'src/utils/flow'

import { sockets } from '../..'
import SelectControl from '../../Controls/SelectControl'

export default class CastComponent extends Rete.Component {
  constructor() {
    super('Cast data')
    this.task = {
      outputs: {
        result: 'output',
      },
    }
  }

  handleChangeType = node => newType => {
    if (newType) {
      const output = node.outputs.get('result')
      if (output) {
        output.name = newType
        output.socket = sockets[newType]
      } else {
      }

      node.update()
      setTimeout(() => {
        this.editor.view.updateConnections({
          node,
        })
      }, 1)
    }
  }

  builder(node) {
    const inp = new Rete.Input('data', 'Data', sockets.anyTypeSocket, true)
    node.addInput(inp)
    const out = new Rete.Output('result', 'Result', sockets.anyTypeSocket)
    node.addOutput(out)
    const options = Object.keys(sockets)
      .map(socketName => ({
        value: socketName,
        label: socketName,
      }))
      .sort(compareNodeOptions)

    const ctrl = new SelectControl(
      this.editor,
      node,
      'entityType',
      'Cast to',
      this.handleChangeType(node),
      options,
    )
    return node.addControl(ctrl)
  }
}
