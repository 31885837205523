// @ts-nocheck
import React from 'react'
import invert from 'lodash.invert'
import {
  Theme,
  makeStyles,
  SvgIcon,
  Tooltip,
  capitalize,
} from '@material-ui/core'

import { ReactComponent as readIcon } from 'src/assets/icons/read.svg'
import { ReactComponent as createIcon } from 'src/assets/icons/create.svg'
import { ReactComponent as editIcon } from 'src/assets/icons/edit.svg'
import { ReactComponent as deleteIcon } from 'src/assets/icons/delete.svg'

const iconColors = {
  READ: '#2C77E8',
  CREATE: '#16981A',
  EDIT: '#E93AD2',
  DELETE: '#E74427',
  DEFAULT: 'rgba(255, 255, 255, 0.75)',
}

const useStyles = makeStyles<Theme, { icon: string }>(theme => ({
  iconButton: {
    border: 'none',
    padding: 0,
    margin: '0px 4px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      padding: 0,
    },
    '&:disabled': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    '&:disabled svg': {
      filter: 'none',
    },
  },
  iconButtonSvg: {
    borderRadius: '50%',
    borderWidth: 3,
    borderStyle: 'solid',
    transition: `all ${theme.transitions.duration.standard}ms`,
    color: '#AAA',
    borderColor: '#AAA',
    backgroundColor: 'transparent',
    '&[data-perm-kind="full"]': {
      backgroundColor: ({ icon }) => iconColors[icon],
      color: iconColors.DEFAULT,
      borderColor: 'transparent',
    },
    '&[data-perm-kind~="outlined"]': {
      borderColor: ({ icon }) => iconColors[icon],
      color: ({ icon }) => iconColors[icon],
    },
    '&:hover': {
      filter: 'brightness(70%)',
    },
    '&:active': {
      filter: 'brightness(130%)',
    },
  },
}))

interface IPermissionButton {
  disabled?: boolean
  onClick: (level: number) => void
  className?: string
  level: number
  kind?: 'outlined' | 'full'
}

const PermissionButton = ({
  disabled,
  onClick,
  level,
  kind,
}: IPermissionButton): JSX.Element => {
  const PERMISSIONS = {
    read: 0,
    edit: 1,
    create: 2,
    delete: 3,
  }

  const LEVEL_ICON = {
    0: readIcon,
    1: editIcon,
    2: createIcon,
    3: deleteIcon,
  }

  const getPermissionName = (btnLevel: number): keyof typeof PERMISSIONS => {
    const invertedPermissions = invert(PERMISSIONS)

    return invertedPermissions[btnLevel]
  }

  const classes = useStyles({
    icon: getPermissionName(level).toUpperCase(),
  })

  const handleTooltipTitle = (): string =>
    Object.keys(PERMISSIONS)
      .filter((_, i) => i <= level)
      .map(permission => capitalize(permission))
      .join(' / ')

  return (
    <button
      className={classes.iconButton}
      disabled={disabled}
      onClick={() => onClick(level)}
    >
      <Tooltip title={handleTooltipTitle()}>
        <SvgIcon
          fontSize='large'
          className={classes.iconButtonSvg}
          data-perm-kind={kind}
          component={LEVEL_ICON[level]}
          viewBox='0 0 18 18'
        />
      </Tooltip>
    </button>
  )
}

export default PermissionButton
