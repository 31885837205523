import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import * as theme from 'src/parser/shared/PDF/theme'
import NoContent from 'src/parser/shared/PDF/NoContent'
import { TSectionContent } from 'src/parser/shared/PDF/Sections'

import {
  commonStyles,
  createDirectionalStyles,
  getDirection,
} from 'src/parser/shared/PDF/Title/CommonUtils'

const Location: TSectionContent = ({ entity, map }) => {
  const { t, i18n } = useTranslation()
  const { location } = entity
  const isRTL = getDirection(i18n.language) === 'rtl'
  const directionalStyles = createDirectionalStyles(commonStyles, i18n.language)

  if (!location?.latitude || !location?.longitude) {
    return <NoContent />
  }

  return (
    <>
      <View
        style={[
          directionalStyles.container,
          { flexDirection: isRTL ? 'row-reverse' : 'row' },
        ]}
      >
        <Text style={[theme.styles.subtitle, directionalStyles.textAlign]}>
          {`${t('alarm_report.location.latitude')} : `}
        </Text>
        <Text style={[theme.styles.text, directionalStyles.textAlign]}>
          {t('alarm_report.location.lat_lng_format', {
            value: location.latitude,
          })}
        </Text>
        <Text>{` `}</Text>
        <Text style={[theme.styles.subtitle, directionalStyles.textAlign]}>
          {`${t('alarm_report.location.longitude')} : `}
        </Text>
        <Text style={[theme.styles.text, directionalStyles.textAlign]}>
          {t('alarm_report.location.lat_lng_format', {
            value: location.longitude,
          })}
        </Text>
      </View>
      {map && (
        <Image
          source={map}
          style={{
            width: '100%',
            alignSelf: 'center',
            marginTop: theme.padding(1),
          }}
        />
      )}
    </>
  )
}

export default Location
