import Rete from '@obvious.tech/rete'

import Input from 'src/components/flow/Input'
import { validateTouched } from '../../../../helpers/validationHelper'
import compareDiagramChangesHelper from '../../../../helpers/CompareDiagramChangesHelper'

class NumberControl extends Rete.Control {
  component = Input
  NUMBER_REGEX = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/

  constructor(emitter, node, key, label, onChange) {
    super(key)
    this.emitter = emitter
    this.key = key
    const initial = node.data[key] ?? ''
    node.data[key] = initial
    this.props = {
      label,
      value: initial,
      onChange: value => {
        if (value && !this.NUMBER_REGEX.test(value)) return

        this.setValue(value)
        onChange && onChange(value)
      },
      onBlur: ({ target: { value } }) => {
        if (value) {
          const number = Number(value)
          this.setValue(number)
          onChange && onChange(number)
        }
        compareDiagramChangesHelper.compare(this.emitter.toJSON())

        if (!node.validation) return

        node.validation = { ...validateTouched(node) }
        node.update()
      },
    }
  }

  setValue(val) {
    this.props.value = val
    this.putData(this.key, val)
    this.update()
  }
}

export default NumberControl
