import Rete from '@obvious.tech/rete'
import React from 'react'
import {
  FormControl,
  FormLabel,
  Chip,
  makeStyles,
  Button,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  item: {
    margin: theme.spacing(0.5, 0),
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

const FileInputControlComponent = ({
  id,
  label,
  files,
  onChange,
  multiple,
  accept,
  btnLabel,
  placeholder,
  onDelete,
}) => {
  const classes = useStyles()
  const inputRef = React.useRef(null)
  return (
    <FormControl margin='dense'>
      {label && (
        <FormLabel htmlFor={id || 'custom-file-input'}>{label}</FormLabel>
      )}
      <input
        ref={inputRef}
        id={id || 'custom-file-input'}
        type='file'
        style={{
          display: 'none',
        }}
        accept={accept}
        multiple={multiple}
        onChange={onChange}
      />
      <div className={classes.container}>
        <Button
          variant='outlined'
          onClick={() => {
            inputRef.current.click()
          }}
          color='primary'
          className={classes.item}
        >
          {btnLabel || 'Upload file'}
        </Button>
        <div className={classes.itemWrapper}>
          {files.length ? (
            files.map((file, index) => {
              const filename =
                file.name.length > 15
                  ? file.name.slice(0, 15) + '...'
                  : file.name

              return (
                <Chip
                  key={index}
                  onDelete={() => onDelete(index)}
                  variant='outlined'
                  label={filename}
                  className={classes.item}
                />
              )
            })
          ) : (
            <span className={classes.item}>
              {placeholder || 'No file uploaded'}
            </span>
          )}
        </div>
      </div>
    </FormControl>
  )
}

export default class FileInputControl extends Rete.Control {
  component = FileInputControlComponent

  constructor(
    emitter,
    node,
    key,
    label,
    onChange,
    options = {
      accept: '',
      multiple: false,
      btnLabel: '',
      placeholder: '',
    },
  ) {
    super(key)
    this.emitter = emitter
    this.key = key

    const initial = []

    this.props = {
      label,
      files: initial,
      onChange: e => {
        const files = Array.from(e.target.files).map(item => item)
        e.target.value = null
        if (!files.length) {
          return false
        }
        this.setValue(
          options.multiple ? [...this.props.files, ...files] : files,
        )
        onChange && onChange(files)
        this.emitter.trigger('process')
      },
      onDelete: index => {
        this.setValue(this.props.files.filter((item, i) => i !== index))
        this.emitter.trigger('process')
      },
      ...options,
    }
  }

  setValue(val) {
    this.props.files = val
    this.putData(this.key, val)
    this.update()
  }
}
