import { TEntityOverride } from 'src/types'

import ImageList from './ImageList'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      id: {
        displayedName: 'Id',
      },
      imageData: {
        type: 'Image',
      },
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    displayedComponent: ImageList,
    properties: {
      imageData: {
        type: 'Image',
      },
    },
  },
}

export default override
