import React, { useEffect, useState } from 'react'
import { Popover, Box, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider, useEditContext } from 'react-admin'

import flowClasses from './theme/classes'
import flowStyles from './theme/styles'
import TableWithList from './TableWithList'
import useErrorFetch from 'src/hooks/useErrorFetch'

const useStyles = makeStyles(theme => {
  return {
    popupTitleContainer: {
      ...flowClasses.popupTitleContainer,
      position: 'relative',
      textAlign: 'center',
    },
    popupTitle: flowClasses.popupTitle,
    openDiagramWindow: {
      marginLeft: theme.spacing(1),
    },
    rootLabel: {
      fontStyle: 'italic',
      fontSize: '12pt',
      color: flowStyles.palette.text.placeholder,
      '&$labelDense': {
        transform: 'translate(14px, 6px) scale(1)',
      },
      '&$labelShrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
        color: flowStyles.palette.text.placeholder,
      },
    },
    labelDense: {},
    labelShrink: {},

    inputRoot: {
      fontStyle: 'italic',
      fontSize: '12pt',
      color: flowStyles.palette.text.placeholder,
      '& $inputDense': {
        paddingTop: '3px',
        paddingBottom: '3px',
      },
      '&$inputFocused $inputNotched': {
        border: '1px solid rgba(0,0,0,0.5)',
      },
    },
    inputFocused: {},
    inputDense: {},
    inputNotched: {},

    scrollBar: {
      height: '60vh',
      width: '680px',
    },
    thumbVertical: {
      backgroundColor: 'white',
      borderRadius: '3px',
      width: '60%',
      margin: '0 auto',
    },
    trackVertical: {
      position: 'absolute',
      width: '10px',
      right: '0px',
      bottom: '0px',
      padding: '2px 0',
      top: '0px',
      borderRadius: '3px',
      backgroundColor: 'black',
      textAlign: 'center',
    },
  }
})

const ListOfBLueprint = ({ open, close, listAnchorEl }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const editContext = useEditContext()
  const fetchError = useErrorFetch()

  const [enteredString, setEnteredString] = useState('')
  const [bps, setBps] = useState([])
  const [filteredBlueprints, setFilteredBlueprints] = useState([])

  const onChange = ({ target: { value } }) => setEnteredString(value)

  useEffect(() => {
    const results = bps.filter(bp =>
      bp.name.toLowerCase().includes(enteredString.toLowerCase()),
    )
    setFilteredBlueprints(results)
  }, [enteredString, bps])

  useEffect(() => {
    if (!open) return

    const getAllBlueprints = async () => {
      try {
        const [microservice, api] = editContext.resource.split('/')
        const [diagrams, modules] = await Promise.all([
          dataProvider.getList(`${microservice}/${api}/diagram`, {}),
          dataProvider.getList(`${microservice}/${api}/module`, {}),
        ])

        const updatedDiagrams = diagrams.data.map(diagram => ({
          ...diagram,
          type: 'diagram',
        }))
        const updatedModules = modules.data.map(subDiagram => ({
          ...subDiagram,
          type: 'module',
        }))

        setBps([...updatedDiagrams, ...updatedModules])
      } catch (e) {
        fetchError(e)
      }
    }

    getAllBlueprints()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Popover
      id='log-popover'
      open={open}
      anchorEl={listAnchorEl}
      onClose={close}
      className={classes.openDiagramWindow}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box
        py={2}
        className={classes.popupTitleContainer}
      >
        <div className={classes.popupTitle}> Open </div>
      </Box>
      <Box>
        <Box
          pt={2}
          px={2}
          mb={2}
        >
          <TextField
            id='outlined-basic'
            placeholder='Search your project'
            variant='outlined'
            value={enteredString}
            onChange={onChange}
            size='small'
            fullWidth
            InputLabelProps={{
              classes: {
                root: classes.rootLabel,
                marginDense: classes.labelDense,
                shrink: classes.labelShrink,
              },
            }}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                focused: classes.inputFocused,
                inputMarginDense: classes.inputDense,
                notchedOutline: classes.inputNotched,
              },
            }}
          />
        </Box>
        <Box className={classes.scrollBar}>
          <TableWithList
            bps={filteredBlueprints}
            onClose={close}
          />
        </Box>
      </Box>
    </Popover>
  )
}

export default ListOfBLueprint
