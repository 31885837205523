const extendGlobalEntity = (entity, viewKey) => {
  const { global, list, create, edit, ...entityWithoutViews } = entity
  const view = entity?.[viewKey]
  const { properties = {}, ...restViewProperty } = view

  const keys = [
    ...new Set(
      Object.keys(global.properties).concat(...Object.keys(properties)),
    ),
  ]

  const newProperties = Object.fromEntries(
    keys.map(key => [
      key,
      {
        ...global.properties[key],
        ...properties[key],
      },
    ]),
  )

  for (const key in properties) {
    if (!newProperties.hasOwnProperty(key)) {
      newProperties[key] = properties[key]
    }
  }

  const extended = {
    ...entityWithoutViews,
    ...global,
    ...restViewProperty,
    properties: newProperties,
  }

  return extended
}

export default extendGlobalEntity
