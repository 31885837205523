import LogComponent from '../Nodes/Log/LogComponent'
import CastComponent from '../Nodes/Sockets/CastComponent'
import ArrayComponent from '../Nodes/Arrays/ArrayComponent'
import TimerComponent from '../Nodes/Actions/TimerComponent'
import StringComponent from '../Nodes/String/StringComponent'
import ModuleComponent from '../Nodes/Module/ModuleComponent'
import ActionCollector from '../Nodes/Actions/ActionCollector'
import NumberComponent from '../Nodes/Number/NumberComponent'
// Removed until implemented on FlowEngine (see issue #861)
// import ScriptComponent from '../Nodes/Script/ScriptComponent';
import IfElseComponent from '../Nodes/Actions/IfElseComponent'
import ObjectComponent from '../Nodes/Objects/ObjectComponent'
import BooleanComponent from '../Nodes/Boolean/BooleanComponent'
import UserTaskComponent from '../Nodes/UserTask/UserTaskComponent'
import FunctionComponent from '../Nodes/Function/FunctionComponent'
import ForEachComponent from '../Nodes/Arrays/Loops/ForEachComponent'
import SwitchCaseComponent from '../Nodes/Switch/SwitchCaseComponent'
import PushToArrayComponent from '../Nodes/Arrays/PushToArrayComponent'
import MergeArraysComponent from '../Nodes/Arrays/MergeArraysComponent'
import ModuleInputComponent from '../Nodes/Module/ModuleInputComponent'
import ComposeComponent from '../Nodes/Objects/Compose/ComposeComponent'
import FileUploadComponent from '../Nodes/FileInput/FileUploadComponent'
import ComparisonComponent from '../Nodes/Comparison/ComparisonComponent'
import ModuleOutputComponent from '../Nodes/Module/ModuleOutputComponent'
import MergeObjectsComponent from '../Nodes/Objects/MergeObjectsComponent'
import DateFunctionComponent from '../Nodes/Function/DateFunctionComponent'
import LocationCallbackComponent from '../Nodes/Callbacks/LocationCallback'
import ArraySortByComponent from '../Nodes/Arrays/Loops/ArraySortByComponent'
import ArrayCollectorComponent from '../Nodes/Arrays/ArrayCollectorComponent'
import ArrayFilterComponent from '../Nodes/Arrays/Loops/ArrayFilterComponent'
import DecomposeComponent from '../Nodes/Objects/Decompose/DecomposeComponent'
import HttpRequestsComponent from '../Nodes/HTTPRequests/HttpRequestsComponent'
import ArrayFindOneComponent from '../Nodes/Arrays/Loops/ArrayFindOneComponent'
import EndOfProcessComponent from '../Nodes/EndOfProcess/EndOfProcessComponent'
import ConstellationComponent from '../Nodes/Constellation/ConstellationComponent'
import GenericDecomposeComponent from '../Nodes/Objects/Decompose/GenericDecomposeComponent'
import ConstellationEntityEventComponent from '../Nodes/Triggers/ConstellationEventComponent'
import ConstellationEntityWaitEventComponent from '../Nodes/Actions/ConstellationWaitEventComponent'
import ScheduleWithIntervalComponent from '../Nodes/Triggers/ScheduleWithIntervalComponent'

import {
  ComparisonTypes,
  comparisonFunctions,
} from '../../../helpers/ComparisonHelper'
import {
  functionTypes,
  functionsDefinitions,
} from '../../../helpers/FunctionHelper'

const editorComponents = ({
  microservices,
  paths,
  dataProvider,
  isDiagram,
}) => {
  const entitiesEventsComponents = Object.keys(microservices)
    .reduce((acc, microservice) => {
      const waitEntityComponents = microservices[microservice].map(event => [
        new ConstellationEntityWaitEventComponent({
          entity: event,
          event: 'handleUpdateEntity',
          microservice,
          type: 'Wait',
        }),
        new ConstellationEntityWaitEventComponent({
          entity: event,
          event: 'handleCreateEntity',
          microservice,
          type: 'Wait',
        }),
        new ConstellationEntityWaitEventComponent({
          entity: event,
          event: 'handleDeleteEntity',
          microservice,
          type: 'Wait',
        }),
      ])
      const entityComponents = microservices[microservice].map(event => [
        new ConstellationEntityEventComponent({
          entity: event,
          event: 'handleUpdateEntity',
          microservice,
        }),
        new ConstellationEntityEventComponent({
          entity: event,
          event: 'handleCreateEntity',
          microservice,
        }),
        new ConstellationEntityEventComponent({
          entity: event,
          event: 'handleDeleteEntity',
          microservice,
        }),
      ])

      if (isDiagram) {
        return [...acc, ...entityComponents, ...waitEntityComponents]
      }

      return [...acc, ...waitEntityComponents]
    }, [])
    .flat(1)

  const comparisonComponents = Object.keys(ComparisonTypes).reduce(
    (comparisonsComponents, comparisonType) =>
      comparisonType !== 'object'
        ? [
            ...comparisonsComponents,
            ...ComparisonTypes[comparisonType].map(comparisonFunctionName => {
              const fct =
                comparisonFunctions[comparisonFunctionName](comparisonType)
              return new ComparisonComponent(
                `${comparisonType} - ${comparisonFunctionName}`,
                [comparisonType, ...fct.args],
              )
            }),
          ]
        : comparisonsComponents,
    [],
  )

  const functionComponents = Object.keys(functionTypes).reduce(
    (functionsComponents, functionType) =>
      functionType !== 'object'
        ? [
            ...functionsComponents,
            ...functionTypes[functionType].map(functionName => {
              const fct = functionsDefinitions[functionName](functionType)

              if (functionType === 'date') {
                return new DateFunctionComponent(
                  `${functionType} - ${functionName}`,
                  [...fct.args],
                  fct.returnType,
                )
              }

              return new FunctionComponent(
                `${functionType} - ${functionName}`,
                [functionType, ...fct.args],
                fct.returnType,
              )
            }),
          ]
        : functionsComponents,
    [],
  )

  const pathComponents = Object.keys(paths)
    .filter(p => p !== 'Module')
    .map(
      path =>
        new ConstellationComponent(
          path,
          paths[path].microservice,
          paths[path].functions,
        ),
    )
    .flat(2)
    .sort((a, b) => a.name > b.name)

  const moduleComponents = [
    new ModuleComponent(isDiagram, dataProvider),
    new ModuleInputComponent(isDiagram),
    new ModuleOutputComponent(isDiagram),
  ]

  const specificComponents = isDiagram ? [new EndOfProcessComponent()] : []

  const allComponents = [
    ...pathComponents,
    ...entitiesEventsComponents,
    ...comparisonComponents,
    ...functionComponents,
    new NumberComponent(),
    new StringComponent(),
    new BooleanComponent(),
    new IfElseComponent(),
    new LogComponent(),
    new ArrayComponent(),
    new ArrayCollectorComponent(),
    new PushToArrayComponent(),
    new TimerComponent('Timer'),
    new DecomposeComponent(),
    new ComposeComponent(),
    new ForEachComponent(),
    new ArrayFindOneComponent(),
    new ArraySortByComponent(),
    new ObjectComponent(),
    new MergeObjectsComponent(),
    new ArrayFilterComponent(),
    new UserTaskComponent(),
    new HttpRequestsComponent(),
    new GenericDecomposeComponent(),
    new FileUploadComponent(),
    // Removed until implemented on FlowEngine (see issue #861)
    // new ScriptComponent(),
    new ScheduleWithIntervalComponent(),
    new LocationCallbackComponent(),
    new SwitchCaseComponent(),
    new MergeArraysComponent(),
    new CastComponent(),
    new ActionCollector(),
    ...moduleComponents,
    ...specificComponents,
  ]

  return allComponents
}

export default editorComponents
