// @ts-nocheck

import { Font } from '@react-pdf/renderer'

import RobotoBold from 'src/assets/fonts/Roboto-Bold.ttf'
import RobotoLight from 'src/assets/fonts/Roboto-Light.ttf'
import RobotoRegular from 'src/assets/fonts/Roboto-Regular.ttf'
import RobotoArabic from 'src/assets/fonts/Roboto-Arabic.ttf'

/** needs to be called before any react-pdf including this font is rendered */

// changed all PDF fonts to RobotoArabic as temporary solution
// agreed with Beranger
export default () => {
  Font.register({
    family: 'Roboto',
    fonts: [{ src: RobotoArabic }],
  })
  Font.register({
    family: 'RobotoArabic',
    fonts: [
      { src: RobotoArabic }, // font-style: normal, font-weight: normal
    ],
  })
}
