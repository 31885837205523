import { minValue, maxValue, regex, maxLength, minLength } from 'react-admin'

export const isHexColor = color => {
  if (!color) return false

  const hexColorRegex = /^#([\da-f]{3}){1,2}$|^#([\da-f]{4}){1,2}$/i
  const isValidHex = hexColorRegex.test(color)

  if (isValidHex) return false

  return 'Must be a valid hex color.'
}

export const validationFunctions = {
  minLength: a => b => minLength(a)(b || ''),
  maxLength: a => b => maxLength(a)(b || ''),
  pattern: a => b => regex(new RegExp(a))(b || ''),
  minValue: a => b => minValue(a)(b || 0),
  maxValue: a => b => maxValue(a)(b || 0),
  multipleOf: a => b => b % a === 0 ? [] : `Input should be a multiple of ${a}`,
}
