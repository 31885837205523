import Rete from '@obvious.tech/rete'
import StringControl from '../../Controls/SocketControls/StringControl'
import { sockets } from '../..'
import SelectControl from '../../Controls/SelectControl'
import DefaultNode from '../../Components/CustomNode'
import NodeTypizer from '../../Components/NodeTypizer'

export default class ModuleOutputComponent extends Rete.Component {
  constructor(isDiagram) {
    super('Module Output')
    this.module = {
      nodeType: 'output',
      socket: node => sockets[node.data.socketType],
    }
    this.task = {
      outputs: {},
    }
    this.isHidden = !!isDiagram
    this.contextMenuPath = ['Module']
    this.data.component = NodeTypizer(DefaultNode, {
      type: 'module',
    })
  }

  builder(node) {
    const options = Object.keys(sockets).map(s => ({
      value: s,
      label: s.split('Socket')[0],
    }))

    const handleInput = name => {
      node.inputs.forEach(o => {
        o.connections.forEach(connection =>
          this.editor.removeConnection(connection),
        )
        node.removeInput(o)
      })
      if (name) {
        node.addInput(
          new Rete.Input('input', name.split('Socket')[0], sockets[name], true),
        )
      }
      node.update()
      setTimeout(() => {
        this.editor.view.updateConnections({
          node,
        })
      }, 1)
    }

    return node
      .addControl(
        new SelectControl(
          this.editor,
          node,
          'socketType',
          'Entity',
          handleInput,
          options,
        ),
      )
      .addControl(new StringControl(this.editor, node, 'name', 'Socket name'))
  }
}
