import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { PDFViewer } from './PDFViewer'
import { usePDF } from '@react-pdf/renderer'
import useDownloadZip from 'src/parser/entities/Alarm/UserMenu/useDownloadZip'
import Preview from 'src/parser/entities/Alarm/Preview/Preview'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#323639',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#424649',
    },
  },
}))

const AlarmPDFViewer = (props): JSX.Element | null => {
  const [pdf] = usePDF({
    document: (
      <Preview
        {...props}
        mode={'download'}
      />
    ),
  })

  const src = pdf.url ? `${pdf.url}#toolbar=1` : undefined

  const { entity } = props
  const [downloadZip, loading] = useDownloadZip({
    ...props,
    pdf,
    alarm: entity,
  })
  const classes = useStyles()

  if (pdf.loading || pdf.error !== null || pdf.blob === null) {
    return null
  }

  return (
    <PDFViewer
      src={src}
      pdf={pdf}
      title={props.title}
      showDownloadButton
      loading={loading}
      buttonClasses={classes}
      onDownload={downloadZip}
    />
  )
}

export default AlarmPDFViewer
