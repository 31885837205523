import { isHexColor } from 'src/adminPanel/screens/inputs/validationHelpers'
import { TEntityOverride } from 'src/types'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      color: {
        type: 'color',
        validations: [isHexColor],
      },
      groups: {
        type: 'groups',
      },
    },
  },
}

export default override
