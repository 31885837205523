import React from 'react'
import { makeStyles } from '@material-ui/styles'
import * as RL from 'react-leaflet'
import * as L from 'leaflet'

import Map from 'src/components/Map/Map'

type TLocation = {
  latitude: number
  longitude: number
  altitude: number
}

type TTrack = {
  groups: string[] | null
  id: string
  isEntityActive: boolean
  location: TLocation
  locationHistory: Array<{
    timestamp: string
    location: TLocation
  }> | null
  markerId: string
  trackedEntityBehavior: string
  trackedEntityId: string
}

type TProps = {
  record: TTrack
}

type TTrackPathNode = {
  timestamp: string
  leaveTimestamp?: string
  location: L.LatLng
}

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '50vh',
    marginTop: 8,
    boxShadow: 'inset -2px -2px black',
  },
  pathLine: {
    cursor: 'grab',
  },
  pathPane: {
    opacity: 0.65,
  },
  marker: {
    transition: 'filter 0.3 ease 0s',
    '&:hover': {
      filter: 'brightness(70%)',
    },
    '&:active': {
      filter: 'brightness(200%)',
    },
  },
})

const toLatLng = (src: TTrack['locationHistory']) => {
  if (!src?.length) return []

  return src.map(item => ({
    ...item,
    location: new L.LatLng(item.location.latitude, item.location.longitude),
  }))
}

const TOO_CLOSE_METERS_DIST = 1
const areTooClose = (a: TTrackPathNode, b: TTrackPathNode) => {
  return a.location.distanceTo(b.location) < TOO_CLOSE_METERS_DIST
}

function mergeTooClose(src: TTrackPathNode[]) {
  const path: TTrackPathNode[] = []

  let node: TTrackPathNode
  let prevNode: TTrackPathNode | null = null
  let stoppedOnNode: TTrackPathNode | null = null
  for (let i = 0; i < src.length; i++) {
    node = {
      ...src[i],
      location: src[i].location.clone(),
    }
    if (prevNode != null && areTooClose(prevNode, node)) {
      stoppedOnNode = stoppedOnNode ?? prevNode
    } else {
      path.push(stoppedOnNode ?? node)
      stoppedOnNode = null
    }
    if (
      stoppedOnNode != null &&
      stoppedOnNode.leaveTimestamp !== node.timestamp
    ) {
      stoppedOnNode.leaveTimestamp = node.timestamp
    }
    prevNode = node
  }

  return path
}

const Timestamp = (props: { legend: string; value: string | undefined }) => {
  if (!props.value) return null
  const date = new Date(props.value)
  const dateString = date.toLocaleString()
  return (
    <div>
      <b>{props.legend}: </b>
      {dateString}
    </div>
  )
}

const PATH_WEIGHT = 8
const MARKER_RADIUS = 7
const MARKER_BORDER_WEIGHT = 2.5
const MARKER_LARGER_MULT = 1.4

/**
 * @TODO
 * Paths were hard-coded for the moment,
 * We'll need to make custom components to be able to do things nicer.
 * eg: with current library, we're unable to scale CircleMarker on hover
 * as it is a <path> element with hardcoded position, and not transform
 * scaling would require the transformOrigin to be set to the center of
 * it, which would be really sub-optimal.
 *
 * @see https://gitlab.obvious.tech/ooda/product/admin/issues/190
 */

export default ({ record }: TProps) => {
  const classes = useStyles()

  if (record.locationHistory == null) return null

  const path = mergeTooClose(toLatLng(record.locationHistory))
  const pathLocations = path.map(node => node.location)
  const firstLocation = pathLocations[0]
  const lastIndex = Math.max(0, path.length - 1)
  const lastLocation = pathLocations[lastIndex]

  return (
    <Map
      className={classes.map}
      bounds={new L.LatLngBounds(firstLocation, lastLocation)}
    >
      <RL.Pane className={classes.pathPane}>
        <RL.Polyline
          className={classes.pathLine}
          positions={pathLocations}
          lineCap='round'
          lineJoin='round'
          opacity={1}
          color='#888'
          weight={PATH_WEIGHT + 6}
        />
        <RL.Polyline
          className={classes.pathLine}
          positions={pathLocations}
          lineCap='round'
          lineJoin='round'
          opacity={1}
          color='#fff'
          weight={PATH_WEIGHT + 4}
        />
        <RL.Polyline
          className={classes.pathLine}
          positions={pathLocations}
          lineCap='round'
          lineJoin='round'
          opacity={1}
          color='#3388ff'
          weight={PATH_WEIGHT}
        />
      </RL.Pane>
      <RL.Pane>
        {path.map(({ location, timestamp, leaveTimestamp }, index) => {
          const isEnd = index === lastIndex
          const isHalt = !!leaveTimestamp
          const markerMult = isEnd ? MARKER_LARGER_MULT : 1

          return (
            <RL.CircleMarker
              key={index}
              center={location}
              radius={MARKER_RADIUS * markerMult}
              color='#111'
              weight={MARKER_BORDER_WEIGHT * markerMult}
              fillColor={isHalt ? '#f66' : '#fff'}
              fillOpacity={1}
              fill
            >
              <RL.Tooltip direction='top'>
                {isEnd && <b>Last known location</b>}
                <Timestamp
                  legend={
                    isEnd ? (!leaveTimestamp ? 'Updated' : 'Arrival') : 'Time'
                  }
                  value={timestamp}
                />
                <Timestamp
                  legend={isEnd ? 'Updated' : 'Departure'}
                  value={leaveTimestamp}
                />
              </RL.Tooltip>
            </RL.CircleMarker>
          )
        })}
      </RL.Pane>
    </Map>
  )
}
