import { store } from '../../../ConnectedApp'
import {
  updateBlueprintInOpenedList,
  updateFetchedBlueprint,
} from '../../../redux/editor/editor.actions'
import { areBlueprintsEqual } from './openedListFunctions'

class CompareDiagramChangesHelper {
  _savedDiagram

  set currentDiagram(diagram) {
    this._savedDiagram = diagram
  }

  get currentDiagram() {
    return this._savedDiagram
  }

  async handleDispatch(updatedLocally) {
    if (this.savedDiagram.updatedLocally === updatedLocally) return

    await store.dispatch(
      updateBlueprintInOpenedList({
        id: this.savedDiagram.id,
        updatedLocally,
      }),
    )
    await store.dispatch(
      updateFetchedBlueprint({
        id: this.savedDiagram.id,
        updatedLocally,
      }),
    )
  }

  getDiagramFromState(diagramId) {
    const { editor } = store.getState()
    const { openedBlueprints } = editor

    return openedBlueprints.find(({ id }) => id === diagramId)
  }

  compare(editorDiagram) {
    if (!this.savedDiagram) return

    if (areBlueprintsEqual(this.savedDiagram.data, editorDiagram)) {
      this.handleDispatch(false)
      return
    }

    this.handleDispatch(true)
  }
}

export default new CompareDiagramChangesHelper()
