import React from 'react'
import { AlarmProvider } from 'src/parser/shared/PDF/Provider/index'
import AlarmPDFViewer from '../PDFViewer/AlarmPDFViewer'
import api from 'src/utils/api'
import { IAlarm } from '@obvious.tech/constellation'
import ExternalPDF from './ExternalPDF'
import { useParams } from 'react-router-dom'

const getAlarmByID = api.getResourceByID<IAlarm>('alarms/api/Alarm')

export const ExternalAlarmPDF = (): JSX.Element => {
  const { id } = useParams<Record<string, string>>()

  return (
    <ExternalPDF
      id={id}
      getData={getAlarmByID}
      Provider={AlarmProvider}
      ViewerComponent={AlarmPDFViewer}
      errorTitle='Failed to fetch alarm.'
      loadingPrimary='Fetching...'
    />
  )
}
