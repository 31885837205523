import React from 'react'
import { NumberField } from 'react-admin'

const CustomNumberField = ({ property, name, ...props }) => (
  <NumberField
    {...props}
    source={name}
    label={property.displayedName}
  />
)

export default CustomNumberField
