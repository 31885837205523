// @ts-nocheck
import React from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { AutocompleteArrayInputProps } from 'react-admin'

type TProps = AutocompleteArrayInputProps & {
  getOptionLabel?: (option: any) => string
  placeholder?: string
  disabled?: boolean
  property: {
    [key: string]: any
    readOnly: boolean
    displayedName?: string
  }
  options?: string[]
  value: string[]
  onChange: (value: string[]) => void
  defaultValue?: string[]
  className?: string
}

const SelectMultipleInput = ({
  className,
  property,
  name,
  onChange,
  value = [],
  defaultValue = [],
  options = [],
  children,
  getOptionLabel = v => v,
  loading = false,
  placeholder = name,
  disabled = false,
}: TProps): JSX.Element => {
  return (
    <Autocomplete
      className={className}
      disabled={
        window.isNullish(property.readOnly) ? disabled : property.readOnly
      }
      multiple
      loading={loading}
      options={options ?? defaultValue ?? []}
      onChange={(e, value) => {
        onChange(value ?? [])
      }}
      freeSolo
      openOnFocus
      includeInputInList
      ChipProps={
        property.readOnly
          ? {
              onDelete: undefined,
            }
          : undefined
      }
      getOptionLabel={getOptionLabel}
      value={value?.length > 0 ? value : []}
      renderInput={params => {
        return (
          children ?? (
            <TextField
              {...params}
              variant='outlined'
              label={property.displayedName ?? name}
              placeholder={placeholder}
            />
          )
        )
      }}
    />
  )
}

export default SelectMultipleInput
