import React from 'react'
import { usePDF } from '@react-pdf/renderer'
import { PDFViewer } from './PDFViewer'
import Preview from 'src/parser/entities/AgentActivityReport/ExternalPDF/Preview/Preview'

const ActivityReportPDFViewer = (props): JSX.Element | null => {
  const [pdf] = usePDF({
    document: (
      <Preview
        {...props}
        mode={'download'}
      />
    ),
  })

  const src = typeof pdf.url === 'string' ? `${pdf.url}#toolbar=1` : undefined

  if (pdf.loading || pdf.error !== null || pdf.blob === null) {
    return null
  }

  return (
    <PDFViewer
      src={src}
      pdf={pdf}
      title={props.title}
      showDownloadButton={false}
    />
  )
}

export default ActivityReportPDFViewer
