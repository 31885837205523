import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    '& .MuiInput-input': {
      textAlign: 'center',
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.contrastText,
    },
    '& label': {
      fontSize: theme.typography.fontSize,
    },
    '& label.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.primary,
    },
  },
}))
