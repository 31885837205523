import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import * as serviceWorker from './serviceWorker'
import registerGlobals from 'src/global'
import registerFonts from 'src/parser/entities/Alarm/Preview/registerPDFFonts'
import { Buffer } from 'buffer'

import 'src/i18n/config'

registerFonts()

window.Buffer = Buffer

// probably broken polyfill in react-pdf package
globalThis.process ??= {} as typeof globalThis.process
globalThis.process.nextTick ??= (fn, ...args) => {
  setTimeout(() => {
    fn(...args)
  }, 0)
}

registerGlobals()

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
