import React from 'react'
import { sanitizeListRestProps, useTranslate } from 'react-admin'

import CustomFields from '.'

export default ({ name, property, basePath, resource, ...rest }) => {
  const translate = useTranslate()
  const fieldProps = {
    ...sanitizeListRestProps(rest),
    name,
    basePath,
    property,
    resource,
    label: translate(name),
  }
  if (property.displayedComponent) {
    return React.createElement(property.displayedComponent, fieldProps)
  } else if (property.referenceTo) {
    if (property.type !== 'array') {
      return React.createElement(CustomFields.reference.default, fieldProps)
    }
    return React.createElement(CustomFields.reference.array, fieldProps)
  }
  return React.createElement(
    CustomFields[property.type] || CustomFields[undefined],
    fieldProps,
  )
}
