// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Divider,
  Typography,
  Theme,
  makeStyles,
  capitalize,
  TextField,
  InputAdornment,
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'

import { TRootState } from 'src/redux'
import { DataGridContainer, Table } from 'src/components/DataGrid'
import { Permissions } from 'src/components/Authorizations'
import {
  getMaxPermissionLevel,
  mapDefaultMicroservices,
} from 'src/utils/authorizations'
import { AUTHORIZATION_TYPES } from 'src/api'
import AuthorizationBox from 'src/components/Authorizations/AuthorizationBox'

import { TAuthorizationTypedData } from 'src/api/authorizationTypes'
import PtzPriorityAuthorization from 'src/components/Authorizations/PtzPriorityAuthorization'
import ScreenshotAuthorization from 'src/components/Authorizations/ScreenshotAuthorization'

const useStyles = makeStyles((theme: Theme) => ({
  searchInput: {
    width: '100%',
  },
  table: {
    '& tbody tr td': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

type TAuthorizationPermission = {
  microservice: string
  entity: string
  permissionLevel: number | null
  type?: string
}

type TAuthorizationsProps = {
  recordAuthorizations: TAuthorizationTypedData[]
  setRecordAuthorizations: React.Dispatch<
    React.SetStateAction<TAuthorizationTypedData[]>
  >
}

const Authorizations: React.FunctionComponent<TAuthorizationsProps> = ({
  recordAuthorizations,
  setRecordAuthorizations,
}) => {
  const classes = useStyles()

  const microservices = useSelector((state: TRootState) => state.microservices)

  const [authorizations, setAuthorizations] = useState<
    TAuthorizationPermission[]
  >([])
  const [search, setSearch] = useState<string>('')

  const [screenshotAuthorization, setScreenshotAuthorization] = useState<
    boolean | null | 'loading'
  >('loading')
  const [ptzPriorityAuthorization, setPtzPriorityAuthorization] = useState<
    number | null | 'loading'
  >('loading')

  const columns = [
    {
      key: 'microservice',
      label: 'Service',
      render: ({ microservice }) => capitalize(microservice),
    },
    {
      key: 'entity',
      label: 'Entity',
    },
    {
      key: 'permissionLevel',
      label: 'Permissions',
      render: ({ permissionLevel, entity }) => (
        <Permissions
          entity={entity}
          permissionLevel={permissionLevel}
          onClick={handleAuthorization}
        />
      ),
    },
  ]

  useEffect(() => {
    const parseAuthorizations = () => {
      if (!microservices) return

      const defaultMicroservices: TAuthorizationPermission[] =
        mapDefaultMicroservices(microservices, {
          permissionLevel: null,
        })

      if (!recordAuthorizations?.length) {
        setAuthorizations(defaultMicroservices)
      }

      const groupAuthorization = filterGroupAuthorizations(recordAuthorizations)

      const parsed = defaultMicroservices.map(authorization => {
        const cAuthorization = {
          ...authorization,
        }
        const permissions = filterPermissionsForEntity(
          groupAuthorization,
          authorization.entity,
        )

        cAuthorization.permissionLevel =
          permissions.length > 0 ? getMaxPermissionLevel(permissions) : null

        return cAuthorization
      })

      setAuthorizations(parsed)
    }

    parseAuthorizations()
  }, [recordAuthorizations, microservices])

  useEffect(() => {
    const netPtzPriority =
      recordAuthorizations.find(
        auth => auth.type === 'PtzPriorityAuthorization',
      )?.data?.weight ?? null
    const netScreenshot =
      recordAuthorizations.find(auth => auth.type === 'ScreenshotAuthorization')
        ?.data?.enabled ?? null

    setPtzPriorityAuthorization(netPtzPriority)
    setScreenshotAuthorization(netScreenshot)
  }, [recordAuthorizations])

  const filteredEntities = () => {
    const query = search.trim().toLocaleLowerCase()
    return query
      ? authorizations.filter(({ entity }) =>
          entity.trim().toLowerCase().includes(query),
        )
      : authorizations
  }

  const filterPermissionsForEntity = (
    arr: TAuthorizationTypedData[],
    entity: string,
  ) =>
    arr.filter(
      auth =>
        auth.type === 'EntityGroupAuthorization' &&
        // @ts-expect-error
        (auth.data.entity === entity || auth.data.entity === '*'),
    )

  const filterGroupAuthorizations = (
    authorizations: TAuthorizationTypedData[],
  ) => {
    return authorizations.filter(
      authorization => authorization.type === AUTHORIZATION_TYPES.GROUP,
    )
  }

  const handleAuthorization = (level: null | number, entity: string) => {
    const currentEntityAuthorization = recordAuthorizations.find(
      ({ type, data }) =>
        type === 'EntityGroupAuthorization' &&
        // @ts-expect-error
        data.entity === entity,
    )

    if (level === null) {
      const updatedGroupAuthorizations = recordAuthorizations.filter(
        authorization =>
          authorization.type === 'EntityGroupAuthorization' &&
          // @ts-expect-error
          authorization.data.entity !== entity,
      )

      setRecordAuthorizations(updatedGroupAuthorizations)
      return
    }

    const newEntity = {
      type: AUTHORIZATION_TYPES.GROUP,
      data: {
        level,
        entity,
      },
    } as const

    const updatedGroupAuthorizations: TAuthorizationTypedData[] =
      currentEntityAuthorization !== undefined
        ? recordAuthorizations.map(authorization =>
            authorization.type === 'EntityGroupAuthorization' &&
            // @ts-expect-error
            authorization.data.entity === entity
              ? newEntity
              : authorization,
          )
        : [...recordAuthorizations, newEntity]

    setRecordAuthorizations(updatedGroupAuthorizations)
  }

  const genericChangeAuthorization = (
    argAuth: TAuthorizationTypedData<
      'PtzPriorityAuthorization' | 'ScreenshotAuthorization'
    >,
  ) => {
    const existentAuthorization = recordAuthorizations.find(
      auth => auth.type === argAuth.type,
    )

    if (existentAuthorization == null) {
      setRecordAuthorizations(prev => [...prev, argAuth])
      return
    }

    setRecordAuthorizations(prev =>
      prev.map(auth => (auth.type === argAuth.type ? argAuth : auth)),
    )
  }

  const genericDeleteAuthorization = (arg: {
    type: 'PtzPriorityAuthorization' | 'ScreenshotAuthorization'
  }) => {
    const updatedGroupAuthorizations = recordAuthorizations.filter(
      auth => auth.type !== arg.type,
    )

    setRecordAuthorizations(updatedGroupAuthorizations)
  }

  const changeScreenshotAuthorization = (enabled: true | null) => {
    if (screenshotAuthorization === 'loading') return

    setScreenshotAuthorization('loading')

    if (enabled === null) {
      genericDeleteAuthorization({
        type: 'ScreenshotAuthorization',
      })
      return
    }

    genericChangeAuthorization({
      type: 'ScreenshotAuthorization',
      data: {
        enabled,
      },
    })
  }

  const changePtzPriorityAuthorization = (weight: number | null) => {
    if (ptzPriorityAuthorization === 'loading') return

    setPtzPriorityAuthorization('loading')

    if (weight === null) {
      genericDeleteAuthorization({
        type: 'PtzPriorityAuthorization',
      })
      return
    }
    genericChangeAuthorization({
      type: 'PtzPriorityAuthorization',
      data: {
        weight,
      },
    })
  }

  useEffect(() => {
    const netPtzPriority =
      recordAuthorizations.find(
        auth => auth.type === 'PtzPriorityAuthorization',
      )?.data?.weight ?? null
    const netScreenshot =
      recordAuthorizations.find(auth => auth.type === 'ScreenshotAuthorization')
        ?.data?.enabled ?? null

    setPtzPriorityAuthorization(netPtzPriority)
    setScreenshotAuthorization(netScreenshot)
  }, [recordAuthorizations])

  return (
    <Box>
      <Box p={2}>
        <Typography variant='h5'>Authorizations</Typography>
      </Box>
      <Divider />
      <AuthorizationBox>
        <Box p={2}>
          <TextField
            variant='outlined'
            label='Search'
            type='search'
            className={classes.searchInput}
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <DataGridContainer fixedFirstCol={false}>
          <Table
            columns={columns}
            rowData={filteredEntities()}
            className={classes.table}
          />
        </DataGridContainer>
      </AuthorizationBox>

      <AuthorizationBox>
        <Box p={2}>
          <Typography variant='h5'>Other Authorizations</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <ScreenshotAuthorization
            value={screenshotAuthorization}
            onSubmit={changeScreenshotAuthorization}
          />
          <br />
          <PtzPriorityAuthorization
            value={ptzPriorityAuthorization}
            onSubmit={changePtzPriorityAuthorization}
          />
        </Box>
      </AuthorizationBox>
    </Box>
  )
}

export default Authorizations
