// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useEditContext, Record, useDataProvider, useQuery } from 'react-admin'
import {
  Box,
  Divider,
  Typography,
  Theme,
  makeStyles,
  capitalize,
  TextField,
  InputAdornment,
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'

import { TRootState } from 'src/redux'
import { DataGridContainer, Table } from 'src/components/DataGrid'
import { Permissions } from 'src/components/Authorizations'
import {
  getMaxPermissionLevel,
  mapDefaultMicroservices,
  filterPermissionsForEntity,
} from 'src/utils/authorizations'
import useErrorFetch from 'src/hooks/useErrorFetch'
import Spin from 'src/components/Spin'
import { API_ENDPOINTS } from 'src/api'
import {
  TAuthorizationData,
  TAuthorizationType,
} from 'src/api/authorizationTypes'
import PtzPriorityAuthorization from 'src/components/Authorizations/PtzPriorityAuthorization'
import ScreenshotAuthorization from 'src/components/Authorizations/ScreenshotAuthorization'
import GroupsAutocomplete from 'src/components/Authorizations/GroupsAutocomplete'
import AuthorizationBox from 'src/components/Authorizations/AuthorizationBox'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& tbody tr td': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

type TAuthorizationPermission = {
  microservice: string
  entity: string
  permissionLevel: number | null
  type?: string
}

type TAuthorizationsProps = {
  id: string
}

const Authorizations = ({ id }: TAuthorizationsProps) => {
  const classes = useStyles()
  const fetchError = useErrorFetch()
  const dataProvider = useDataProvider()
  const editContext = useEditContext()

  const fetchRolePermissions = useQuery({
    type: 'getList',
    resource: `${editContext.resource}/${id}/Authorization`,
    payload: {},
  })

  const microservices = useSelector((state: TRootState) => state.microservices)

  const [authorizations, setAuthorizations] = useState<
    TAuthorizationPermission[]
  >([])
  const [search, setSearch] = useState<string>('')
  const [rolePermissions, setRolePermissions] = useState<Record[] | null>(null)
  const [currentGroup, setCurrentGroup] = useState<Record | null>(null)

  const [screenshotAuthorization, setScreenshotAuthorization] = useState<
    boolean | null | 'loading'
  >('loading')
  const [ptzPriorityAuthorization, setPtzPriorityAuthorization] = useState<
    number | null | 'loading'
  >('loading')

  const columns = [
    {
      key: 'microservice',
      label: 'Service',
      render: ({ microservice }) => capitalize(microservice),
    },
    {
      key: 'entity',
      label: 'Entity',
    },
    {
      key: 'permissionLevel',
      label: 'Permissions',
      render: ({ permissionLevel, entity }) => (
        <Permissions
          entity={entity}
          permissionLevel={permissionLevel}
          onClick={handleAuthorization}
        />
      ),
    },
  ]

  useEffect(() => {
    if (!fetchRolePermissions.loaded || fetchRolePermissions.error) return

    setRolePermissions(fetchRolePermissions.data)
  }, [
    fetchRolePermissions.loaded,
    fetchRolePermissions.data,
    fetchRolePermissions.error,
  ])

  useEffect(() => {
    if (!microservices) return

    const defaultMicroservices: TAuthorizationPermission[] =
      mapDefaultMicroservices(microservices, {
        permissionLevel: null,
      })

    if (rolePermissions == null || !currentGroup) {
      setAuthorizations(defaultMicroservices)
      return
    }

    const roleGroupAuthorizations = rolePermissions.filter(
      ({ group }) => group === currentGroup.name,
    )

    if (roleGroupAuthorizations.length === 0) {
      setAuthorizations(defaultMicroservices)
      return
    }

    const mappedGroupRoleAuthorizations = defaultMicroservices.map(
      authorization => ({
        ...authorization,
        permissionLevel: getMaxPermissionLevel(
          filterPermissionsForEntity(
            roleGroupAuthorizations,
            authorization.entity,
          ),
        ),
      }),
    )

    setAuthorizations(mappedGroupRoleAuthorizations)
  }, [currentGroup, rolePermissions, microservices])

  const filteredEntities = () => {
    const query = search.trim().toLocaleLowerCase()
    return query
      ? authorizations.filter(({ entity }) =>
          entity.trim().toLowerCase().includes(query),
        )
      : authorizations
  }

  const createAuthorization = async <
    T extends TAuthorizationType = TAuthorizationType,
  >(
    type: T,
    data: TAuthorizationData<T>,
  ) => {
    if (rolePermissions === null || currentGroup === null) return
    try {
      const res = await dataProvider.create(
        `${editContext.resource}/${editContext.record?.id}/Authorization`,
        {
          data: {
            type,
            group: currentGroup?.name,
            data,
          },
        },
      )

      setRolePermissions([
        ...rolePermissions,
        {
          ...res.data,
          source: 'User',
        },
      ])
    } catch (e) {
      fetchError(e)
    }
  }

  const editAuthorization = async <
    T extends TAuthorizationType = TAuthorizationType,
  >(
    permission: { id: string; type: T },
    data: TAuthorizationData<T>,
  ) => {
    if (rolePermissions === null) return

    try {
      const res = await dataProvider.update(API_ENDPOINTS.AUTHORIZATION, {
        id: permission.id,
        data: {
          data,
        },
        previousData: permission,
      })

      const updatedRolesPermissions = rolePermissions.map(permission =>
        permission.id === res.data.id
          ? {
              ...permission,
              ...res.data,
            }
          : permission,
      )
      setRolePermissions(updatedRolesPermissions)
    } catch (e) {
      fetchError(e)
    }
  }

  const deleteAuthorization = async (id: string | number) => {
    if (rolePermissions === null) return

    try {
      await dataProvider.delete(API_ENDPOINTS.AUTHORIZATION, {
        id,
        previousData: {
          id,
        },
      })

      const updatedRolesPermissions = rolePermissions.filter(
        permission => permission.id !== id,
      )
      setRolePermissions(updatedRolesPermissions)
    } catch (e) {
      fetchError(e)
    }
  }

  const handleAuthorization = (level: null | number, entity: string) => {
    if (!currentGroup || rolePermissions == null) return

    const existentRoleAuthorization = rolePermissions.find(
      ({ group, data }) =>
        group === currentGroup?.name && data.entity === entity,
    )

    if (existentRoleAuthorization && level === null) {
      return deleteAuthorization(existentRoleAuthorization.id)
    }

    if (level === null) return

    if (
      existentRoleAuthorization &&
      level !== existentRoleAuthorization.level
    ) {
      return editAuthorization(existentRoleAuthorization, {
        entity,
        level,
      })
    }

    if (!existentRoleAuthorization) {
      return createAuthorization('EntityGroupAuthorization', {
        entity,
        level,
      })
    }
  }

  const genericChangeAuthorization = <
    T extends Exclude<TAuthorizationType, 'EntityGroupAuthorization'> = Exclude<
      TAuthorizationType,
      'EntityGroupAuthorization'
    >,
  >(options: {
    type: T
    data: TAuthorizationData<T>
  }) => {
    if (!currentGroup || rolePermissions == null) return

    const existentAuthorization = rolePermissions.find(
      ({ group, type }) =>
        group === currentGroup?.name && type === options.type,
    )

    if (!existentAuthorization) {
      return createAuthorization(options.type, options.data)
    }

    return editAuthorization(existentAuthorization, options.data)
  }

  const genericDeleteAuthorization = <
    T extends Exclude<TAuthorizationType, 'EntityGroupAuthorization'> = Exclude<
      TAuthorizationType,
      'EntityGroupAuthorization'
    >,
  >(options: {
    type: T
  }) => {
    if (!currentGroup || rolePermissions == null) return

    const existentAuthorization = rolePermissions.find(
      ({ group, type }) =>
        group === currentGroup?.name && type === options.type,
    )

    if (!existentAuthorization) return

    deleteAuthorization(existentAuthorization.id)
  }

  useEffect(() => {
    if (rolePermissions === null) return

    const netPtzPriority =
      rolePermissions.find(auth => auth.type === 'PtzPriorityAuthorization')
        ?.data?.weight ?? null
    const netScreenshot =
      rolePermissions.find(auth => auth.type === 'ScreenshotAuthorization')
        ?.data?.enabled ?? null

    setPtzPriorityAuthorization(netPtzPriority)
    setScreenshotAuthorization(netScreenshot)
  }, [rolePermissions])

  const changeScreenshotAuthorization = (enabled: true | null) => {
    if (screenshotAuthorization === 'loading') return

    setScreenshotAuthorization('loading')

    if (enabled === null) {
      genericDeleteAuthorization({
        type: 'ScreenshotAuthorization',
      })
      return
    }

    genericChangeAuthorization({
      type: 'ScreenshotAuthorization',
      data: {
        enabled,
      },
    })
  }

  const changePtzPriorityAuthorization = (weight: number | null) => {
    if (ptzPriorityAuthorization === 'loading') return

    setPtzPriorityAuthorization('loading')

    if (weight === null) {
      genericDeleteAuthorization({
        type: 'PtzPriorityAuthorization',
      })
      return
    }
    genericChangeAuthorization({
      type: 'PtzPriorityAuthorization',
      data: {
        weight,
      },
    })
  }

  return (
    <Box>
      <Box p={2}>
        <Typography variant='h5'>Authorizations</Typography>
      </Box>
      <Divider />
      <AuthorizationBox>
        <Box
          p={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <GroupsAutocomplete
            currentGroup={currentGroup}
            setCurrentGroup={setCurrentGroup}
            rolePermissions={rolePermissions}
          />
          <TextField
            variant='outlined'
            label='Search'
            type='search'
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <Spin spinning={fetchRolePermissions.loading}>
          <DataGridContainer fixedFirstCol={false}>
            <Table
              columns={columns}
              rowData={filteredEntities()}
              className={classes.table}
            />
          </DataGridContainer>
        </Spin>
      </AuthorizationBox>

      <AuthorizationBox>
        <Box p={2}>
          <Typography variant='h5'>Other Authorizations</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <ScreenshotAuthorization
            disabled={currentGroup === null || rolePermissions === null}
            value={screenshotAuthorization}
            onSubmit={changeScreenshotAuthorization}
          />
          <br />
          <PtzPriorityAuthorization
            disabled={currentGroup === null || rolePermissions === null}
            value={ptzPriorityAuthorization}
            onSubmit={changePtzPriorityAuthorization}
          />
        </Box>
      </AuthorizationBox>
    </Box>
  )
}

export default Authorizations
