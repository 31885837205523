import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { useTranslate } from 'react-admin'

import { IInputProps } from 'src/types'

const CustomNumberInput = ({
  classes,
  property,
  name,
  onChange,
  error,
  value,
}: IInputProps<number | null>): JSX.Element => {
  const translate = useTranslate()

  const [input, setInput] = useState(value?.toString() ?? '')
  const required: boolean = property?.required ?? false

  const handleHelperText = (): string => {
    if (window.isNullish(error)) return ''

    return translate(error?.message ?? error)
  }

  const handleValidateInput = (value: string): void => {
    const FLOAT_REGEX = /^\s*[-]?(\d+|\.\d+|\d+\.\d+|\d+\.)?\s*$/
    const INTEGER_REGEX = /^\s*[-]?(\d+)?\s*$/

    const regex: typeof FLOAT_REGEX | typeof INTEGER_REGEX =
      property?.type === 'integer' ? INTEGER_REGEX : FLOAT_REGEX

    if (!regex.test(value)) return

    const rmLeadingZerosRegex = /^(-)?0+(?=\d)/
    const formattedInput = value.replace(rmLeadingZerosRegex, '$1')

    setInput(formattedInput)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value: inputValue } = e.target

    const inputNoMinus = inputValue.replace(/-/g, '')
    const numMinus = inputValue.length - inputNoMinus.length
    const maybeMinus = numMinus % 2 === 0 ? '' : '-'

    const value = `${maybeMinus}${inputNoMinus.trim()}`

    switch (value) {
      case '-':
        if (input === '') {
          setInput(value)
          return
        }

        if (required) {
          setInput(input === '-0' ? '0' : '-0')
          return
        }

        setInput('')
        return

      case '':
        setInput(required ? '0' : '')
        return

      default:
        handleValidateInput(value)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target

    // replacing ending dot and single '-' value
    const formattedString = value
      .replace(/\.\s*$/, '')
      .replace(/^-$/, required ? '0' : '')
    setInput(formattedString)
    onChange(formattedString === '' ? null : Number(formattedString))
  }

  return (
    <TextField
      fullWidth
      className={classes}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={property.displayedName ?? name}
      label={property.displayedName ?? name}
      value={input}
      helperText={handleHelperText()}
      disabled={property?.readOnly}
    />
  )
}

export default React.memo(CustomNumberInput)
