import Rete from '@obvious.tech/rete'
import { isValid, format, parse } from 'date-fns'

import DateTimeInput from '../../../../../components/flow/DT/DateTimeInput'
import { withThemeProvider } from 'src/utils/hocs'
import { validateTouched } from 'src/adminPanel/Flow/helpers/validationHelper'
import CompareDiagramChangesHelper from 'src/adminPanel/Flow/helpers/CompareDiagramChangesHelper'

export default class DateControl extends Rete.Control {
  component = withThemeProvider(DateTimeInput)

  constructor(emitter, node, key, label, onChange) {
    super(key)
    this.emitter = emitter
    this.node = node
    this.key = key
    this.formats = {
      date: 'MM/dd/yyyy',
      time: 'hh:mm a',
    }

    const initial = this.formatDateForInput(node.data[key], key)

    this.props = {
      label,
      type: key,
      format: this.formats[key],
      value: initial,
      onChange: dateFormatValue => {
        const formattedDate = this.formatDateForNode(dateFormatValue, key)
        onChange && onChange(this.key, formattedDate)
        this.setValue(dateFormatValue, node)
      },
      onBlur: () => {
        CompareDiagramChangesHelper.compare(this.emitter.toJSON())
        if (!node.validation) return

        node.validation = {
          ...validateTouched(node),
        }
        node.update()
      },
    }
  }

  formatDateForNode(value, type) {
    const formatTypes = {
      date: 'yyyy-MM-dd',
      time: 'HH:mm',
    }

    // returning '' because server code has strict comparison for '' when date is not passed or invalid
    if (!value) return ''
    if (!formatTypes[type]) return value
    if (!isValid(value)) return ''

    return format(value, formatTypes[type])
  }

  formatDateForInput(value, type) {
    const typesToFormat = {
      time: 'HH:mm',
    }

    if (!value) return null
    if (!typesToFormat[type]) return value

    return parse(value, typesToFormat[type], new Date())
  }

  setValue(val, node) {
    this.props.value = val
    node.update()
  }
}
