import React from 'react'
import { AgentActivityProvider } from 'src/parser/shared/PDF/Provider/index'
import AgentActivityPDFViewer from '../PDFViewer/AgentActivityPDFViewer'
import ExternalPDF from './ExternalPDF'
import api from 'src/utils/api'
import { IAgentActivityReport } from '@obvious.tech/constellation'
import { useParams } from 'react-router-dom'

const getAgentActivityReportByID = api.getResourceByID<IAgentActivityReport>(
  'units/api/AgentActivityReport',
)

export const ExternalAgentActivityReportPDF = (): JSX.Element => {
  const { id } = useParams<Record<string, string>>()

  return (
    <ExternalPDF
      id={id}
      getData={getAgentActivityReportByID}
      Provider={AgentActivityProvider}
      ViewerComponent={AgentActivityPDFViewer}
      errorTitle='Failed to fetch agentActivityReport.'
      loadingPrimary='Fetching...'
    />
  )
}
