import Rete from '@obvious.tech/rete'

import { compareNodeOptions } from 'src/utils/flow'

import { sockets } from '../..'
import HttpRequestsControl from './HttpRequestsController'
import { getStringFromStatusCode } from '../../../../helpers/HTTPRequestHelper'
import SelectSocketForOutputControl from '../../Controls/Select/SelectSocketForOutputControl'

export default class HttpRequestsComponent extends Rete.Component {
  constructor() {
    super('Http request')
    this.task = {
      outputs: {
        200: 'option', // OK
        400: 'option', // bad request
        401: 'option', // unauthorized
        403: 'option', // forbidden
        404: 'option', // not found
        500: 'option', // Internal server error
        502: 'option', // Bad gateway
        503: 'option', // service unvailable
        504: 'option', // gateway timeout
        response: 'output',
      },
    }
  }

  builder(node) {
    const inpAct = new Rete.Input('act', 'Execute', sockets.actionSocket)
    const inpheaders = new Rete.Input(
      'headers',
      'Headers',
      sockets.objectSocket,
      true,
    )
    const inpMethod = new Rete.Input('method', 'Method', sockets.stringSocket)
    const inpEndpoint = new Rete.Input(
      'endpoint',
      'Endpoint',
      sockets.stringSocket,
    )
    const out200 = new Rete.Output(
      '200',
      getStringFromStatusCode(200),
      sockets.actionSocket,
    )
    node.data.checkboxes &&
      Object.keys(node.data.checkboxes).forEach(statusCode => {
        if (node.data.checkboxes[statusCode].checked) {
          node.addOutput(
            new Rete.Output(
              statusCode,
              getStringFromStatusCode(parseInt(statusCode)),
              sockets.actionSocket,
            ),
          )
        }
      })
    const response = new Rete.Output(
      'response',
      'response',
      sockets.anyTypeSocket,
    )
    const controller = new HttpRequestsControl(
      this.editor,
      node,
      'Request',
      'Request',
      'request',
    )

    node
      .addInput(inpAct)
      .addInput(inpheaders)
      .addInput(inpMethod)
      .addInput(inpEndpoint)
      .addOutput(response)
      .addOutput(out200)
      .addControl(controller)

    const options = Object.keys(sockets)
      .map(s => ({
        value: s,
        label: s.split('Socket')[0],
      }))
      .sort(compareNodeOptions)

    const handleOutput = name => {
      node.outputs.get('response').connections.forEach(async connection => {
        await this.editor.removeConnection(connection)
      })
      node.removeOutput(node.outputs.get('response'))

      node.update()
      this.editor.view.updateConnections({
        node,
      })

      if (name) {
        node.addOutput(
          new Rete.Output('response', name.split('Socket')[0], sockets[name]),
        )
      }
    }

    node.addControl(
      new SelectSocketForOutputControl({
        emitter: this.editor,
        node,
        key: 'Type of response',
        label: 'Type of response',
        onChange: handleOutput,
        options,
        defaultValue: 'objectSocket',
        output: 'response',
      }),
    )

    return node
  }
}
