import React from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  SvgIcon,
  Tooltip,
} from '@material-ui/core'

export const PDFViewer = (props): JSX.Element | null => {
  const { src, pdf } = props

  if (pdf.loading || pdf.error !== null || pdf.blob === null) {
    return null
  }

  return (
    <Box
      display='flex'
      flex={1}
      position='relative'
    >
      {props.showDownloadButton && (
        <Box
          position='absolute'
          bottom='20px'
          right='40px'
        >
          <Tooltip title='Download ZIP'>
            {props.loading ? (
              <CircularProgress
                style={{
                  color: 'white',
                }}
              />
            ) : (
              <IconButton
                classes={props.buttonClasses}
                size='medium'
                onClick={props.onDownload}
                aria-label='upload picture'
                component='span'
              >
                <SvgIcon htmlColor='white'>
                  <path d='M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-2 6h-2v2h2v2h-2v2h-2v-2h2v-2h-2v-2h2v-2h-2V8h2v2h2v2z'></path>
                </SvgIcon>
              </IconButton>
            )}
          </Tooltip>
        </Box>
      )}
      <iframe
        src={src}
        title={props.title}
        style={{
          flex: 1,
          height: '100vh',
        }}
      />
    </Box>
  )
}

export default PDFViewer
