import React from 'react'
import { Image } from '@react-pdf/renderer'

import * as theme from 'src/parser/shared/PDF/theme'
import NoContent from 'src/parser/shared/PDF/NoContent'
import { TSectionContent } from 'src/parser/shared/PDF/Sections'

const Location: TSectionContent = ({ entity, map }) => {
  const location = entity.checkIn?.location

  if (!location?.latitude || !location?.longitude) {
    return <NoContent />
  }

  return (
    <div>
      {map && (
        <Image
          source={map}
          style={{
            width: '100%',
            alignSelf: 'center',
            marginTop: theme.padding(1),
          }}
        />
      )}
    </div>
  )
}

export default Location
