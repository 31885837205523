import React, { useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { useDataProvider, useEditContext } from 'react-admin'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { capitalize, Box } from '@material-ui/core'

import {
  removeOpenedFromList,
  areBlueprintsEqual,
} from './helpers/openedListFunctions'

import * as editorActions from '../../redux/editor/editor.actions'
import * as editorSelectors from '../../redux/editor/editor.selectors'
import flowStyles from './theme/styles'

import { editor } from './components/Flow'
import BlueprintTabs from './BlueprintTabs'
import CreateNewDiagramDialog from './CreateNewDiagramDialog'
import ConfirmationDialog from './ConfirmationDialog'
import RouteLeavingGuard from './routerGuard'
import EditorContainer from './EditorContainer'
import useErrorFetch from 'src/hooks/useErrorFetch'
import SimulationDialog from 'src/components/flow/Simulation'

const useComponentStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(),
  },
  flowHomeIcon: {
    color: flowStyles.palette.primary.main,
    borderRadius: '4px 0',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    alignSelf: 'center',
    color: 'white',
  },
  icon: {
    color: 'white',
  },
  appBar: {
    height: '64px',
    backgroundColor: flowStyles.palette.primary.main,
  },
  footerContainer: {
    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.35)',
    backgroundColor: flowStyles.palette.primary.main,
  },
  blueprintTitle: {
    fontWeight: 700,
    fontSize: '16pt',
  },
})

const useStyles = makeStyles({
  root: {
    '& .node *': {
      fontFamily: flowStyles.fontFamily,
    },
  },
})

const eventHandlers = {
  list: [],
  remove() {
    this.list.forEach(({ target, event, cb }) => {
      target.removeEventListener(event, cb)
    })
    this.list = []
    return this
  },
  add({ target = document, event, cb }) {
    target.addEventListener(event, cb, false)
    this.list.push({
      target,
      event,
      cb,
    })
    return this
  },
}

function preventCTRLS(event) {
  const charCode = String.fromCharCode(event.which).toLowerCase()
  if (event.ctrlKey && charCode === 's') {
    event.preventDefault()
  }
}

const EditorComponent = ({
  updateBlueprintInOpenedList,
  replaceOpenedWith,
  setConfDialogInEditor,
  cleanOpened,
  setToCloseDiagramId,
}) => {
  const location = useLocation()
  const history = useHistory()
  const myClasses = useStyles()
  const fetchError = useErrorFetch()
  const dataProvider = useDataProvider()
  const editContext = useEditContext()

  const openedBlueprints = useSelector(editorSelectors.openedBlueprints)
  const currentBlueprint = useSelector(editorSelectors.currentBlueprint)
  const toCloseDiagramId = useSelector(editorSelectors.toCloseDiagramId)
  const confDialogVisible = useSelector(editorSelectors.confDialogVisible)

  const [, , , urlType, diagramId] = location.pathname.split('/')
  const type = urlType.toLowerCase()
  const blueprintsRef = useRef(openedBlueprints)

  const editorRoutes = {
    diagram: '/flow/api/Diagram/:id',
    module: '/flow/api/Module/:id',
    createRoutes: ['/flow/api/Diagram/create', '/flow/api/Module/create'],
  }

  const matchedDiagramRoute = useRouteMatch({
    path: editorRoutes.diagram,
    exact: true,
  })
  const matchedModuleRoute = useRouteMatch({
    path: editorRoutes.module,
    exact: true,
  })

  useEffect(() => {
    eventHandlers
      .add({
        target: document,
        event: 'keydown',
        cb: preventCTRLS,
      })
      .add({
        target: window,
        event: 'beforeunload',
        cb: handleLeavePage,
      })

    return () => {
      eventHandlers.remove()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    blueprintsRef.current.blueprints = openedBlueprints
  }, [openedBlueprints])
  useEffect(() => {
    blueprintsRef.current.currentBlueprintId = currentBlueprint
  }, [currentBlueprint])

  const getCurrentDiagram = () => {
    if (!currentBlueprint) return
    return openedBlueprints.find(bp => bp.id === diagramId)
  }

  const getDiagramToClose = () => {
    if (!toCloseDiagramId) return null
    return openedBlueprints.find(bp => bp.id === toCloseDiagramId)
  }

  const prepareBlueprint = diagram => {
    const result = {}

    let blueprint
    if (diagram && diagram.nodes) {
      blueprint = {
        ...diagram,
      }
    } else if (diagram) {
      blueprint = openedBlueprints.find(
        blueprint => blueprint.id === diagram,
      ).data
    } else {
      blueprint = editor.toJSON()
    }

    const events = Object.keys(blueprint.nodes || {})
      .filter(
        node =>
          blueprint.nodes[node].name.indexOf('- On ') !== -1 ||
          blueprint.nodes[node].name === 'Schedule with interval',
      )
      .map(node => blueprint.nodes[node].name)
    result.data = blueprint
    result.events = events

    return result
  }

  const onPatchOne = async (id, patch) => {
    try {
      const blueprintInStorage = openedBlueprints.find(
        blueprint => blueprint.id === id,
      )

      const params = {
        id,
        data: {
          ...blueprintInStorage,
          ...patch,
        },
      }

      const { data } = await dataProvider.update(editContext.resource, params)

      return data
    } catch (e) {
      fetchError(e)
    }
  }

  const removeTabAndCloseModalWindow = toCloseDiagramId => {
    replaceOpenedWith(removeOpenedFromList(openedBlueprints, toCloseDiagramId))
    if (toCloseDiagramId) {
      setConfDialogInEditor({
        diagramId: null,
        visible: false,
      })
    }
  }

  const closeWithoutSaving = () => {
    removeTabAndCloseModalWindow(toCloseDiagramId)
    handleRedirectAfterClose(toCloseDiagramId)
  }

  const closeWithSaving = async () => {
    const updatedBlueprint = await onPatchOne(
      toCloseDiagramId,
      prepareBlueprint(toCloseDiagramId),
    )

    if (!updatedBlueprint) return
    removeTabAndCloseModalWindow(toCloseDiagramId)
    handleRedirectAfterClose(toCloseDiagramId)
  }

  const hideConfDialog = () => {
    if (toCloseDiagramId) {
      setConfDialogInEditor({
        diagramId: null,
        visible: false,
      })
    }
  }

  const handleLeavePage = e => {
    if (isThereUpdatedBlueprint()) {
      e.preventDefault()
      e.returnValue = ''
    } else {
      delete e.returnValue
    }
  }

  const handleRedirectAfterClose = toCloseId => {
    const blueprintsAfterClosing = openedBlueprints.filter(
      ({ id }) => id !== toCloseId,
    )
    const closingCurrentBlueprint = openedBlueprints.find(
      ({ id }) => id === toCloseId,
    )

    if (!blueprintsAfterClosing.length) {
      history.push('/flow/api/Diagram')
      return
    }

    if (closingCurrentBlueprint) {
      history.push(`/flow/api/Diagram/${blueprintsAfterClosing[0].id}`)
    }
  }

  const closeBlueprint = ({ id }) => {
    const blueprintToClose = openedBlueprints.find(pb => pb.id === id)
    let isSaved = true
    if (blueprintToClose.updatedLocally) {
      isSaved = false
    }

    if (diagramId === id) {
      if (!areBlueprintsEqual(blueprintToClose.data, editor.toJSON())) {
        updateBlueprintInOpenedList({
          ...blueprintToClose,
          ...prepareBlueprint(),
          updatedLocally: true,
        })
        isSaved = false
      }
    }

    if (!isSaved) {
      setConfDialogInEditor({
        diagramId: id,
        visible: true,
      })
    } else {
      replaceOpenedWith(removeOpenedFromList(openedBlueprints, id))
      handleRedirectAfterClose(id)
    }
  }

  const addTab = (type, id) => {
    history.push(`/flow/api/${capitalize(type)}/${id}`)
  }

  const addBlueprint = blueprint => {
    addTab(blueprint.type, blueprint.id)
  }

  const isThereUpdatedBlueprint = () => {
    const { blueprints, currentBlueprintId } = blueprintsRef.current

    if (!blueprints.length) return false
    const updatedBlueprint = blueprints.find(bp => bp.updatedLocally)
    if (!updatedBlueprint) {
      const currentBlueprint = blueprints.find(
        bp => bp.id === currentBlueprintId,
      )

      if (!currentBlueprint) {
        return false
      }

      if (areBlueprintsEqual(currentBlueprint.data, editor.toJSON())) {
        return false
      }

      return diagramId
    }

    return updatedBlueprint.id
  }

  const diagram = getCurrentDiagram()

  return (
    <Box
      width='100%'
      flex={1}
      position='relative'
      className={myClasses.root}
    >
      <RouteLeavingGuard
        key='RouteLeavingGuard'
        when
        navigate={path => {
          history.push(path)
        }}
        getCurrentName={() => {
          const blueprint = openedBlueprints.find(
            bp => bp.id === toCloseDiagramId,
          )
          return blueprint && blueprint.name
        }}
        closeWithoutSaving={() => {
          closeWithoutSaving()
          cleanOpened()
        }}
        closeWithSaving={async () => {
          const updatedBlueprint = await onPatchOne(
            toCloseDiagramId,
            prepareBlueprint(
              toCloseDiagramId === diagram.id ? null : toCloseDiagramId,
            ),
          )
          if (!updatedBlueprint) return

          removeTabAndCloseModalWindow(toCloseDiagramId)
          cleanOpened()
          return updatedBlueprint
        }}
        shouldBlockNavigation={location => {
          const blueprintId = location.pathname.match(/[-\w]{16,}/)
          if ((matchedDiagramRoute || matchedModuleRoute) && blueprintId) {
            return false
          }
          if (!isThereUpdatedBlueprint()) {
            cleanOpened()
            return false
          }

          setToCloseDiagramId(isThereUpdatedBlueprint())
          return true
        }}
      />

      <BlueprintTabs
        closeBlueprint={closeBlueprint}
        addTab={addTab}
      />

      <EditorContainer />

      {editor && <SimulationDialog diagram={getCurrentDiagram()} />}

      <CreateNewDiagramDialog
        key='CreateNewDiagramDialog'
        type={type}
        getBlueprintData={() => {
          return {
            nodes: prepareBlueprint().data.nodes,
            events: prepareBlueprint().events,
          }
        }}
        addBlueprint={addBlueprint}
        currentName={diagram && diagram.name}
      />
      <ConfirmationDialog
        confDialog={confDialogVisible}
        currentName={getDiagramToClose() && getDiagramToClose().name}
        closeWithoutSaving={closeWithoutSaving}
        closeWithSaving={closeWithSaving}
        cancel={hideConfDialog}
      />
    </Box>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentBlueprint: function (id) {
      dispatch(editorActions.setCurrentBlueprint(id))
    },
    replaceOpenedWith: list => {
      dispatch(editorActions.replaceOpenedWith(list))
    },
    cleanOpened: () => {
      dispatch(editorActions.cleanOpened())
    },
    setToCloseDiagramId: id => {
      dispatch(editorActions.setToCloseDiagramId(id))
    },
    setConfDialogInEditor: value => {
      dispatch(editorActions.setConfDialogInEditor(value))
    },
    updateBlueprintInOpenedList: bp => {
      return dispatch(editorActions.updateBlueprintInOpenedList(bp))
    },
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(useComponentStyles)(EditorComponent))
