import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Card, CardHeader, Link, Typography } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin'
import Image from 'material-ui-image'

import Portainer from 'src/assets/img/portainer.png'
import Swagger from 'src/assets/img/swagger.png'
import Kibana from 'src/assets/img/kibana.png'

import Ooda from 'src/assets/img/ooda.png'
import Agenz from 'src/assets/img/agenz.png'
import NodeManager from 'src/assets/img/node_manager.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  AppLinksContent: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-around',
  },
  AppDownloadContent: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  download: {
    flex: 1,
  },
  link: {
    width: 240,
  },
  logo: {
    width: 120,
  },
  textLogo: {
    textAlign: 'center',
  },
  backgroundedImage: {
    backgroundColor: 'black',
  },
}))

const externalApps = [
  {
    title: 'PORTAINER',
    link: window._env_.PORTAINER_URL,
    logo: Portainer,
  },
  {
    title: 'API',
    link: window._env_.API_URL,
    logo: Swagger,
  },
  {
    title: 'DASHBOARD',
    link: window._env_.KIBANA_URL,
    logo: Kibana,
  },
]

const downloads = [
  {
    title: 'OODA',
    link: window._env_.OODA_DOWNLOAD,
    logo: Ooda,
  },
  {
    title: 'Agenz',
    link: window._env_.AGENZ_DOWNLOAD,
    logo: Agenz,
  },
  {
    title: 'NODE MANAGER',
    link: window._env_.NODE_MANAGER_URL,
    logo: NodeManager,
    styles: {
      backgroundColor: 'black',
    },
  },
]

export default function DashBoard() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Title title='APPLICATIONS' />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Card>
            <CardHeader title='External apps'></CardHeader>
            <CardContent className={classes.AppLinksContent}>
              {externalApps.map(app => (
                <div
                  key={app.title.replace(' ', '')}
                  className={classes.link}
                >
                  <Link
                    href={app.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Image
                      className={classes.logo}
                      src={app.logo}
                    />
                    <div className={classes.textLogo}>
                      <Typography>{app.title}</Typography>
                    </div>
                  </Link>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Card>
            <CardHeader title='App releases'></CardHeader>
            <CardContent className={classes.AppLinksContent}>
              {downloads.map(download => (
                <div
                  key={download.title.replace(' ', '')}
                  className={classes.link}
                >
                  <Link
                    href={download.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Image
                      className={classes.logo}
                      src={download.logo}
                      style={download.styles}
                    />
                    <div className={classes.textLogo}>
                      <Typography>{`Download ${download.title}`}</Typography>
                    </div>
                  </Link>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
