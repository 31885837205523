import React from 'react'
import { createPortal } from 'react-dom'
import { ClickAwayListener, Menu } from '@material-ui/core'

import SubComponents from './SubComponents'

export default function DropdownMenu({
  id,
  children,
  onClose,
  container = null,
  className,
}) {
  const [childrenWithProps, setChildrenWithProps] = React.useState([])
  const $container = React.isValidElement(container)
    ? container
    : document?.getElementById(container)

  React.useEffect(() => {
    if (!$container) return

    setChildrenWithProps(
      React.Children.map(
        children,
        child =>
          child &&
          React.cloneElement(child, {
            closeMenu: onClose,
          }),
      ),
    )
  }, [children, onClose, $container])

  if (!$container) return null

  const $menu = (
    <ClickAwayListener onClickAway={onClose}>
      <Menu
        id={id}
        anchorEl={$container}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean($container)}
        onClose={onClose}
        classes={{
          list: className,
        }}
      >
        {childrenWithProps}
      </Menu>
    </ClickAwayListener>
  )

  return createPortal($menu, $container)
}

Object.assign(DropdownMenu, SubComponents)
