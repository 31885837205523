import Rete from '@obvious.tech/rete'
import { sockets } from '../..'

export default class ActionCollector extends Rete.Component {
  constructor() {
    super('Action Collector')
    this.contextMenuPath = ['Actions']

    this.task = {
      outputs: {
        then: 'option',
      },
    }
  }

  builder(node) {
    const inp1 = new Rete.Input(
      'act',
      'Wait for actions',
      sockets.actionSocket,
      true,
    )
    const out1 = new Rete.Output('then', 'Then', sockets.actionSocket)

    return node.addInput(inp1).addOutput(out1)
  }
}
