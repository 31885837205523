export default async apiPromise => {
  const api = await apiPromise
  return {
    getMicroserviceForEntity: async entity => {
      let pathToGetEntity
      pathToGetEntity = Object.keys(api.paths).find(path => {
        return path.split('/')[3] === entity
      })
      if (!pathToGetEntity) return null
      return pathToGetEntity.split('/')[1]
    },
  }
}
