import SwaggerEntities from '../../adminPanel/Flow/components/SwaggerParser/Entities/SwaggerEntities'
import * as actionNames from './editor.constants'

export const initialState = {
  openedBlueprints: [],
  fetchedBlueprints: [],
  definitions: {},
  currentBlueprint: '',
  simulationDialogVisible: false,
  toCloseDiagramId: null,
  confDialogVisible: false,
  zoomValue: 1,
}

function addOpened(state, payload) {
  const { openedBlueprints, ...newState } = state
  const newOpenedBlueprints = openedBlueprints.slice()
  const blueprintExists = newOpenedBlueprints.find(blueprint => {
    return blueprint.id === payload.id
  })
  if (!blueprintExists) {
    newOpenedBlueprints.push(payload)
    return {
      ...newState,
      openedBlueprints: newOpenedBlueprints,
      currentBlueprint: payload.id,
    }
  }
  return state
}

function updateOpened(state, payload) {
  const { openedBlueprints, ...newState } = state
  const newOpenedBlueprints = openedBlueprints.slice()
  const blueprintIndex = newOpenedBlueprints.findIndex(blueprint => {
    return blueprint.id === payload.blueprintId
  })
  if (blueprintIndex !== -1) {
    newOpenedBlueprints[blueprintIndex].data = {
      ...payload.blueprintData,
    }
    newOpenedBlueprints[blueprintIndex].updatedLocally = true
  }
  return {
    ...newState,
    openedBlueprints: newOpenedBlueprints,
  }
}

function markAsCurrent(state, payload) {
  const newState = {
    ...state,
  }
  newState.currentBlueprint = payload
  return newState
}

function addFetchedBlueprint(state, payload) {
  if (state.fetchedBlueprints.find(({ id }) => id === payload.id)) return state

  const newState = {
    ...state,
    fetchedBlueprints: [...state.fetchedBlueprints, payload],
  }

  return newState
}

function updateFetchedBlueprint(state, payload) {
  if (!state.fetchedBlueprints.find(({ id }) => id === payload.id)) {
    return state
  }

  const newState = {
    ...state,
    fetchedBlueprints: state.fetchedBlueprints.map(blueprint =>
      blueprint.id === payload.id
        ? {
            ...blueprint,
            ...payload,
          }
        : blueprint,
    ),
  }

  return newState
}

function renameBlueprint(state, payload) {
  const { openedBlueprints, ...newState } = state
  const newOpenedBlueprints = openedBlueprints.slice()
  const blueprintIndex = newOpenedBlueprints.findIndex(
    blueprint => blueprint.id === payload.id,
  )

  newOpenedBlueprints[blueprintIndex] = {
    ...newOpenedBlueprints[blueprintIndex],
  }
  newOpenedBlueprints[blueprintIndex].name = payload.name
  return {
    ...newState,
    openedBlueprints: newOpenedBlueprints,
  }
}

export default function editorReducer(state = initialState, action) {
  switch (action.type) {
    case actionNames.SAVE_DIAGRAM:
      return {
        ...state,
        saved: action.payload,
      }

    case actionNames.SET_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      }

    case actionNames.ADD_FETCHED_BLUEPRINT:
      return addFetchedBlueprint(state, action.payload)

    case actionNames.UPDATE_FETCHED_BLUEPRINT:
      return updateFetchedBlueprint(state, action.payload)

    case actionNames.ADD_BLUEPRINT_TO_LIST_OF_OPENED:
      return addOpened(state, action.payload)

    case actionNames.UPDATE_OPENED_BLUEPRINT:
      return updateOpened(state, action.payload)

    case actionNames.MARK_BLUEPRINT_AS_CURRENT:
      return markAsCurrent(state, action.payload)

    case actionNames.RENAME_BLUEPRINT:
      return renameBlueprint(state, action.payload)

    case actionNames.SHOW_NEWBLUEPRINT_DIALOG:
      return {
        ...state,
        newBlueprintDialog: {
          variant: actionNames.SHOW_NEWBLUEPRINT_DIALOG,
        },
      }

    case actionNames.SHOW_CHANGE_TITLE_DIALOG:
      return {
        ...state,
        newBlueprintDialog: {
          variant: actionNames.SHOW_CHANGE_TITLE_DIALOG,
          blueprint: action.payload,
        },
      }

    case actionNames.SAVE_WITH_NAME_DIALOG:
      return {
        ...state,
        newBlueprintDialog: {
          variant: actionNames.SAVE_WITH_NAME_DIALOG,
          blueprint: action.payload,
        },
      }

    case actionNames.HIDE_NEWBLUEPRINT_DIALOG:
      return {
        ...state,
        newBlueprintDialog: false,
      }

    case actionNames.UPDATE_BLUEPRINT:
      const updatedBlueprint = action.payload
      return {
        ...state,
        openedBlueprints: state.openedBlueprints.map(diagram =>
          diagram.id === updatedBlueprint.id
            ? {
                ...diagram,
                ...updatedBlueprint,
              }
            : diagram,
        ),

        newBlueprintDialog: false,
      }

    case actionNames.REMOVE_OPENED_BLUEPRINT:
      return {
        ...state,
        openedBlueprints: state.openedBlueprints.filter(
          ({ id }) => id !== action.payload,
        ),
      }

    case actionNames.ADD_BLUEPRINT:
      const newList = [...state.openedBlueprints]
      newList.push(action.payload)
      return {
        ...state,
        openedBlueprints: newList,
        newBlueprintDialog: false,
      }

    case actionNames.TOGGLE_SIMULATION_DIALOG:
      return {
        ...state,
        simulationDialogVisible: action.payload,
      }

    case actionNames.SET_CURRENT_BLUEPRINT:
      return {
        ...state,
        currentBlueprint: action.payload,
      }

    case actionNames.REPLACE_OPENED_WITH:
      return {
        ...state,
        openedBlueprints: action.payload,
      }

    case actionNames.CLEAN_OPENED:
      return {
        ...state,
        openedBlueprints: [],
        currentBlueprint: '',
      }

    case actionNames.SET_TO_CLOSE_DIAGRAMID:
      return {
        ...state,
        toCloseDiagramId: action.payload,
      }

    case actionNames.TOGGLE_CONF_DIALOG:
      return {
        ...state,
        confDialogVisible: action.payload,
      }

    case actionNames.SET_CONF_DIALOG:
      return {
        ...state,
        toCloseDiagramId: action.payload.diagramId,
        confDialogVisible: action.payload.visible,
      }

    case actionNames.SET_EDITOR_ZOOM_VALUE:
      return {
        ...state,
        zoomValue: action.payload,
      }

    case actionNames.SET_FLOW_DEFINITIONS:
      return {
        ...state,
        definitions: {
          ...action.payload,
          entities: SwaggerEntities(action.payload.entities),
        },
      }

    default:
      return state
  }
}
