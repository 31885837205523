import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Title, Resource } from 'react-admin'
import { useSelector } from 'react-redux'

import CustomList from 'src/adminPanel/screens/List/CustomList'
import api from 'src/utils/api'
import { IAgentActivityReport, IPassport } from '@obvious.tech/constellation'

import { AgentActivityReportTitle } from './AgentActivityReportTitle'
import { TEntityOverride } from 'src/types'

const getAgentByID = api.getResourceByID<IPassport>(
  'authentication/api/Passport',
)

const AgentActivityReport = (): JSX.Element => {
  const location = useLocation()
  const authorization = localStorage.getItem('token') ?? ''
  const { passportId } = useParams<Record<string, string>>()
  const [passport, getPassport] = api.useApiAction(getAgentByID)

  const baseEntity = useSelector(
    // @ts-expect-error
    state => state.definitions.entities.AgentActivityReport,
  )

  const entity: TEntityOverride<IAgentActivityReport> = {
    ...baseEntity,
    list: {
      ...baseEntity.list,
      properties: {
        ...baseEntity.list.properties,
        passportId: {
          hidden: true,
        },
        groups: {
          hidden: true,
        },
      },
    },
  }

  useEffect(() => {
    if (typeof authorization !== 'string') {
      return
    }

    return getPassport({
      id: passportId,
      token: authorization,
    })
  }, [location.search, passportId, authorization, getPassport])

  return (
    <>
      <Title title={<AgentActivityReportTitle passport={passport} />} />
      <Resource
        key={'AgentActivityReport'}
        name={'units/api/AgentActivityReport'}
        list={props => (
          <CustomList
            passportId={passportId}
            name={'AgentActivityReport'}
            entity={entity}
            {...props}
          />
        )}
        show={null}
      />
    </>
  )
}

export default AgentActivityReport
