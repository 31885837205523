import { TEntityOverride } from 'src/types'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      cameraId: {
        displayedName: 'Camera',
        referenceTo: 'videomanager/api/Camera',
        referencedField: 'name',
      },
      vms: {
        referenceTo: 'videomanager/api/Vms',
        referencedField: 'name',
      },
      groups: {
        type: 'groups',
      },
      fileId: {
        hidden: true,
      },
    },
  },
  list: {
    properties: {
      overlayImage: {
        hidden: true,
      },
    },
  },
}

export default override
