import Rete from '@obvious.tech/rete'

import { compareNodeOptions } from 'src/utils/flow'

import DefaultNode from '../../Components/CustomNode'
import NodeTypizer from '../../Components/NodeTypizer'
import SelectControl from '../../Controls/SelectControl'
import fetchModules from '../../Editor/fetchModules'

export default class ModuleComponent extends Rete.Component {
  constructor(isDiagram, dataProvider) {
    super('Module')
    this.module = {
      nodeType: 'module',
    }
    this.task = { outputs: {} }
    this.contextMenuPath = isDiagram ? [] : ['Module']
    this.data.component = NodeTypizer(DefaultNode, { type: 'module' })
    this.dataProvider = dataProvider
  }

  getModules() {
    return fetchModules({
      dataProvider: this.dataProvider,
      shouldTransform: false,
    })
  }

  async builder(node) {
    const options = (await this.getModules())
      .filter(module => {
        return !!module.data?.nodes
      })
      .map(module => {
        return {
          value: module.name,
          label: module.name,
        }
      })
      .sort(compareNodeOptions)

    const onChange = async value => {
      node.data.module = value
      if (!value) return
      this.updateModuleSockets(node)

      setTimeout(() => {
        node.update()
        this.editor.view.updateConnections({ node })
      })
    }

    const ctrl = new SelectControl(
      this.editor,
      node,
      'module',
      'Module name',
      onChange,
      options,
    )

    return node.addControl(ctrl)
  }
}
