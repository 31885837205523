export default entities => {
  const definitions = Object.keys(entities).reduce((acc, definitionKey) => {
    const entity = entities[definitionKey]
    if (entity.type === 'object') {
      return {
        ...acc,
        [definitionKey]: {
          properties: Object.keys(entities[definitionKey].properties).map(
            cur => ({
              ...entities[definitionKey].properties[cur],
              name: cur,
              type:
                (entities[definitionKey].properties[cur].type === 'integer'
                  ? 'number'
                  : entities[definitionKey].properties[cur].type) || 'object',
              entity:
                entities[definitionKey].properties[cur].type === 'array'
                  ? entities[definitionKey].properties[cur].items.$ref &&
                    entities[definitionKey].properties[
                      cur
                    ].items.$ref.substring(14)
                  : entities[definitionKey].properties[cur].$ref &&
                    entities[definitionKey].properties[cur].$ref.substring(14),
            }),
          ),
          required: entities[definitionKey].required,
        },
      }
    } else {
      return acc
    }
  }, {})
  return definitions
}
