import React from 'react'
import { Text, StyleSheet, View, Link } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import { formatDate } from './CheckInOutData'

import * as theme from 'src/parser/shared/PDF/theme'

export const MAPS_BASEPATH = 'https://www.google.com/maps'

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 900,
    paddingVertical: theme.padding(0.5),
  },
  headerText: {
    flex: 0.25,
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.padding(0.5),
  },
  fieldText: {
    flex: 0.25,
    color: theme.colors.text,
  },
  link: {
    flex: 0.25,
    textDecoration: 'none',
    color: theme.colors.link,
  },
})

export const Header = ({ config }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <View style={styles.header}>
      {config.map((item, index) => (
        <Text
          key={index}
          style={styles.headerText}
        >
          {t(item.translation)}
        </Text>
      ))}
    </View>
  )
}

const CommonField = ({ index, item }): JSX.Element => {
  const odd = index % 2 === 1
  const {
    lastUpdate,
    selectedAnswers,
    location,
    description,

    creationDate,
    severity,
    status,
    category,
  } = item
  const locationIsDefined = typeof location === 'object'
  const source = locationIsDefined
    ? `${MAPS_BASEPATH}?q=${location.latitude},${location.longitude}`
    : MAPS_BASEPATH

  const date =
    lastUpdate !== undefined ? formatDate(lastUpdate) : formatDate(creationDate)
  const answers = selectedAnswers?.join(', ')

  const formattedLocation = locationIsDefined
    ? {
        latitude: location.latitude.toFixed(7),
        longitude: location.longitude.toFixed(7),
      }
    : null

  return (
    <View
      style={[
        styles.field,
        {
          backgroundColor: odd
            ? theme.colors.field
            : theme.colors.fieldSecondary,
        },
      ]}
    >
      <Text style={styles.fieldText}>{date}</Text>
      <Text style={styles.fieldText}>{severity || description}</Text>
      <Text style={styles.fieldText}>{answers || status}</Text>
      {category !== undefined && (
        <Text style={styles.fieldText}>{category}</Text>
      )}
      {formattedLocation !== null && (
        <Link
          style={styles.link}
          src={source}
        >
          <Text>
            {`${formattedLocation.latitude} ; ${formattedLocation.longitude}`}
          </Text>
        </Link>
      )}
    </View>
  )
}

export const AlarmEntry = ({ alarm, index }): JSX.Element => {
  return (
    <CommonField
      index={index}
      item={alarm}
    />
  )
}

export const Field = ({ task, index }): JSX.Element => {
  return (
    <CommonField
      index={index}
      item={task}
    />
  )
}
