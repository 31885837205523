import React, { useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import defaultClasses from './theme/classes'
import { editor } from './components/Flow'
import {
  ErrorInfoText,
  FixWarningsButton,
  UsePreSaveValidation,
} from './PreSaveValidation'
import { DialogButton } from './components/shared'

const useStyles = makeStyles({
  ...defaultClasses,
})

const ConfirmationDialog = ({
  confDialog,
  cancel,
  currentName,
  closeWithSaving,
  closeWithoutSaving,
}) => {
  const classes = useStyles()

  const [errors, , isEditorValid] = UsePreSaveValidation()

  useEffect(() => {
    if (!editor || !confDialog) return

    isEditorValid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confDialog])

  return (
    <div>
      <Dialog
        open={confDialog}
        onClose={cancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{`${currentName} blueprint is not saved`}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Some changes can be lost.
          </DialogContentText>
          {errors && <ErrorInfoText mb={1} />}
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <DialogButton onClick={closeWithoutSaving}>
            close without saving
          </DialogButton>
          {errors && (
            <FixWarningsButton
              onClose={cancel}
              errors={errors}
            />
          )}
          <DialogButton onClick={closeWithSaving}>save and close</DialogButton>
          <DialogButton onClick={cancel}>cancel</DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationDialog
