// @ts-nocheck
import React, { cloneElement } from 'react'
import { createPortal } from 'react-dom'
import { useTranslate, Record } from 'react-admin'

interface TitleProps {
  className?: string
  record?: Record
  title: string | React.ReactElement
}

const TITLE_EL_ID = 'react-admin-title'

const TitlePortal = ({
  className,
  record,
  title,
}: TitleProps): JSX.Element | null => {
  const translate = useTranslate()
  const container = document.getElementById(TITLE_EL_ID)

  if (window.isNullish(container)) {
    console.error(`Container ${TITLE_EL_ID} not found.`)
    return null
  }

  const titleElement =
    typeof title === 'string' ? (
      <span className={className}>
        {translate(title, {
          _: title,
        })}
      </span>
    ) : (
      cloneElement(title, {
        className,
        record,
      })
    )

  return createPortal(titleElement, container)
}

export default TitlePortal
