import React from 'react'

export const UserMenuContext = React.createContext()

const UserMenuProvider = ({ children }) => {
  const [options, setOptions] = React.useState([])

  return (
    <UserMenuContext.Provider
      value={{
        options,
        setOptions,
      }}
    >
      {children}
    </UserMenuContext.Provider>
  )
}
export default UserMenuProvider
