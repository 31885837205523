import { makeStyles } from '@material-ui/core/styles'
import React, { ComponentProps } from 'react'
import makeCSSCheckerboard from 'src/utils/makeCSSCheckerboard'

const useStyles = makeStyles({
  root: {
    ...makeCSSCheckerboard({
      size: 4,
    }),
  },
})

type Props = Pick<ComponentProps<'img'>, 'src' | 'alt' | 'className'>

const ImagePreview = ({ className, src, alt }: Props) => {
  const classes = useStyles()
  return (
    <img
      className={[classes.root, className].join(' ')}
      alt={alt}
      src={src}
    />
  )
}

export default ImagePreview
