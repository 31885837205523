import React from 'react'
import { Edit } from 'react-admin'
import { connect } from 'react-redux'

import InputList from '../inputs/InputList'
import CustomTitle from '../Title/Title'
import extendGlobalEntity from 'src/parser/entities/extendGlobalEntity'

export const CustomEdit = ({ entity, name, ...props }) => {
  const { id, basePath, resource } = props
  const editEntity = extendGlobalEntity(entity, 'edit')

  const DisplayedComponent = editEntity.displayedComponent

  const properties = editEntity.properties

  const transformNumberValue = ({ record, srcRecord, key }) => {
    const required = properties[key]?.required ?? false
    const value = srcRecord[key]
    if (window.isNullish(value) && !required) return

    record[key] = value
  }

  const transform = srcRecord => {
    const record = {}

    Object.keys(srcRecord).forEach(key => {
      const property = properties[key]
      if (property?.readOnly) return

      if (property?.type === 'number' || property?.type === 'integer') {
        transformNumberValue({
          key,
          srcRecord,
          record,
        })

        return
      }

      record[key] = srcRecord[key]
    })

    return record
  }

  return DisplayedComponent ? (
    <DisplayedComponent
      entity={editEntity}
      name={name}
      id={id}
      basePath={basePath}
      resource={resource}
      {...props}
    />
  ) : (
    <Edit
      transform={transform}
      title={
        <CustomTitle
          entity={editEntity}
          {...props}
        />
      }
      basePath={basePath}
      resource={resource}
      redirect={
        editEntity.redirect === undefined ? 'list' : editEntity.redirect
      }
      id={id}
      key={id}
    >
      <InputList
        entity={editEntity}
        {...props}
      />
    </Edit>
  )
}

const mapStateToProps = state => {
  return {
    entities: state.definitions.entities || {},
    microservices: state.microservices,
  }
}

export default connect(mapStateToProps, null)(CustomEdit)
