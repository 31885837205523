import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  makeStyles,
  Button,
  Snackbar,
  SnackbarProps,
  ClickAwayListenerProps,
} from '@material-ui/core'
import classnames from 'classnames'
import {
  hideNotification,
  getNotification,
  undo,
  complete,
  undoableEventEmitter,
  useTranslate,
} from 'react-admin'

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  undo: {
    color: theme.palette.primary.light,
  },
}))

type TNotificationType = 'info' | 'warning' | 'success' | 'error'

interface IProps extends SnackbarProps {
  classes?: { [key: string]: string }
  type?: TNotificationType
  className?: string
  autoHideDuration?: number
}

interface INotification {
  undoable?: boolean
  message: string
  autoHideDuration?: number
  messageArgs: any
  type?: TNotificationType
  ClickAwayListenerProps?: ClickAwayListenerProps
}

export const DEFAULT_AUTO_HIDE_DURATION = 5000

const Notification = (props: IProps): JSX.Element => {
  const {
    classes: classesOverride,
    type = 'info',
    className,
    autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
    ...rest
  } = props
  const styles = useStyles(props)
  const dispatch = useDispatch()
  const translate = useTranslate()

  const [open, setOpen] = useState(false)

  const notification: INotification | undefined = useSelector(getNotification)
  const {
    type: nType = 'info',
    autoHideDuration: nAutoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
    ClickAwayListenerProps = {},
    message = '',
    messageArgs = {},
    undoable = false,
  } = notification ?? {}

  useEffect(() => {
    setOpen(Boolean(notification))
  }, [notification])

  const handleRequestClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onExiting = useCallback(() => {
    if (!window.isNullish(notification?.undoable)) {
      dispatch(complete())
      undoableEventEmitter.emit('end', {
        isUndo: false,
      })
    }

    dispatch(hideNotification())
  }, [dispatch, notification])

  const handleUndo = useCallback(() => {
    dispatch(undo())
    undoableEventEmitter.emit('end', {
      isUndo: true,
    })
  }, [dispatch])

  const notificationArgs = useMemo(
    () => (window.isNullish(messageArgs) ? {} : messageArgs),
    [messageArgs],
  )
  const hideDuration = useMemo(
    () =>
      window.isNullish(nAutoHideDuration)
        ? autoHideDuration
        : nAutoHideDuration,
    [autoHideDuration, nAutoHideDuration],
  )
  const notificationType = useMemo(
    () => (window.isNullish(nType) ? type : nType),
    [nType, type],
  )

  return (
    <Snackbar
      open={open}
      message={translate(message, notificationArgs)}
      autoHideDuration={hideDuration}
      disableWindowBlurListener={undoable}
      TransitionProps={{
        onExiting,
      }}
      onClose={handleRequestClose}
      ContentProps={{
        className: classnames(styles[notificationType], className),
      }}
      action={
        undoable ? (
          <Button
            color='primary'
            className={styles.undo}
            size='small'
            onClick={handleUndo}
          >
            {translate('ra.action.undo')}
          </Button>
        ) : null
      }
      ClickAwayListenerProps={ClickAwayListenerProps}
      {...rest}
      {...notificationArgs}
    />
  )
}

export default React.memo(Notification)
