import React from 'react'
import { ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin'

export default ({ name, property, ...props }) => {
  return (
    <ReferenceArrayField
      {...props}
      label={property.displayedName || name}
      source={name}
      reference={property.referenceTo}
    >
      <SingleFieldList>
        <ChipField source={property.referencedField} />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}
