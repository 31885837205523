import React, { useState } from 'react'
import { Prompt } from 'react-router-dom'

import ConfirmationDialog from './ConfirmationDialog'

const RouteLeavingGuard = ({
  when,
  getCurrentName,
  closeWithoutSaving,
  navigate,
  closeWithSaving,
  shouldBlockNavigation,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const showModal = location => {
    setModalVisible(true)
    setLastLocation(location)
  }

  const closeModal = async callback => {
    await setModalVisible(false)
    callback && callback()
  }

  const handleBlockedNavigation = nextLocation => {
    if (nextLocation.pathname.startsWith('/login')) return true

    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation)
      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    const cb = async () => {
      if (lastLocation) {
        await setConfirmedNavigation(true)
        navigate(lastLocation.pathname)
      }
    }

    closeModal(cb)
  }

  const handleCloseWithSaving = async () => {
    const updatedBlueprint = await closeWithSaving()
    if (!updatedBlueprint) return

    handleConfirmNavigationClick()
  }

  return (
    <>
      <Prompt
        when={when}
        message={handleBlockedNavigation}
      />
      <ConfirmationDialog
        confDialog={modalVisible}
        currentName={getCurrentName()}
        closeWithoutSaving={() => {
          closeWithoutSaving()
          handleConfirmNavigationClick()
        }}
        closeWithSaving={handleCloseWithSaving}
        cancel={() => {
          closeModal()
        }}
      />
    </>
  )
}
export default RouteLeavingGuard
