import React from 'react'
import { Loading } from 'react-admin'
import styled from 'styled-components'

const defaultLoadingWrapperHeight =
  'calc(100vh - 48px - 8px - 25px)' /* header height, padding-top, padding-bottom of component */

const StyledLoading = styled(Loading)`
  height: ${({ height }) => height || defaultLoadingWrapperHeight};
`

const CustomLoader = props => {
  return <StyledLoading {...props} />
}

export default CustomLoader
