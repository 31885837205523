import { IGeofence } from '@obvious.tech/constellation'

import { TEntityOverride } from 'src/types'

const override: TEntityOverride<IGeofence> = {
  order: ['id', 'groups', 'name', 'layers', 'geoJson'],
  global: {
    properties: {
      id: {
        displayedName: 'ID',
      },
      groups: {
        type: 'groups',
      },
      name: {
        displayedName: 'Name',
      },
      geoJson: {
        displayedName: 'GeoJSON',
        type: 'string',
      },
      layers: {
        displayedName: 'Layers/Filters',
      },
    },
  },
  list: {
    properties: {
      geoJson: {
        hidden: true,
      },
    },
  },
  edit: {
    properties: {
      geoJson: {
        type: 'JSONObject',
      },
    },
  },
  create: {
    properties: {
      geoJson: {
        type: 'JSONObject',
      },
    },
  },
}

export default override
