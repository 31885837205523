const AUTHORIZATION_TYPES = {
  GROUP: 'EntityGroupAuthorization',
  SCREENSHOT: 'ScreenshotAuthorization',
  PTZ_PRIORITY: 'PtzPriorityAuthorization',
} as const

export type TAuthorizationType =
  (typeof AUTHORIZATION_TYPES)[keyof typeof AUTHORIZATION_TYPES]

export type TAuthorizationData<
  Type extends TAuthorizationType = TAuthorizationType,
> = {
  EntityGroupAuthorization: {
    entity: string
    level: number
  }
  ScreenshotAuthorization: {
    enabled: boolean
  }
  PtzPriorityAuthorization: {
    weight: number
  }
}[Type]

export type TAuthorizationTypedData<
  Type extends TAuthorizationType = TAuthorizationType,
> = {
  type: Type
  data: TAuthorizationData<Type>
}

export default AUTHORIZATION_TYPES
