import React from 'react'
import { Text } from '@react-pdf/renderer'

import NoContent from 'src/parser/shared/PDF/NoContent'
import * as theme from 'src/parser/shared/PDF/theme'
import { TSectionContent } from 'src/parser/shared/PDF/Sections'
import { TEntity } from 'src/utils/api'
import { IAlarm } from '@obvious.tech/constellation'
import {
  commonStyles,
  createDirectionalStyles,
} from 'src/parser/shared/PDF/Title/CommonUtils'
import { useTranslation } from 'react-i18next'

const Description: TSectionContent = ({ entity }) => {
  const { i18n } = useTranslation()
  const alarm = entity as TEntity<IAlarm>
  if (!alarm.description) return <NoContent />
  const directionalStyles = createDirectionalStyles(commonStyles, i18n.language)

  return (
    <Text style={[theme.styles.text, directionalStyles.textAlign]}>
      {alarm.description}
    </Text>
  )
}

export default Description
