import History from './history'
import Act from './action'
import { AddNodeAction, DragNodeAction, RemoveNodeAction } from './actions/node'
import {
  AddConnectionAction,
  RemoveConnectionAction,
} from './actions/connection'

function trackNodes(editor, history) {
  let prev = {}
  editor.on('nodecreated', node => history.add(new AddNodeAction(editor, node)))
  editor.on('noderemoved', node =>
    history.add(new RemoveNodeAction(editor, node)),
  )
  editor.on('selectnode', payload => {
    const { wasMoved } = payload

    const selection = editor.selected.list

    if (!wasMoved) return

    history.add(new DragNodeAction(editor, selection, prev))
  })
  editor.on('nodeselected', node => {
    prev = {}
    editor.selected.list.forEach(node => {
      prev = {
        ...prev,
        [node.id]: [...node.position],
      }
    })
  })
  editor.on('nodetranslated', ({ node }) => {
    if (history.last instanceof DragNodeAction && history.last.node === node) {
      history.last.update(node)
    }
  })
}

function trackConnections(editor, history) {
  editor.on('connectioncreated', c =>
    history.add(new AddConnectionAction(editor, c)),
  )
  editor.on('connectionremoved', c =>
    history.add(new RemoveConnectionAction(editor, c)),
  )
}

function install(editor) {
  editor.bind('undo')
  editor.bind('redo')
  editor.bind('addhistory')
  editor.bind('cleanhistory')

  const history = new History()

  editor.on('undo', () => history.undo())
  editor.on('redo', () => history.redo())
  editor.on('addhistory', action => history.add(action))
  editor.on('cleanhistory', () => history.clean())

  trackNodes(editor, history)
  trackConnections(editor, history)
}

export const Action = Act

export default {
  name: 'history',
  install,
}
