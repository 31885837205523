import React from 'react'
import { makeStyles } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import { useInput } from 'react-admin'

const useStyles = makeStyles((theme: any) => ({
  timePicker: theme.inputs.textfield,
}))

const TimeInput = ({ property, name, ...props }) => {
  const classes = useStyles()
  const {
    input: { onChange, value },
  } = useInput({
    source: name,
    ...props,
  })
  return (
    <TimePicker
      className={classes.timePicker}
      value={value}
      label={property.displayedName}
      onChange={e => {
        onChange(e.toUTCString())
      }}
    />
  )
}

export default TimeInput
