import React, { useCallback, useState, useEffect } from 'react'
import { TableContainer } from '@material-ui/core'

import { SIZES } from './DataGridContainer.constants'
import useStyles from './DataGridContainer.styles'

export type TContainerStyleProps = {
  contentHeight: number
  scrollable: {
    left: boolean
    right: boolean
    up: boolean
    down: boolean
  }
  fixedFirstCol?: boolean
  hasEdit?: boolean
}

type TDataGridContainerProps = {
  fixedFirstCol?: boolean
  children: React.ReactNode
  hasEdit?: boolean
}

export const DataGridContainer = ({
  fixedFirstCol = true,
  hasEdit = true,
  children,
}: TDataGridContainerProps) => {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null)
  const [contentlessHeight, setContentlessHeight] = useState(0)
  const [contentHeight, setContentHeight] = useState(0)
  const [scrollable, setScrollable] = useState<
    TContainerStyleProps['scrollable']
  >({
    left: false,
    right: false,
    up: false,
    down: false,
  })

  const classes = useStyles({
    contentHeight,
    scrollable,
    fixedFirstCol,
    hasEdit,
  })

  const updateContentHeight = (contentlessHeight: number) => {
    setContentHeight(window.innerHeight - contentlessHeight)
  }

  const initContentlessHeight = useCallback(
    (contentlessNode: HTMLDivElement | null) => {
      if (contentlessNode == null) return
      const parentBB = contentlessNode
        // TO-DO: change to dynamic value from props
        .closest('[data-app=CustomDatagridContainer]')!
        .getBoundingClientRect()
      const newContentlessHeight =
        parentBB.y + parentBB.height + SIZES.TABLE_HEADER_HEIGHT
      updateContentHeight(newContentlessHeight)
      setContentlessHeight(newContentlessHeight)
    },
    [],
  )

  const updateScrollable = useCallback(
    (t: HTMLDivElement | null) => {
      if (t == null) return
      const newScrollable = {
        right:
          !!t.scrollWidth &&
          Math.ceil(t.clientWidth + t.scrollLeft) < t.scrollWidth,
        left: !!t.scrollWidth && !!t.scrollLeft,
        up: !!t.scrollHeight && !!t.scrollTop,
        down:
          !!t.scrollHeight &&
          Math.ceil(t.clientHeight + t.scrollTop) < t.scrollHeight,
      }

      const scrollableChanged = Object.keys(newScrollable).some(
        k => scrollable[k] !== newScrollable[k],
      )

      if (!scrollableChanged) return
      setScrollable(newScrollable)
    },
    [scrollable],
  )

  const containerElCb = useCallback((containerEl: HTMLDivElement | null) => {
    setContainerEl(containerEl)
  }, [])

  useEffect(() => {
    const onWindowResize = () => {
      updateContentHeight(contentlessHeight)
      updateScrollable(containerEl)
    }

    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [updateScrollable, containerEl, contentlessHeight])

  useEffect(() => {
    updateScrollable(containerEl)
  }, [children, containerEl, updateScrollable])

  const handleTableScroll = () => {
    updateScrollable(containerEl)
  }

  if (contentlessHeight === 0) return <div ref={initContentlessHeight} />

  return (
    <TableContainer
      ref={containerElCb}
      onScroll={handleTableScroll}
      className={`${classes.container} ${classes.row}`}
    >
      {children}
    </TableContainer>
  )
}

export default DataGridContainer
