import { useGetOne } from 'react-admin'
import React from 'react'
import GenericInput from 'src/components/inputs/GenericInput'
import { FormControl, Typography } from '@material-ui/core'

const TYPES = {
    undefined: 'undefined',
    number: 'number',
    boolean: 'boolean',
    string: 'string',
    '[object Function]': 'function',
    '[object RegExp]': 'regexp',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object Error]': 'error',
  },
  TOSTRING = Object.prototype.toString

function getType(o) {
  return TYPES[typeof o] || TYPES[TOSTRING.call(o)] || (o ? 'object' : 'null')
}

const Input = ({ name, parameter, onChange, storedValue }) => {
  const value = storedValue || {}
  if (
    !Object.keys(parameter).includes('defaultValue') &&
    !Object.keys(parameter).includes('defaultvalue')
  ) {
    return (
      <div key={name}>
        <Typography variant='h6'>{name}</Typography>
        {Object.keys(parameter).map(subParameter => {
          const handleChange = e => {
            onChange({
              ...value,
              [subParameter]: e,
            })
          }
          return (
            <Input
              key={subParameter}
              name={subParameter}
              parameter={parameter[subParameter]}
              onChange={handleChange}
              storedValue={value[subParameter]}
            />
          )
        })}
      </div>
    )
  } else {
    let type = getType(parameter.defaultValue || parameter.defaultvalue)
    if (['null', 'undefined'].includes(type)) {
      type = 'string'
    }
    return (
      <GenericInput
        helperText={parameter.description}
        key={name}
        property={{
          type: type,
          defaultValue: parameter.defaultValue || parameter.defaultvalue,
        }}
        name={name}
        onChange={onChange}
        value={storedValue}
        label={name}
        placeholder={name}
      />
    )
  }
}

const ConnectorParametersForm = ({
  record,
  entity,
  onChange,
  value,
  formData = {},
  name,
  ...rest
}) => {
  let {
    data: template,
    loaded,
    error,
  } = useGetOne(
    'connectors/api/Template',
    value?.templateId || formData.templateId,
  )
  if (loaded && !error) {
    return (
      <FormControl
        label={name}
        variant='outlined'
        fullWidth
      >
        {Object.keys(template.parameters).map(parameterName => {
          const parameter = template.parameters[parameterName]
          const handleChange = e => {
            onChange({
              ...value,
              [parameterName]: e,
            })
          }
          return (
            <Input
              key={parameterName}
              name={parameterName}
              parameter={parameter}
              onChange={handleChange}
              storedValue={value?.[parameterName]}
            />
          )
        })}
      </FormControl>
    )
  }
  return null
}

export default ConnectorParametersForm
