// @ts-nocheck
import React, { useState, ReactElement, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import ActionDelete from '@material-ui/icons/Delete'
import {
  Record,
  OnSuccess,
  useDelete,
  OnFailure,
  useResourceContext,
  Confirm,
  Button,
  ButtonProps,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin'

import { DEFAULT_AUTO_HIDE_DURATION } from 'src/adminPanel/layout/Notification'

const useStyles = makeStyles(
  theme => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  {
    name: 'RaDeleteWithConfirmButton',
  },
)

const defaultIcon = <ActionDelete />

interface Props extends ButtonProps {
  basePath?: string
  className?: string
  confirmTitle?: string
  confirmContent?: string
  icon?: ReactElement
  label?: string
  record?: Record
  redirect?: string
  resource?: string
  invalid?: boolean
  pristine?: boolean
  undoable?: boolean
  onSuccess?: OnSuccess
  onFailure?: OnFailure
  notificationTimeout?: number
}

const DeleteWithConfirmButton = ({
  basePath,
  className,
  confirmTitle = 'ra.message.delete_title',
  confirmContent = 'ra.message.delete_content',
  icon = defaultIcon,
  label = 'ra.action.delete',
  record,
  redirect = 'list',
  onSuccess,
  onFailure,
  undoable = true,
  notificationTimeout = DEFAULT_AUTO_HIDE_DURATION,
  resource: propResource,
  ...rest
}: Props): JSX.Element => {
  const classes = useStyles()
  const resource = useResourceContext({
    resource: propResource,
  })
  const notify = useNotify()
  const redirectTo = useRedirect()
  const refresh = useRefresh()

  const [open, setOpen] = useState(false)

  const [deleteOne, { loading }] = useDelete(
    resource,
    record?.id ?? '',
    record,
    {
      onSuccess: response => {
        setOpen(false)
        if (!window.isNullish(onSuccess)) {
          onSuccess(response)
          return
        }

        const listPath = window.isNullish(basePath)
          ? window.isNullish(resource)
            ? '/'
            : `/${resource}`
          : basePath
        notify(
          'ra.notification.deleted',
          'info',
          {
            smart_count: 1,
          },
          undoable,
          notificationTimeout,
        )
        redirectTo(redirect, listPath)
        refresh()
      },
      onFailure: error => {
        setOpen(false)
        if (!window.isNullish(onFailure)) {
          onFailure(error)
          return
        }

        const errorMessage =
          error === 'string'
            ? error
            : window.isNullish(error?.message)
            ? 'ra.notification.http_error'
            : error.message

        notify(errorMessage, 'warning', {
          _: errorMessage,
        })
        refresh()
      },
      undoable,
    },
  )

  const handleDelete = useCallback(() => {
    if (window.isNullish(record)) return

    deleteOne({
      payload: {
        id: record.id,
        previousData: record,
      },
    })
  }, [deleteOne, record])

  const handleDialogOpen = (): void => {
    setOpen(true)
  }

  const handleDialogClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={
          window.isNullish(className)
            ? classes.deleteButton
            : `${classes.deleteButton} ${className}`
        }
        {...rest}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default DeleteWithConfirmButton
