import React from 'react'
import { TextField } from '@material-ui/core'

export type TProps = {
  value: number | null | 'loading'
  disabled?: boolean
  onSubmit: (value: number | null) => void
}

const PtzPriorityInput = (props: TProps) => {
  const [rawValue, setRawValue] = React.useState<number | '-' | ''>('')
  const ptzInputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>()

  React.useEffect(() => {
    if (props.value === 'loading') {
      return
    }

    setRawValue(props.value ?? '')
  }, [props.value])

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isValid = ptzInputRef.current?.validity.valid ?? false

    if (!isValid) {
      return
    }

    const value = e.target.value

    if (value === '-') {
      setRawValue('-')
      return
    }

    const newValue = value === '' ? '' : parseInt(value)

    setRawValue(newValue)
  }

  const onSubmit = () => {
    if (rawValue === '-') {
      return
    }

    const newValue = rawValue === '' ? null : rawValue

    if (newValue === props.value) {
      return
    }

    props.onSubmit(newValue)
  }

  return (
    <TextField
      inputRef={ptzInputRef}
      error={rawValue === '-'}
      inputProps={{
        pattern: '^(-?[0-9]*|)$',
      }}
      placeholder='none'
      variant='filled'
      label='Ptz Priority'
      disabled={props.value === 'loading' || props.disabled === true}
      value={rawValue}
      onChange={onChange}
      onBlur={onSubmit}
      onKeyPress={e => e.key === 'Enter' && ptzInputRef.current?.blur()}
    />
  )
}

export default PtzPriorityInput
