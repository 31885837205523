// @ts-nocheck
import { NodeEditor, listenWindow } from '@obvious.tech/rete'

import {
  renderConnection,
  renderPathData,
  updateConnection,
  getMapItemRecursively,
} from './utils'
import { Picker } from './picker'
import { Flow, FlowParams } from './flow'
import './events'

function install(editor: NodeEditor) {
  editor.bind('connectionpath')
  editor.bind('connectiondrop')
  editor.bind('connectionpick')
  editor.bind('resetconnection')

  const picker = new Picker(editor)
  const flow = new Flow(picker)
  const socketsParams = new WeakMap<Element, FlowParams>()

  function pointerDown(this: HTMLElement, e: PointerEvent) {
    const flowParams = socketsParams.get(this)

    if (flowParams) {
      const { input, output } = flowParams

      editor.view.container.dispatchEvent(new PointerEvent('pointermove', e))
      e.preventDefault()
      e.stopPropagation()
      flow.start({ input, output }, input || output)
    }
  }

  function pointerUp(this: Window, e: PointerEvent) {
    const flowEl = document.elementFromPoint(e.clientX, e.clientY)

    if (picker.io) {
      editor.trigger('connectiondrop', picker.io)
    }
    if (flowEl) {
      flow.complete(getMapItemRecursively(socketsParams, flowEl) || {})
    }
  }

  editor.on('destroy', listenWindow('pointerup', pointerUp))

  editor.on('resetconnection', () => flow.complete())

  editor.on('rendersocket', ({ el, input, output }) => {
    socketsParams.set(el, { input, output })

    el.removeEventListener('pointerdown', pointerDown)
    el.addEventListener('pointerdown', pointerDown)
  })

  editor.on('renderconnection', ({ el, connection, points }) => {
    const d = renderPathData(editor, points, connection)

    renderConnection({ el, d, connection })
  })

  editor.on('updateconnection', ({ el, connection, points }) => {
    const d = renderPathData(editor, points, connection)

    updateConnection({ el, d })
  })
}

export default {
  name: 'connection',
  install,
}
export { defaultPath } from './utils'
