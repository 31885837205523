import React from 'react'

import { UserMenuOptions as UMO } from 'src/components/UserMenu'
import useDownloadZip from './useDownloadZip'

const DownloadZip = props => {
  const [downloadZip, loading] = useDownloadZip(props)

  return (
    <UMO.Item
      closeMenu={props.closeMenu}
      disabled={loading}
      onClick={downloadZip}
      title={loading ? 'Loading...' : 'Download Zip'}
    />
  )
}

export default DownloadZip
