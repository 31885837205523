import React from 'react'
import { Socket } from 'rete-react-render-plugin'
import InputSocketAndControllerComponent from './InputSocketAndControllerComponent'

class SwichInputComponent extends React.Component {
  render() {
    const { bindSocket, bindControl, inputs } = this.props

    function computeline(size, index) {
      if (size <= 3) return
      return index % 2 === 0 && index !== 0 ? <hr></hr> : ''
    }

    const defaultCaseInput = inputs.find(input => input.key === 'defaultValue')

    return (
      <>
        {inputs.reduce(
          (acc, input, index) =>
            input.key !== 'defaultValue'
              ? [
                  ...acc,
                  <div
                    className='input'
                    key={input.key}
                  >
                    {computeline(inputs.length, index)}
                    <InputSocketAndControllerComponent
                      input={input}
                      bindSocket={bindSocket}
                      bindControl={bindControl}
                    />
                  </div>,
                ]
              : acc,
          [],
        )}
        <hr></hr>
        <div
          className='input'
          key={defaultCaseInput.key}
        >
          <Socket
            type='input'
            socket={defaultCaseInput.socket}
            io={defaultCaseInput}
            innerRef={bindSocket}
          />
          <div className='input-title'>{defaultCaseInput.name}</div>
        </div>
      </>
    )
  }
}

export default SwichInputComponent
