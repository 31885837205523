import React from 'react'

import Section from './Section'
import * as theme from './theme'
import { TAlarmReportProps, TAgentReportProps } from './Provider'

export type TContentProps = {
  bgMain: string
  bgSecondary: string
} & TAgentReportProps &
  TAlarmReportProps

export type TEvenOddProps = {
  bgMain: string
  bgSecondary: string
}

export type TSectionContent = (props: TContentProps) => JSX.Element

export type TProps = {
  sections: Array<{
    title?: string
    Component: any
    variant?: 'CheckIn' | 'CheckOut'
  }>
  sectionProps: Omit<TContentProps, keyof TEvenOddProps>
  mode?: 'Alarm' | 'ActivityReport'
}

export default ({ sections, sectionProps, mode }: TProps): JSX.Element => {
  return (
    <>
      {sections.map(({ title, Component, variant }, index) => {
        const evenOrOdd = index % 2 === (mode === 'ActivityReport' ? 1 : 0)
        const bgMain = evenOrOdd
          ? theme.colors.sectionEvenBg
          : theme.colors.sectionOddBg
        const bgSecondary = !evenOrOdd
          ? theme.colors.sectionOddSecondaryBg
          : theme.colors.sectionEvenSecondaryBg

        return (
          <Section
            key={title}
            title={title}
            bgMain={bgMain}
            variant={variant}
          >
            <Component
              {...{
                ...sectionProps,
                variant,
                bgMain,
                bgSecondary,
              }}
            />
          </Section>
        )
      })}
    </>
  )
}
