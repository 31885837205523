import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Box, MenuItem } from '@material-ui/core'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { showNewBlueprintDialog } from 'src/redux/editor/editor.actions'
import { UserMenuOptions as UMO } from 'src/components/UserMenu'
import { editor } from 'src/adminPanel/Flow/components/Flow'
import { flowShortcuts } from 'src/shortcuts'
import { prettifyShortcut } from 'src/utils/str'

import {
  removeConnections,
  copyPasteSelectedNodes,
  deleteSelectedNodes,
} from '../helpers/ReteHelpers'
import downloadObject from '../helpers/downloadObject'

const FlowUserMenu = ({
  showDialogForChangeTitle,
  saveBlueprint,
  showDialogForSaveAs,
  openFileChooser,
  showSimulationDialog,
  centerBlueprint,
  toggleMinimap,
  zoom,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const menuItems = [
    {
      onClick: () => dispatch(showNewBlueprintDialog()),
      title: t('flow.menu.new_blueprint'),
      shortcut: prettifyShortcut(flowShortcuts.SHOW_NEW_BLUEPRINT_DIALOG),
    },
    {
      onClick: () => showDialogForChangeTitle(),
      title: t('flow.menu.rename'),
      shortcut: prettifyShortcut(flowShortcuts.RENAME),
    },
    {
      onClick: () => saveBlueprint(),
      title: t('flow.menu.save'),
      shortcut: prettifyShortcut(flowShortcuts.SAVE),
    },
    {
      onClick: () => showDialogForSaveAs(),
      title: t('flow.menu.save_as'),
      shortcut: prettifyShortcut(flowShortcuts.SAVE_AS),
    },
    {
      onClick: () => downloadObject(editor.toJSON()),
      title: t('flow.menu.export'),
      shortcut: prettifyShortcut(flowShortcuts.DOWNLOAD),
    },
    {
      onClick: () => openFileChooser(),
      title: t('flow.menu.import'),
      shortcut: prettifyShortcut(flowShortcuts.UPLOAD),
    },
    'DIVIDER',
    {
      onClick: () => showSimulationDialog(),
      title: t('flow.menu.simulate'),
      shortcut: prettifyShortcut(flowShortcuts.SIMULATE),
    },
    'DIVIDER',
    {
      onClick: () => editor.trigger('selectAllNodes'),
      title: t('flow.menu.select_all_nodes'),
      shortcut: prettifyShortcut(flowShortcuts.SELECT_ALL_NODES),
    },
    {
      onClick: () => editor.clear(),
      title: t('flow.menu.clear_editor'),
      shortcut: prettifyShortcut(flowShortcuts.CLEAN_BOARD),
    },
    {
      onClick: () => removeConnections(editor),
      title: t('flow.menu.delete_all_links'),
      shortcut: prettifyShortcut(flowShortcuts.DELETE_ALL_LINKS),
    },
    {
      onClick: () => removeConnections(editor, true),
      title: t('flow.menu.delete_selected_nodes_links'),
      shortcut: prettifyShortcut(flowShortcuts.DELETE_LINKS),
    },
    {
      onClick: () => copyPasteSelectedNodes(editor),
      title: t('flow.menu.duplicate_all'),
      shortcut: prettifyShortcut(flowShortcuts.DUPLICATE_NODES),
    },
    {
      onClick: () => deleteSelectedNodes(editor),
      title: t('flow.menu.delete_all_selected_nodes'),
      shortcut: prettifyShortcut(flowShortcuts.DELETE_NODES),
    },
    'DIVIDER',
    {
      onClick: () => editor.trigger('undo'),
      title: t('flow.menu.undo'),
      shortcut: prettifyShortcut(flowShortcuts.UNDO),
    },
    {
      onClick: () => editor.trigger('redo'),
      title: t('flow.menu.redo'),
      shortcut: prettifyShortcut(flowShortcuts.REDO),
    },
    'DIVIDER',
    'ZOOM',
    'DIVIDER',
    {
      onClick: centerBlueprint,
      title: t('flow.menu.center'),
      shortcut: prettifyShortcut(flowShortcuts.CENTER),
    },
    'DIVIDER',
    {
      onClick: () => toggleMinimap(true),
      title: t('flow.menu.toggle_minimap'),
      shortcut: prettifyShortcut(flowShortcuts.TOGGLE_MAP),
    },
  ]

  return (
    <UMO>
      {menuItems.map((item, i) => {
        if (typeof item === 'object') {
          return (
            <UMO.Item
              key={`${item.title}_${i}`}
              title={item.title}
              onClick={item.onClick}
              shortcut={item.shortcut}
            />
          )
        } else {
          switch (item) {
            case 'DIVIDER':
              return <UMO.Divider key={`divider_${i}`} />
            case 'ZOOM':
              return (
                <UMO.Item
                  title='Zoom'
                  shortcut='alt + -/+'
                  key={`${item.title}_${i}`}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    <MenuItem onClick={zoom.zoomOut}>
                      <RemoveIcon />
                    </MenuItem>
                    <Box
                      minWidth='40px'
                      textAlign='center'
                    >
                      {zoom.value} %
                    </Box>
                    <MenuItem onClick={zoom.zoomIn}>
                      <AddIcon />
                    </MenuItem>
                  </Box>
                </UMO.Item>
              )
            default:
              return null
          }
        }
      })}
    </UMO>
  )
}

export default FlowUserMenu
