import Rete from '@obvious.tech/rete'
import { sockets, definitions } from '../../..'
import { getSocketFromType } from '../../../Sockets/utils'
import SelectControl from '../../../Controls/SelectControl'
import CheckboxesModalControl from '../../../Controls/Modal/CheckboxesModal'
import {
  getPossibleComparisons,
  comparisonFunctions,
} from '../../../../../helpers/ComparisonHelper'

export default class ArrayFilterComponent extends Rete.Component {
  contextMenuPath = ['Arrays']
  task = {
    outputs: {
      oAct: 'option',
      res: 'output',
    },
  }

  checkboxes = []
  filters = []
  arrayInput = {
    inputKey: 'filterList',
    get: node => node.inputs.get(this.arrayInput.inputKey),
    changeName: (node, name = 'Entity[]') =>
      (this.arrayInput.get(node).name = name),
    reteInput: (inputName = 'Entity[]') =>
      new Rete.Input(this.arrayInput.inputKey, inputName, sockets.arraySocket),
  }

  constructor() {
    super('Filter')
  }

  drawInputs = node => {
    if (!node.data.inputs) return

    Object.keys(node.data.inputs)
      .filter(key => node.data.inputs[key])
      .forEach(key => {
        const filter = this.checkboxes.find(input => input.name === key)
        const existingInput = node.inputs.get(key)
        if (!filter || existingInput) return

        node.addInput(filter.input)
      })
  }

  getCheckboxes = (node, entity = null) => {
    const { properties = [] } = definitions.entities[entity || node.data.entity]
    return properties.map(p => {
      let inp
      const { args: comparisonFunctionArguments } = comparisonFunctions.equals(
        p.type,
        p.entity,
      )
      const inputSocket = comparisonFunctionArguments.length
        ? comparisonFunctionArguments.length > 1
          ? sockets[`${p.entity}CallbackSocket`]
          : getSocketFromType(p.type, p.entity, p.format)
        : sockets.anyTypeSocket
      inp = new Rete.Input(p.name, p.name, inputSocket)

      this.filters = getPossibleComparisons(p.type, p.entity).map(
        comparison => ({
          value: comparison,
          label: comparison,
        }),
      )

      inp.addControl(
        new SelectControl(
          this.editor,
          node,
          p.name,
          p.name,
          newComparisonKey => {
            node.data[p.name] = newComparisonKey
            const { args } = comparisonFunctions[newComparisonKey](
              p.type,
              p.entity,
            )

            inp.socket =
              sockets[
                args.length > 1
                  ? `${p.entity || p.type}CallbackSocket`
                  : args.length
                  ? `${args[0]}Socket`
                  : 'noTypeSocket'
              ]
            node.update()
            setTimeout(() => {
              this.editor.view.updateConnections({
                node,
              })
            }, 1)
          },
          this.filters,
          'exist',
        ),
      )

      return {
        key: p.name,
        name: p.name,
        input: inp,
      }
    })
  }

  builder(node) {
    node.addInput(this.arrayInput.reteInput('Entity[]'))

    node.data = {
      inputs: {},
      functions: {},
      ...node.data,
    }

    if (!node.data.entity) return

    this.building(node, node.data.entity)
    this.drawInputs(node)
  }

  handleInput =
    (editor, node) =>
    ({ name, input }, checked) => {
      if (checked && !node.inputs.get(input.key)) {
        node.addInput(input)
      } else if (!checked && node.inputs.get(input.key)) {
        input.connections.map(connection => editor.removeConnection(connection))
        node.removeInput(input)
      }
      node.update()
      setTimeout(() => {
        editor.view.updateConnections({
          node,
        })
      }, 1)
    }

  building(node, entity) {
    if (node.data.entity !== entity || (entity && !node.outputs.has('res'))) {
      node.data.entity = entity
      node.outputs.forEach(o => {
        if (o.key === 'oAct' || (entity && o.name === entity)) return
        o.connections.forEach(connection =>
          this.editor.removeConnection(connection),
        )
        node.removeOutput(o)
      })
      node.controls.forEach(c => {
        node.removeControl(c)
      })
      if (node.data.entity !== entity) {
        node.data.inputs = {}
      }
      if (entity) {
        this.checkboxes = this.getCheckboxes(node, node.data.entity)
        this.arrayInput.changeName(node, `${entity}[]`)
        node.addOutput(
          new Rete.Output('res', entity, sockets[entity + 'ArraySocket']),
        )
        this.drawInputs(node)
        node.addControl(
          new CheckboxesModalControl(
            this.editor,
            node,
            'inputs',
            this.handleInput(this.editor, node),
            this.checkboxes,
            'Inputs',
          ),
        )
      } else {
        this.arrayInput.changeName(node, 'Entity[]')
        node.inputs.forEach(i => {
          if (['iAct', this.arrayInput.inputKey].includes(i.key)) return
          i.connections.forEach(connection =>
            this.editor.removeConnection(connection),
          )
          node.removeInput(i)
        })
      }
    }

    setTimeout(() => {
      node && node.update()
      this.editor.view.updateConnections({
        node,
      })
    }, 1)
  }

  connected = connection => {
    if (connection.input.key === this.arrayInput.inputKey) {
      setTimeout(() => {
        this.building(
          connection.input.node,
          connection.output.socket.name.split('Array')[0],
        )
      })
    }
  }

  disconnected = connection => {
    if (connection.input.key === this.arrayInput.inputKey) {
      setTimeout(() => {
        this.building(connection.input.node)
      })
    }
  }
}
