import Rete from '@obvious.tech/rete'
import React, { Fragment } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ListSubheader,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = {
  dialogContainer: {
    width: '40wh',
  },
  methodContainer: {
    flex: 1,
  },
  endPointContainer: {
    flex: 4,
  },
  urlContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  parametersContainer: {
    width: '40wh',
  },
}

export default class HttpRequestsControl extends Rete.Control {
  component = ({
    label,
    title,
    values,
    handleChange,
    addParam,
    removeParam,
    handleDialog,
    open,
  }) => {
    return (
      <Fragment>
        <Button
          onClick={handleDialog(true)}
          color='primary'
          variant='outlined'
        >
          {label}
        </Button>
        <Dialog
          onClose={handleDialog(false)}
          open={open}
          fullWidth
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <div style={styles.urlContainer}>
              <div style={styles.methodContainer}>
                <FormControl
                  variant='outlined'
                  style={{
                    minWidth: 100,
                  }}
                >
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Method :
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={values.method}
                    onChange={e => handleChange('method')(e.target.value)}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'GET'}>GET</MenuItem>
                    <MenuItem value={'POST'}>POST</MenuItem>
                    <MenuItem value={'PATCH'}>PATCH</MenuItem>
                    <MenuItem value={'PUT'}>PUT</MenuItem>
                    <MenuItem value={'DELETE'}>DELETE</MenuItem>
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div style={styles.endPointContainer}>
                <TextField
                  error={false}
                  onChange={e => handleChange('endpoint')(e.target.value)}
                  id='standard-error-helper-text'
                  label='Endpoint'
                  defaultValue={values.endpoint}
                />
              </div>
            </div>
            <div style={styles.parametersContainer}>
              <List
                style={{
                  margin: 4,
                  flex: 1,
                }}
              >
                <ListSubheader>{'Headers :'}</ListSubheader>
                {values.headers &&
                  values.headers.map((params, i) => (
                    <ListItem key={`${params.key}_${i}`}>
                      <div
                        style={{
                          margin: 4,
                          flex: 1,
                        }}
                      >
                        <TextField
                          onChange={e =>
                            handleChange('headers')(
                              {
                                key: e.target.value,
                              },
                              i,
                            )
                          }
                          error={false}
                          id='standard-error-helper-text'
                          label='Key'
                          defaultValue={params.key}
                        />
                      </div>
                      <div
                        style={{
                          margin: 4,
                          flex: 1,
                        }}
                      >
                        <TextField
                          onChange={e =>
                            handleChange('headers')(
                              {
                                value: e.target.value,
                              },
                              i,
                            )
                          }
                          error={false}
                          id='standard-error-helper-text'
                          label='Value'
                          defaultValue={params.value}
                        />
                      </div>
                      <div>
                        <IconButton onClick={() => removeParam(i)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </ListItem>
                  ))}
                <ListItem>
                  <Button onClick={addParam}>ADD</Button>
                </ListItem>
              </List>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }

  constructor(emitter, node, title, label, optionKey) {
    super(title)
    this.title = title
    this.emitter = emitter

    const initial = node.data.requestData || {
      method: '',
      endpoint: '',
      headers: [
        {
          key: '',
          value: '',
        },
      ],
    }

    this.props = {
      title,
      label,
      values: node.data.requestData || initial,
      open: node.data.open || false,
      addParam: () => {
        const newValues = {
          ...this.props.values,
          headers: [
            ...this.props.values.headers,
            {
              key: '',
              value: '',
            },
          ],
        }
        this.props.values = newValues
        this.putData('requestData', newValues)
        this.update()
      },
      removeParam: i => {
        const newValues = {
          ...this.props.values,
          headers: this.props.values.headers.filter((_elem, idx) => idx !== i),
        }
        this.props.values = newValues
        this.putData('requestData', newValues)
        this.update()
      },
      handleDialog: value => () => {
        this.props.open = value
        this.putData('open', value)
        this.update()
      },
      handleChange: key => (value, i) => {
        let newValues
        if (key === 'headers') {
          newValues = {
            ...this.props.values,
            headers: this.props.values.headers.map((header, j) => {
              return j === i
                ? {
                    ...header,
                    ...value,
                  }
                : header
            }),
          }
        } else {
          newValues = {
            ...this.props.values,
            [key]: value,
          }
        }
        this.props.values = newValues
        this.putData('requestData', newValues)
      },
    }
  }
}
