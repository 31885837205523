import React, { useMemo } from 'react'
import qs from 'query-string'
import { useHistory } from 'react-router-dom'

import { Calendar, DateObject } from 'react-multi-date-picker'

export const CustomDatePicker = (): JSX.Element => {
  const history = useHistory()

  const dateInterval = useMemo(() => {
    const { dateFrom, dateTo } = qs.parse(history.location.search)

    const dayStart = new DateObject(dateFrom)

    const dayEnd = new DateObject(dateTo)
    dayEnd.hour = 0
    dayEnd.minute = 0
    dayEnd.second = 0
    dayEnd.millisecond = 0

    return [dayStart, dayEnd]
  }, [history.location.search])

  const handleDateRangeChange = (newDateRange: DateObject[]): void => {
    if (newDateRange.length !== 2) return
    const [dateFrom, dateTo] = newDateRange
    dateFrom.hour = 0
    dateFrom.minute = 0
    dateFrom.second = 0
    dateFrom.millisecond = 0

    dateTo.hour = 0
    dateTo.minute = 0
    dateTo.second = 0
    dateTo.millisecond = 0

    dateTo.day += 1
    dateTo.millisecond -= 1

    const searchParams = new URLSearchParams()

    if (dateFrom !== undefined && dateTo !== undefined) {
      searchParams.set('dateFrom', dateFrom.toDate().toISOString())
      searchParams.set('dateTo', dateTo.toDate().toISOString())
    }

    history.replace({
      search: searchParams.toString(),
    })
  }

  return (
    <Calendar
      value={dateInterval}
      onChange={handleDateRangeChange}
      range
      rangeHover
    />
  )
}
