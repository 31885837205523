import React from 'react'
import { Typography } from '@material-ui/core'

import useLogs, {
  TUseLogsResult,
} from 'src/parser/entities/Alarm/Preview/Provider/useLogs'
import useUserTasks, {
  TUseUserTasksResult,
} from 'src/parser/entities/Alarm/Preview/Provider/useUserTasks'
import useAssignedPassport, {
  TUseAssignedPassportResult,
} from 'src/parser/entities/Alarm/Preview/Provider/useAssignedPassport'
import useAttachments, {
  TUseAttachmentsResult,
} from 'src/parser/entities/Alarm/Preview/Provider/useAttachments'
import { IAlarm, IAgentActivityReport } from '@obvious.tech/constellation'
import useAdditionalDetails, {
  TUseAdditionalDetailsResult,
} from 'src/parser/entities/Alarm/Preview/Provider/useAdditionalDetails'
import useAdditionalDetailsCameras, {
  TUseAdditionalDetailsCamerasResult,
} from 'src/parser/entities/Alarm/Preview/Provider/useAdditionalDetailsCameras'
import Loadable, { TLoadableGetData } from 'src/utils/Loadable'
import useGuardTours, {
  TUseGuardToursResults,
} from 'src/parser/entities/AgentActivityReport/ExternalPDF/Provider/useGuardTours'
import { TEntity } from 'src/utils/api'
import { Alert, AlertTitle } from '@material-ui/lab'
import PDFLoading from '../PDFLoading'
import useAgentAssignedPassport, {
  TUseAgentAssignedPassportResult,
} from 'src/parser/entities/AgentActivityReport/ExternalPDF/Provider/useAssignedPassport'
import { AlarmMapProvider, AgentActivityMapProvider } from './MapProvider'

export type TAgentReportProps = {
  map: any
  loading: boolean
  entity: TEntity<IAgentActivityReport>
  assignedPassport: TLoadableGetData<TUseAgentAssignedPassportResult>
  guardTours: TLoadableGetData<TUseGuardToursResults>
}

export type TAlarmReportProps = {
  map: any
  loading: boolean
  entity: TEntity<IAlarm>
  additionalDetails: TLoadableGetData<TUseAdditionalDetailsResult>
  cameras: TLoadableGetData<TUseAdditionalDetailsCamerasResult>
  logs: TLoadableGetData<TUseLogsResult>
  tasks: TLoadableGetData<TUseUserTasksResult>
  assignedPassport: TLoadableGetData<TUseAssignedPassportResult>
  attachments: TLoadableGetData<TUseAttachmentsResult>
}

type TAlarmLoadedProps = React.PropsWithChildren<TAlarmReportProps>
type TAgentLoadedProps = React.PropsWithChildren<TAgentReportProps>

const Loaded = ({
  loading,
  children,
  ...rest
}: TAgentLoadedProps | TAlarmLoadedProps): JSX.Element => {
  return loading ? (
    <PDFLoading />
  ) : (
    /* @ts-expect-error */
    React.cloneElement(children, { ...rest })
  )
}

export type TAgentProps = React.PropsWithChildren<{
  entity: TEntity<IAgentActivityReport>
  authorization?: string
}>

export type TAlarmProps = React.PropsWithChildren<{
  entity: TEntity<IAlarm>
  authorization?: string
}>

const FailedReport = (loadables: any) => {
  return (
    <Alert severity='error'>
      <AlertTitle>Failed to create PDF Report</AlertTitle>
      {loadables.error.map(it => {
        const [from, error] = it
        const errors: Array<{ message: string }> = Array.isArray(error)
          ? error
          : [error]

        return (
          <>
            {errors.map(error => (
              <Typography>{`${from}: ${error?.message}`}</Typography>
            ))}
          </>
        )
      })}
    </Alert>
  )
}

const Provider = ({
  entity,
  authorization,
  loadables,
  children,
  MapProvider,
}): JSX.Element => {
  return (
    <MapProvider
      loading={false}
      entity={entity}
      authorization={authorization}
    >
      <Loaded
        map={undefined}
        entity={entity}
        {...loadables.data}
      >
        {children}
      </Loaded>
    </MapProvider>
  )
}

export const AlarmProvider = ({
  entity,
  authorization,
  children,
}: TAlarmProps): React.ReactElement => {
  const alarmLoadables = {
    additionalDetails: useAdditionalDetails({
      alarm: entity,
    }),
    cameras: useAdditionalDetailsCameras({
      alarm: entity,
      authorization,
    }),
    logs: useLogs({
      alarm: entity,
      authorization,
    }),
    tasks: useUserTasks({
      alarm: entity,
      authorization,
    }),
    assignedPassport: useAssignedPassport({
      entity,
      authorization,
    }),
    attachments: useAttachments({
      alarm: entity,
      authorization,
    }),
  }

  const loadables = Loadable.all(alarmLoadables)

  if (Loadable.isFailure(loadables)) {
    return <FailedReport loadables={loadables} />
  }

  if (loadables.loading || !loadables.loaded) return <PDFLoading />

  return (
    <Provider
      entity={entity}
      authorization={authorization}
      loadables={loadables}
      MapProvider={AlarmMapProvider}
    >
      {children}
    </Provider>
  )
}

export const AgentActivityProvider = ({
  entity,
  authorization,
  children,
}: TAgentProps): React.ReactElement => {
  const agentReportLoadables = {
    assignedPassport: useAgentAssignedPassport({
      entity,
      authorization,
    }),
    guardTours: useGuardTours({
      entity,
      authorization,
    }),
  }
  const loadables = Loadable.all(agentReportLoadables)

  if (Loadable.isFailure(loadables)) {
    return <FailedReport loadables={loadables} />
  }

  if (loadables.loading || !loadables.loaded) return <PDFLoading />

  return (
    <Provider
      entity={entity}
      authorization={authorization}
      loadables={loadables}
      MapProvider={AgentActivityMapProvider}
    >
      {children}
    </Provider>
  )
}
