export class FileHelper {
  static fileToString(file) {
    const fr = new FileReader()

    return new Promise((resolve, reject) => {
      fr.onerror = () => {
        fr.abort()
        reject('error')
      }

      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsText(file)
    })
  }

  static fileToUrl(file) {
    return URL.createObjectURL(file)
  }

  static downloadFile(file, name) {
    const url = this.fileToUrl(file)
    const a = document.createElement('a')
    a.setAttribute('style', 'display : none')
    a.download = name
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }
}
