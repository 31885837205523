// @ts-nocheck
import React from 'react'
import { ToolbarProps } from 'react-admin'
import { makeStyles } from '@material-ui/styles'

import FormToolbar from 'src/adminPanel/screens/Actions/FormToolbar/FormToolbar'

const useStyles = makeStyles(() => ({
  toolbar: {
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
}))

const EditFormToolbar = (props: ToolbarProps): JSX.Element => {
  const classes = useStyles()
  return (
    <FormToolbar
      withSave={false}
      {...props}
      className={classes.toolbar}
    />
  )
}

export default EditFormToolbar
