export const simulationDialog = state => state.editor?.simulationDialogVisible

export const openedBlueprints = state => state.editor?.openedBlueprints

export const currentBlueprint = state => state.editor?.currentBlueprint

export const toCloseDiagramId = state => state.editor?.toCloseDiagramId

export const confDialogVisible = state => state.editor?.confDialogVisible

export const fetchedBlueprints = state => state.editor?.fetchedBlueprints
