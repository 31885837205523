// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'
import {
  RedirectionSideEffect,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useCreate,
  useDataProvider,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import qs from 'query-string'

import useErrorFetch from 'src/hooks/useErrorFetch'
import { TState } from 'src/redux/microservices/types'
import { TValues } from './CreateRole'

const useToolbarStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
})

type TToolbarProps = ToolbarProps & {
  width?: Breakpoint
}

const CreateRoleToolbar = (props: TToolbarProps) => {
  const { basePath } = props

  const resource = useResourceContext(props)
  const toolbarClasses = useToolbarStyles()
  const dataProvider = useDataProvider()
  const fetchError = useErrorFetch()
  const [create] = useCreate(resource)
  const redirectTo = useRedirect()
  const notify = useNotify()

  const submitting = useSelector((state: TState) => state.admin.loading > 0)

  const onSuccessActions = ({ redirect, newRecord }) => {
    notify('ra.notification.created', 'info', {
      smart_count: 1,
    })
    redirectTo(redirect, basePath, newRecord.id, newRecord)
  }

  const createDefaultRole = ({ values, redirect }) => {
    create(
      {
        payload: {
          data: values,
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          onSuccessActions({
            redirect,
            newRecord,
          })
        },
        onFailure: e => {
          fetchError(e)
        },
      },
    )
  }

  const createFromRoleTemplate = async ({
    values,
    redirect,
    roleTemplateId,
    group,
  }) => {
    try {
      const { data } = await dataProvider.create(
        `/authentication/api/RoleTemplate/${roleTemplateId}/Role?${qs.stringify(
          {
            group,
          },
        )}`,
        {
          data: values,
        },
      )

      onSuccessActions({
        redirect,
        newRecord: data,
      })
    } catch (e) {
      fetchError(e)
    }
  }

  const handleSave = async (
    values: object,
    redirect: RedirectionSideEffect,
  ) => {
    const { roleTemplate, roleTemplateCheckbox, group, ...roleValues } =
      values as TValues

    roleTemplateCheckbox
      ? createFromRoleTemplate({
          values: roleValues,
          redirect,
          roleTemplateId: roleTemplate?.id,
          group,
        })
      : createDefaultRole({
          values: roleValues,
          redirect,
        })
  }

  return (
    <Toolbar
      classes={toolbarClasses}
      {...props}
      saving={submitting}
    >
      <SaveButton
        label='Create'
        onSave={handleSave}
      />
    </Toolbar>
  )
}

export default CreateRoleToolbar
