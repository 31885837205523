// @ts-nocheck
import { TEntityOverride } from 'src/types'

import EditFormToolbar from './EditFormToolbar/EditFormToolbar'
import FileIdField from './List/downloadTemplate'
import TemplateListActions from './List/ListActions'

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    displayedActions: TemplateListActions,
    properties: {
      fileId: {
        displayedComponent: FileIdField,
      },
    },
  },
  create: {
    disabled: true,
  },
  edit: {
    displayedFormToolbar: EditFormToolbar,
    properties: {
      groups: {
        readOnly: true,
      },
    },
  },
}

export default override
