// @ts-nocheck
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Box,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'
import { Record, useGetList, useTranslate } from 'react-admin'

import { API_ENDPOINTS } from 'src/api'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const RoleTemplateSelect = ({ input, meta }: FieldRenderProps<Record>) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { data, loading } = useGetList(API_ENDPOINTS.ROLE_TEMPLATE, {})

  const handleHelperText = () => {
    const { error } = meta

    if (!error) return ''

    return translate(error?.message ? error.message : error)
  }

  return (
    <Autocomplete
      options={Object.values(data ?? {})}
      className={classes.input}
      value={input.value || null}
      onChange={(_, record) => input.onChange(record)}
      onBlur={input.onBlur}
      loading={loading}
      loadingText='Loading...'
      getOptionLabel={record => record.name}
      renderOption={record => (
        <Box>
          <Typography>{record.name}</Typography>
          {record.description && (
            <Typography variant='body2'>{record.description}</Typography>
          )}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          name={input.name}
          helperText={handleHelperText()}
          label='Role template'
        />
      )}
    />
  )
}

export default RoleTemplateSelect
