// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useDataProvider, useNotify, Record as RaRecord } from 'react-admin'
import { useSelector } from 'react-redux'

import { TRootState } from 'src/redux'
import useDebounce from 'src/utils/hooks/useDebounce'

import AutocompleteInput from './Autocomplete/AutocompleteInput'
import StringInput from './StringInput'
import { IInputProps } from 'src/types'

const ReferencedInput = ({
  property,
  name,
  initialValue,
  id,
  onChange,
  value,
  getOptionLabel,
  error,
  classes,
  ...props
}: IInputProps<string>) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const apiMicroServices = useSelector((state: TRootState) => state.api)

  const [, , microservice] = property.referenceTo.split('/')
  const { referencedField, referenceTo } = property

  const [loading, setLoading] = useState<boolean>(false)
  const [currentLoading, setCurrentLoading] = useState<boolean>(true)

  const [search, setSearch] = useState<string>(initialValue)
  const { debounce } = useDebounce()
  const [data, setData] = useState<RaRecord[] | null>(null)

  const fetchData = React.useCallback(
    async (search: string) => {
      setLoading(true)
      const result = await dataProvider.getManyReference(
        referenceTo,
        {
          pagination: {},
          sort: {
            field: referencedField ?? 'id',
            order: 'ASC',
          },
          filter: {
            [referencedField ?? 'id']: search ?? '',
          },
        },
        {
          field: referencedField ?? 'id',
          order: 'ASC',
        },
      )
      setLoading(false)

      return result?.data
    },
    [dataProvider, referencedField, referenceTo],
  )

  useEffect(() => {
    if (!referenceTo) return
    ;(async () => {
      try {
        setCurrentLoading(true)
        const data =
          initialValue != null
            ? (
                await dataProvider.getOne(referenceTo, {
                  id: initialValue,
                })
              )?.data
            : null
        const search =
          (data?.[referencedField ?? 'id'] as string | undefined) ?? ''
        setData(await fetchData(search))
        setSearch(search)
      } catch (e) {
        notify(`${name} - ${e}`, 'warning')
      } finally {
        setCurrentLoading(false)
      }
    })()
  }, [
    initialValue,
    referenceTo,
    referencedField,
    dataProvider,
    name,
    notify,
    fetchData,
  ])

  if (!Object.keys(apiMicroServices).includes(microservice)) {
    return (
      <StringInput
        property={property}
        name={name}
        onChange={onChange}
        value={value}
        {...props}
      />
    )
  }

  const onChangeValue = (e?: string) => {
    onChange(e ?? '')
  }

  return (
    <span>
      <AutocompleteInput
        className={classes}
        freeSolo
        referencedField={property.referencedField ?? 'id'}
        choices={data != null ? data : []}
        input={search}
        onChange={onChangeValue}
        onInputChange={(value: string) => {
          setSearch(value)
          debounce(fetchData)(value).then(setData)
        }}
        getOptionLabel={getOptionLabel}
        disabled={currentLoading}
        loading={currentLoading}
        refreshing={loading}
        label={property.displayedName ?? name}
        placeholder={name}
        onChangeValue={property.onChangeValue}
        error={error}
      />
      {currentLoading && <LinearProgress />}
    </span>
  )
}

export default ReferencedInput
