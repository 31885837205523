import React from 'react'
import { connect } from 'react-redux'
import inputs from 'src/components/inputs'
import { useTranslate } from 'react-admin'
import sanitizeFormProps from './sanitizeRestProps'

const GenericInput = ({
  property,
  name,
  definitions = {},
  microservices = {},
  onChange,
  value,
  ...rest
}) => {
  const translate = useTranslate()
  const { enums = {}, objects = {} } = definitions
  const { type, $ref, id, displayedName } = property

  const inputProps = {
    ...sanitizeFormProps(rest),
    name,
    value,
    onChange,
    property: {
      ...property,
      displayedName: translate(displayedName || name || id || ''),
    },
  }
  if (property.basedOn) {
    return React.createElement(inputs['basedOn'], inputProps)
  }
  if (property.displayedComponent) {
    return React.createElement(property.displayedComponent, inputProps)
  }
  if (property.referenceTo) {
    const referenceTo = property.referenceTo.split('/')
    if (microservices?.[referenceTo?.[0]]?.includes(referenceTo?.[2])) {
      return React.createElement(
        inputs['referenceTo'][property.type],
        inputProps,
      )
    }
  }
  if ($ref) {
    const reference = $ref.split('/')[2]
    if (!type || !inputs[type]) {
      if (!!objects[reference]) {
        return React.createElement(inputs['object'], {
          ...inputProps,
          property: { ...inputProps.property, ...objects[reference] },
        })
      }
      if (!!enums[reference]) {
        return React.createElement(inputs['enum'], {
          ...inputProps,
          property: enums[reference],
        })
      }
    }
  }
  if (inputs[type]) return React.createElement(inputs[type], inputProps)
  if (property.type === 'integer') {
    return React.createElement(inputs['number'], inputProps)
  }
  console.log(`property ${name} is not defined`)
  return <span key={name} />
}

const mapStateToProps = state => {
  return {
    definitions: state.definitions,
    microservices: state.microservices,
  }
}

export default connect(mapStateToProps, null)(GenericInput)
