import React from 'react'
import { useInput } from 'react-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const EntityBehaviourInput = ({ property, name, getGroups, ...rest }) => {
  const classes = useStyles()

  const {
    input: { onChange, value },
  } = useInput({
    source: name,
    ...rest,
  })
  const entities = ['Camera', 'Agent', 'Alarm']
  return (
    <Autocomplete
      id='multiple-limit-tags'
      className={classes.input}
      options={entities}
      onChange={(e, value) => {
        onChange(value)
      }}
      value={value || ''}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={property.displayedName ?? name}
            onChange={e => {
              onChange(e.target.value)
            }}
            placeholder={'Entity behaviour'}
          />
        )
      }}
    />
  )
}

export default EntityBehaviourInput
