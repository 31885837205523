// @ts-nocheck
import React from 'react'
import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ spinning }: any) => ({
    opacity: spinning ? 0.8 : 1,
    filter: spinning ? 'blur(2px)' : 'blur(0)',
    pointerEvents: spinning ? 'none' : 'all',
    transition: `opacity ${theme.transitions.duration.shortest}ms`,
  }),
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    // to center the material ui progress
    marginTop: -12,
    marginLeft: -12,
  },
}))

type TSpinProps = {
  spinning: boolean
  children: React.ReactChild | React.ReactChildren
}

const Spin = ({ spinning, children }: TSpinProps) => {
  const classes = useStyles({
    spinning,
  })

  return (
    <Box position='relative'>
      {spinning && <CircularProgress className={classes.loader} />}
      <Box className={classes.container}>{children}</Box>
    </Box>
  )
}

export default Spin
