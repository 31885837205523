// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useDataProvider, Record, useEditContext, useQuery } from 'react-admin'
import { useSelector } from 'react-redux'
import {
  Box,
  Divider,
  Typography,
  Theme,
  makeStyles,
  capitalize,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'

import { TRootState } from 'src/redux'
import { DataGridContainer, Table } from 'src/components/DataGrid'
import useErrorFetch from 'src/hooks/useErrorFetch'
import { Permissions } from 'src/components/Authorizations'
import {
  parseDefaultMicroservices,
  mapPermissionForGroup,
  mapAuthorizations,
} from './Authorizations.utils'
import Spin from 'src/components/Spin'
import { API_ENDPOINTS } from 'src/api'
import {
  TAuthorizationData,
  TAuthorizationType,
} from 'src/api/authorizationTypes'
import PtzPriorityAuthorization from 'src/components/Authorizations/PtzPriorityAuthorization'
import ScreenshotAuthorization from 'src/components/Authorizations/ScreenshotAuthorization'
import GroupsAutocomplete from 'src/components/Authorizations/GroupsAutocomplete'
import AuthorizationBox from 'src/components/Authorizations/AuthorizationBox'

import useFetchRolePermissions from './useFetchRolePermissions'
import Source from './Source'

const useStyles = makeStyles((theme: Theme) => ({
  rolesCheckbox: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  table: {
    '& tbody tr td': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

export type TAuthorizationPermission = {
  microservice: string
  entity: string
  permissionLevels: {
    user: number | null
    role: number | null
  }
  source: string[]
}

const Authorizations = ({ id }) => {
  const editContext = useEditContext()
  const dataProvider = useDataProvider()
  const fetchError = useErrorFetch()

  const fetchPassportPermissions = useQuery({
    type: 'getList',
    resource: `${editContext.resource}/${id}/Authorization`,
    payload: {},
  })
  const fetchRolePermissions = useFetchRolePermissions(editContext)

  const classes = useStyles()

  const columns = [
    {
      key: 'microservice',
      label: 'Service',
      render: ({ microservice }) => capitalize(microservice),
    },
    {
      key: 'entity',
      label: 'Entity',
    },
    {
      key: 'permissionLevels',
      label: 'Permissions',
      render: ({ permissionLevels, entity }) => (
        <Permissions
          permissionLevels={permissionLevels}
          entity={entity}
          onClick={handleAuthorization}
          disabled={!currentGroup}
        />
      ),
    },
    {
      key: 'source',
      label: 'Source',
      render: ({ source }) => (
        <Source
          source={source}
          rolePermissions={rolesPermissions}
        />
      ),
    },
  ]

  const [authorizations, setAuthorizations] = useState<
    TAuthorizationPermission[]
  >([])
  const [currentGroup, setCurrentGroup] = useState<Record | null>(null)
  const [rolesPermissions, setRolesPermissions] = useState<Record[] | null>(
    null,
  )
  const [passportPermissions, setPassportPermissions] = useState<
    Record[] | null
  >(null)
  const [showRolesCheckbox, setShowRolesCheckbox] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')

  const [screenshotAuthorization, setScreenshotAuthorization] = useState<
    boolean | null | 'loading'
  >('loading')
  const [ptzPriorityAuthorization, setPtzPriorityAuthorization] = useState<
    number | null | 'loading'
  >('loading')

  const microservices = useSelector((state: TRootState) => state.microservices)

  useEffect(() => {
    if (!fetchPassportPermissions.loaded || fetchPassportPermissions.error) {
      return
    }

    const permissions = fetchPassportPermissions.data.map(permission => ({
      ...permission,
      source: 'User',
    }))
    setPassportPermissions(permissions)
  }, [
    fetchPassportPermissions.loaded,
    fetchPassportPermissions.data,
    fetchPassportPermissions.error,
  ])

  useEffect(() => {
    if (!fetchRolePermissions.loaded || fetchRolePermissions.error != null)
      return

    setRolesPermissions(fetchRolePermissions.data)
  }, [
    fetchRolePermissions.loaded,
    fetchRolePermissions.data,
    fetchRolePermissions.error,
  ])

  useEffect(() => {
    if (!microservices) return

    setAuthorizations(parseDefaultMicroservices(microservices))
  }, [microservices])

  useEffect(() => {
    if (!currentGroup) {
      setAuthorizations(parseDefaultMicroservices(microservices))
      return
    }

    parseGroupAuthorizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup, showRolesCheckbox])

  useEffect(() => {
    if (!rolesPermissions?.length) return

    parseGroupAuthorizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesPermissions])

  useEffect(() => {
    if (!currentGroup) return

    parseGroupAuthorizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passportPermissions])

  const filteredEntities = () => {
    const query = search.trim().toLocaleLowerCase()
    return query
      ? authorizations.filter(({ entity }) =>
          entity.trim().toLowerCase().includes(query),
        )
      : authorizations
  }

  const createAuthorization = async <
    T extends TAuthorizationType = TAuthorizationType,
  >(
    type: T,
    data: TAuthorizationData<T>,
  ) => {
    if (passportPermissions === null) return
    try {
      const res = await dataProvider.create(
        `${editContext.resource}/${editContext.record?.id}/Authorization`,
        {
          data: {
            type,
            group: currentGroup?.name,
            data,
          },
        },
      )

      setPassportPermissions([
        ...passportPermissions,
        {
          ...res.data,
          source: 'User',
        },
      ])
    } catch (e) {
      fetchError(e)
    }
  }

  const editAuthorization = async <
    T extends TAuthorizationType = TAuthorizationType,
  >(
    permission: { id: string; type: T },
    data: TAuthorizationData<T>,
  ) => {
    if (passportPermissions === null) return

    try {
      const res = await dataProvider.update(API_ENDPOINTS.AUTHORIZATION, {
        id: permission.id,
        data: {
          data,
        },
        previousData: permission,
      })

      const updatedRolesPermissions = passportPermissions.map(permission =>
        permission.id === res.data.id
          ? {
              ...permission,
              ...res.data,
            }
          : permission,
      )
      setPassportPermissions(updatedRolesPermissions)
    } catch (e) {
      fetchError(e)
    }
  }

  const deleteAuthorization = async (id: string | number) => {
    if (passportPermissions === null) return

    try {
      await dataProvider.delete(API_ENDPOINTS.AUTHORIZATION, {
        id,
        previousData: {
          id,
        },
      })

      const updatedRolesPermissions = passportPermissions.filter(
        permission => permission.id !== id,
      )
      setPassportPermissions(updatedRolesPermissions)
    } catch (e) {
      fetchError(e)
    }
  }

  const handleAuthorization = (level: null | number, entity: string) => {
    if (!currentGroup || passportPermissions == null) return

    const existentPassportAuthorization = passportPermissions.find(
      ({ group, data }) =>
        group === currentGroup?.name && data.entity === entity,
    )

    if (existentPassportAuthorization && level === null) {
      return deleteAuthorization(existentPassportAuthorization.id)
    }

    if (level === null) return

    if (
      existentPassportAuthorization &&
      level !== existentPassportAuthorization.level
    ) {
      return editAuthorization(existentPassportAuthorization, {
        entity,
        level,
      })
    }

    if (!existentPassportAuthorization) {
      return createAuthorization('EntityGroupAuthorization', {
        entity,
        level,
      })
    }
  }

  const genericChangeAuthorization = <
    T extends Exclude<TAuthorizationType, 'EntityGroupAuthorization'> = Exclude<
      TAuthorizationType,
      'EntityGroupAuthorization'
    >,
  >(options: {
    type: T
    data: TAuthorizationData<T>
  }) => {
    if (!currentGroup || passportPermissions == null) return

    const existentAuthorization = passportPermissions.find(
      ({ group, type }) =>
        group === currentGroup?.name && type === options.type,
    )

    if (!existentAuthorization) {
      return createAuthorization(options.type, options.data)
    }

    return editAuthorization(existentAuthorization, options.data)
  }

  const genericDeleteAuthorization = <
    T extends Exclude<TAuthorizationType, 'EntityGroupAuthorization'> = Exclude<
      TAuthorizationType,
      'EntityGroupAuthorization'
    >,
  >(options: {
    type: T
  }) => {
    if (!currentGroup || passportPermissions == null) return

    const existentAuthorization = passportPermissions.find(
      ({ group, type }) =>
        group === currentGroup?.name && type === options.type,
    )

    if (!existentAuthorization) return

    deleteAuthorization(existentAuthorization.id)
  }

  const parseGroupAuthorizations = () => {
    if (!currentGroup || passportPermissions == null) return
    const roleGroupPermissions = showRolesCheckbox
      ? mapPermissionForGroup(currentGroup.name, rolesPermissions ?? [])
      : []
    const passportGroupPermissions = mapPermissionForGroup(
      currentGroup.name,
      passportPermissions,
    )

    if (
      roleGroupPermissions.length === 0 &&
      passportGroupPermissions.length === 0
    ) {
      setAuthorizations(parseDefaultMicroservices(microservices))
      return
    }

    const authorizationsWithPermissions = mapAuthorizations(
      authorizations,
      roleGroupPermissions,
      passportGroupPermissions,
    )

    setAuthorizations(authorizationsWithPermissions)
  }

  useEffect(() => {
    if (passportPermissions === null) return

    const netPtzPriority =
      passportPermissions.find(auth => auth.type === 'PtzPriorityAuthorization')
        ?.data?.weight ?? null
    const netScreenshot =
      passportPermissions.find(auth => auth.type === 'ScreenshotAuthorization')
        ?.data?.enabled ?? null

    setPtzPriorityAuthorization(netPtzPriority)
    setScreenshotAuthorization(netScreenshot)
  }, [passportPermissions])

  const changeScreenshotAuthorization = (enabled: true | null) => {
    if (screenshotAuthorization === 'loading') return

    setScreenshotAuthorization('loading')

    if (enabled === null) {
      genericDeleteAuthorization({
        type: 'ScreenshotAuthorization',
      })
      return
    }

    genericChangeAuthorization({
      type: 'ScreenshotAuthorization',
      data: {
        enabled,
      },
    })
  }

  const changePtzPriorityAuthorization = (weight: number | null) => {
    if (ptzPriorityAuthorization === 'loading') return

    setPtzPriorityAuthorization('loading')

    if (weight === null) {
      genericDeleteAuthorization({
        type: 'PtzPriorityAuthorization',
      })
      return
    }
    genericChangeAuthorization({
      type: 'PtzPriorityAuthorization',
      data: {
        weight,
      },
    })
  }

  return (
    <Box>
      <Box p={2}>
        <Typography variant='h5'>Authorizations</Typography>
      </Box>
      <Divider />
      <AuthorizationBox>
        <Box
          p={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <GroupsAutocomplete
            currentGroup={currentGroup}
            setCurrentGroup={setCurrentGroup}
            rolePermissions={rolesPermissions}
            passportPermissions={passportPermissions}
          />
          <Box
            display='flex'
            alignItems='center'
          >
            <FormControlLabel
              className={classes.rolesCheckbox}
              label='Show roles'
              checked={showRolesCheckbox}
              onChange={(_, checked) => setShowRolesCheckbox(checked)}
              control={<Checkbox color='primary' />}
            />
            <TextField
              variant='outlined'
              label='Search'
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Spin
          spinning={
            fetchPassportPermissions.loading || fetchRolePermissions.loading
          }
        >
          <DataGridContainer fixedFirstCol={false}>
            <Table
              columns={columns}
              rowData={filteredEntities()}
              className={classes.table}
            />
          </DataGridContainer>
        </Spin>
      </AuthorizationBox>

      <AuthorizationBox>
        <Box p={2}>
          <Typography variant='h5'>Other Authorizations</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <ScreenshotAuthorization
            disabled={currentGroup === null || passportPermissions === null}
            value={screenshotAuthorization}
            onSubmit={changeScreenshotAuthorization}
          />
          <br />
          <PtzPriorityAuthorization
            disabled={currentGroup === null || passportPermissions === null}
            value={ptzPriorityAuthorization}
            onSubmit={changePtzPriorityAuthorization}
          />
        </Box>
      </AuthorizationBox>
    </Box>
  )
}

export default Authorizations
