import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { cloneElement } from 'react'
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: 0,
    minHeight: 'unset',
    alignSelf: 'center',
  },
}))

const ListActions = ({
  filters,
  maxResults,
  hasCreate = true,
  hasExport = true,
  children,
}) => {
  const classes = useStyles()

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext()
  return (
    <TopToolbar className={classes.toolbar}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
      {hasExport && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
      )}
      {children}
    </TopToolbar>
  )
}

export default ListActions
