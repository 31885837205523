import React, { useState, useEffect } from 'react'
import { HttpError } from 'react-admin'
import ReactPlayer from 'react-player'
import LinearProgress from '@material-ui/core/LinearProgress'

import useErrorFetch from 'src/hooks/useErrorFetch'

interface ISoundFieldProps {
  value: { id: string; name: string; rawFile?: File } | null
  resource: string
  recordId: string
}

const SoundField = ({
  value,
  resource,
  recordId,
}: ISoundFieldProps): JSX.Element => {
  const fetchError = useErrorFetch()
  const [audio, setAudio] = useState<string>()
  const [fetching, setFetching] = useState(false)

  const { id, rawFile } = value ?? {}

  useEffect(() => {
    if (window.isNullish(id) && window.isNullish(rawFile)) {
      setAudio(undefined)
      return
    }

    const fetchInitialSound = async (): Promise<void> => {
      setFetching(true)
      const url = `${window._env_.API_URL}/${resource}/${recordId}/sound/download`
      const options = {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
        },
        method: 'GET',
      }

      try {
        const response = await fetch(url, options)
        if (!response.ok) {
          const error = await response.json()
          const errorMessage = window.isNullish(error.errorMessage)
            ? 'Failed to fetch audio.'
            : error.errorMessage

          throw new HttpError(errorMessage, response.status)
        }

        const audioBlob = await response.blob()
        const audioUrl = window.URL.createObjectURL(audioBlob)

        setAudio(audioUrl)
      } catch (e) {
        // @ts-expect-error
        await fetchError(e)
      } finally {
        setFetching(false)
      }
    }

    if (!window.isNullish(id)) {
      fetchInitialSound().finally(() => {})
      return
    }

    if (window.isNullish(rawFile)) return

    const blob = new Blob([rawFile], {
      type: rawFile.type,
    })
    const url = window.URL.createObjectURL(blob)
    setAudio(url)
  }, [id, rawFile, fetchError, resource, recordId])

  if (fetching) return <LinearProgress />

  return (
    <ReactPlayer
      url={audio}
      controls
      width='100%'
      height='40px'
      config={{
        file: {
          forceAudio: true,
        },
      }}
    />
  )
}

export default SoundField
