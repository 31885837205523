import { TEntityOverride } from 'src/types'

import EditPassport from './EditPassport'
import PasswordInput from './User/PasswordInput'

const override: TEntityOverride = {
  titleField: 'username',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
      roleIds: {
        referenceTo: 'authentication/api/Role',
        referencedField: 'name',
        required: false,
        defaultValue: [],
        displayedName: 'Roles',
      },
    },
  },
  create: {},
  edit: {
    displayedComponent: EditPassport,
    properties: {
      password: {
        displayedComponent: PasswordInput,
      },
    },
  },
  list: {
    properties: {
      password: {
        hidden: true,
      },
      accessKey: {
        hidden: true,
      },
    },
  },
}

export default override
