import { TEntityOverride } from 'src/types'

import CreateToolbar from './Actions/CreateToolbar'

const defaultManifest = {
  origin: {
    longitude: 0,
    latitude: 0,
    altitude: 0,
  },
  boundingBox: {
    north: 0,
    west: 0,
    south: 0,
    east: 0,
  },
  scale: 0,
  zoomLevel: 1,
}

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      viewerOrigin: {
        hidden: true,
      },
      viewerAltitude: {
        hidden: true,
      },
      fileId: {
        hidden: true,
      },
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    properties: {
      manifest: {
        hidden: true,
      },
    },
  },
  create: {
    displayedFormToolbar: CreateToolbar,
    properties: {
      manifest: {
        defaultValue: defaultManifest,
      },
      fileId: {
        displayedName: 'File',
        type: 'file',
        readOnly: false,
        hidden: false,
      },
    },
  },
}

export default override
