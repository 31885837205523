import React from 'react'
import {
  Typography,
  List,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Box,
} from '@material-ui/core'
import { AnyObject } from 'react-final-form'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import GenericInput from '../GenericInput'

interface IProps {
  value: AnyObject[] | null
  onChange: (newValue: AnyObject[]) => void
  property: any
  name: string
  itemProps?: any
}

const ObjectArrayInput = ({
  property,
  onChange,
  value,
  name,
  itemProps = {},
}: IProps): JSX.Element => {
  const listItems = value ?? []

  return (
    <Box width='100%'>
      <Typography variant='body1'>{property.displayedName ?? name}</Typography>
      <List>
        {listItems.map((item, i) => [
          <ListItem key={i}>
            <GenericInput
              property={property.items}
              onChange={e => {
                const newValue = [
                  ...listItems.slice(0, i),
                  e,
                  ...listItems.slice(i + 1),
                ]
                onChange(newValue)
              }}
              value={item}
              {...itemProps}
            />
            {!window.isNullish(property.readOnly) && property.readOnly && (
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => {
                    onChange([
                      ...listItems.slice(0, i),
                      ...listItems.slice(i + 1),
                    ])
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>,
          <Divider key={`${i}-divider`} />,
        ])}
        <ListItem>
          <IconButton
            edge='end'
            aria-label='add'
            onClick={e => onChange([...listItems, {}])}
          >
            <AddIcon />
          </IconButton>
        </ListItem>
      </List>
    </Box>
  )
}

export default ObjectArrayInput
