import React, { memo } from 'react'
import { CardContent, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const PaginationLimit = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CardContent>
      <Typography variant='body2'>{t('navigation.no_results')}</Typography>
    </CardContent>
  )
}

export default memo(PaginationLimit)
