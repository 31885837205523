import * as constants from './api.constants'
import parser from '../../parser/parser'

export const setAPI = api => ({
  type: constants.SET_API,
  payload: api,
})

export const initAPI = () => {
  const { paths: pathsPromise } = parser(window._env_.API_URL)
  return async dispatch => {
    dispatch(setAPI(await pathsPromise))
  }
}
