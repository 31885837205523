import React, { useEffect } from 'react'
import { Loading, Title } from 'react-admin'
import { Alert, AlertTitle } from '@material-ui/lab'
import { usePDF } from '@react-pdf/renderer'

import AlarmPreview from '../../entities/Alarm/Preview/Preview'
import AgentActivityPreview from '../../entities/AgentActivityReport/ExternalPDF/Preview/Preview'
import CustomTitle from 'src/adminPanel/screens/Title/Title'
import { UserMenuOptions as UMO } from 'src/components/UserMenu'
import DownloadZip from '../../entities/Alarm/UserMenu/DownloadZip'
import api from 'src/utils/api'
import { Typography } from '@material-ui/core'
import { IAlarm, IAgentActivityReport } from '@obvious.tech/constellation'
import {
  AlarmProvider,
  AgentActivityProvider,
} from 'src/parser/shared/PDF/Provider/index'

const ErrorMessage = (message, entity): JSX.Element => {
  return (
    <Alert severity='error'>
      <AlertTitle>{message}</AlertTitle>
      <Typography>{entity.error.message}</Typography>
    </Alert>
  )
}

const PDFViewer = ({
  Preview,
  download = true,
  ...props
}): JSX.Element | null => {
  const [pdf] = usePDF({
    document: (
      <Preview
        {...props}
        mode={'download'}
      />
    ),
  })

  if (pdf.loading || pdf.error !== null || pdf.blob === null) {
    return null
  }

  const src = pdf.url ? `${pdf.url}#toolbar=1` : undefined

  return (
    <>
      {download && (
        <UMO>
          <DownloadZip
            {...{
              ...props,
              pdf,
            }}
          />
        </UMO>
      )}
      <iframe
        src={src}
        title={props.title}
        style={{
          flex: 1,
          height: '100%',
        }}
      />
    </>
  )
}

const getAlarmByID = api.getResourceByID<IAlarm>('alarms/api/Alarm')
const getAgentActivityReportByID = api.getResourceByID<IAgentActivityReport>(
  'units/api/AgentActivityReport',
)

export const AlarmToPDF = ({ id, entity }): JSX.Element => {
  const [alarm, getAlarm] = api.useApiAction(getAlarmByID)

  useEffect(() => {
    return getAlarm({
      id,
    })
  }, [id, getAlarm])

  if (alarm.error !== null) {
    return <ErrorMessage message={'Failed to fetch alarm.'} />
  }

  if (!alarm.loaded) {
    return (
      <Loading
        loadingPrimary='Fetching Alarm...'
        loadingSecondary=''
      />
    )
  }

  return (
    <>
      <Title
        title={
          <CustomTitle
            record={undefined}
            entity={entity}
          />
        }
      />
      <AlarmProvider entity={alarm.data}>
        <PDFViewer Preview={AlarmPreview} />
      </AlarmProvider>
    </>
  )
}

export const AgentActivityToPDF = ({ id, entity }): JSX.Element => {
  const [agentActivityReport, getAgentActivityReport] = api.useApiAction(
    getAgentActivityReportByID,
  )
  const authToken = localStorage.getItem('token')

  useEffect(() => {
    if (typeof authToken !== 'string') return

    return getAgentActivityReport({
      id,
      token: authToken,
    })
  }, [id, authToken, getAgentActivityReport])

  if (agentActivityReport.error !== null) {
    return <ErrorMessage message={'Failed to fetch agentActivityReport.'} />
  }

  if (!agentActivityReport.loaded) {
    return (
      <Loading
        loadingPrimary='Fetching ActivityReport...'
        loadingSecondary=''
      />
    )
  }

  return (
    <>
      <Title
        title={
          <CustomTitle
            record={undefined}
            entity={entity}
          />
        }
      />
      <AgentActivityProvider entity={agentActivityReport.data}>
        <PDFViewer
          download={false}
          Preview={AgentActivityPreview}
        />
      </AgentActivityProvider>
    </>
  )
}
