// @ts-nocheck
import React from 'react'
import { Create, Record, SimpleForm } from 'react-admin'
import { Field } from 'react-final-form'
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Theme,
} from '@material-ui/core'

import CustomTitle from 'src/adminPanel/screens/Title/Title'
import EntityPropertiesList from 'src/components/EntityPropertiesList'
import ConnectedInput from 'src/adminPanel/screens/inputs/ConnectedInput'
import RoleTemplateSelect from './RoleTemplatesSelect'
import CreateRoleToolbar from './CreateRoleToolbar'
import ConditionFormGroup from 'src/components/ConditionFormGroup'
import ReferencedInput from 'src/components/inputs/ReferencedInput'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  groupInput: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}))

export type TValues = {
  roleTemplateCheckbox?: boolean
  roleTemplate?: Record
  group?: string
  name: string
  groups: string[]
  description: string
}

const CreateRole = ({ entity = {}, basePath, resource }) => {
  const classes = useStyles()

  const isRequired = (value: any) => (value ? undefined : 'Required')

  return (
    <Create
      title={
        <CustomTitle
          entity={entity}
          record={null}
        />
      }
      basePath={basePath}
      resource={resource}
    >
      <SimpleForm
        redirect='edit'
        toolbar={<CreateRoleToolbar />}
      >
        {/* @ts-expect-error */}
        <EntityPropertiesList
          entity={entity}
          render={({ propertyKey, property, entity }) => (
            <ConnectedInput
              id={propertyKey}
              key={propertyKey}
              classes={{}}
              resource={resource}
              name={propertyKey}
              property={property}
              required={property.required}
            />
          )}
        />
        <ConditionFormGroup
          when='roleTemplateCheckbox'
          is={true}
          type='checkbox'
          label={
            <Field
              className={classes.input}
              type='checkbox'
              initialValue={false}
              name='roleTemplateCheckbox'
            >
              {({ input }) => (
                <FormControlLabel
                  {...input}
                  label='Create from template'
                  control={<Checkbox color='primary' />}
                />
              )}
            </Field>
          }
        >
          <Field
            name='roleTemplate'
            validate={isRequired}
            component={RoleTemplateSelect}
          />
          <Field
            name='group'
            validate={isRequired}
          >
            {({ input, meta }) => (
              <div className={classes.groupInput}>
                <ReferencedInput
                  {...input}
                  error={meta.error}
                  property={{
                    referenceTo: 'authentication/api/Group',
                    referencedField: 'name',
                    displayedName: 'Group',
                    onChangeValue: 'name',
                  }}
                />
              </div>
            )}
          </Field>
        </ConditionFormGroup>
      </SimpleForm>
    </Create>
  )
}

export default CreateRole
