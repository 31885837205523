import React, { useMemo, useCallback } from 'react'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import { useLoading } from 'react-admin'

import CloseIcon from '@material-ui/icons/Close'
import PermissionButton from './PermissionButton'

interface IPermissionsProps {
  entity: string
  permissionLevels?: {
    role: null | number
    user: null | number
  }
  permissionLevel?: null | number
  onClick: (level: number | null, entity: string) => void
  disabled?: boolean
}

const PERMISSIONS = ['read', 'create', 'edit', 'delete'] as const

const Permissions = ({
  entity,
  permissionLevels,
  permissionLevel,
  onClick,
  disabled,
}: IPermissionsProps): JSX.Element => {
  const isLoading = useLoading()

  const handleClick = useCallback(
    (level: number | null): void => {
      onClick(level, entity)
    },
    [onClick, entity],
  )

  const isPermissionEnabled = useCallback(
    (btnLevel: number, role: string): boolean =>
      permissionLevels?.[role] !== null && permissionLevels?.[role] >= btnLevel,
    [permissionLevels],
  )

  const getMultiplesPermissionsKind = useCallback(
    (level: number): 'full' | 'outlined' | undefined => {
      const isUserPermissionEnabled = isPermissionEnabled(level, 'user')
      if (isUserPermissionEnabled) return 'full'

      const isRolePermissionEnabled = isPermissionEnabled(level, 'role')
      if (isRolePermissionEnabled) return 'outlined'
    },
    [isPermissionEnabled],
  )

  const getPermissionKind = useCallback(
    (level: number): 'full' | undefined => {
      if (permissionLevel === null || permissionLevel === undefined) return

      if (permissionLevel >= level) return 'full'
    },
    [permissionLevel],
  )

  const handlePermissionKind = useCallback(
    (level: number): 'full' | 'outlined' | undefined => {
      return !window.isNullish(permissionLevels)
        ? getMultiplesPermissionsKind(level)
        : getPermissionKind(level)
    },
    [getMultiplesPermissionsKind, getPermissionKind, permissionLevels],
  )

  const noPermission = useMemo(
    () =>
      window.isNullish(permissionLevel) &&
      window.isNullish(permissionLevels?.role) &&
      window.isNullish(permissionLevels?.user),
    [permissionLevel, permissionLevels],
  )

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      <IconButton
        disabled={noPermission || isLoading || disabled}
        onClick={() => handleClick(null)}
      >
        <Tooltip title='Remove permission'>
          <CloseIcon />
        </Tooltip>
      </IconButton>
      {PERMISSIONS.map((_, i) => (
        <PermissionButton
          key={i}
          onClick={handleClick}
          disabled={isLoading || disabled}
          level={i}
          kind={handlePermissionKind(i)}
        />
      ))}
    </Box>
  )
}

export default React.memo(Permissions)
