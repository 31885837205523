import React from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { formatDistanceToNow, isPast } from 'date-fns'
import { useTranslation } from 'react-i18next'

import GetAppIcon from '@material-ui/icons/GetApp'

import { compare } from 'src/utils/str'
import Container from 'src/components/Container'
import { dateFnsLocales } from 'src/i18n/config'

import { IParsedLicense } from './EditLicense'
import HardwareIdField from '../Actions/DownloadLicense'
import LicenseSection, { ISectionRecord } from './LicenseSection'

const useStyles = makeStyles((theme: Theme) => ({
  titleSection: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  contentSection: {
    display: 'grid',
    overflow: 'hidden',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2),
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1fr 1fr',
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
    fontSize: 22,
  },
}))
interface IProps {
  license: IParsedLicense
  resource: string
}

const LicenseFields = ({ license, resource }: IProps): JSX.Element => {
  const classes = useStyles()
  const { i18n, t } = useTranslation()

  const {
    expiration: licenseExp,
    name,
    email,
    signature,
    type,
    id,
    ...restLicense
  } = license

  const getExpirationDate = (): { expiration: string; isExpired: boolean } => {
    const expirationDate = new Date(licenseExp)

    const isExpired = isPast(expirationDate)
    const formattedDate = t('date.short_format', {
      date: expirationDate,
    })
    const distance = formatDistanceToNow(expirationDate, {
      locale: dateFnsLocales[i18n.language],
    })
    const expirationTime: string = isExpired
      ? t('license.file_content.expired')
      : distance
    const formattedExpiration = `${formattedDate} - ${expirationTime}`

    return {
      expiration: formattedExpiration,
      isExpired,
    }
  }

  const getLicenseValues = (
    group: 'licensed' | 'quantities',
  ): ISectionRecord[] => {
    const groupsInvalidValue = {
      licensed: 'no',
      quantities: '0',
    }
    const groupsValidValue = {
      licensed: 'yes',
      quantities: 'unlimited',
    }

    const getType = (key: string): 'valid' | 'invalid' | 'default' => {
      const licenseValue = restLicense[key]
      if (licenseValue === groupsInvalidValue[group]) return 'invalid'
      if (licenseValue === groupsValidValue[group]) return 'valid'

      return 'default'
    }

    const translateValue = (key: string): string => {
      const value = restLicense[key]
      const path = `license.values.${value}` as const
      return t(path, {
        defaultValue: value,
      })
    }

    const translateKey = (key: string): string => {
      // transforms string to the translation file keys format
      const translationKey = key.replace(` ${group}`, '').replace(' ', '_')
      const path = `license.file_content.${group}.${translationKey}`
      return t(path)
    }

    return Object.keys(restLicense)
      .filter(key => key.includes(group))
      .map(key => ({
        key: translateKey(key),
        value: translateValue(key),
        type: getType(key),
      }))
      .sort((a, b) => compare(a.key, b.key))
  }

  const { expiration, isExpired } = getExpirationDate()

  const generalValues: ISectionRecord[] = [
    {
      key: t('license.file_content.expiration'),
      value:
        type !== 'Standard' ? expiration : t('license.file_content.perpetual'),
      type: type !== 'Standard' ? (isExpired ? 'invalid' : 'valid') : 'default',
    },
    {
      key: t('license.file_content.type'),
      value: type,
    },
  ]
  const customerValues: ISectionRecord[] = [
    {
      key: t('license.file_content.name'),
      value: name,
    },
    {
      key: t('license.file_content.email'),
      value: email,
    },
  ]
  const licensedValues = getLicenseValues('licensed')
  const licensedQuantities = getLicenseValues('quantities')

  return (
    <Container
      display='grid'
      gridTemplateColumns='1fr'
      gridTemplateRows='auto 1fr'
    >
      <Box className={classes.titleSection}>
        <Typography variant='h5'>License #{id}</Typography>
        <HardwareIdField
          // @ts-expect-error TOTO: Migrate HardwareIdField to TS
          icon={<GetAppIcon className={classes.downloadIcon} />}
          buttonProps={{
            color: 'primary',
          }}
          resource={resource}
          record={{
            hardwareId: id,
          }}
        />
      </Box>
      <Box className={classes.contentSection}>
        <LicenseSection
          data={generalValues}
          title={t('license.section_title.general')}
        />
        <LicenseSection
          data={customerValues}
          title={t('license.section_title.customer')}
        />
        <LicenseSection
          data={licensedValues}
          title={t('license.section_title.licensed')}
        />
        <LicenseSection
          data={licensedQuantities}
          title={t('license.section_title.quantities')}
        />
      </Box>
    </Container>
  )
}

export default React.memo(LicenseFields)
