import * as React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useTranslate } from 'react-admin'
import {
  makeStyles,
  Typography,
  Breadcrumbs,
  capitalize,
} from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: theme.palette.common.white,
    },
    link: {
      color: 'inherit',
    },
    separator: {
      color: theme.palette.common.white,
    },
  }
})

const CustomTitle = ({
  record,
  entity,
  disableCapitalize = false,
  ...props
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const location = useLocation()
  const forbiddenWords = ['api', 'create']

  const pathnames = location.pathname.split('/')

  const handleCheckLink = ({ path, index }) => {
    const indexBeforeApi = 1
    return {
      last: index === pathnames.length - 1,
      to: `${pathnames.slice(0, index + 1).join('/')}`,
      notExistentRoute:
        forbiddenWords.includes('api') && path && index === indexBeforeApi,
    }
  }

  const getCustomTitle = (notExistentRoute, path) => {
    if (notExistentRoute) return path

    let title = translate(path)

    if (entity.title && record) {
      title = entity?.title(record)
      return title
    }

    if (record) {
      if (entity.titleField && record[entity.titleField]) {
        title = record[entity.titleField]
      } else {
        title = record.id
      }
    }

    return disableCapitalize ? title : capitalize(title)
  }

  if (props.basePath === '') return <div></div>

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<Typography className={classes.separator}>{'>'}</Typography>}
    >
      <Typography
        className={classes.title}
        variant='h6'
      >
        <Link
          className={classes.link}
          to='/dashboard'
        >
          Home
        </Link>
      </Typography>
      {pathnames.map((path, index) => {
        const { last, to, notExistentRoute } = handleCheckLink({
          path,
          index,
        })

        if (!path || forbiddenWords.includes(path)) return null
        return last || notExistentRoute ? (
          <Typography
            variant='h6'
            className={classes.title}
            key={to}
          >
            {getCustomTitle(notExistentRoute, path)}
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant='h6'
            key={to}
          >
            <Link
              className={classes.link}
              to={to}
              key={to}
            >
              {capitalize(path)}
            </Link>
          </Typography>
        )
      })}
    </Breadcrumbs>
  )
}
export default CustomTitle
