function copyPasteSelectedNodes(editor) {
  const nodes = [...editor.selected.list]
  nodes.forEach(node => {
    editor.components
      .get(node.name)
      .createNode()
      .then(newNode => {
        newNode.position = node.position.map(p => p + 20)
        editor.addNode(newNode)
      })
  })
}

function removeConnections(editor, onlySelected) {
  const nodes = onlySelected ? editor.selected.list : editor.nodes
  nodes.forEach(node => {
    const connections = node.getConnections()
    connections.forEach(connection => {
      editor.removeConnection(connection)
    })
  })
}

function deleteSelectedNodes(editor) {
  const selected = [...editor.selected.list]
  const realSelected = selected.filter(node => {
    return editor.nodes.includes(node)
  })

  realSelected.forEach(node => {
    editor.removeNode(node)
  })
}

export { copyPasteSelectedNodes, removeConnections, deleteSelectedNodes }
