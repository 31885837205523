const getTypeKeyFromDefinition = definition => {
  if (definition.enum) {
    return 'enums'
  } else if (
    definition.properties &&
    Object.keys(definition.properties).includes('id')
  ) {
    return 'entities'
  } else {
    return 'objects'
  }
}
export default async (apiPromise, options = {}) => {
  const api = await apiPromise
  const { entities, enums, objects } = Object.keys(api.definitions).reduce(
    (acc, key) => {
      const type = getTypeKeyFromDefinition(api.definitions[key])
      return {
        ...acc,
        [type]: {
          ...acc[type],
          [key]: api.definitions[key],
        },
      }
    },
    {
      enums: {},
      entities: {},
      objects: {},
    },
  )

  const swaggerDefinitions = {
    entities: api.definitions,
    enums,
  }
  return {
    entities: options.entities ? options.entities(entities) : entities,
    enums: options.enums ? options.entities(enums) : enums,
    objects: options.objects ? options.entities(objects) : objects,
    swaggerDefinitions,
  }
}
