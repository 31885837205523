import React, { useState } from 'react'
import StringInput from 'src/components/inputs/StringInput'
import { IInputProps } from 'src/types'

const PasswordInput = ({
  property,
  name,
  onChange,
  value,
  classes,
  ...props
}: IInputProps<string>) => {
  const [input, setInput] = useState('')

  const handleInputChange = (value: string) => {
    setInput(value)
    onChange(value)
  }

  return (
    <StringInput
      classes={classes}
      property={property}
      name='Password'
      onChange={handleInputChange}
      value={input}
      placeholder='Keep existing'
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  )
}

export default PasswordInput
