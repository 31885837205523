import { CircleMarker } from 'react-leaflet'
import React from 'react'

type TProps = {
  latLng: [number, number]
  variant?: 'checkIn' | 'checkOut'
}

const CheckInOutMarker = ({
  latLng,
  variant = 'checkIn',
}: TProps): JSX.Element | null => {
  if (latLng === undefined) return null

  return (
    <CircleMarker
      center={latLng}
      radius={10}
      color={variant === 'checkOut' ? '#F44336' : '#2ECFAD'}
    />
  )
}

export default CheckInOutMarker
