import Rete from '@obvious.tech/rete'
import React from 'react'
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputBase,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { validateTouched } from '../../../helpers/validationHelper'
import compareDiagramChangesHelper from '../../../helpers/CompareDiagramChangesHelper'
import { withThemeProvider } from 'src/utils/hocs'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    border: 'none',
    fontSize: '11pt',
    color: theme.palette.text.primary,
    padding: '3px 26px 3px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    backgroundColor: ' white',
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.49);',
    textAlign: 'center',
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '11pt',
    zIndex: 1,
    '&$focused': {
      color: theme.palette.text.primary,
      top: 0,
    },
    '&$filled': {
      color: 'green',
    },
  },
  focused: {},
  shrink: {
    top: 0,
  },
  selectRoot: {
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}))

const SelectComponent = props => {
  const { label, value, onChange, options } = props
  const classes = useStyles(props)
  return (
    <FormControl fullWidth>
      <InputLabel
        classes={{
          root: classes.root,
          focused: classes.focused,
          shrink: classes.shrink,
        }}
      >
        {label}
      </InputLabel>
      <Select
        classes={{
          select: classes.selectRoot,
        }}
        value={value}
        onChange={onChange}
        input={<BootstrapInput />}
      >
        {options
          .sort((a, b) => a.label > b.label)
          .map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default class SelectControl extends Rete.Control {
  component = withThemeProvider(SelectComponent)

  constructor(
    emitter,
    node,
    key,
    label,
    onChange,
    options,
    defaultValue,
    run = true,
    output,
  ) {
    super(key)
    this.key = key
    this.output = output
    this.emitter = emitter
    const initial = !node.data.switchInputs
      ? node.data[key] || defaultValue || ''
      : defaultValue || ''
    node.data[key] = initial

    if (run && onChange) {
      onChange(initial)
    }

    this.props = {
      label: label || key,
      value: initial,
      options,
      onChange: e => {
        onChange && onChange(e.target.value)
        this.setValue(e.target.value)
        const nodeInput = node.inputs && node.inputs.get(key || label)
        if (nodeInput) {
          let output
          nodeInput.connections.forEach(connection => {
            output = connection.output
            this.emitter.removeConnection(connection)
          })

          if (output) {
            this.emitter.connect(output, nodeInput)
          }
        }
        compareDiagramChangesHelper.compare(this.emitter.toJSON())
        this.emitter.trigger('process')
        if (node.validation) {
          node.validation = {
            ...validateTouched(node),
          }
          node.update()
        }
      },
    }
  }

  setValue(val) {
    this.props.value = val
    this.putData(this.key, val)
    this.update()
  }
}
