import isequal from 'lodash.isequal'

export const updateOpenedBlueprints = (list, updated) => {
  const result = list.slice()
  const toUpdate = list.findIndex(elem => elem.id === updated.id)
  if (toUpdate === -1) return result
  result[toUpdate] = {
    ...result[toUpdate],
    ...updated,
    updatedLocally: true,
  }

  return result
}

export const replaceOpenedBlueprints = (list, updated) => {
  const result = list.slice()
  const toUpdate = list.findIndex(elem => elem.id === updated.id)
  if (toUpdate === -1) return result
  result[toUpdate] = {
    ...updated,
    type: result[toUpdate].type,
  }

  return result
}

export const removeOpenedFromList = (list, id) => {
  return list.filter(item => item.id !== id)
}

export const areBlueprintsEqual = (bp1, bp2) => {
  const copyBp1 = {
    ...bp1,
    nodes: bp1.nodes ? bp1.nodes : {},
  }
  const copyBp2 = {
    ...bp2,
    nodes: bp2.nodes ? bp2.nodes : {},
  }
  const preparedBp1 = JSON.stringify(copyBp1)
  const preparedBp2 = JSON.stringify(copyBp2)

  return isequal(preparedBp1, preparedBp2)
}
