// @ts-nocheck
import React from 'react'
import { Create, Record } from 'react-admin'
import { INotificationType } from '@obvious.tech/constellation'

import InputList from 'src/adminPanel/screens/inputs/InputList'
import CustomTitle from 'src/adminPanel/screens/Title/Title'
import { TEntityOverrideView } from 'src/types'

interface ICreateNotificationTypeProps {
  entity: TEntityOverrideView<'create', INotificationType>
  name: string
  basePath: string
  resource: string
}

export const CreateNotificationType = ({
  entity = {},
  name,
  ...props
}: ICreateNotificationTypeProps): JSX.Element => {
  const { basePath, resource } = props

  const transform = (record: Record): Record => {
    return {
      ...record,
      groups: record.groups?.length > 0 ? record.groups : null,
    }
  }

  return (
    <Create
      title={
        <CustomTitle
          record={null}
          entity={entity}
          {...props}
        />
      }
      transform={transform}
      basePath={basePath}
      resource={resource}
    >
      <InputList
        entity={entity}
        {...props}
      />
    </Create>
  )
}

export default CreateNotificationType
