import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import { getControl } from '../../../../helpers/ControlHelper'
import { isRequired } from '../../../../helpers/validationHelper'
import { getSocketFromType } from '../../Sockets/utils'

export default class ComparisonComponent extends Rete.Component {
  constructor(name, types) {
    super(name)
    this.types = types
    this.contextMenuPath = ['Comparisons', types[0]]
    this.task = {
      outputs: {
        bool: 'output',
      },
    }
    this.validation = {
      errorRules: this.types.reduce(
        (acc, input, i) => ({
          ...acc,
          [`input${i}`]: [isRequired()],
        }),
        {},
      ),
    }
  }

  builder(node) {
    this.types.forEach((type, i) => {
      const inp = new Rete.Input(
        'input' + i,
        type,
        getSocketFromType(type, undefined),
      )
      inp.addControl(getControl(this.editor, node, type, 'input' + i, type))
      node.addInput(inp)
    })
    const out1 = new Rete.Output('bool', 'Boolean', sockets.booleanSocket)

    return node.addOutput(out1)
  }

  worker(node, inputs, outputs) {}
}
