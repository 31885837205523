import { IconButton, makeStyles, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { LeafletMouseEvent } from 'leaflet'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { IInputProps } from 'src/types'
import Map from '../Map/Map'
import Marker from '../Map/Marker'
import CustomObjectInput from './ObjectInput'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  coordinateInputs: {
    margin: 8,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  fullfill: {},
})

const CoordinatesInput: FunctionComponent<IInputProps<any>> = ({
  value,
  onChange,
  name,
  property,
  record,
  objects,
  classes,
  ...rest
}) => {
  const ownClasses = useStyles()

  const { latitude, longitude, altitude } = value || {}

  const handleCoordinateSelection = (e: LeafletMouseEvent) => {
    const { lat, lng } = e.latlng
    onChange({
      latitude: lat,
      longitude: lng,
      altitude,
    })
  }
  return (
    <div className={classes}>
      <Typography variant='body1'>{property.displayedName ?? name}</Typography>
      <div className={ownClasses.flex}>
        <div
          style={{
            height: 300,
            width: 500,
          }}
        >
          <Map
            center={{
              lat: latitude || 0,
              lng: longitude || 0,
            }}
            // length={4}
            onclick={handleCoordinateSelection}
            zoom={14}
            style={{
              height: '300px',
              width: '500px',
            }}
          >
            {latitude && longitude ? <Marker coordinates={value} /> : null}
          </Map>
        </div>
        <div className={ownClasses.coordinateInputs}>
          <div className={ownClasses.fullfill}>
            <IconButton
              aria-label='clear'
              color='primary'
              onClick={() => {
                onChange(record?.[name])
              }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <CustomObjectInput
            value={value}
            onChange={onChange}
            property={objects.Coordinate}
            name='coordinates'
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    objects: state.definitions.objects || {},
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatesInput)
