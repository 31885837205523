import React from 'react'
import { Document, Page } from '@react-pdf/renderer'

import Attachments from './Attachments'
import Sections from 'src/parser/shared/PDF/Sections'
import Logs from './sections/Logs'
import UserTasks from './sections/UserTasks'
import Description from './sections/Description'
import Location from './sections/Location'
import Medias from './sections/Medias'
import { TAlarmReportProps } from 'src/parser/shared/PDF/Provider'
import AlarmTitle from 'src/parser/shared/PDF/Title/AlarmTitle'
import { AlarmHeader } from 'src/parser/shared/PDF/Header/CommonHeader'
import { PageFooter, styles } from 'src/parser/shared/PDF/PageFooter'

const sections = [
  {
    title: 'alarm_report.description.title',
    Component: Description,
  },
  {
    title: 'alarm_report.location.title',
    Component: Location,
  },
  {
    title: 'alarm_report.medias.title',
    Component: Medias,
  },
  {
    title: 'alarm_report.logs.title',
    Component: Logs,
  },
  {
    title: 'alarm_report.user_tasks.title',
    Component: UserTasks,
  },
]

export default (props: TAlarmReportProps): JSX.Element => {
  return (
    <Document>
      <Page
        style={styles.page}
        wrap
      >
        <AlarmTitle {...props} />
        <AlarmHeader {...props} />
        <Sections
          // @ts-expect-error
          sectionProps={props}
          sections={sections}
        />
        <PageFooter />
      </Page>
      {(props.attachments.length > 0 ||
        (props.additionalDetails.videoUrls?.length ?? 0) > 0 ||
        (props.additionalDetails.imageUrls?.length ?? 0) > 0 ||
        (props.additionalDetails.audioUrls?.length ?? 0) > 0) && (
        <Page
          style={styles.page}
          wrap
        >
          <AlarmTitle {...props} />
          <Attachments {...props} />
          <PageFooter />
        </Page>
      )}
    </Document>
  )
}
