// @ts-nocheck
import React, { useEffect } from 'react'
import { LinearProgress } from '@material-ui/core'
import { Record, useGetList } from 'react-admin'

import { API_ENDPOINTS } from 'src/api'
import ReferencedInput from 'src/components/inputs/ReferencedInput'
import useErrorFetch from 'src/hooks/useErrorFetch'

const REFERENCE = {
  referencedField: 'firstname',
  referenceTo: API_ENDPOINTS.AGENT,
}

const AgentEntityIdInput = ({
  name,
  onChange,
  value,
  record,
  property,
  ...rest
}) => {
  const fetchError = useErrorFetch()

  const { data, loading, error } = useGetList(API_ENDPOINTS.PASSPORT, {})
  const passports = data ? Object.values(data) : []

  useEffect(() => {
    if (!error) return

    fetchError(error)
  }, [error, fetchError])

  const getOptionLabel = ({
    firstname,
    lastname,
    passportId,
  }: Record): string => {
    const username = getAgentPassportUsername(passportId)
    if (!username) return lastname ? `${lastname} ${firstname}` : firstname

    return `${username} ( ${lastname ?? ''} ${firstname} )`
  }

  const getAgentPassportUsername = (passportId: string | null) => {
    const passport = passports.find(({ id }) => id === passportId)
    return passport?.username
  }

  if (loading) return <LinearProgress />

  return (
    <ReferencedInput
      name={name}
      {...rest}
      onChange={onChange}
      value={value}
      record={record}
      property={{
        ...property,
        ...REFERENCE,
      }}
      getOptionLabel={getOptionLabel}
    />
  )
}

export default AgentEntityIdInput
