import Rete from '@obvious.tech/rete'

import { sockets, definitions } from '../..'
import NodeTypizer from '../../Components/NodeTypizer'
import DefaultNode from '../../Components/CustomNode'
import OutputWithDecompose from '../../Outputs/OutputWithDecompose'
import createEventName from '../../../../helpers/prepareEventName'
import { getSocketFromType } from '../../Sockets/utils'
import CheckboxesModalControl from '../../Controls/Modal/CheckboxesModal'
import SelectControl from '../../Controls/SelectControl'
import {
  getPossibleComparisons,
  comparisonFunctions,
} from '../../../../helpers/ComparisonHelper'

const UpdatedEvent = entity => ({
  creatorId: sockets.stringSocket,
  traceId: sockets.stringSocket,
  entity: sockets[`${entity}Socket`],
  entityId: sockets.stringSocket,
  id: sockets.stringSocket,
})

const CreatedEvent = entity => ({
  creatorId: sockets.stringSocket,
  traceId: sockets.stringSocket,
  entity: sockets[`${entity}Socket`],
  id: sockets.stringSocket,
})

const DeletedEvent = entity => ({
  creatorId: sockets.stringSocket,
  traceId: sockets.stringSocket,
  entity: sockets[`${entity}Socket`],
  id: sockets.stringSocket,
  reason: sockets.stringSocket,
})

const addOutputToNode = (node, eventEntity, editor) => {
  const makeOutput = output => {
    if (output !== 'entity') {
      return new Rete.Output(output, output, eventEntity[output])
    }
    return new OutputWithDecompose(output, output, eventEntity[output], editor)
  }
  Object.keys(eventEntity).forEach(output => {
    node.addOutput(makeOutput(output))
  })
}

export default class ConstellationEntityEventComponent extends Rete.Component {
  constructor(options) {
    const { microservice, entity, event, type } = options
    const name = createEventName(microservice, entity, event, type)
    super(name)
    this.data.component = NodeTypizer(DefaultNode, {
      type: 'trigger',
    })
    this.currentTask = null
    this.entity = entity
    this.event = event
    let outputs
    switch (event) {
      case 'handleUpdateEntity':
        outputs = Object.keys(UpdatedEvent(entity)).reduce(
          (acc, output) => ({
            ...acc,
            [output]: 'output',
          }),
          {
            act: 'option',
          },
        )
        break
      case 'handleCreateEntity':
        outputs = Object.keys(CreatedEvent(entity)).reduce(
          (acc, output) => ({
            ...acc,
            [output]: 'output',
          }),
          {
            act: 'option',
          },
        )
        break
      case 'handleDeleteEntity':
        outputs = Object.keys(DeletedEvent(entity)).reduce(
          (acc, output) => ({
            ...acc,
            [output]: 'output',
          }),
          {
            act: 'option',
          },
        )
        break
      default:
        outputs = {
          act: 'option',
        }
    }
    this.task = {
      outputs,
      init: task => {
        this.currentTask = task
      },
    }
    this.contextMenuPath = ['Triggers', 'Constellation', microservice, entity]
  }

  getPropertiesforEntity = (node, entity = null) => {
    const { properties = [] } = definitions.entities[entity || node.data.entity]
    return properties.map(p => {
      return {
        key: p.name,
        name: p.name,
      }
    })
  }

  createAndAddInput = (node, entity, name) => {
    const { properties = [] } = definitions.entities[entity || node.data.entity]
    const property = properties.find(p => p.name === name)

    const filters = getPossibleComparisons(property.type, property.entity).map(
      comparison => ({
        value: comparison,
        label: comparison,
      }),
    )

    const { args: comparisonFunctionArguments } = comparisonFunctions.equals(
      property.type,
      property.entity,
    )
    const inputSocket = comparisonFunctionArguments.length
      ? comparisonFunctionArguments.length > 1
        ? sockets[`${property.entity}CallbackSocket`]
        : getSocketFromType(property.type, property.entity, property.format)
      : sockets.anyTypeSocket
    const inp = new Rete.Input(property.name, property.name, inputSocket)
    inp.addControl(
      new SelectControl(
        this.editor,
        node,
        property.name,
        property.name,
        newComparisonKey => {
          // node.data[property.name] = newComparisonKey;
          const { args } = comparisonFunctions[newComparisonKey](
            property.type,
            property.entity,
          )
          inp.socket =
            sockets[
              args.length > 1
                ? `${property.entity || property.type}CallbackSocket`
                : args.length
                ? `${args[0]}Socket`
                : 'noTypeSocket'
            ]
          node.update()

          setTimeout(() => {
            this.editor.view.updateConnections({
              node,
            })
          }, 1)
        },
        filters,
        filters[0].value,
      ),
    )
    node.addInput(inp)
  }

  handleInput =
    (editor, node, entity) =>
    ({ name, input }, checked) => {
      if (checked && !node.inputs.get(name)) {
        this.createAndAddInput(node, entity, name)
      } else if (!checked && node.inputs.get(name)) {
        node.inputs
          .get(name)
          .connections.map(connection => editor.removeConnection(connection))
        node.removeInput(node.inputs.get(name))
        if (node.data[name]) {
          delete node.data[name]
        }
      }
      node.update()
      setTimeout(() => {
        editor.view.updateConnections({
          node,
        })
      }, 1)
    }

  building(node, entity) {
    node.controls.forEach(c => {
      node.removeControl(c)
    })
    this.checkboxes = this.getPropertiesforEntity(node, entity)
    node.addControl(
      new CheckboxesModalControl(
        this.editor,
        node,
        'inputs',
        this.handleInput(this.editor, node, entity),
        this.checkboxes,
        'Conditions',
      ),
    )
    setTimeout(() => {
      node && node.update()
      this.editor.view.updateConnections({
        node,
      })
    }, 1)
  }

  builder(node) {
    node.data = {
      inputs: {},
      ...node.data,
    }
    node.addOutput(new Rete.Output('act', 'Then', sockets.actionSocket))
    switch (this.event) {
      case 'handleUpdateEntity':
        addOutputToNode(node, UpdatedEvent(this.entity), this.editor)
        break
      case 'handleCreateEntity':
        addOutputToNode(node, CreatedEvent(this.entity), this.editor)
        break
      case 'handleDeleteEntity':
        addOutputToNode(node, DeletedEvent(this.entity), this.editor)
        break
      default:
        break
    }
    this.building(node, this.entity)
    if (node.data.inputs) {
      Object.keys(node.data.inputs)
        .filter(key => node.data.inputs[key])
        .forEach(key => {
          this.createAndAddInput(node, this.entity, key)
        })
    }
  }

  worker(node, inputs, outputs) {}
}
