import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { TAlarmReportProps } from 'src/parser/shared/PDF/Provider'

import * as theme from 'src/parser/shared/PDF/theme'
import NoContent from 'src/parser/shared/PDF/NoContent'
import { TSectionContent } from 'src/parser/shared/PDF/Sections'

import {
  commonStyles,
  createDirectionalStyles,
} from 'src/parser/shared/PDF/Title/CommonUtils'

const Medias: TSectionContent = alarm => {
  const { cameras } = alarm as TAlarmReportProps
  const { t, i18n } = useTranslation()
  const directionalStyles = createDirectionalStyles(commonStyles, i18n.language)

  return (
    <>
      <View style={directionalStyles.container}>
        <Text style={[theme.styles.subtitle, directionalStyles.text]}>
          {`${t('alarm_report.medias.cameras')} : `}
        </Text>
        {cameras.length ? (
          <Text style={theme.styles.textSecondary}>
            {cameras.map(e => e.name ?? e.id).join(', ')}
          </Text>
        ) : (
          <NoContent />
        )}
      </View>
      {
        // TODO: Check what needs to be implemented here
        /*
      <Text>
        <Text style={theme.styles.subtitle}>
          {`${t('alarm_report.medias.screenshots')} : `}
        </Text>
        <NoContent />
      </Text>
      */
      }
    </>
  )
}

export default Medias
