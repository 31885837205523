import Rete from '@obvious.tech/rete'
import { sockets } from '../..'
import StringControl from '../../Controls/SocketControls/StringControl'
export default class StringComponent extends Rete.Component {
  constructor() {
    super('String')
    this.task = {
      outputs: {
        str: 'output',
      },
    }
    this.contextMenuPath = ['Inputs']
  }

  builder(node) {
    const out1 = new Rete.Output('str', 'String', sockets.stringSocket)
    const ctrl = new StringControl(this.editor, node, 'str')

    return node.addControl(ctrl).addOutput(out1)
  }

  worker(node) {}
}
