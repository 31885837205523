import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import BooleanControl from '../Controls/SocketControls/BooleanControl'
import NumberControl from '../Controls/SocketControls/NumberControl'
import StringControl from '../Controls/SocketControls/StringControl'
import Control from 'src/components/flow/Control'
import Socket from '../Sockets/Socket'

const useStyles = makeStyles(() => ({
  socketControl: {
    width: 'calc(100% - 16px)',
    padding: '4px 2px',
  },
}))

const InputSocketAndControllerComponent = ({
  bindSocket,
  bindControl,
  input,
  error,
  warning,
}) => {
  const classes = useStyles()

  const shouldRenderInput = () => {
    const control = input.control

    if (!control) return false
    const inputInstances = [BooleanControl, NumberControl, StringControl]
    const inputInstance = inputInstances.find(
      instance => control instanceof instance,
    )

    if (inputInstance && input.connections.length) return false

    return true
  }

  const handleRenderControl = () => {
    if (!shouldRenderInput()) {
      return <div className='input-title'> {input.name} </div>
    }

    return (
      <Control
        className={classes.socketControl}
        bindControl={bindControl}
        control={input.control}
      />
    )
  }

  return (
    <>
      <Socket
        type='input'
        error={error}
        alignSelf={shouldRenderInput() ? 'flex-end' : 'center'}
        warning={warning}
        socket={input.socket}
        io={input}
        innerRef={bindSocket}
      />
      {handleRenderControl()}
    </>
  )
}

export default InputSocketAndControllerComponent
