// @ts-nocheck
import * as React from 'react'
import { Route } from 'react-router-dom'
import { RouteWithoutLayout } from 'react-admin'

import Dashboard from 'src/adminPanel/screens/Custom/DashBoard'
import Applications from 'src/adminPanel/screens/Custom/Applications'
import GroupList from 'src/adminPanel/screens/Custom/Group/GroupList'
import { withAuthentication } from 'src/utils/hocs'
import { ExternalAlarmPDF } from 'src/parser/shared/PDF/ExternalPDF/ExternalAlarmPDF'
import { ExternalAgentActivityReportPDF } from 'src/parser/shared/PDF/ExternalPDF/ExternalAgentActivityPDF'
import AgentActivityReport from 'src/parser/entities/AgentActivityReport/AgentActivity'

export default [
  <Route
    exact
    path='/dashboard'
    component={withAuthentication(Dashboard)}
    key='dashboard_route'
  />,
  <Route
    exact
    path='/apps'
    component={withAuthentication(Applications)}
    key='apps_route'
  />,
  <Route
    exact
    path='/groups'
    component={withAuthentication(GroupList)}
    key='groups_route'
  />,
  <Route
    exact
    path='/authentication/api/Passport/:passportId/AgentActivityReport'
    component={withAuthentication(AgentActivityReport)}
    key='agentActivityReport_route'
  />,
  <RouteWithoutLayout
    exact
    noLayout
    path='/pdf/alarm/:id'
    component={ExternalAlarmPDF}
    key='alarm_pdf_route'
  />,
  <RouteWithoutLayout
    exact
    noLayout
    path='/pdf/agentActivityReport/:id'
    component={ExternalAgentActivityReportPDF}
    key='agentActivityReport_pdf_route'
  />,
]
