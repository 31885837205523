import { Chip } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import React from 'react'

import { TEntityOverrideProp } from 'src/types'

interface IProps {
  classes: string
  onChange: (...args: any[]) => void
  property: TEntityOverrideProp
  name: string
  value: string[]
}

const BasicArrayInput = ({
  classes,
  property,
  name,
  onChange,
  value,
}: IProps): JSX.Element => {
  return (
    <ChipInput
      disabled={property.readOnly}
      value={value ?? property.defaultValue}
      onChange={chips => onChange(chips)}
      onDelete={chip => {
        onChange(value.filter(item => item !== chip))
      }}
      chipRenderer={(
        { value, isDisabled, isReadOnly, handleClick, handleDelete, className },
        key,
      ) => (
        <Chip
          key={key}
          className={className}
          style={{
            pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
          }}
          onClick={handleClick}
          onDelete={isDisabled || isReadOnly ? undefined : handleDelete}
          label={value}
        />
      )}
      variant='outlined'
      label={property.displayedName ?? name}
      placeholder={name}
      classes={{
        root: classes,
      }}
    />
  )
}

export default BasicArrayInput
