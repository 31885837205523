import React, { useState } from 'react'
import { Datagrid } from 'react-admin'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import get from 'lodash/get'

import SelectionDialog from './SelectionDialog'
import LocalStorage from './LocalStorage'
import { DataGridContainer } from 'src/components/DataGrid'

const arrayToSelection = (values: string[]) => {
  return values.reduce((selection: Record<string, boolean>, columnName) => {
    selection[columnName] = true
    return selection
  }, {})
}

const getInitialSelection = ({
  defaultColumns,
  resource,
  storage,
  children,
}) => {
  const previousSelection = storage.get(resource)

  // if we have a previously stored value, let's return it
  if (!isEmpty(previousSelection)) {
    return previousSelection
  }

  // if defaultColumns are set let's return them
  if (defaultColumns.length) {
    return arrayToSelection(defaultColumns)
  }

  // otherwise we fallback on the default behaviour : display all columns
  const columnNames = filter(
    React.Children.map(children, field => get(field, ['props', 'source'])),
  )
  return arrayToSelection(columnNames)
}

const getColumnLabels = ({ children }) => {
  return filter(
    React.Children.map(
      children,
      field =>
        field && {
          source: get(field, ['props', 'source']),
          label: get(field, ['props', 'label']),
        },
    ),
    item => item?.source,
  )
}

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage

export type TProps = {
  resource: string
  storage: typeof LocalStorage
  closeModal: () => void
  children: React.ReactElement
  open: boolean
  defaultColumns: any[]
  hasEdit?: boolean
}

const CustomizableDatagrid = (props: TProps) => {
  props = Object.assign({}, props, {
    storage: props.storage ?? LocalStorage,
    defaultColumns: props.defaultColumns ?? [],
  })

  const { closeModal, defaultColumns, hasEdit = true, ...dataGridProps } = props

  const [selection, setSelection] = useState<Record<string, boolean>>(
    getInitialSelection(props),
  )

  const toggleColumn = (columnName: string) => {
    setSelection(prevSelection => {
      const newSelection = {
        ...prevSelection,
        [columnName]: !prevSelection[columnName],
      }

      props.storage.set(props.resource, newSelection)
      return newSelection
    })
  }

  const renderChild = (child: React.ReactElement) => {
    const source = get(child, ['props', 'source'])

    // Show children without source, or children explicitly visible

    const dirtySortable: unknown = child.props.sortable
    const sortable =
      typeof dirtySortable === 'boolean' ? dirtySortable : undefined

    if (!source || selection?.[source]) {
      return React.cloneElement(child, {
        sortable,
      })
    }

    return null
  }

  return (
    <>
      {props.open && (
        <SelectionDialog
          selection={selection}
          columns={getColumnLabels(props)}
          onColumnClicked={toggleColumn}
          onClose={props.closeModal}
        />
      )}
      <DataGridContainer hasEdit={hasEdit}>
        <Datagrid {...dataGridProps}>
          {React.Children.map(props.children, renderChild)}
        </Datagrid>
      </DataGridContainer>
    </>
  )
}

export default CustomizableDatagrid
