import React from 'react'
import { useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { format } from 'date-fns'

import { makeStyles } from '@material-ui/core/styles'
import { withStyles, capitalize } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { default as MuiTableCell } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import flowClasses from './theme/classes'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    width: 250,
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
    width: 'auto',
  },
  {
    id: 'edited',
    numeric: true,
    disablePadding: false,
    label: 'Edited',
    width: 'auto',
  },
  {
    id: 'editedBy',
    numeric: true,
    disablePadding: false,
    label: 'Edited by',
    width: 'auto',
  },
]

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: 'none',
    '&.diagramName': {},
    '&.withBorder': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  stickyHeader: {
    backgroundColor: 'white',
  },
  sizeSmall: {
    '&:last-child': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))(MuiTableCell)

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding='none'
          className={classes.cellPad}
        ></TableCell>
        <TableCell
          padding='none'
          className='withBorder'
        ></TableCell>
        {headCells.map(headCell => (
          <TableCell
            className='withBorder'
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: headCell.width,
            }}
          >
            <TableSortLabel
              classes={{
                root: classes.flexDirectionDefault,
              }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={classes.cellPad}></TableCell>
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'inherit',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 550,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cellLeftPadding: {
    paddingLeft: theme.spacing(2),
  },
  cellRightPadding: {
    paddingRight: theme.spacing(2),
  },
  circle: {
    width: '17px',
    height: '17px',
    borderRadius: '50%',
    backgroundColor: '#fa6c51',
    display: 'inline-block',
    margin: '0 10px 0 0 ',
    '&.diagram': {
      backgroundColor: '#fa6c51',
    },
    '&.module': {
      backgroundColor: '#fdcd56',
    },
  },

  rowHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#cad0d7 !important',
    },
  },
  flexDirectionDefault: {
    flexDirection: 'row',
  },
  container: {
    height: 'inherit',
    width: '100%',
    maxHeight: 'inherit',
  },
  thumbVertical: flowClasses.thumbVertical,
  trackVertical: flowClasses.trackVertical,
  cellPad: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  blueprintType: {
    textTransform: 'capitalize',
  },
}))

export default function EnhancedTable(props) {
  const history = useHistory()
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event, type, id) => {
    history.push(`/flow/api/${capitalize(type)}/${id}`)
    props.onClose()
  }

  const getType = type => {
    if (type === 'module') return 'module'
    return 'diagram'
  }

  return (
    <div className={classes.root}>
      <Scrollbars
        className={classes.container}
        renderThumbVertical={props => (
          <div
            {...props}
            style={{
              with: '10px',
            }}
            className={classes.thumbVertical}
          />
        )}
        renderTrackVertical={props => (
          <div
            {...props}
            style={{
              with: '60%',
            }}
            className={classes.trackVertical}
          />
        )}
      >
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size='small'
          stickyHeader
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.bps.length}
          />
          <TableBody>
            {stableSort(props.bps, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`
                const formattedDateUpdated = format(
                  new Date(row.lastUpdate),
                  'MM/dd/yyyy',
                )

                return (
                  <TableRow
                    hover
                    classes={{
                      hover: classes.rowHover,
                    }}
                    onClick={event => handleClick(event, row.type, row.id)}
                    role='checkbox'
                    tabIndex={-1}
                    key={`${row.name}_${index}`}
                  >
                    <TableCell className={classes.cellPad}></TableCell>
                    <TableCell
                      padding='none'
                      align='left'
                    >
                      <div
                        className={`${classes.circle} ${getType(row.type)}`}
                      ></div>
                    </TableCell>
                    <TableCell
                      padding='none'
                      component='th'
                      id={labelId}
                      scope='row'
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align='left'
                      className={classes.blueprintType}
                    >
                      {getType(row.type)}
                    </TableCell>
                    <TableCell align='left'>{formattedDateUpdated}</TableCell>
                    <TableCell
                      align='left'
                      className={classes.cellRightPadding}
                    >
                      {row.createdBy}
                    </TableCell>
                    <TableCell className={classes.cellPad}></TableCell>
                  </TableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      </Scrollbars>
    </div>
  )
}
