import React from 'react'
import { Create } from 'react-admin'

import InputList from '../../../../adminPanel/screens/inputs/InputList'
// TODO: remove later
import packageJson from '../../../../../../../package.json'
import CustomTitle from '../../../../adminPanel/screens/Title/Title'

export const CustomCreate = ({ entity = {}, name, ...props }) => {
  const { basePath, resource } = props

  const transform = record => ({
    ...record,
    data: {
      id: `${packageJson.reteApplicationName}@${packageJson.version}`,
      nodes: {},
    },
  })

  return (
    <Create
      title={
        <CustomTitle
          entity={entity}
          {...props}
        />
      }
      transform={transform}
      redirect={entity.redirect === undefined ? 'list' : entity.redirect}
      basePath={basePath}
      resource={resource}
    >
      <InputList
        entity={entity}
        {...props}
      />
    </Create>
  )
}

export default CustomCreate
