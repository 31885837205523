import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const FormDialog = ({
  title,
  description,
  inputLabel,
  onConfirm,
  open,
  onClose,
  onInputChange,
  value,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label={inputLabel}
          value={value}
          onChange={e => {
            onInputChange && onInputChange(e.target.value)
          }}
          type='text'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={e => {
            onConfirm(value)
          }}
          color='primary'
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormDialog
