import React from 'react'

import BooleanField from './BooleanField'
import NumberField from './NumberField'
import StringField from './StringField'
import CoordinatesField from './CoordinateField'
import ReferenceArrayField from './ReferenceArrayField'
import ReferenceField from './ReferenceField'
import ImageField from './ImageField'
import ArrayField from './ArrayField'
import ColorField from './ColorField'
import CustomDateField from './DateField'
import { DiagramStatusField } from './editableFields'
import GroupsField from './GroupsField'

export default {
  reference: {
    default: ReferenceField,
    array: ReferenceArrayField,
  },
  array: ArrayField,
  color: ColorField,
  string: StringField,
  number: NumberField,
  boolean: BooleanField,
  Image: ImageField,
  Coordinates: CoordinatesField,
  'date-time': CustomDateField,
  groups: GroupsField,
  DiagramStatus: DiagramStatusField,
  undefined: () => <span></span>,
} as const
