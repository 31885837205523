import { Action } from 'redux'

export type TState = {
  [microserviceName: string]: any
}

export const actionTypes = {
  SET_MICROSERVICES: 'parser/api/setMicroservices',
} as const

export interface TSetMicroservicesAction
  extends Action<typeof actionTypes.SET_MICROSERVICES> {
  payload: Partial<TState>
}

export type TAction = TSetMicroservicesAction
